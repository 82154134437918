import {
    required,
    maxLength,
} from 'react-admin';

export const validations = {
    validateSourceStep: [required()],
    validateSourceStepOutput: [required(), maxLength(50)],
    validateNextStep: [required()],
};

export const validateWholeRecord = (values) => {
    const errors = {};
    if (!values.next_step && !values.next_step_sub_process) {
        const errorMsg = 'Next Step or Next Sub-process is required';
        errors.next_step = [errorMsg];
        errors.next_step_sub_process = [errorMsg];
    }
    return errors
};