import React, {useEffect, useState} from 'react';
import {TextInput, SimpleForm, SaveButton, Toolbar, BooleanInput, FormDataConsumer} from 'react-admin';
import { Api } from "../../../helpers/api";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  useNotify,
  useRedirect,
  useRefresh,
} from 'ra-core';

const EntitiesMerger = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [art_id_1, setArt_id_1] = useState("");
  const [art_id_1_Description, setArt_id_1_Description] = useState("");
  const [art_id_2, setArt_id_2] = useState("");
  const [art_id_2_Description, setArt_id_2_Description] = useState("");
  const [entity_id_1, setEntity_id_1] = useState("");
  const [entity_id_1_Description, setEntity_id_1_Description] = useState("");
  const [entity_id_2, setEntity_id_2] = useState("");
  const [entity_id_2_Description, setEntity_id_2_Description] = useState("");
  const [loading, setLoading] = useState(false);

  const [use_art_id, setEntity_id_Art_id_panel] = useState("");
  let description = "";

  const formStyle = {
    backgroundColor: '#fafafa', // Set your desired background color here
    padding: '20px', // Add any other styling properties as needed
  };

  // ARTID
  useEffect(() => {
    if (art_id_1.length === 12){
      getArtIdDescription(art_id_1, "artId1");
    }
    else{
      setArt_id_1_Description("");
    }
    if (art_id_2.length === 12){
      getArtIdDescription(art_id_2, "artId2");
    }
    else {
      setArt_id_2_Description("");
    }
  }, [art_id_1, art_id_2]);


  // EntityID
  useEffect(() => {
    if (/^\d+$/.test(entity_id_1)) {
      getEntityIdDescription(entity_id_1, "entityId1");
    }
    else{
      setEntity_id_1_Description("");
    }

    if (/^\d+$/.test(entity_id_2)) { //TODO
      getEntityIdDescription(entity_id_2, "entityId2");
    }
    else {
      setEntity_id_2_Description("");
    }
  }, [entity_id_1, entity_id_2]);


  const cleantext = (e) => {
    setEntity_id_1("");
    setEntity_id_2("");
    setArt_id_1("");
    setArt_id_2("");
    setArt_id_1_Description("");
    setArt_id_2_Description("");
  }

  const MapToolbar = props => {
    return (
      <Toolbar {...props}>
          {loading ?           
          (<CircularProgress />)
          : (
          <SaveButton
            disabled={(art_id_1_Description === "" || art_id_2_Description === "") && (entity_id_1_Description === "" || entity_id_2_Description === "")}
            label="Merge"
            onClick={e => setLoading(true)}
          />
          )}
      </Toolbar>
    );
  };

  const doTheMerge = (data, props, notify, redirect, refresh) => {
    console.log(data)
    return Api.post('/entities-merge/merge/', data, true)
      .then(response => {
        if (response.type === 'success'){
          notify(response.result.message, 'success');        
          setLoading(false);
          redirect(`/entities?filter=${JSON.stringify({art_id:response.result.surviving})}`);
        }
        else {
            console.log("Error doing the merge! ", response);
            notify(response.message, 'error');    
            setLoading(false);      
        }
      })
      .catch((e) => {
        console.log("Exception starting the merge - e", e);
        setLoading(false);
      }); 
      
  }

  // ArtID Description
  const getArtIdDescription = (artIdValue, artIdNumber) => {
    description = Api.get(
      `/entities-merge/get-art-id-description?art_id=${artIdValue}`,
      true
    ).then((response) => {
      if (response.type === "success") {
        let {description} = response.result;
        if (artIdNumber === "artId1") {
          setArt_id_1_Description(description);
        }
        else {
          setArt_id_2_Description(description);
        }
      }
    });
    return description;
  }

  // EntityID Description
  const getEntityIdDescription = (entityIdValue, entityIdNumber) => {
    description = Api.get(
      `/entities-merge/get-entity-id-description?entity_id=${entityIdValue}`,
      true
    ).then((response) => {
      if (response.type === "success") {
        let {description} = response.result;
        if (entityIdNumber === "entityId1") {
          setEntity_id_1_Description(description);
        }
        else {
          setEntity_id_2_Description(description);
        }
      }
    });
    return description;
  }


  return (
    <SimpleForm
      style={formStyle}
      save={(data) => {
        console.log(data);
        doTheMerge(data, props, notify, redirect, refresh);

      }}
      toolbar={<MapToolbar style={formStyle} />}
    >
      <h2>Entities Merger</h2>
      <div style={{gap: "30px", width: "50%"}}>
        <BooleanInput defaultValue={false} source="use_art_id" onChange={e => cleantext(e) } label="Check to search by ArtID" />
      </div>
      <div style={{gap: "30px", width: "50%"}}>
        <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => formData.use_art_id &&
                    <div>
                        <div style={{display: "flex", gap:"30px"}}>
                            <TextInput
                              label="ArtID 1"
                              source="art_id_1"
                              style={{ width: "40%" }}
                              onChange={e => setArt_id_1(e.target.value)}
                              value={art_id_1}
                              
                            />
                            <h4>{art_id_1_Description}</h4>
                          </div>
                          <div style={{display: "flex", gap:"30px"}}>
                            <TextInput
                              label="ArtID 2"
                              source="art_id_2"
                              style={{ width: "40%" }}
                              onChange={e => setArt_id_2(e.target.value)}
                              value={art_id_2}
                            />
                            <h4>{art_id_2_Description}</h4>
                        </div>
                    </div>
            }
          </FormDataConsumer>
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => !formData.use_art_id &&
                    <div>
                        <div style={{display: "flex", gap:"30px"}}>
                          <TextInput
                            label="EntityID 1"
                            source="entity_id_1"
                            style={{ width: "40%" }}
                            onChange={e => setEntity_id_1(e.target.value)}
                            value={entity_id_1}
                          />
                          <h4>{entity_id_1_Description}</h4>
                        </div>
                        <div style={{display: "flex", gap:"30px"}}>
                          <TextInput
                            label="EntityID 2"
                            source="entity_id_2"
                            style={{ width: "40%" }}
                            onChange={e => setEntity_id_2(e.target.value)}
                            value={entity_id_2}
                          />
                          <h4>{entity_id_2_Description}</h4>
                        </div>
                    </div>
            }
          </FormDataConsumer>
      </div>
    </SimpleForm>
  );
}

export default EntitiesMerger;