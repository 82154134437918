import React, {
    Fragment,
    useState,
} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { stringify } from 'query-string';
import { Link } from 'react-router-dom';

const CreateSectionObjectsButton = ({className, values, entity_name, button_name }) => {
    if (entity_name != null){
        values.entity_name = entity_name;
    }

    // handle creation of filing object in doc header
    if (values.section == 'header'){
        values.section = 'filing';
    }
    
    return (
        <Fragment>
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={className ?? null}
                component={Link}
                to={{
                    pathname: '/document-edit/create',
                    state: values
                }}
                >
                {button_name}
            </Button>
        </Fragment>
    );
};

CreateSectionObjectsButton.propTypes = {
    values: PropTypes.object
};

export default CreateSectionObjectsButton;