import React from 'react';
import { TextField, TextInput, BooleanInput, AutocompleteInput, ReferenceInput, ArrayInput, SimpleFormIterator, NumberInput, DateInput } from 'react-admin';
import { validations } from './validations';

const CommAddresses = (props) => {
    console.log('PROPS');
    console.log(props);

    return (
        <div>
            { 'location' in props && 
                <div>
                    <TextInput source='textAR' fullWidth={true} label='Text AR' validate={validations.validateTextAR} />
                    <TextInput source='typeAR' label='Type AR' validate={validations.validateTypeAR} />
                </div>    
            }
            <ReferenceInput source='language_codes' label='Language Code' reference='enumerations-language-codes' allowEmpty={true}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <TextInput source='address' label='Address' validate={validations.validateAddress} />
            <DateInput source='start' label='Start Date' />
            <DateInput source='end' label='End Date' />
            <BooleanInput source='active' initialValue={true} label='Active?' validate={validations.validateActive} />
            <BooleanInput source='validated' initialValue={true} label='Validated?' validate={validations.validateValidated} />
        </div>
    );
};

export default CommAddresses;