import React, {
    Fragment,
} from 'react';
import PropTypes from 'prop-types';

import {
    useNotify,
    useRedirect,
    useRefresh,
    useUnselectAll
} from 'ra-core';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import { Api } from "../../helpers/api";

function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].slice(1);     
    }
    return splitStr.join(' '); 
}

const DeleteItemObject = (props) => {
    console.log('props', props);
    let {additionalMessage} = props;
    let selected_ids = []
    let body = {}
    let item_id = ''
    let length = 0
    const resource = props.resource
    const resource_without_hifen = resource.replace(/-/g, " ")

    let item = ''
    let singular_item = ''
    let plural_item = ''
    if (resource_without_hifen.slice(-4) == 'sses'){
        singular_item = resource_without_hifen.slice(0,-2)
        plural_item = resource_without_hifen.slice(0,-2) + 'es'
    }
    else if (resource_without_hifen.slice(-1) == 's'){
        singular_item = resource_without_hifen.slice(0,-1)
        plural_item = resource_without_hifen.slice(0,-1) + 's'
    }
    else if (resource_without_hifen == 'document edit'){
        singular_item = 'object'
        plural_item = 'objects'
    }
    else{
        singular_item = resource_without_hifen
        plural_item = resource_without_hifen + 's'
    }
    console.log('singular_item', singular_item, plural_item)

    if ("selectedIds" in props){
        selected_ids = props.selectedIds
        item = singular_item
        if (selected_ids.length > 0){
            console.log('selectedIds', selected_ids)
            item_id = selected_ids[0]
            body = {selected_ids: selected_ids}
            length = selected_ids.length
            if (selected_ids.length > 1){
                item = plural_item
            }
        }
    }
    else if (item_id == ''){
        item_id = props.record.id
        length = 1
        item = singular_item
        console.log('itemID', item_id)
    }
    
    console.log('filterValues', props.filterValues);
    if ("filterValues" in props){
        const filter_values = props.filterValues;
        if(Object.keys(filter_values).length > 0){
            body.identifiers = {};
            if ('document_id' in filter_values){
                body.identifiers.doc_id = filter_values.document_id;
            }
            if ('entity_id' in filter_values){
                body.identifiers.entity_id = filter_values.entity_id;
            }
            if ('section_name' in filter_values){
                body.identifiers.section = filter_values.section_name;
            }
            if ('source_code' in filter_values){
                body.identifiers.source_code = filter_values.source_code;
            }
            if ('source_id' in filter_values){
                body.identifiers.source_id = filter_values.source_id;
            }
        }
    }

    console.log('body', body);
    

    console.log ('props delete', props);
    let redirect_url = `${props.basePath}/`
    if (props.basePath == "/process-step-transitions"){
        redirect_url = `/processes/${props.filterValues.process_id}/steps`
    }
    
    if(['/entity-names', '/entity-idkeys', '/entity-dates', '/entity-predicates'].indexOf(props.basePath) > -1){
        let entity_info_path = props.basePath;
        let entity_info_tab = entity_info_path.split('-')[1];
        redirect_url = `/entities/${props.filterValues.entity_id}/${entity_info_tab}`
    }

    if(['/supplier-product-requests-params', '/supplier-product-requests-credentials'].indexOf(props.basePath) > -1){
        let entity_info_path = props.basePath;
        let entity_info_tab = entity_info_path.split('-').pop();
        redirect_url = `/supplier-product-requests/${props.filterValues.supplier_product_request_id}/${entity_info_tab}`
    }


    
    if (props.basePath == "/source-section-quality-levels") {
        redirect_url = props.basePath + props.location.search;
    }
    console.log('redirect_url',redirect_url)

    // const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const unselect = useUnselectAll();

    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);

    };

    const handleClick = e => {

        return Api.delete(`${props.basePath}/${item_id}/`, body, true)
            .then(response => {
                console.log('response', response)
                if (response.type === "success") {
                    console.log("result", response.result);
                    if (props.basePath == '/document-edit'){
                        return response.result;
                    }
                    redirect(redirect_url);
                    window.location.reload();
                }
                else if (response.type === "auth_error") {
                    redirect('/login');
                }
                else {
                    if (response.type == "server_error") {
                        
                        if (response.text.includes("foreign key")){
                            // can be another server error
                            notify('The ' + singular_item + ' you are trying to delete is being used', 'warning');
                            setOpen(false);
                        }  
                    }
                    if (response.type == "not_found") {
                        // can be another type of error
                        notify('Could not find the ' + singular_item + '!', 'warning');
                    }
                }
            })
            .then(response => {
                if (props.basePath == '/document-edit'){
                    Api.post(`/document-edit/check-section/`, body, true)
                    .then(response => {
                        if (response.type === 'success'){
                            let result = response.result;
                            if (result.empty){    
                                redirect(`/document-edit?batch_id=${props.batch_id}`);
                                window.location.reload();
                            }
                            else{
                                refresh();
                            }
                        }
                    })
                }
            })
            .catch((e) => { 
                console.log("Exception starting the process - e", e);
                notify('Error starting the process!', 'warning')
            })
    };

    return (
        <Fragment>
            <Button
                style={{
                    background: "#FF0000",
                    boxSizing: "border-box",
                    /* Shadow/xs */
                    border: "#FF0000",
                    borderRadius: "8px",
                    /* Inside auto layout */
                    color: "#FFFFFF",
                    fontSize: "85%",
                    fontWeight: "bold",
                    textTransform:"none",
                    bottom:"5px"
                }}
                onClick={handleClickOpen}
            >
                Delete {length} {titleCase(item)}
            </Button>
            <Dialog
                id = "dialog-box"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <span style={{"fontWeight": "bold"}}>Are you sure you want to delete this {item}?</span>
                </DialogTitle>
                <DialogContent>
                <DialogContentText 
                    id="alert-dialog-description"
                >
                    You are about to <span style={{"fontWeight": "bold"}}>permanently delete</span> the {item} selected. You can't undo this. <span style={{"fontWeight": "bold"}}>{additionalMessage}</span>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    style={{
                        /* current_last */
                        background: "#F4F4F4",
                        /* Shadow/xs */
                        borderColor: "#F4F4F4",
                        borderRadius: "8px",
                        fontWeight: "bold",
                        fontSize: "85%",
                        textTransform:"none"
                    }}
                >
                    Cancel
                </Button>
                <Button 
                    onClick={() => {
                        handleClick();
                        unselect(resource);
                    }}
                    autoFocus
                    variant="outlined"
                    style={{
                        background: "#FF0000",
                        boxSizing: "border-box",
                        /* Shadow/xs */
                        borderRadius: "8px",
                        border: "#FF0000",
                        /* Inside auto layout */
                        color: "#FFFFFF",
                        fontSize: "85%",
                        fontWeight: "bold",
                        textTransform:"none"
                    }}
                >
                    Delete 
                </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

DeleteItemObject.propTypes = {
    record: PropTypes.object,
};

export default DeleteItemObject;
