import React, { useState, useEffect } from "react";
import {
  Edit,
  Button,
  TabbedForm,
  FormTab,
  Loading,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  SelectInput,
  FormDataConsumer,
  Toolbar,
  SaveButton,
  BooleanInput,
} from "react-admin";
import "../../index.css";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../constants/styleOverride";
import MappingInput from "./MappingInput";
import { Api } from "../../helpers/api";
import IMSectionForm from "../../components/IMSections";
import BackButton from "../../components/BackButton";
import { validations } from "./validations";
import IconContentAdd from "@material-ui/icons/Add";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import ADITester from "../ADITester/List";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const ImportMappingEdit = (props) => {
  const [relationType, setRelationType] = useState("");
  const [importMappingName, setimportMappingName] = useState("");
  const [loading, setLoading] = useState(true);
  const [valueTypes, setTypes] = useState([]);
  const [docSections, setDocSections] = useState([]);
  const [orderedSections, setOrderedSections] = useState([]);
  const [mapppingFunctions, setFunctions] = useState([]);
  const [modalChoices, setModalChoices] = useState([]);
  const [choices, setChoices] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [showDialog2, setShowDialog2] = useState(false);
  const [showTester, setShowTester] = useState(false);
  const [showFilingMapping, setShowFilingMapping] = useState(false);
  const [functionsByType, setFunctionsByType] = useState([]);
  const [linkedSources, setlinkedSources] = useState([]);
  const [docSample, setDocSample] = useState('');


  const relationSections = [
    { id: "directorshipsParent", name: "directorshipsParent" },
    { id: "directorshipsChild", name: "directorshipsChild" },
    { id: "ownershipsParent", name: "ownershipsParent" },
    { id: "ownershipsChild", name: "ownershipsChild" },
    { id: "relationshipsParent", name: "relationshipsParent" },
    { id: "relationshipsChild", name: "relationshipsChild" },
    { id: "connectionsParent", name: "connectionsParent" }
  ];

  const getRelationType = () => {
    return Api.get(`/import-mappings/${props.id}/?edit=1`, true).then(
      (response) => {
        if (response.type === "success") {
          setRelationType(response.result.relation_id ? "C" : "P");
          setimportMappingName(response.result.name);
          if ("entities" in response.result) {
            if ("sections" in response.result.entities) {
              setDocSections(
                Object.keys(response.result.entities.sections).map((section) => ({
                  id: section,
                  name: section,
                }))
              );
            }
          }
          if (response.result?.filing) {
            setShowFilingMapping(true);
          }
          setlinkedSources(response.result.sources);
          getSample(response.result.sources);
        }
        setLoading(false);
      }
    );
  };

  const getValueTypes = () => {
    return Api.get(
      `/enumerations/?page_size=25&property_name=import_mapping_value_types`,
      true
    ).then((response) => {
      if (response.type === "success") {
        setTypes(response.result.results);
      }
    });
  };

  const getFunctions = () => {
    return Api.get(`/import-mapping-functions/params/`, true).then(
      (response) => {
        if (response.type === "success") {
          setFunctions(response.result.results);
        }
      }
    );
  };

  const getFunctionsByTypes = () => {
    return Api.get(`/import-mapping-functions/types/`, true).then(
      (response) => {
        if (response.type === "success") {
          setFunctionsByType(response.result.results);
        }
      }
    );
  };

  const getSections = () => {
    return Api.get(`/enumerations-im-sections/`, true).then((response) => {
      if (response.type === "success") {
        setOrderedSections(
          response.result.results.map((section) => ({
            id: section.label,
            name: section.label,
            order: section.info2,
            relation: section.info3,
          }))
        );
      }
    });
  };

  function isParent(element) {
    if (["B", "P"].includes(element.relation)) {
      return element;
    }
  }

  function isChild(element) {
    if (["B", "C"].includes(element.relation)) {
      return element;
    }
  }

  function isNotOnDoc(entry) {
    if (!docSections.find((element) => element.id === entry.id)) {
      return entry;
    }
  }

  function isInDoc(entry) {
    if (docSections.find((element) => element.id === entry.id)) {
      return entry;
    }
  }

  const handleChoices = (ordSections, relationType) => {
    // Delete sections that are not on the correct relation type
    let cleaned = [];
    if (relationType === "P") {
      cleaned = ordSections.filter(isParent);
    } else if (relationType === "C") {
      cleaned = ordSections.filter(isChild);
    }

    // Set sections that come from the api
    const modalChoices = cleaned.filter(isNotOnDoc);
    setModalChoices(modalChoices);
    setChoices(ordSections.filter(isInDoc));
  };

  const getSample = linkedSources => {
    if (linkedSources.length == 1){
        Api.get(`/adi-tester/${linkedSources[0]["code"]}/get-sample/`, true).then(
          response => {
            setDocSample(JSON.stringify(response.result, null, 4));
          }
      );
    }
};

  useEffect(() => {
    getRelationType();
    getValueTypes();
    getFunctions();
    getFunctionsByTypes();
    getSections();
  }, []);

  useEffect(() => {
    handleChoices(orderedSections, relationType);
  }, [orderedSections, docSections, relationType]);

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleClick2 = () => {
    setShowDialog2(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleCloseClick2 = () => {
    setShowDialog2(false);
  };

  const handleOpenTester = () => {
    getSample(linkedSources);
    setShowTester(true);
  };

  const handleCloseTester = () => {
    setShowTester(false);
  };

  function removeObjectWithId(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  }

  function notInChoices(entry) {
    if (!choices.find((element) => element.id === entry.id)) {
      return entry;
    }
  }

  const handleRemove = (sectionValue) => {
    setShowDialog2(false);
    removeObjectWithId(choices, sectionValue);
    setChoices(choices);

    const new_choices = orderedSections.filter(notInChoices);
    setModalChoices(new_choices);
  };

  const handleSaveClick = (sectionValue) => {
    // Add the section to the sections array or perform any other action
    setShowDialog(false);
    const choicesLabels = [
      ...choices.map(function (choice) {
        return choice.name;
      }),
      sectionValue,
    ];
    const newChoices = orderedSections.filter((section) => {
      if (choicesLabels.find((element) => element === section.id)) {
        return section;
      }
    });
    setChoices(newChoices);
    removeObjectWithId(modalChoices, sectionValue);
    setModalChoices(modalChoices);
  };

  function validateObject(object) {
    let errors = {};
    for (let field in object) {
      if (
        object[field]?.type !== undefined &&
        object[field]?.value === undefined
      ) {
        errors[field] = { value: "Value missing for field ".concat(field) };
      }
    }
    if (Object.keys(errors).length === 0) {
      return undefined;
    }
    return errors;
  }

  const validateForm = (values) => {
    let errors = {};
    const sections = values.entities?.sections;
    for (let k in sections) {
      let errors2 = sections[k].map(validateObject);

      errors2 = errors2.filter(function (element) {
        return element !== undefined;
      });
      if (errors2.length) {
        errors[k] = errors2;
      }
    }
    return errors;
  };


  const UserEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
      <Button label='Test' startIcon={<DoubleArrowIcon />} variant="outlined" onClick={handleOpenTester} />
    </Toolbar>
  );

  if (loading|functionsByType.length==0) return <Loading />;

  return (
    <ThemeProvider theme={theme}>
      <BackButton {...props} action={"edit"} />
      <h3 {...props}>{`Edit ${props.options.label} - ${importMappingName}`}</h3>
      <Edit {...props}>
        {relationType === "P" ? (
          <TabbedForm
            toolbar={<UserEditToolbar />}
            redirect={false}
            validate={validateForm}
            syncWithLocation={false}
          >
            <FormTab label="Header">
              <MappingInput
                source=""
                sourceName="priority"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
                required={true}
              />
              <MappingInput
                source=""
                sourceName="status"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
                required={true}
              />
              <MappingInput
                source=""
                sourceName="validated"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
                required={true}
              />
              <MappingInput
                source=""
                sourceName="controlled"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
                required={true}
              />
              <MappingInput
                source=""
                sourceName="updateDate"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
                required={true}
              />
              <MappingInput
                source=""
                sourceName="effectiveDate"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
                required={true}
              />
              <MappingInput
                source=""
                sourceName="url"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />
              <MappingInput
                source=""
                sourceName="internalNote"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />
              <MappingInput
                source=""
                sourceName="dataEnrichment"
                types={valueTypes}
                dataEnrichmentSections={orderedSections}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />
              <MappingInput
                source=""
                sourceName="notParsedData"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />
            </FormTab>

            <FormTab label="Entity" path="entity">
              <MappingInput
                source="entities"
                sourceName="type"
                labelName="Type"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
                required={true}
              />
              <MappingInput
                source="entities"
                sourceName="relation"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
                required={true}
              />
              <MappingInput
                source="entities"
                sourceName="countryCode"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
                required={true}
              />
              <MappingInput
                source="entities"
                sourceName="reference"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />
              <MappingInput
                source="entities"
                sourceName="referenceFormer"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />
              <MappingInput
                source="entities"
                sourceName="crawlingType"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />
              <MappingInput
                source="entities"
                sourceName="crawlingDate"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />
              <MappingInput
                source="entities"
                sourceName="url"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />
              <MappingInput
                source="entities"
                sourceName="createEntity"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />

              <MappingInput
                source="entities"
                sourceName="characteristic"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />

              <MappingInput
                source="entities"
                sourceName="regName"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />
            </FormTab>

            <FormTab label="Sections" path="sections">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <SelectInput
                  source="section"
                  choices={choices}
                  allowEmpty
                  style={{ alignSelf: "center" }}
                />
                <Button
                  label="ADD SECTION"
                  onClick={handleClick}
                  style={{ alignSelf: "center", marginRight: "1rem" }}
                />
              </div>
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <>
                    {formData.section && (
                      <div>
                        <div
                          style={{ position: "relative", textAlign: "right" }}
                        >
                          <Button
                            label="REMOVE SECTION"
                            style={{ color: "red", display: "inline-block" }}
                            onClick={handleClick2}
                          />
                        </div>
                        <ArrayInput
                          source={`entities.sections.${formData.section}`}
                          label={false}
                          {...rest}
                          key={formData.section}
                          style={{ width: "100%" }}
                        >
                          <SimpleFormIterator>
                            <IMSectionForm
                              section={formData.section}
                              types={valueTypes}
                              functions={mapppingFunctions}
                              functionsByType={functionsByType}
                              style={{ width: "100%" }}
                            />
                          </SimpleFormIterator>
                        </ArrayInput>
                      </div>
                    )}

                    <Dialog
                      fullWidth
                      open={showDialog}
                      onClose={handleCloseClick}
                      aria-label="Add Section"
                    >
                      <DialogTitle>Add Section</DialogTitle>
                      <DialogContent>
                        <SelectInput
                          source="sectionToAdd"
                          choices={modalChoices}
                          allowEmpty
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button
                          label="Cancel"
                          icon={<IconCancel />}
                          onClick={handleCloseClick}
                        />
                        <Button
                          label="Save"
                          icon={<IconContentAdd />}
                          onClick={() => {
                            handleSaveClick(formData.sectionToAdd);
                            formData.section = formData.sectionToAdd;
                          }}
                        />
                      </DialogActions>
                    </Dialog>

                    <Dialog
                      id="dialog-box"
                      open={showDialog2}
                      onClose={handleCloseClick}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        <span style={{ fontWeight: "bold" }}>
                          Are you sure you want to delete this section?
                        </span>
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          You are about to{" "}
                          <span style={{ fontWeight: "bold" }}>
                            permanently delete
                          </span>{" "}
                          this section.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleCloseClick2}
                          variant="outlined"
                          style={{
                            /* current_last */
                            background: "#F4F4F4",
                            /* Shadow/xs */
                            borderColor: "#F4F4F4",
                            borderRadius: "8px",
                            fontWeight: "bold",
                            fontSize: "85%",
                            textTransform: "none",
                          }}
                          label="Cancel"
                        />
                        <Button
                          onClick={() => {
                            handleRemove(formData.section);
                            formData.entities.sections[formData.section] = [];
                            formData.section = choices.length
                              ? choices[0].id
                              : "";
                          }}
                          autoFocus
                          variant="outlined"
                          style={{
                            background: "#FF0000",
                            boxSizing: "border-box",
                            /* Shadow/xs */
                            borderRadius: "8px",
                            border: "#FF0000",
                            /* Inside auto layout */
                            color: "#FFFFFF",
                            fontSize: "85%",
                            fontWeight: "bold",
                            textTransform: "none",
                          }}
                          label="Remove"
                        />
                      </DialogActions>
                    </Dialog>
                  </>
                )}
              </FormDataConsumer>
            </FormTab>

            <FormTab label="Filing" path="filing">
              <BooleanInput
                source="filingExists"
                label="Is Filing available?"
                redirect={false}
                defaultValue={showFilingMapping}
              />
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.filingExists && (
                    <>
                      <MappingInput
                        source="filing"
                        sourceName="textAR"
                        labelName="TextAR"
                        tab="filing"
                        types={valueTypes}
                        functions={mapppingFunctions}
                        functionsByType={functionsByType}
                        required={true}
                        {...rest}
                      />
                      <MappingInput
                        source="filing"
                        sourceName="typeAR"
                        labelName="TypeAR"
                        tab="filing"
                        types={valueTypes}
                        functions={mapppingFunctions}
                        functionsByType={functionsByType}
                        {...rest}
                      />
                      <MappingInput
                        source="filing"
                        sourceName="status"
                        labelName="Status"
                        tab="filing"
                        types={valueTypes}
                        functions={mapppingFunctions}
                        functionsByType={functionsByType}
                        required={true}
                        {...rest}
                      />
                      <MappingInput
                        source="filing"
                        sourceName="reference"
                        labelName="Reference"
                        tab="filing"
                        types={valueTypes}
                        functions={mapppingFunctions}
                        functionsByType={functionsByType}
                        {...rest}
                      />
                      <MappingInput
                        source="filing"
                        sourceName="url"
                        labelName="Url"
                        tab="filing"
                        types={valueTypes}
                        functions={mapppingFunctions}
                        functionsByType={functionsByType}
                        {...rest}
                      />
                      <MappingInput
                        source="filing"
                        sourceName="serialNumber"
                        labelName="SerialNumber"
                        tab="filing"
                        types={valueTypes}
                        functions={mapppingFunctions}
                        functionsByType={functionsByType}
                        {...rest}
                      />
                      <MappingInput
                        source="filing"
                        sourceName="mainSerialNumber"
                        labelName="MainSerialNumber"
                        tab="filing"
                        types={valueTypes}
                        functions={mapppingFunctions}
                        functionsByType={functionsByType}
                        {...rest}
                      />
                      <MappingInput
                        source="filing"
                        sourceName="effectiveDate"
                        labelName="EffectiveDate"
                        tab="filing"
                        types={valueTypes}
                        functions={mapppingFunctions}
                        functionsByType={functionsByType}
                        required={true}
                        {...rest}
                      />
                      <MappingInput
                        source="filing"
                        sourceName="content"
                        labelName="Content"
                        tab="filing"
                        types={valueTypes}
                        functions={mapppingFunctions}
                        functionsByType={functionsByType}
                        required={true}
                        {...rest}
                      />
                      <MappingInput
                        source="filing"
                        sourceName="type"
                        labelName="Type"
                        tab="filing"
                        types={valueTypes}
                        functions={mapppingFunctions}
                        functionsByType={functionsByType}
                        required={true}
                        {...rest}
                      />
                      <MappingInput
                        source="filing"
                        sourceName="languageCodes"
                        labelName="LanguageCodes"
                        tab="filing"
                        types={valueTypes}
                        functions={mapppingFunctions}
                        functionsByType={functionsByType}
                        required={true}
                        {...rest}
                      />
                      <MappingInput
                        source="filing"
                        sourceName="chapter"
                        labelName="Chapter"
                        tab="filing"
                        types={valueTypes}
                        functions={mapppingFunctions}
                        functionsByType={functionsByType}
                        required={true}
                        {...rest}
                      />
                      <MappingInput
                        source="filing"
                        sourceName="title"
                        labelName="Title"
                        tab="filing"
                        types={valueTypes}
                        functions={mapppingFunctions}
                        functionsByType={functionsByType}
                        {...rest}
                      />
                      <MappingInput
                        source="filing"
                        sourceName="pageNumber"
                        labelName="PageNumber"
                        tab="filing"
                        types={valueTypes}
                        functions={mapppingFunctions}
                        functionsByType={functionsByType}
                        {...rest}
                      />
                      <MappingInput
                        source="filing"
                        sourceName="notification"
                        labelName="Notification"
                        tab="filing"
                        types={valueTypes}
                        functions={mapppingFunctions}
                        functionsByType={functionsByType}
                        required={true}
                        {...rest}
                      />
                      <ArrayInput
                        source="filing.contacts"
                        label="Contacts"
                        {...rest}
                      >
                        <SimpleFormIterator>
                          <MappingInput
                            sourceName="designation"
                            labelName="Designation"
                            tab="filing"
                            types={valueTypes}
                            functions={mapppingFunctions}
                            functionsByType={functionsByType}
                            required={true}
                            {...rest}
                          />
                          <MappingInput
                            sourceName="textLines"
                            labelName="TextLines"
                            tab="filing"
                            types={valueTypes}
                            functions={mapppingFunctions}
                            functionsByType={functionsByType}
                            required={true}
                            {...rest}
                          />
                        </SimpleFormIterator>
                      </ArrayInput>
                    </>
                  )
                }
              </FormDataConsumer>
            </FormTab>
          </TabbedForm>
        ) : (
          <TabbedForm
            toolbar={<UserEditToolbar />}
            redirect={false}
          >
            <FormTab label="Entity">
              <SelectInput
                source="entities.relation_section"
                label="Relation Section"
                choices={relationSections}
                fullWidth={true}
                validate={validations.validateRequired}
              />
              <TextInput
                source="entities.related_path"
                label="Related Path"
                fullWidth={true}
                validate={validations.validateRequired}
              />
              <MappingInput
                source="entities"
                sourceName="type"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
                required={true}
              />
              <MappingInput
                source="entities"
                sourceName="countryCode"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />
              <MappingInput
                source="entities"
                sourceName="createEntity"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />

              <MappingInput
                source="entities"
                sourceName="manualEntityMatch"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />

              <MappingInput
                source="entities"
                sourceName="characteristic"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />

              <MappingInput
                source="entities"
                sourceName="regName"
                tab="entities"
                types={valueTypes}
                functions={mapppingFunctions}
                functionsByType={functionsByType}
              />

            </FormTab>

            <FormTab label="Sections" path="sections">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <SelectInput
                  source="section"
                  choices={choices}
                  allowEmpty
                  style={{ alignSelf: "center" }}
                />
                <Button
                  label="ADD SECTION"
                  onClick={handleClick}
                  style={{ alignSelf: "center", marginRight: "1rem" }}
                />
              </div>
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <>
                    {formData.section && (
                      <div>
                        <div
                          style={{ position: "relative", textAlign: "right" }}
                        >
                          <Button
                            label="REMOVE SECTION"
                            style={{ color: "red", display: "inline-block" }}
                            onClick={handleClick2}
                          />
                        </div>
                        <ArrayInput
                          source={`entities.sections.${formData.section}`}
                          label={false}
                          {...rest}
                          key={formData.section}
                          style={{ width: "100%" }}
                        >
                          <SimpleFormIterator>
                            <IMSectionForm
                              section={formData.section}
                              types={valueTypes}
                              functions={mapppingFunctions}
                              functionsByType={functionsByType}
                              style={{ width: "100%" }}
                            />
                          </SimpleFormIterator>
                        </ArrayInput>
                      </div>
                    )}

                    <Dialog
                      fullWidth
                      open={showDialog}
                      onClose={handleCloseClick}
                      aria-label="Add Section"
                    >
                      <DialogTitle>Add Section</DialogTitle>
                      <DialogContent>
                        <SelectInput
                          source="sectionToAdd"
                          choices={modalChoices}
                          allowEmpty
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button
                          label="Cancel"
                          icon={<IconCancel />}
                          onClick={handleCloseClick}
                        />
                        <Button
                          label="Save"
                          icon={<IconContentAdd />}
                          onClick={() => {
                            handleSaveClick(formData.sectionToAdd);
                            formData.section = formData.sectionToAdd;
                          }}
                        />
                      </DialogActions>
                    </Dialog>

                    <Dialog
                      id="dialog-box"
                      open={showDialog2}
                      onClose={handleCloseClick}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        <span style={{ fontWeight: "bold" }}>
                          Are you sure you want to delete this section?
                        </span>
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          You are about to{" "}
                          <span style={{ fontWeight: "bold" }}>
                            permanently delete
                          </span>{" "}
                          this section.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleCloseClick2}
                          variant="outlined"
                          style={{
                            /* current_last */
                            background: "#F4F4F4",
                            /* Shadow/xs */
                            borderColor: "#F4F4F4",
                            borderRadius: "8px",
                            fontWeight: "bold",
                            fontSize: "85%",
                            textTransform: "none",
                          }}
                          label="Cancel"
                        />
                        <Button
                          onClick={() => {
                            handleRemove(formData.section);
                            formData.entities.sections[formData.section] = [];
                            formData.section = choices.length
                              ? choices[0].id
                              : "";
                          }}
                          autoFocus
                          variant="outlined"
                          style={{
                            background: "#FF0000",
                            boxSizing: "border-box",
                            /* Shadow/xs */
                            borderRadius: "8px",
                            border: "#FF0000",
                            /* Inside auto layout */
                            color: "#FFFFFF",
                            fontSize: "85%",
                            fontWeight: "bold",
                            textTransform: "none",
                          }}
                          label="Remove"
                        />
                      </DialogActions>
                    </Dialog>
                  </>
                )}
              </FormDataConsumer>
            </FormTab>
          </TabbedForm>
        )}
      </Edit>
      <Dialog
        fullWidth
        maxWidth={false}
        open={showTester}
        onClose={handleCloseTester}
      >
        <DialogTitle disableTypography style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <h2>Test Import Mapping</h2>
          <IconButton >
            <CloseIcon onClick={handleCloseTester} />
          </IconButton>
        </DialogTitle>
        <ADITester {...props} linkedSources={linkedSources} defaultSample={docSample} />
      </Dialog>
    </ThemeProvider>
  );
};

export default ImportMappingEdit;
