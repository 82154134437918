import React from 'react';
import { List, SimpleForm, ReferenceInput, SelectInput, AutocompleteInput, TextInput, BooleanInput, DateInput } from 'react-admin';
import { validations } from './validations';


const PersonStatuses = (props) => {

    console.log('PROPS', props);
    
    
    return (
    <div>
        { 'location' in props &&
            <TextInput source='textAR' label='Text AR' validate={validations.validateTextAR} />
        }
        <ReferenceInput source='status' label='Status' reference='enumerations-person-status' allowEmpty={true} >
            <AutocompleteInput optionText="label" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes' allowEmpty={true} >
            <AutocompleteInput optionText="label" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput source='translationCode' label='Translation Code' reference='enumerations-translation-codes' allowEmpty={true} >
            <SelectInput optionText="label" optionValue="id" />
        </ReferenceInput>
        { (('record' in props && props.record.status === 'LIV') || ('location' in props)) &&
            <DateInput source='start' label='Start Date' />}
        {  (('record' in props && props.record.status === 'DYD') || ('location' in props)) &&
            <DateInput source='end' label='End Date' />
        }
        <BooleanInput source='active' initialValue={true} label='Active?' validate={validations.validateActive} />
    </div>
    )

}

export default PersonStatuses;