import React from 'react';
import { Create, SimpleForm, FileInput, FileField} from 'react-admin';
import { validations } from './validations';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const ExtractionServiceScriptCreate = props => {

  return (
    <>
      <Create undoable={true} {...props}>
        <SimpleForm redirect="list">
          <BackButton {...props} action = {"create"} />
          <PageTitle {...props}>Create</PageTitle>
          <FileInput source="python_file" accept=".py" label="Upload Python script" validate={validations.validateFile}>
            <FileField source="src" title="title"/>
          </FileInput>
        </SimpleForm>
      </Create>
    </>
  );
}

export default ExtractionServiceScriptCreate;
