import {minLength, maxLength, required, } from 'react-admin';

export const validations = {
  validateIdkeyAR: [required(), minLength(1), maxLength(50)],
  validateTextAR: [minLength(1), maxLength(200)],
  validateIdkeyCode: [required(), minLength(3), maxLength(6)],
  validateIdkey: [required(), minLength(1), maxLength(50)],
  validateIsPrimary: [],
  validateActive: [required()],
  validateValidated: [required()]
};
