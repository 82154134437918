import React from 'react';
import { Filter, List, Datagrid, TextField, SelectInput, DateInput, AutocompleteInput} from 'react-admin';
import { useState, useEffect } from 'react';
import {useDataProvider, Loading, Error, useCreateController} from 'react-admin';
import { useFormState } from 'react-final-form';
import ClearFiltersButton from '../../../components/ClearFiltersButton';

const months = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
]

function years(startYear) {
    var currentYear = new Date().getFullYear(), years = [];
    startYear = startYear || 1980;  
    while ( startYear <= currentYear ) {
        years.push({"id":startYear, "name":startYear});
        startYear++;
    }   
    return years;
}

const PeriodValueInput = props => {
    const { values } = useFormState();
    if (values.period == 1){
        window.period_label = "Date"
        return (
        <React.Fragment>
            <DateInput source="period_value" label="Date" helperText="" />
        </React.Fragment>
        )}
    else if (values.period == 2){
        window.period_label = "Month"
        return(
        <React.Fragment>
            <SelectInput source="period_value" choices={months} label="Month" helperText=""  allowEmpty/>
        </React.Fragment>)}
    else{
        window.period_label = "Year"
        return(<SelectInput source="period_value" choices={years(2020)} label="Year" helperText="" allowEmpty/>)}
};

const YearInput = props => {
    const { values } = useFormState();
    if (values.period == 1){
        window.period_label = "Date"
        return (
        <React.Fragment>
            <SelectInput source="year" choices={years(2020)} label="Year" helperText="" allowEmpty/>
        </React.Fragment>
        )}
    else if (values.period == 2){
        window.period_label = "Month"
        return(
        <React.Fragment>
            <SelectInput source="year" choices={years(2020)} label="Year" helperText=""  allowEmpty/>
        </React.Fragment>)}
    else{
        window.period_label = "Year"
        return null
    }
};



const RecordsList = props => {
    const dataProvider = useDataProvider();
    const [sources, setSources] = useState();
    const [periods, setPeriods] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const {
        loaded, // boolean that is false until the record is available
        record, // record fetched via dataProvider.getOne() based on the id from the location
    } = useCreateController(props);

    useEffect(() => {

        if (loaded && !sources && !periods) { 
            dataProvider.getList('sources', {
                pagination: { page: 1, perPage: 300 },
                sort: { field: 'code', order: 'ASC' },
            })
                .then(( response ) => {
                    if (response) {
                        setSources(response.data);
                    }
                    else {
                        console.log("response", response);
                    }
                    setLoading(false);
                })
                .catch(error => {
                    console.log("error", error);
                    setError(error);
                    setLoading(false);
                })

                dataProvider.getList('period-types', {
                    pagination: { page: 1, perPage: 300 },
                    sort: { field: 'label', order: 'ASC' },
                })
                    .then(( response ) => {
                        if (response) {
                            setPeriods(response.data);
                        }
                        else {
                            console.log("response", response);
                        }
                        setLoading(false);
                    })
                    .catch(error => {
                        console.log("error", error);
                        setError(error);
                        setLoading(false);
                    })
        }
    }, [record, dataProvider, loaded, sources]);

    if (loading) return <Loading />;
    if (error) return <Error />;
    
    let year_field = null
    if (window.period_label == "Date" || window.period_label == "Month") {
        year_field = <TextField source="year" label="Year"/>
    }

    const RecordsFilter = (props) => {
        return(
            <div>
                <ClearFiltersButton {...props} filters={{source_id: '', month: '', year: ''}}/>
                <Filter {...props} >
                    <SelectInput source="period" choices={periods} optionText="label" alwaysOn/>
                    <AutocompleteInput source="source_code" choices={sources} optionText="code" optionValue="code" allowEmpty alwaysOn/>
                    <PeriodValueInput source="period_value"  allowEmpty alwaysOn/> 
                    <YearInput source="year"  allowEmpty alwaysOn/> 
                </Filter>
            </div>
        );
    }
    
    
    

    return(
        <>
            <h2>{props.options.label}</h2>
            <List title=" " filters={<RecordsFilter />} bulkActionButtons={false} exporter={false} {...props} perPage={25} sort={{ field: 'period_value', order: 'DESC' }}>
                
                <Datagrid>
                    <TextField label="Source" source="source_code" sortable={false}/>
                    <TextField source="period_value" label={window.period_label}/>
                    {year_field}
                    <TextField label="Total records" source="record_count" />
                </Datagrid>
            </List>
        </>
    )
};

export default RecordsList;