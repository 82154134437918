import React from 'react';
import { TextField, Card, SelectField, TextInput, BooleanInput, ReferenceInput, AutocompleteInput, SelectInput, ArrayInput, SimpleFormIterator, NumberInput, DateInput } from 'react-admin';
import { Box, Toolbar } from '@material-ui/core';
import {validations} from './validations';

const EntityIdkey = (props) => {
    const location = props.location.pathname;

    return (
        <>
            <TextInput source="idkey" fullWidth={true} validate={validations.validateIdkey} />
            <ReferenceInput source='idkey_code' reference='enumerations-idkey-codes' validate={validations.validateIdkeyCode} >
                <AutocompleteInput optionText="code" />
            </ReferenceInput>
            <BooleanInput source="active" label="Is it active?" validate={validations.validateActive} defaultValue={0}/>
            <BooleanInput source="variation" label="Is it a variation?" />
            { location.includes('create') &&
                <ReferenceInput label="Insert source" source="insert_source" reference="sources" validate={validations.validateInsertSource} >
                    <AutocompleteInput optionText="code" optionValue="id" />
                </ReferenceInput>
            }
        </>
    );
}


export default EntityIdkey;


