import {minLength, maxLength, required, } from 'react-admin';

export const validations = {
  validateTextAR: [required(), minLength(1), maxLength(200)],
  validateIndustryCodes: [required(), minLength(1), maxLength(9)],
  validateIndustryCode: [minLength(4), maxLength(6)],
  validateLabel: [required()],
  validateActive: [required()],
  validateValidated: [required()]
};
