import React from 'react';
import {Filter, SearchInput, SelectInput, SimpleForm, FormDataConsumer} from 'react-admin';

const EntitiesFilter = (props) => {
    if (props.resetFilters){
        props.setFilters({'art_id': '', 'name': props.mainName, 'search_type': 'Name exact', 'idkey': ''}) 
    }

    let name_filters = ["Name starts", "Name exact"];

    let choices = [
        {id: "ART ID", name: "ART ID"},
        {id: "Idkey", name: "Idkey"},
        {id: 'Name starts', name: 'Name starts'},
        {id: 'Name exact', name: 'Name exact'}
    ]

    const handleFilters = ({ formData, ...rest }) => {
        if (name_filters.includes(formData.search_type)){
            return <Filter {...props}>
                        <SearchInput source="name" style={{width: '700px'}} alwaysOn placeholder="Name" 
                            onChange= { (e) => {
                                let name = e;
                                if (name !== ''){
                                   name = name.target.value;
                                }
                                props.setFilters({'art_id': '', 'name': name, 'search_type': formData.search_type, 'idkey': ''});
                            }}
                        />
                    </Filter>
        }
        else if (formData.search_type === "ART ID"){
            return  <Filter {...props}>
                        <SearchInput source="art_id" style={{width: '200px'}} alwaysOn placeholder="Art ID" 
                            onChange= { (e) => {
                                let artId = e;
                                if (artId !== ''){
                                   artId = artId.target.value;
                                }
                                props.setFilters({'art_id': artId, 'name': '', 'search_type': formData.search_type, 'idkey': ''});
                            }}
                        />
                    </Filter>
        }
        else{
            return <Filter>
                        <SearchInput source="idkey" style={{width: '350px'}} alwaysOn placeholder="Idkey" 
                            onChange= { (e) => {
                                let idkey = e;
                                if (idkey !== ''){
                                   idkey = idkey.target.value;
                                }
                                props.setFilters({'art_id': '', 'name': '', 'search_type': formData.search_type, 'idkey': idkey});
                            }}
                        />  
                    </Filter>
        }
    }

    return  <SimpleForm toolbar={false}>
                <div style={{ display: 'flex', width: '100%'}}>
                    <SelectInput label="Search by" source="search_type" choices={choices} initialValue="Name exact" 
                        onChange = {(e) => {
                            if (e.target.value === "ART ID"){
                                props.setFilters({'art_id': props.filterValues.art_id, 'name': '', 'search_type': e.target.value, 'idkey': ''});
                            }
                            else if (e.target.value === "Idkey"){
                                props.setFilters({'art_id': '', 'name': '', 'search_type': e.target.value, 'idkey': props.filterValues.idkey});
                            }
                            else {
                                props.setFilters({'art_id': '', 'name': props.filterValues.name, 'search_type': e.target.value, 'idkey': ''});
                            }
                        }}
                    />
                    <div style={{ marginTop: '0px', marginLeft: '10px'}} >
                        <FormDataConsumer subscription={{ values: true }}>
                            {handleFilters}
                        </FormDataConsumer>
                    </div>
                </div>
            </SimpleForm>
}

export default EntitiesFilter;