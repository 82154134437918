import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {EditButton} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: "bold"
    }
  }))(TableCell);

const RenderRows = ({values}) => {
    return values.map(function(row, index) {
        console.log(row);
        return (
            <TableRow key={index}>
                {row.map(function(value) {
                    return <TableCell>{value}</TableCell>
                })}
            </TableRow>
        )})
}

const RenderHeaders = ({headers}) => {
    return headers.map(function(header) {
        return <StyledTableCell>{header}</StyledTableCell>
    })
}
  
export default class TableComponent extends PureComponent {
    
    render() {
        return (
            <TableContainer component={Paper}>
                <Table aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <RenderHeaders headers={this.props.headers}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <RenderRows values={this.props.values}/>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}