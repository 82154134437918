import React from 'react';
import { Button, TopToolbar } from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RefreshIcon from '@material-ui/icons/Refresh';
import MUIButton from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import ButtonWithDialog from "../../components/ButtonWithDialog";

export const ShowDocButton = (props) => {
    return <Button label="DOC" variant="contained" color="primary" startIcon={<VisibilityIcon />} className={props.class} onClick={() => window.open(`/#/documents/${props.record.doc_id}/show`, '_blank')} />
  }

export const DocEditButton = (props) => {
    return <Button label="EDIT" variant="contained" color="primary" startIcon={<EditIcon/>} className={props.class} onClick={() => window.open(`/#/document-edit?batch_id=${props.record.batch_id}&document_id=${props.record.doc_id}`)}  />
}

export const RefreshDataButton = (props) => {
    return <Button label="Refresh" style={props.style} variant="contained" color="primary" startIcon={<RefreshIcon/>} className={props.class} onClick={() => window.location.reload()} />
}

export const ClearFiltersButton = ({filters, ...props}) => {
    return(
        props.context !== 'button' && 
        <MUIButton 
            onClick={() => {props.setFilters(filters); props.showDoc(false);}}
            startIcon={<CloseIcon />}
            style={{
                boxSizing: "border-box",
                /* Shadow/xs */
                borderRadius: "8px",
                color: "#0000008a",
                /* Inside auto layout */
                fontSize: "85%",
                fontWeight: "bold",
                textTransform:"none"
            }}
        >
            Clear Filters
        </MUIButton>
    );
}

export const CompleteTaskButton = (props) => {
    return  <ButtonWithDialog
              variant="contained" 
              color="secondary"
              className={props.class}
              buttonLabel="Complete Task"
              dialogTitleMessage="Are you sure you want to complete this task?"
              dialogContentMessage={`Complete task ${props.taskId} for batch ${props.batchId}? You can't undo this`}
              confirmButtonLabel="Confirm"
              onConfirm={() => props.onConfirm()}
            />
}

export const ButtonsToolbar = (props) => (
    <TopToolbar style={{display: 'flex', justifyContent: 'flex-end'}}>
        <div style={{display: "flex", columnGap: "20px"}}>
            <RefreshDataButton class={props.classes.refresh} {...props} />
            { props.showDoc && <CompleteTaskButton class={props.classes.complete} {...props} />}
        </div>
    </TopToolbar>
)