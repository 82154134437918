import React from 'react';
import { TextInput, BooleanInput, ReferenceInput, SelectInput, AutocompleteInput, DateInput } from 'react-admin';
import { validations } from './validations';

const PersonNames = (props) => (
    
        <div>
            { 'location' in props && 
                <TextInput source='nameAR' label='Name AR' validate={validations.validateNameAR} />
            }
            <TextInput source='fullName' label='Full Name' validate={validations.validateFullName} />
            <TextInput source='title' label='Title' validate={validations.validateTitle} />
            <TextInput source='firstName' label='First Name' validate={validations.validateFirstName} />
            <TextInput source='middleName' label='Middle Name' validate={validations.validateMiddleName} />
            <TextInput source='lastName' label='Last Name' validate={validations.validateLastName} />
            <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes' validate={validations.validateLanguageCode}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput source='translationCode' label='Translation Code' reference='enumerations-translation-codes' allowEmpty={true} >
                <SelectInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <DateInput source='start' label='Start Date' />
            <DateInput source='end' label='End Date' />
            <BooleanInput source='active' initialValue={true} label='Active?' validate={validations.validateActive} />
            <BooleanInput source='controlled' initialValue={false} label='Controlled?' helperText='Manually controlled'/>
        </div>
            
)

export default PersonNames;