import {minLength, maxLength, required, } from 'react-admin';

export const validations = {
  validateTextAR: [required(), minLength(1)],
  validateUpdateDate: [required()],
  validateLicensor: [required(), minLength(3), maxLength(100)],
  validateNumber: [required(), minLength(1), maxLength(50)],
  validateUnique: [required()],
  validateLicenseStatus: [required()],
  validateLabel: [minLength(3)],
  validateCode: [minLength(1), maxLength(10)],
  validateStandard: [minLength(1), maxLength(10)]
};