import React from 'react';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import {BooleanInput, DateInput, Edit, SimpleForm, TextInput, SelectInput, AutocompleteArrayInput, ReferenceArrayInput} from 'react-admin';
import {validations} from './validations';
import {perPageReferenceFields} from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const SupplierEdit = props => {

  return (
    <>
      <Edit undoable={true} {...props}>
        <SimpleForm toolbar={<EditFormToolbarActions showDeleteButton={false}/>}>
          <BackButton {...props} action = {"edit"}/>
          <PageTitle {...props}>Edit</PageTitle>
          <TextInput source="name" fullWidth={true} validate={validations.validateName}/>
          <TextInput source="address" fullWidth={true} validate={validations.validateAddress}/>
          <TextInput source="reference" fullWidth={true} validate={validations.validateReference}/>
          <ReferenceArrayInput label='Products' reference='products' source='products' perPage={perPageReferenceFields}>
            <AutocompleteArrayInput optionText='label' optionValue='id'/>
          </ReferenceArrayInput>
          <SelectInput source="order_type" label="Order Type" choices={[
            {id: "ByWIN", name: "ByWIN"},
            {id: "ByName", name: "ByName"},
            {id: "ByWINandReference", name:"ByWINandReference"},
            {id: "ByRegNumberAndCountry", name:"ByRegNumberAndCountry"}
          ]} validate={validations.validateOrderType} />
          <BooleanInput source="active" label="Is it active?"/>
          <DateInput source='start_date' validate={validations.validateStartDate}/>
          <DateInput source='end_date'/>
        </SimpleForm>
      </Edit>
    </>
  );
}

export default SupplierEdit;
