import React from "react";
import { useState, useEffect } from "react";
import { List, Datagrid, TextField, Filter, ReferenceInput, AutocompleteInput } from "react-admin";
import { Api } from "../../../helpers/api";
import ClearFiltersButton from "../../../components/ClearFiltersButton";

const UpdatedEntityCounts = (props) => {
  const [fields, setFields] = useState([]);

  useEffect(() => {
    getDynamicFields();
  }, []);

  const getDynamicFields = () => {
    return Api.get(`/statistics/updated-entity-counts/`, true).then(response => {
      if (response.type === "success") {
          setFields(response.result.fields)
      }
    });
  }

  const EntityCountsFilters = (props) => {
    const { filterValues } = props;
    const isSourceSelected = Boolean(filterValues.source);

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Filter {...props}>
                <ReferenceInput
                    label="Source"
                    source="source"
                    reference="sources"
                    alwaysOn
                >
                    <AutocompleteInput optionText="code" optionValue="id" />
                </ReferenceInput>
            </Filter>
            {isSourceSelected && (
                <ClearFiltersButton {...props} filters={{ source: "", id: "" }} style={{ marginLeft: 'auto' }} />
            )}
        </div>
    );
  };

  return (
    <>
      <h2>{props.options.label}</h2>
        <List
          title="New entities"
          {...props}
          bulkActionButtons={false}
          exporter={false}
          actions={null}
          filters={<EntityCountsFilters/>}
          pagination={false}
          style={
            fields.length + 1 > 14 ? { width: "1630px", overflowX: "auto" } : null
          }
        >
          <Datagrid>
            <TextField label="Source" source="source" sortable={false} />
            {fields.length !== 0 &&
              fields.map((str_date, index) => (
                <TextField
                  key={index}
                  label={str_date}
                  source={str_date}
                  sortable={false}
                />
              ))}
          </Datagrid>
        </List>
    </>
  );
};

export default UpdatedEntityCounts;
