import React, {
    Fragment,
} from 'react';
import DeleteItemObject from '../DeleteItemObject';
import PropTypes from 'prop-types';


const DocBulkActionButtons = props => {
    return (
        <Fragment {...props}>
            <DeleteItemObject {...props} />
        </Fragment>
    ) 
};

DocBulkActionButtons.propTypes = {
    record: PropTypes.object,
};

export default DocBulkActionButtons;
