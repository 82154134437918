import React, { PureComponent, useState } from "react";
import { useNotify } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import RabbitMQLogo from "../../static/icons/RabbitMQ.png";
import CloudWatchLogo from "../../static/icons/Aws-cloudwatch.png";
import CloudWatchLogo_grey from "../../static/icons/Aws-cloudwatch_grey.png";
import ECSLogo from "../../static/icons/Aws-ecs-logo.png";
import { userHasPermission } from "../../helpers/authProvider";
import CustomAPIRequest from "./helpers";
import ButtonWithDialog from "../../components/ButtonWithDialog";

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontWeight: "bold",
  },
}))(TableCell);

const LinksField = ({ row, record }) => {
  const notify = useNotify();
  const dialogTitleMessage = "Run ECS Task";
  const dialogContentMessage = `Do you want to run the ECS Task for ${row?.step_title}?`;
  const dialogNumberField = "If yes, how many tasks to you want to run?";
  const icon_width = 20;

  const handleClickRabbitMQ = () => {
    const rabbit_mq_url = row?.queue_url;
    window.open(rabbit_mq_url, "_blank");
  };
  const handleClickCloudWatch = () => {
    const cloudwatch_url = row?.cloudwatch_url;
    window.open(cloudwatch_url, "_blank");
  };
  const handleClickRunECS = (numberCounter) => {
    let tagErrorMessage = "ECS Task";
    let taskEndpoint = "start_task/";

    CustomAPIRequest({
      id: record.id,
      pathEnd: taskEndpoint,
      stateSet: null,
      tagErrorMessage: tagErrorMessage,
      notify: notify,
      notifyMessage: "ECS Task Started",
      method: "post",
      bodyData: { task_definition: row?.td_name, counter: numberCounter },
    });
  };

  const icon_style = {
    width: icon_width,
    height: "30px",
    width: "30px",
    marginLeft: "0.8rem",
    cursor: "pointer",
    borderRadius: "8px",
  };
  return (
    <div
      style={{
        display: "flex",
        width: "auto",
        justifyContent: "space-around",
      }}
    >
      {!record.final_state &&
        row.last_batch_finished_at == null &&
        row?.queue_url && (
          <ButtonWithDialog
            variant="raised"
            color="transparent"
            style={{ ...icon_style, backgroundColor: "transparent" }}
            dialogTitleMessage={dialogTitleMessage}
            dialogContentMessage={dialogContentMessage}
            dialogNumberField={dialogNumberField}
            icon={ECSLogo}
            onConfirm={handleClickRunECS}
            numberFieldParams={{
              defaultValue: "1",
              max: "10",
              min: "0",
              step: "1",
            }}
          />
        )}
      {!record.final_state &&
        row.last_batch_finished_at == null &&
        row?.queue_url && (
          <img
            onClick={handleClickRabbitMQ}
            style={icon_style}
            src={RabbitMQLogo}
          />
        )}
      {
        // !record.final_state &&
        row?.cloudwatch_url && row.last_batch_finished_at == null && (
          <img
            onClick={handleClickCloudWatch}
            style={icon_style}
            src={CloudWatchLogo}
          />
        )
      }
      {row?.cloudwatch_url && row.last_batch_finished_at != null && (
        <img
          onClick={handleClickCloudWatch}
          style={icon_style}
          src={CloudWatchLogo_grey}
        />
      )}
    </div>
  );

  return null;
};
export default class JobStepStatusTable extends PureComponent {
  render() {
    // const notify = useNotify();
    // const refresh = useRefresh();
    // const redirect = useRedirect();
    // const dispatch = useDispatch();
    return (
      <TableContainer component={Paper}>
        <Table aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Step</StyledTableCell>
              {/* <StyledTableCell align="right">Initial Records</StyledTableCell> */}
              <StyledTableCell align="right">Batches</StyledTableCell>
              <StyledTableCell>Step Output</StyledTableCell>
              <StyledTableCell align="right">Output Records</StyledTableCell>
              <StyledTableCell>First Created At</StyledTableCell>
              <StyledTableCell>Last Finished At</StyledTableCell>
              {userHasPermission("management") && (
                <StyledTableCell>Links</StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.step_title}
                </TableCell>
                {/* <TableCell align="right">{row.initial_record_count}</TableCell> */}
                <TableCell align="right">{row.batches_count}</TableCell>
                <TableCell>{row.step_output}</TableCell>
                <TableCell align="right">{row.record_count}</TableCell>
                <TableCell>{row.first_batch_created_at}</TableCell>
                <TableCell>{row.last_batch_finished_at}</TableCell>
                {userHasPermission("management") && (
                  <TableCell>
                    <LinksField row={row} record={this.props.record} />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
