import React from 'react';
import { Show, ReferenceManyField, SearchInput, List, Filter, NumberInput, SimpleShowLayout, AutocompleteInput, ReferenceInput, ReferenceField, Datagrid, TextField, EditButton, CreateButton } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useRecordContext } from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import { perPageReferenceFields } from "../../constants";
import "../../index.css";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import Add from '@material-ui/icons/Add';
import Search from '@material-ui/icons/Search';
import ClearFiltersButton from '../../components/ClearFiltersButton';
import {validations} from "./validations";

const SourceEntityFilters = (props) => {
    return(
        <div>
            <h5 class="title">Source Entity ID</h5>
            <Filter {...props}>
                <NumberInput source="id" alwaysOn />
            </Filter>
            <h5 class="title">or ...</h5>
            <h5 class="title">Reference</h5>
            <Filter {...props}>
                <SearchInput source="reference" alwaysOn />
            </Filter>
            <h5 class="title">Win</h5>
            <Filter {...props}>
                <SearchInput source="win" alwaysOn />
            </Filter>
            <h5 class="title">Name</h5>
            <Filter {...props}>
                <SearchInput source="name" alwaysOn />
            </Filter>  
        </div>
    );
}

const Names = (props) => {
    console.log('props record', props.record)
    const id = props.record.id;
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ReferenceManyField
                    reference="source-entity-names"
                    target="source_entity"
                    label="Names"
                >
                    <Datagrid>
                        <TextField source="name" />
                        <EditButton />
                    </Datagrid>
                </ReferenceManyField>
                <Button label="Add name" 
                    component={Link}
                    to={{
                        pathname: 'source-entity-names/create',
                        state: {id:id}
                    }}
                    startIcon={<Add/>}
                    style = {{
                        color: "#3248A8"
                    }}
                >
                    Add name
                </Button>
                
            </SimpleShowLayout>
        </Show>
    );
}

const SourceEntitiesList = props => (
    <ThemeProvider theme={theme}>
        <h2>{props.options.label}</h2>
        <List filters={<SourceEntityFilters/>} bulkActionButtons={false} exporter={false} {...props} 
                actions={<ListToolbarActions showCreateButton={false} />} perPage={5}>
            <Datagrid expand={<Names/>}>
                <TextField label="Source Entity ID" source="id"/>
                <ReferenceField label="Source Code" source="source_id" reference="sources" link={false}>
                    <TextField source="code" />
                </ReferenceField>
                <TextField source="reference"/>
                <TextField source="win"/>
                {/* <ReferenceManyField label="Names" reference="source-entity-names" target="source_entity">
                    <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceManyField> */}
                <EditButton />
            </Datagrid>
        </List>
    </ThemeProvider>
);

export default SourceEntitiesList;