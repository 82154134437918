import React from 'react';
import { TextInput, NumberInput, BooleanInput, ReferenceInput, AutocompleteInput, DateInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import { validations } from './validations';

const KeyFigures = (props) => {
    console.log('key figures props');
    console.log(props);

    return (
        <div>
            { 'location' in props &&
                <TextInput source='stringAR' label='String AR' validate={validations.validateStringAR} />
            }
            <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes' validate={validations.validateLanguageCode}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <TextInput source='type' label='Type' defaultValue='Y' validate={validations.validateType} />
            <ReferenceInput source='levelOfRounding' label='Level of Rounding' reference='enumerations-rounding-levels' >
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <NumberInput source='number_of_months' label='Number of months' min={0} max={18} />
            <ReferenceInput source='currencyCode' label='Currency Code' reference='enumerations-currency-codes' >
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <BooleanInput source='restated' defaultValue={false} label='Restated?' validate={validations.validateRestated} />
            <BooleanInput source='active' defaultValue={true} label='Active?' validate={validations.validateActive} />
            <BooleanInput source='validated' defaultValue={true} label='Validated?' validate={validations.validateValidated} />
            <DateInput source='end' label='End Date' validate={validations.validateEnd} />
            <ArrayInput source='elements' label='Elements' validate={validations.validateElements} >
                <SimpleFormIterator>
                    <NumberInput source='value' label='Value' min={-9999999999999.9} max={9999999999999.9} validate={validations.validateElementsValue}/>
                    <ReferenceInput source='type' label='Type' reference='enumerations-financial-item-codes' validate={validations.validateElementsType} fullWidth={true}>
                        <AutocompleteInput optionText="label" optionValue="id" />
                    </ReferenceInput>
                    <BooleanInput source='exact' label="Is Exact?" defaultValue={false} validate={validations.validateElementsExact} />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source='elementsAR' label='ElementsAR' validate={validations.validateElementsAR} >
                <SimpleFormIterator>
                    <TextInput source='stringAR' label='String AR' validate={validations.validateElementsARstringAR} />
                    <TextInput source='valueAR' label='ValueAR' validate={validations.validateElementsARvalueAR} />
                    <ReferenceInput source='group' label='Group' reference='enumerations-group-financial-item-codes' validate={validations.validateElementsARGroup} fullWidth={true}>
                        <AutocompleteInput optionText="label" optionValue="code" />
                    </ReferenceInput>
                    <BooleanInput source='exact' label="Is Exact?" defaultValue={false} validate={validations.validateElementsARExact} />
                </SimpleFormIterator>
            </ArrayInput>
        </div>  
    );  
};

export default KeyFigures;