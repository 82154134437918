import React, {
    Fragment,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
    useNotify,
    useRedirect,
    fetchStart, fetchEnd,
    useRefresh
} from 'ra-core';

import Button from '@material-ui/core/Button';
import { Api } from "../../helpers/api";

const CompleteTaskButton = ({ record }) => {


    const dispatch = useDispatch();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const handleClick = e => {
        dispatch(fetchStart()); // start the global loading indicator 

        const body_data = {};

        if (record.status !== 1 && record.status !== 2){
            console.log("Task already finished");
            notify('Task already finished!', 'warning'); 
        }

        return Api.post(`/tasks/${record.id}/finish/`, body_data, true)
            .then(response => {
                if (response.type === "success") {
                    notify('Task finished');
                    redirect('/tasks');
                    refresh();
                } 
                else if (response.type === "auth_error") {
                    console.log("Error completing the Task - authentication error");
                    redirect('/login');
                }
                else {
                    console.log("Error completing the Task - response", response.type);
                    if (response.error) {
                        notify(response.error, 'warning');    
                    }
                    else {
                        notify("Could not complete the Task!", 'warning');
                    }
                }
            })
            .catch((e) => {
                console.log("Exception completing the Task - e", e);
                notify('Error completing the Task', 'warning')
            })
            .finally(() => {
                dispatch(fetchEnd()); // stop the global loading indicator
            });

        e.stopPropagation();
    };

    return (
        <Fragment>
            <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleClick}
            >
                Complete Task
            </Button>
        </Fragment>
    );
};

CompleteTaskButton.propTypes = {
    record: PropTypes.object,
};

export default CompleteTaskButton;
