import React from 'react';
import { TextField, TextInput, BooleanInput, ReferenceInput, AutocompleteInput, NumberInput, DateInput } from 'react-admin';
import { validations } from './validations';

const Capitals = (props) => {
    console.log('PROPS');
    console.log(props);

    return (
        <div>
            { 'location' in props && <TextInput source='textAR' fullWidth={true} label='Text AR' validate={validations.validateTextAR} />}
            <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes'>
                <AutocompleteInput optionText="label" optionValue="id" validate={validations.validateLanguageCode} />
            </ReferenceInput>
            <ReferenceInput source='levelOfRounding' label='Level of Rounding' reference='enumerations-rounding-levels'>
                <AutocompleteInput optionText="label" optionValue="id" validate={validations.validateLevelOfRounding} />
            </ReferenceInput>
            <ReferenceInput source='currencyCode' label='Currency Code' reference='enumerations-currency-codes' allowEmpty={true}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <NumberInput source='issued' label='Issued' min={-9999999999999.9} max={9999999999999.9} />
            <NumberInput source='paidup' label='Paidup' min={-9999999999999.9} max={9999999999999.9} />
            <ReferenceInput source='currencyCode2' label='Currency Code 2' reference='enumerations-currency-codes' allowEmpty={true}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <NumberInput source='issued2' label='Issued 2' min={-9999999999999.9} max={9999999999999.9} />
            <NumberInput source='paidup2' label='Paidup 2' min={-9999999999999.9} max={9999999999999.9} />
            <ReferenceInput source='currencyCode3' label='Currency Code 3' reference='enumerations-currency-codes' allowEmpty={true}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <NumberInput source='issued3' label='Issued 3' min={-9999999999999.9} max={9999999999999.9} />
            <NumberInput source='paidup3' label='Paidup 3' min={-9999999999999.9} max={9999999999999.9} />
            <DateInput source='start' label='Start Date' />
            <DateInput source='end' label='End Date' />
            <BooleanInput source='active' deafultValue={true} label='Active?' validate={validations.validateActive} />
        </div>
    );
};

export default Capitals;