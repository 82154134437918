import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, ArrayInput, SimpleFormIterator, NumberInput, DateInput } from 'react-admin';
import { validations } from './validations';


const Filings = (props) => {
    console.log('PROPS');
    console.log(props);

    return (
        <div>
            { 'location' in props &&
                <div>
                    <TextInput source='textAR' fullWidth={true} label='Text AR' validate={validations.validateTextAR} />
                    {/* check if typeAR is string or list */}
                    <TextInput source='typeAR' label='Type AR' validate={validations.validateTypeAR} />
                </div>
            }
            <ReferenceInput source='status' label='Status' reference='enumerations-filing-status' validate={validations.validateStatus} >
                <AutocompleteInput optionText="label" optionValue="code" />
            </ReferenceInput>
            <TextInput source='reference' label='reference' validate={validations.validateReference} />
            <TextInput source='url' label='Url' validate={validations.validateUrl} />
            <NumberInput source='serialNumber' label='Serial Number' min={1} max={9999999999} />
            <NumberInput source='mainSerialNumber' label='Main Serial Number' min={1} max={9999999999} />
            <DateInput source='effectiveDate' label='Effective Date' />
            <TextInput source='content' label='Content' validate={validations.validateContent} />
            <ReferenceInput source='type' label='Type' reference='enumerations-filing-types' allowEmpty={true}>
                <AutocompleteInput optionText="label" optionValue="code" />
            </ReferenceInput>
            <ReferenceInput source='languageCodes' label='Language Code' reference='enumerations-language-codes' allowEmpty={true}>
                <AutocompleteInput optionText="label" optionValue="code" />
            </ReferenceInput>
            <TextInput source='chapter' label='Chapter' validate={validations.validateChapter} />
            <TextInput source='title' label='Title' validate={validations.validateTitle} />
            <NumberInput source='pageNumber' label='Page number' min={1} max={99999} />
            <TextInput source='notification' label='Notification' validate={validations.validateNotification} />
            <ArrayInput source='contacts' label='Contacts' validate={validations.validateContacts} >
                <SimpleFormIterator>
                    <TextInput source='designation' label='Designation' validate={validations.validateDesignation} />
                    <ArrayInput source='textLines' label='Text lines' validate={validations.validateTextLines}>
                        <SimpleFormIterator>
                            <TextInput label='Text line' validate={validations.validateTextLine} />
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleFormIterator>
            </ArrayInput>
        </div>
    );
};

export default Filings;