import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, BooleanInput, FormDataConsumer, FileInput, FileField} from 'react-admin';
import { validations } from './validations';
import { perPageReferenceFields } from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const EnumerationsCreate = props => {
  return (
    <>
      <Create undoable={true} {...props}>
        <SimpleForm redirect="list">
          <BackButton {...props} action = {"create"}/>
          <PageTitle {...props}>Create</PageTitle>
          <BooleanInput source="bulkUpload" label="Multiple enumerations?" />
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => formData.bulkUpload &&
              <FileInput source="bulk_file" accept=".csv" label="Upload enumerations csv file" validate={validations.validateFile}>
                <FileField source="src" title="title"/>
              </FileInput>
            }
          </FormDataConsumer>

          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => !formData.bulkUpload &&
              <React.Fragment>
                <BooleanInput source="newPropName" label="New property name?" />
                <FormDataConsumer subscription={{ values: true }}>
                  {({ formData, ...rest }) => formData.newPropName &&
                    <TextInput fullWidth={true} source="property_name" {...rest} />
                  }
                </FormDataConsumer>
                <FormDataConsumer subscription={{ values: true }}>
                  {({ formData, ...rest }) => !formData.newPropName &&
                    <ReferenceInput label="Select property name" source="property_name" reference="enumerations-property-names" perPage={perPageReferenceFields}>
                      <SelectInput fullWidth={true} optionText="property_name" optionValue="property_name" />
                    </ReferenceInput>
                  }
                </FormDataConsumer>

                <TextInput source="code" fullWidth={true} validate={validations.validateCode} />
                <TextInput source="label" fullWidth={true} validate={validations.validateLabel} />
                <TextInput source="info1" fullWidth={true} validate={validations.validateInfo} />
                <TextInput source="info2" fullWidth={true} validate={validations.validateInfo} />
                <TextInput source="info3" fullWidth={true} validate={validations.validateInfo} />
                <TextInput source="info4" fullWidth={true} validate={validations.validateInfo} />
                <TextInput source="info5" fullWidth={true} validate={validations.validateInfo} />
                <BooleanInput source="active" label="Is it active?" defaultValue="1"/>
              </React.Fragment>
            }
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    </>
  );
}

export default EnumerationsCreate;
