import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const Markets = (props) => {

    return (
        <div>
            <MappingSectionInput field='longTextAR' required={true} {...props} />
            <MappingSectionInput field='exportMarketsAR' {...props} />
            <MappingSectionInput field='importMarketsAR' {...props} />
            <MappingSectionInput field='exportProductsAR' {...props} />
            <MappingSectionInput field='importProductsAR' {...props} />
            <MappingSectionInput field='languageCode' {...props} />
            <MappingSectionInput field='exporter' {...props} />
            <MappingSectionInput field='importer' {...props} />
            <MappingSectionInput field='exportPercentage' {...props} />
            <MappingSectionInput field='exportPercentageExact' {...props} />
            <MappingSectionInput field='importPercentage' {...props} />
            <MappingSectionInput field='importPercentageExact' {...props} />
            <MappingSectionInput field='exportRegions' {...props} />
            <MappingSectionInput field='exportCountries' {...props} />
            <MappingSectionInput field='importRegions' {...props} />
            <MappingSectionInput field='importCountries' {...props} />
            <MappingSectionInput field='active' required={true} {...props} />
            <MappingSectionInput field='validated' required={true} {...props} />
        </div>
    );
};

export default Markets;