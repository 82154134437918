import React from 'react';
import { Edit, TextInput, SelectInput, SimpleForm, required, ReferenceInput, AutocompleteInput, useNotify, useRedirect } from 'react-admin';
import { parameterTypes } from '../../constants';
import Box from "@material-ui/core/Box";
import {validations} from './validations';

const SupplierProductRequestsParamsEdit = props => {

    return (

        <Edit {...props}>
            <SimpleForm sx={{ maxWidth: 500 }} redirect={false} >
                <tr>
                    <td>
                        <TextInput source="key" validate={validations.validateKey} />
                    </td>
                    <td>
                        <TextInput source="value" validate={validations.validateValue} />
                    </td>
                    <td>
                        <SelectInput label='Parameter Type' source="parameter_type" choices={parameterTypes} optionText="text" optionValue="value" isRequired fullWidth validate={validations.validateParameterType} />
                    </td>
                    <td>
                        <TextInput source="parameter_label"  isRequired fullWidth validate={validations.validateParameterLabel} />
                    </td>
                </tr>
            </SimpleForm>
        </Edit>
    );
}

export default SupplierProductRequestsParamsEdit;