import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";


class BasicLineChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [{
                name: "XGUSBRD",
                data: [517,692,617,456,430,592,405,429,468,451,343,383]
            },
            {
                name: "XSCHREG",
                data: [24352,21447,23720,21110,29048,26578,19072,13091,13719,13282,13280,15452]
            },
            {
                name: "XSIDAHU",
                data: [null, null, 85544,42560,75148,78791,75946,83461,76768,76590,105508,124813]
            }
        ],
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: 'Number of companies per month',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                }
            },


        };
    }



    render() {
        return (


            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
            </div>


        );
    }
}

export default BasicLineChart;