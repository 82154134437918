import {
    required,
    maxLength,
    minValue,
    maxValue,
} from 'react-admin';

export const validations = {
    validateYear: [required(), minValue(1800), maxValue(2100)],
    validateMonth: [minValue(1), maxValue(12)],
    validateDay: [minValue(1), maxValue(31)],
    validateEntityDateType: [required()],
    validateInsertSource: [required()]
}