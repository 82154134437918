import React from 'react';
import {Show, SimpleShowLayout, TextField, RichTextField, FileField} from 'react-admin';
import "../../index.css";
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const ExtractionServiceScriptShow = props => {
    return (
        <div>
            <Show {...props}>
                <SimpleShowLayout>
                    <BackButton {...props} action = {"show"} />
                    <PageTitle {...props}>Show</PageTitle>
                    <TextField source="name" />
                    <TextField component="pre" source="code" />
                </SimpleShowLayout>
            </Show>
        </div>
    );
};

export default ExtractionServiceScriptShow;