import React, {useState, useEffect} from 'react';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import {Edit, SimpleForm, ReferenceManyField, NumberInput, TextInput, List, NumberField, AutocompleteInput, Datagrid, TextField, TabbedForm, FormTab,  ReferenceInput, SelectInput, BooleanField, DateField, BooleanInput} from 'react-admin';
import {validations} from './validations';
import {perPageReferenceFields, outputTypes} from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import ContentCreate from '@material-ui/icons/Create';
import SupplierProductRequestsParamsCreate from '../SupplierProductRequestsParams/Create';
import SupplierProductRequestsParamsEdit from '../SupplierProductRequestsParams/Edit';
import SupplierProductRequestsCredentialsEdit from '../SupplierProductRequestsCredentials/Edit';
import SupplierProductRequestsCredentialsCreate from '../SupplierProductRequestsCredentials/Create';
// const EntitiesEdit = props => {

//   return (
//     <>
//       <Edit undoable={true} {...props}>
//         <SimpleForm toolbar={<EditFormToolbarActions showDeleteButton={false}/>}>
//           <BackButton {...props} action = {"edit"}/>
//           <PageTitle {...props}>Edit</PageTitle>
//           <TextInput source="entity_type" />
//           <TextInput source="country_code" />
//           <TextInput source="successor_entity" />
//           <BooleanInput source="verified" label="Is it verified?"/>
//           <BooleanInput source="public" label="Is it public?"/>
//           <BooleanInput source="active" label="Is it active?"/>
//         </SimpleForm>
//       </Edit>
//     </>
//   );
// }


const SupplierProductRequestsEdit = props => {
  
  console.log('PROPS SPR EDIT', props);
  const [showToolbar, setShowToolbar] = useState(true);

  useEffect(() => {
    if (['params', 'credentials'].some(v => props.location.pathname.includes(v))) {
      setShowToolbar(false);
    }
    else {
      setShowToolbar(true);
    }
  }, [props.location.pathname]);

  
  return (
    <ThemeProvider theme={theme}>
        <BackButton {...props} action = {"edit"}/>
        <PageTitle {...props}>Edit</PageTitle>
        <Edit {...props}>
          <TabbedForm toolbar={showToolbar ? <EditFormToolbarActions showDeleteButton={false} /> : false}>
            <FormTab label="Details">
                <ReferenceInput source="supplier_product" reference="supplier-products" fullWidth validate={validations.validateSupplierProduct} >
                  <SelectInput optionText="product_label" />
                </ReferenceInput>
                <TextInput source="endpoint_url"  helperText="Url of the request" fullWidth validate={validations.validateEndpointUrl}/>
                <SelectInput 
                  source="request_method" 
                  choices={[
                    { id: 'GET', label: 'GET' },
                    { id: 'POST', label: 'POST' }
                  ]}
                  optionText="label"
                  helperText={"Type of the request"}
                  validate={validations.validateRequestMethod}
                />
                <TextInput label="Request label" source="label" fullWidth validate={validations.validateLabel}/>
                <BooleanInput source="is_order" defaultValue={false} label="Is it inside an order execution?"/>
                <NumberInput source="request_order" helperText={"execution ordering of the request (empty if no ordering is needed)"} min={1} max={10} defaultValue={null} label="Order of execution"/>
                <Typography variant="h6" gutterBottom>
                  Output
                </Typography>
                <TextInput source="output_check" fullWidth helperText={"path to check if the response is valid"} validate={validations.validateOutputCheck}/>
                <TextInput source="output_path" fullWidth helperText={"path of the response to return"} validate={validations.validateOutputPath}/>
                <SelectInput source="output_type" fullWidth={true} choices={outputTypes} optionText="text" optionValue="value" helperText={"type of the output in the response"} validate={validations.validateOutputType}/>
                <Typography variant="h6" gutterBottom>
                  Save in Cache
                </Typography>
                <BooleanInput source="save_response" helperText={"if the response should be saved in cache"} defaultValue={true} label="Save response in cache?"/>
                <TextInput label="Key to save the response" helperText={"key to where the response has to be associated in the cache"} source="key_save_response" validate={validations.validateKeySaveResponse}/>
                <Typography variant="h6" gutterBottom>
                  Fallback request
                </Typography>
                <ReferenceInput source="fallback_request" reference="supplier-product-requests" allowEmpty 
                  validate={validations.validateFallbackRequest}
                >
                  <AutocompleteInput optionText="label" helperText={"request to perform if the current request fails"} filterToQuery={{id_ne: props.id}}/>
                </ReferenceInput>
                <Typography variant="h6" gutterBottom>
                    Failure
                </Typography>
                <TextInput label="Message on failure" helperText={"message to show when error occurs"} source="error_message" fullWidth={true} validate={validations.validateErrorMessage}/>
            </FormTab>
            <FormTab label="credentials" path='credentials' >
              <SupplierProductRequestsCredentialsCreate {...props} />
              <ReferenceManyField fullWidth reference="supplier-product-requests-credentials" target="supplier_product_request_id" addLabel={false}
                  perPage={perPageReferenceFields} sort={{ field: 'id', order: 'DESC' }}
              >
                  <List {...props}
                  filterDefaultValues={{supplier_product_request_id: props.id}}
                  exporter={false}
                  bulkActionButtons={<DocBulkActionButtons/>}
                  actions={false}
                  > 
                      <Datagrid expand={<SupplierProductRequestsCredentialsEdit {...props} />}>
                        <TextField source="key"/>
                        <TextField source="value"/>
                        <TextField source="credential_type" />
                        <TextField source="credential_location" />
                      </Datagrid>
                  </List>
              </ReferenceManyField>
            </FormTab>
            <FormTab label="Params" path='params' >
              <SupplierProductRequestsParamsCreate {...props} />
              <ReferenceManyField fullWidth reference="supplier-product-requests-params" target="supplier_product_request_id" addLabel={false}
                  perPage={perPageReferenceFields} sort={{ field: 'id', order: 'DESC' }}
              > 
                <List {...props}
                  filterDefaultValues={{supplier_product_request_id: props.id}}
                  exporter={false}
                  bulkActionButtons={<DocBulkActionButtons/>}
                  actions={false}
                > 
                  <Datagrid expand={<SupplierProductRequestsParamsEdit {...props} />}>
                    <TextField source="key" />
                    <TextField source="value" />
                    <TextField source="parameter_type"/>
                    <TextField source="parameter_label"/>
                  </Datagrid>
                </List>
              </ReferenceManyField>
              </FormTab>
          </TabbedForm>
        </Edit>
    </ThemeProvider>
  );
}


export default SupplierProductRequestsEdit;
