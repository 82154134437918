import React from 'react';
import { TextField, TextInput, BooleanInput, AutocompleteInput, SelectInput, ReferenceInput, DateInput } from 'react-admin';
import { validations } from './validations';

const CompanyNames = (props) => (
    
        <div>
            { 'location' in props &&  <TextInput source='nameAR' fullWidth={true} label='Name AR' validate={validations.validateNameAR} />}
            <TextInput source='name' fullWidth={true} label='Name' validate={validations.validateName} />
            <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes' validate={validations.validateLanguageCode}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput source='translationCode' label='Translation Code' reference='enumerations-translation-codes' allowEmpty={true} >
                <SelectInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <DateInput source='start' label='Start Date' />
            <DateInput source='end' label='End Date' />
            <BooleanInput source='active' initialValue={true} label='Active?' validate={validations.validateActive} />
            <BooleanInput source='controlled' initialValue={false} label='Controlled?' helperText='Manually controlled'/>
        </div>
            
)

export default CompanyNames;