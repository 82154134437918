import List from './List';
// import Create from './Create';
import Edit from './Edit';
import Icon from '@material-ui/icons/EventNote';

export default {
  list: List,
  icon: Icon,
  edit: Edit,
//   create: Create
};