import React from 'react';
import { List, TextInput, SimpleForm } from 'react-admin';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../../constants/styleOverride";
import { Api } from '../../../helpers/api';
import {useNotify} from 'ra-core';

const MongoIPList = props => {
    const notify = useNotify();
    const saveIP = (data) => {
        return Api.post('/mongo-ip/save-ip/', data, true)
            .then(response => {
                console.log(response)
                if(response.result.status !== 200) {
                    var message = response.result.message.message
                    var data = response.result.message.data
                    var notification = message.concat(': ', data)
                    notify(notification, {type: 'error', autoHideDuration: 5000 })
                }
                else {
                    notify('Whitelisted IP successfully', 'success');
                }
            });
    };

    return(<ThemeProvider theme={theme}>
        <h2>{props.options.label}</h2>
        
        <SimpleForm save={(data) => {console.log(data); saveIP(data);}} {...props}>
            <TextInput source='ipAddress' label='IP' fullWidth={true} />
            <TextInput source='comment' label='Comment' fullWidth={true} />
        </SimpleForm>
    </ThemeProvider>);
}

export default MongoIPList;
