import React from 'react';
import { useState, useEffect } from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, BooleanInput, useDataProvider, Loading, Error, useCreateController, AutocompleteInput } from 'react-admin';
import { validations } from "./validations";
import { priorityOptions, perPageReferenceFields } from "../../constants";
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const ProcessCreate = props => {
    const dataProvider = useDataProvider();
    const [sources, setSources] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const {
        loaded, // boolean that is false until the record is available
        record, // record fetched via dataProvider.getOne() based on the id from the location
    } = useCreateController(props);

    useEffect(() => {

        if (loaded && !sources) { 
            dataProvider.getList('sources', {
                pagination: { page: 1, perPage: 300 },
                sort: { field: 'code', order: 'ASC' },
            })
                .then(( response ) => {
                    if (response) {
                        setSources(response.data);
                    }
                    else {
                        console.log("response", response);
                    }
                    setLoading(false);
                })
                .catch(error => {
                    console.log("error", error);
                    setError(error);
                    setLoading(false);
                })
        }
    }, [record, dataProvider, loaded, sources]);

    if (loading) return <Loading />;
    if (error) return <Error />;

    return (
        <>
            <Create {...props}>
                <SimpleForm redirect="list">
                    <BackButton {...props} action = {"create"}/>
                    <PageTitle {...props}>Create</PageTitle>
                    <TextInput source="title" fullWidth={true} validate={validations.validateTitle} />
                    <TextInput multiline source="description" fullWidth={true} validate={validations.validateDescription} />
                    <ReferenceInput label="Source" source="source_code" reference="process-source-codes" perPage={perPageReferenceFields}>
                        <AutocompleteInput optionText="code" validate={validations.validateSelection}/>
                    </ReferenceInput>
                    <BooleanInput source="subprocess" initialValue={false} label="Can be a sub-process?" />
                    <BooleanInput source="active" initialValue={false} label="Is active?" />
                    <SelectInput source="priority" choices={priorityOptions} optionText="text" optionValue="value"
                        validate={validations.validatePriority} />
                    <ReferenceInput label="Initial Step" source="initial_step" reference="steps" perPage={perPageReferenceFields} 
                            allowEmpty validate={validations.validateInitialStep}>
                        <SelectInput optionText="title" />
                    </ReferenceInput>
                    <ReferenceInput label="Product" source="product_id" reference="products" perPage={perPageReferenceFields} 
                            allowEmpty>
                        <SelectInput optionText="label" />
                    </ReferenceInput>
                    <ReferenceInput label="Script" source="script_id" reference="extraction-service-script" perPage={perPageReferenceFields} 
                            allowEmpty>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <TextInput source="recipient" label="Process Owners" helperText="Emails separated by ;" fullWidth={true} validate={validations.validateRecipient} />
                </SimpleForm>
            </Create>
        </>
    );
}

export default ProcessCreate;