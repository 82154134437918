import React from 'react';
import Addresses from './Addresses';
import IdKeys from './IdKeys';
import Names from './Names';
import CommAddresses from './CommAddresses';
import CommNumbers from './CommNumbers';
import Sics from './Sics';
import Texts from './Texts';
import OrdinaryCapitals from './OrdinaryCapitals';
import Booleans from './Booleans';
import Credits from './Credits';
import Predicates from './Predicates';
import Licenses from './Licenses';
import KeyFinancials from './keyFinancials';
import Events from './Events';
import Financials from './Financials';
import Shares from './Shares';
import StockExchanges from './StockExchanges';
import Markets from './Markets';
import Notes from './Notes';
import PersonNames from './PersonNames';
import Relations from './Relations';
import Others from './Others';
import Hscs from './Hscs';
import Transactions from './Transactions';

const IMSectionForm = (props) => {
    let section = props.section
    return (
        <>
            {['addresses', 'adminAddresses', 'businessAddresses', 'postalAddresses', 'legalDomiciles', 'liquidationAddresses', 'professionalAddresses', 'birthPlace', 'nationalities', 'branchAddresses'].indexOf(section) > -1 &&
                <Addresses section={section} {...props} />
            }
            {section === 'idkeys' &&
                <IdKeys {...props} />
            }
            { ['names', 'businessNames', 'shortNames'].indexOf(section) > -1 && 
                <Names {...props} />
            }
            {['emailAddresses', 'webAddresses', 'skypeAddresses', 'facebookAddresses', 'instagramAddresses', 'linkedinAddresses', 'twitterAddresses', 'youtubeAddresses', 'googleplusAddresses'].indexOf(section) > -1 &&
                <CommAddresses {...props} />
            }
            {['phoneNumbers', 'faxNumbers', 'mobileNumbers'].indexOf(section) > -1 &&
                <CommNumbers {...props} />
            }
            {['companyStatuses', 'companyTypes', 'registrations', 'personTypes', 'personStatuses'].indexOf(section) > -1 &&
                <Predicates {...props} />
            }
            {section === 'sics' &&
                <Sics {...props} />
            }
            {['activities', 'products', 'industries', 'profiles', 'purposes', 'creditTexts', 'mortgageTexts', 'adverseTexts', 'otherContents'].indexOf(section) > -1 &&
                <Texts {...props} />
            }
            {section === 'ordinaryCapitals' &&
                <OrdinaryCapitals {...props} />
            }
            {section === 'booleans' &&
                <Booleans {...props} />
            }
            {section === 'credits' &&
                <Credits {...props} />
            }
            {section === 'licenses' &&
                <Licenses {...props} />
            }
            {['standardFinancialsSL', 'standardFinancialsCD'].indexOf(section) > -1 &&
                <KeyFinancials {...props} />
            }
            {section === 'events' && 
                <Events {...props} />
            }
            {section === 'financials' &&
                <Financials {...props} />
            }
            {section === 'shares' &&
                <Shares {...props} />
            }
            {section === 'stockExchanges' &&
                <StockExchanges {...props} />
        }
            {section === 'markets' &&
                <Markets {...props} />
            }
            {section === 'notes' &&
                <Notes {...props} />
            }
            {section === 'personNames' &&
                <PersonNames {...props} />
            }
            {['directorshipsParent', 'directorshipsChild', 'ownershipsParent', 'ownershipsChild', 'relationshipsParent', 'relationshipsChild', 'connectionsParent'].indexOf(section) > -1 &&
                <Relations {...props} />
            }
            {section === 'others' &&
                <Others {...props} />
            }
            {section === 'hscs' &&
                <Hscs {...props} />
            }
            {section === 'transactions' &&
                <Transactions {...props} />
            }
        </>
    );
}

export default IMSectionForm;
