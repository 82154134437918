import React, {
    Fragment,
    useState,
} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const FixIssuesButton = ({ record, values }) => {
    
    if (record == null){
        return <>
        
        </>
    }
    
    values['document_id'] = record.id;
    values['batch_id'] = record.batch_id;
    let val = `batch_id=${values['batch_id']}&document_id=${values['document_id']}`
    console.log(values);

    return (
        <Fragment>
            <Button
                variant="outlined"
                color="primary"
                size="small"
                component={Link}
                to={{
                    pathname: '/document-edit',
                    search: val
                }}
                >
                Fix Issues
            </Button>
        </Fragment>
    );
};

FixIssuesButton.propTypes = {
    record: PropTypes.object,
    values: PropTypes.object
};

export default FixIssuesButton;