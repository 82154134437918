import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import Tooltip from '@material-ui/core/Tooltip';
import { useNotify, useRedirect } from 'ra-core';
import { AuthHelper } from "../../helpers/authHelper";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        cursor: "pointer",
    },
}));

const NotificationsBadge = props => {
    const [numNotifications, setNumNotifications] = useState(0);
    const [lastMessageReceived, setLastMessageReceived] = useState(undefined);
    const classes = useStyles();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleClick = (event) => {
        setNumNotifications(0);
        redirect("/notifications");
    };

    const handleNewMessage = () => {
        setNumNotifications(numNotifications + 1);
        notify(lastMessageReceived.message);
    }

    const initWebSocket = () => {
        //console.log("initWebSocket");

        const wsUri = `ws://${window.location.hostname}/ws`;
        const websocket = new WebSocket(wsUri);
    
        websocket.onopen = function (evt) {
            console.log("websocket: connected!");
            const msgToSend = AuthHelper.getSessionToken();
            websocket.send(msgToSend);
        };

        websocket.onmessage = function (evt) {
            console.log("websocket: message received");
            var msg = evt.data;
            setLastMessageReceived({
                received_at: new Date(),
                message: msg
            });
        };

        websocket.onclose = function (evt) {
            console.log("websocket: closed connection!");
        };

        websocket.onerror = function (evt) {
            console.log("websocket: error", evt);
        };
    };


    useEffect(() => {
        //console.log("useEffect1");
        initWebSocket();
    }, []);

    useEffect(() => {
        //console.log("useEffect2");
        if (lastMessageReceived) {
            handleNewMessage();
        }
    }, [lastMessageReceived]);

    return (
        <div className={classes.root} onClick={handleClick}>
            <Tooltip title="Notifications">
                <Badge badgeContent={numNotifications} color="primary">
                    <MailIcon />
                </Badge>
            </Tooltip>
        </div>
    );
};

export default NotificationsBadge;