import React, {
    Fragment,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
    useNotify,
    useRedirect,
    fetchStart, fetchEnd, 
} from 'ra-core';

import Button from '@material-ui/core/Button';
import { Confirm } from 'react-admin';
import { Api } from "../../helpers/api";

const CloseAlertButton = ({ record }) => {

    const dispatch = useDispatch();
    const [open, setOpen, loading] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();

    const handleClick = e => {
        setOpen(true);
        e.stopPropagation();
    };

    const handleDialogClose = e => {
        setOpen(false);
        e.stopPropagation();
    };

    const handleCloseAlert = e => {
        dispatch(fetchStart()); // start the global loading indicator 

        const body_data = {};

        return Api.post(`/alerts/${record.id}/close-alert/`, body_data, true)
            .then(response => {
                if (response.type === "success") {
                    notify('Step set to be re-executed');
                    redirect('/alerts');
                    window.location.reload();
                } 
                else if (response.type === "auth_error") {
                    console.log("Error re-executing the step - authentication error");
                    redirect('/login');
                }
                else {
                    console.log("Error re-executing the step - response", response.type);
                    if (response.error) {
                        notify(response.error, 'warning');    
                    }
                    else {
                        notify("Could not re-execute the step!", 'warning');
                    }
                }
            })
            .catch((e) => {
                console.log("Exception re-executing the step - e", e);
                notify('Error re-executing the step', 'warning')
            })
            .finally(() => {
                dispatch(fetchEnd()); // stop the global loading indicator
            });
    };

    const dialogContent = <Fragment>
        <div>Do you want to re-execute this step?</div>
    </Fragment>

    return (
        <Fragment>
            <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleClick}
            >
                Re-execute Step
            </Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title="Please, confirm:"
                content={dialogContent}
                onConfirm={handleCloseAlert}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

CloseAlertButton.propTypes = {
    record: PropTypes.object,
};

export default CloseAlertButton;
