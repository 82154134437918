import React from "react";
import { SimpleShowLayout } from "react-admin";
import "../../index.css";
import { ThemeProvider } from "@material-ui/core/styles";
import TableComponent from "../../components/TableComponent";
import theme from "../../constants/styleOverride";

const JobInfoShow = (props) => {
  const batches = props.record.batches;
  const wins = props.record.wins;

  const BatchField = ({ record }) => {
    const batch_record = { ...record, batches: batches };

    if (batch_record) {
      return (
        <div style={{ display: "flex", paddingTop: 30 }}>
          <TableComponent
            headers={["job_batch", "documents"]}
            values={batch_record.batches}
            nestedHeader="document_id"
            styleCell={{
              fontSize: "14px",
              borderWidth: "0px",
              boxShadow:
                "0 1px 2px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
            }}
            styleTable={{
              boxShadow:
                "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
            }}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const WinsField = ({ record }) => {
    const win_record = { ...record, wins: wins };

    if (win_record) {
      return (
        <div style={{ display: "flex", paddingTop: 20 }}>
          <TableComponent
            headers={["WIN"]}
            values={wins}
            rowClick={handleWinClick}
            styleTable={{
              boxShadow:
                "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
            }}
            styleRow={{
              display: "flex",
              justifyContent: "space-around",
            }}
            styleCell={{
              fontSize: "12px",
              cursor: "pointer",
              borderWidth: "0px",
              color: "#0B0080",
              textDecoration: "underline",
            }}
            gridCol={5}
            ref={props.ref}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const handleWinClick = (row) => {
    const cimsy_url = row?.cimsy_url;
    window.open(cimsy_url, "_blank");
  };

  return (
    <ThemeProvider theme={theme}>
      <SimpleShowLayout {...props}>
        <h2>WIN's & Batches</h2>
        {wins && <WinsField record={props} />}
        {batches && <BatchField {...props} />}
      </SimpleShowLayout>
    </ThemeProvider>
  );
};

export default JobInfoShow;
