//styleOverride.js

import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    overrides: {
        RaBulkActionsToolbar: {
            toolbar: {
                backgroundColor: "transparent"
            },
            title: {
                color: "transparent"
            },
            icon: {
                color: "transparent"
            }
        },
        RaListToolbar: {
            toolbar: {
                justifyContent: "flex-start",
                ["@media (max-width: 599.95px)"]:{
                    backgroundColor: "transparent"
                },
            },
            actions: {
                ["@media (max-width: 599.95px)"]:{
                    backgroundColor: "transparent"
                }
            }
        },
        RaAutocompleteSuggestionList: {
            suggestionsPaper: {
                backgroundColor: "#fff",
                boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
            }
        },
        RaFilter:{
            form:{
                zIndex: "5",
                marginBottom: "2px"
            }
        },
        RaButton:{
            button:{
                zIndex: "5",
                left: "0px"
            },
            display: "inline-block"
        },
        RaTopToolbar:{
            root:{
                display: "block",
                ["@media (max-width: 599.95px)"]:{
                    backgroundColor: "transparent"
                },
                padding:"8px",
                paddingRight: "16px"
            }
        },
        MuiButtonBase:{
            root:{
                zIndex: "5"
            }
        },
        MuiButton:{
            root:{
                zIndex: "5"
            }
        },
        MuiPaper: {
            elevation1: {
                backgroundColor: "transparent",
                boxShadow: "none",
            },
        },
        MuiTable: {
            root: {
                boxShadow:
                    "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
            }
        },
        MuiToolbar:{
            root: {
                ["@media (max-width: 599.95px)"]:{
                    backgroundColor: "transparent"
                }
            },
            padding: "8px"
        },
        RaSingleFieldList:{
            root: {
                width: "100%",
            }
        },
        MuiFormControlLabel:{
            root: {
                width: "max-content",
            }
        },
        FileInput: {
            backgroundColor: "yellow"
        },
    }
});

export default theme;
