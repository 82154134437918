import React, {
    Fragment,
} from 'react';
import PropTypes from 'prop-types';

import {
    useRedirect,
    useNotify
} from 'ra-core';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { parse } from 'query-string';
import { Api } from "../../helpers/api";

const BackButton = ({batch_id, action, ...props}) => {
    let redirect_url = `${props.basePath}/`
    console.log('BASE PATH', props.basePath);
    if (props.basePath == "/process-step-transitions"){
        const location_search = props.location.search
        const id = location_search.substring(location_search.indexOf('=') + 1)
        redirect_url = `/processes/${id}/steps`
    }
    if (props.basePath == "/document-edit"){
        if (props.location.pathname=="/document-edit"){
            redirect_url = `/documents?filter={"batch_id":` + batch_id + `}`
        }
        else if (props.location.pathname=="/document-edit/create"){
            redirect_url = `/document-edit?filter={"document_id":"${props.location.state.doc_id}","entity_id":"${props.location.state.entity_id}","section_name":"${props.location.state.section}"}`
            if (props.location.state.section == 'filing') {
                redirect_url = `/document-edit?filter={"document_id":"${props.location.state.doc_id}","entity_id":"${props.location.state.entity_id}","section_name":"header"}`
            }
        }
    }
    if (['/entity-names', '/entity-idkeys', '/entity-dates', '/entity-predicates'].indexOf(props.basePath) > -1){
        const { entity_id: entity_id_string } = parse(props.location.search);
        const entity_id = entity_id_string ? parseInt(entity_id_string, 10) : '';
        redirect_url = `/entities/${entity_id}/${props.location.state.entity_info}`
    }

    console.log('props back', props)

    let label = '';

    if ('label' in props.options){
        label = props.options.label
        if (props.basePath == "/source-section-quality-levels"){
            redirect_url = "/source-codes"
            label = "Sources"
        }
    }
    else {
        label = props.resource.replace(/-/g, " ")
    }

    if (props.basePath == "/entities") {
        if ('art_id' in props){
            redirect_url = `/entities?filter={"art_id":"${props.art_id}"}`;
        }
        else{
            redirect_url = `/entities?batch_id=${batch_id}`;
            label = "Entity Matching GUI";
        }
    }

    let singular_item = ''
    if (label.slice(-4) == 'sses'){
        singular_item = label.slice(0,-2)
    }
    else if (label.slice(-1) == 's'){
        singular_item = label.slice(0,-1) 
    }
    else if (label == 'document edit'){
        singular_item = 'object'
    }
    else if (label == 'AT-Editor'){
        if (props.location.pathname=="/document-edit/create"){
            singular_item = 'section/object'
        }
        else{
            singular_item = 'document'
        }
    }
    else if (label == 'SE Editor'){
        singular_item = 'source entity'
    }
    else{
        singular_item = label;
        if (label === "Entity Matching GUI"){
            singular_item = "a document";
        }
    }

    if (label=="source entity names"){
        label = 'SE Editor'
        redirect_url = `/source-entities`
    }

    /* Case of add section or object in at-editor */
    if (props.location.pathname=="/document-edit/create"){
        label = 'Document'
    }

    if ('location' in props){
        if ('state' in props.location){
            if(props.location.state && 'prevPath' in props.location.state){
                redirect_url = `${props.location.state.prevPath}/`
                label = props.location.state.prevPath.replace(/-/g, " ").replace(/\//g, "")
                singular_item = props.options.label.slice(0,-1).replace("My ", "")
            }
        }
    }

    console.log('label', label)
    console.log('singular item', singular_item)
    console.log('redirect_url', redirect_url)

    const redirect = useRedirect();
    const notify = useNotify();
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false);

    };

    const handleClick = e => {
        if (props.basePath == "/import-mappings") {
            Api.post(`/import-mappings/unlock/`, {"doc_id": props.id}).then(
                response => {
                    if (response.type === 'success'){
                        notify('User has finished editing')
                    }
                    else{
                        notify('Bad Request')
                    }
                }
            );
        }
        redirect(redirect_url);
    };

    if (action=='show'){
        return (
            <Fragment>
                <Button
                    style={{
                        "alignSelf": "flex-start", 
                        "color":"#3248A8",
                        "fontStyle": "normal",
                        "fontWeight": "400",
                        "fontSize": "12px",
                        "lineHeight": "150%",
                        "justifyContent": "left",
                        "padding": "6px 0px",
                        "maxWidth": "fit-content",
                        "width": "fit-content",
                    }}
                    onClick={handleClick}
                >
                    &#60; Back to {label}  
                </Button>
            </Fragment>
        );
    }
    else {
        return (
            <Fragment>
                <Button
                    style={{
                        "alignSelf": "flex-start", 
                        "color":"#3248A8",
                        "fontStyle": "normal",
                        "fontWeight": "400",
                        "fontSize": "12px",
                        "lineHeight": "150%",
                        "justifyContent": "left",
                        "padding": "6px 0px",
                        "maxWidth": "fit-content",
                        "width": "fit-content",
                    }}
                    onClick={handleClickOpen}
                >
                    &#60; Back to {label}  
                </Button>
                <Dialog
                    id = "dialog-box"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <span style={{"fontWeight": "bold"}}>Are you sure you want to leave this page?</span>
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText 
                        id="alert-dialog-description"
                    >
                        You have started to {action} a {singular_item.toLowerCase()}. Changes will be lost.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        style={{
                            /* current_last */
                            background: "#F4F4F4",
                            /* Shadow/xs */
                            borderColor: "#F4F4F4",
                            borderRadius: "8px",
                            fontWeight: "bold",
                            fontSize: "85%",
                            textTransform:"none"
                        }}
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={() => {
                            handleClick();
                        }}
                        autoFocus
                        variant="outlined"
                        style={{
                            background: "#3248A8",
                            boxSizing: "border-box",
                            /* Shadow/xs */
                            borderRadius: "8px",
                            border: "#3248A8",
                            /* Inside auto layout */
                            color: "#FFFFFF",
                            fontSize: "85%",
                            fontWeight: "bold",
                            textTransform:"none"
                        }}
                    >
                        Yes, I'm sure 
                    </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
};

BackButton.propTypes = {
    record: PropTypes.object,
};

export default BackButton;
