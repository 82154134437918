import React from 'react';
import { Create } from 'react-admin';
import { StepTransitionCustomForm } from "./stepTransitionCustomForm";

const ProcessStepTransitionCreate = props => {
    return (
        <Create {...props}>
            <StepTransitionCustomForm {...props} showDelete={false} />
        </Create>
    )
};

export default ProcessStepTransitionCreate;