import React, {
    Fragment,
} from 'react';
import PropTypes from 'prop-types';

const JobLinkField = ({ source, record, label = {} }) => 
    <Fragment>
        <a href={`#/jobs/${record[source]}/show`}>{label}</a>
    </Fragment>


JobLinkField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default JobLinkField;