import React from 'react';
import ButtonWithDialog from "../../components/ButtonWithDialog";
import { Button } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';


export const useButtonStyles = makeStyles({
    complete: {
      backgroundColor: "green",
      fontWeight: "bold",
      fontSize: "10px",
      color: "white",
      '&:hover': {
        background: "#32521B",
      }
    },
    delete: {
      backgroundColor: "#F84B4B",
      fontWeight: "bold",
      fontSize: "10px",
      color: "white",
      '&:hover': {
        background: "#D94242",
      }
    },
    popUp: {
      backgroundColor: "#41DBDB",
      fontWeight: "bold",
      fontSize: "10px",
      color: "white",
      '&:hover': {
        background: "#39C0C0",
      }
    },
    add: {
      backgroundColor: "#2173E2",
      fontWeight: "bold",
      fontSize: "10px",
      color: "white",
      '&:hover': {
        background: "#1D65C6",
      }
    }
});

export const ShowDocButton = ({onShowDocClick}) => {
  const classes = useButtonStyles();
  return <Button 
            label="SHOW DOC" 
            id="show_doc" 
            variant="contained" 
            color="primary"
            className={classes.popUp}
            onClick={() => onShowDocClick()}
        />
}

export const CompleteTaskButton = ({batchId, onCompleteTask}) => {
    const classes = useButtonStyles();
    return  <ButtonWithDialog
              variant="contained" 
              color="secondary"
              className={classes.complete}
              endIcon={<CheckCircleIcon />}
              buttonLabel="Complete Task"
              dialogTitleMessage="Are you sure you want to complete this task?"
              dialogContentMessage={`Complete task for batch ${batchId}? You can't undo this`}
              confirmButtonLabel="Confirm"
              onConfirm={()=> onCompleteTask(batchId)}
            />
}

export const DeleteEntityButton = ({values, entity_name, batchId, onEntityDeletion}) => {
  const classes = useButtonStyles();
  return <ButtonWithDialog
      variant="contained" 
      color="secondary"
      className={classes.delete}
      startIcon={<DeleteIcon />}
      buttonLabel="Delete Entity"
      dialogTitleMessage="Are you sure you want to delete this entity from Doc?"
      dialogContentMessage={`Delete ${entity_name} from document ${values["doc_id"]}? You can't undo this`}
      confirmButtonLabel="Delete"
      onConfirm={() => onEntityDeletion(values, batchId)}
      buttonLoadingLabel="Deleting entity from document..."
    />
}