import React from 'react';
import { TextInput, ArrayInput, ReferenceInput, SelectInput, SimpleFormIterator, BooleanInput, DateInput } from 'react-admin';
import { validations } from './validations';

const Sics = props => {
        console.log('SICS PROPS');
        console.log(props);
        
        return(
        
        <div>
            { 'location' in props && 
                <TextInput source='textAR' fullWidth={true} label='Text AR' validate={validations.validateTextAR} />
            }
            <ArrayInput source="industryCodes" label='Industry Codes' validate={validations.validateIndustryCodes}>
                <SimpleFormIterator>
                    <TextInput label='Industry Code' validate={validations.validateIndustryCode} />
                </SimpleFormIterator>
            </ArrayInput>
            <ReferenceInput source='label' label='Label' reference='enumerations-sic-labels' validate={validations.validateLabel} >
                <SelectInput optionText='label' />
            </ReferenceInput>
            <DateInput source='start' label='Start Date' />
            <DateInput source='end' label='End Date' />
            <BooleanInput source='active' initialValue={true} label='Active?' validate={validations.validateActive} />
            <BooleanInput source='validated' initialValue={true} label='Validated?' validate={validations.validateValidated} />
        </div>
        
        );
            
};

export default Sics;