import React, {
    Fragment,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { priorityOptions } from "../../constants";

import {
    useNotify,
    useRedirect,
    useRefresh,
    fetchStart, fetchEnd, 
} from 'ra-core';

import Button from '@material-ui/core/Button';
import { Confirm } from 'react-admin';
import { Api } from "../../helpers/api";

const UpdateDocButton = ({ values }) => {
    console.log(values);
    // const translate = useTranslate();

    const dispatch = useDispatch();
    const [open, setOpen, loading] = useState(false);
    // const [priority, setPriority] = useState(record.priority);
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const handleClick = e => {

        const body = values;

        return Api.post(`/documents/update/`, body, true)
            .then(response => {
                if (response.type === "success") {
                    console.log("result", response.result);
                    notify("Document sucessfully updated!!!")
                    window.location.reload();
                } 
                else if (response.type === "auth_error") {
                    console.log("Error starting the process - authentication error");
                    redirect('/login');
                }
                else {
                    console.log("Error starting the process - response", response);
                    if (response.error) {
                        notify(response.error, 'warning');    
                    }
                    else {
                        notify("Could not start the process!", 'warning');
                    }
                }
            })
            .catch((e) => {
                console.log("Exception starting the process - e", e);
                notify('Error starting the process!', 'warning')
            })
            .finally(() => {
                dispatch(fetchEnd()); // stop the global loading indicator
            });
    };

    /* const handlePriorityChange = e => {
        setPriority(e.target.value);
        e.stopPropagation();
    } */

    /* const handleStartJob = e => {
        dispatch(fetchStart()); // start the global loading indicator 

        const body = {
            priority: priority
        };

        return Api.post(`/processes/${record.id}/start/`, body, true)
            .then(response => {
                if (response.type === "success") {
                    console.log("result", response.result);
                    notify('Process started');
                    redirect('/jobs/' + response.result.job_id + "/show");
                } 
                else if (response.type === "auth_error") {
                    console.log("Error starting the process - authentication error");
                    redirect('/login');
                }
                else {
                    console.log("Error starting the process - response", response);
                    if (response.error) {
                        notify(response.error, 'warning');    
                    }
                    else {
                        notify("Could not start the process!", 'warning');
                    }
                }
            })
            .catch((e) => {
                console.log("Exception starting the process - e", e);
                notify('Error starting the process!', 'warning')
            })
            .finally(() => {
                dispatch(fetchEnd()); // stop the global loading indicator
            });        
    }; */


    return (
        <Fragment>
            <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleClick}
            >
                Update Document
            </Button>
        </Fragment>
    );
};

UpdateDocButton.propTypes = {
    record: PropTypes.object,
};

export default UpdateDocButton;