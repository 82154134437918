import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const PersonNames = (props) => {

    return (
        <div>
            <MappingSectionInput field='nameAR' required={true} {...props} />
            <MappingSectionInput field='fullName'{...props} />
            <MappingSectionInput field='title' {...props} />
            <MappingSectionInput field='firstName' {...props} />
            <MappingSectionInput field='middleName' {...props} />
            <MappingSectionInput field='lastName' {...props} />
            <MappingSectionInput field='languageCode' required={true} {...props} />
            <MappingSectionInput field='translationCode' {...props} />
            <MappingSectionInput field='start' {...props} />
            <MappingSectionInput field='end' {...props} />
            <MappingSectionInput field='characterControlled' {...props} />
            <MappingSectionInput field='active' required={true} {...props} />
            <MappingSectionInput field='validated' required={true} {...props} />
        </div>
    );
};

export default PersonNames;