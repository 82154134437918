import React from 'react';
import { Create, SimpleForm, TextInput} from 'react-admin';
import { validations } from "./validations";
import {perPageReferenceFields} from "../../../constants";

const ArtTenceConfigCreate = props => (
    <>
        <h2>{props.options.label}</h2>
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput label='Key' source="key" fullWidth={true} validate={validations.validateKey} />
                <TextInput multiline resettable label='Configuration' source="configuration" fullWidth={true} validate={validations.validateConfiguration} />                
            </SimpleForm>
        </Create>
    </>
);

export default ArtTenceConfigCreate;