import React, { Fragment } from 'react';
import { TextInput, ReferenceInput, SelectInput, NumberInput } from 'react-admin';
import { useForm } from 'react-final-form';
import { validations } from "./validations";

const StepTypeInputArea = ({ formData, ...rest }) => {
    const form = useForm();

    const typeAuto = 1;
    const typeManual = 2;
    const typeManualWithCompleteButton = 3;
    const typeManualSync = 4;

    return (
        <Fragment>
            <ReferenceInput label="Type" source="type" reference="step-types" allowEmpty {...rest}
                    onChange={e => {
                        if (e.target.value === typeAuto) {
                            form.change('task_url', null);
                            form.change('recipient', null);
                            form.change('manual_task_subject', null);
                            form.change('manual_task_body', null);
                        }
                        else if (e.target.value === typeManual) {
                            form.change('queue_name', null);
                            form.change('max_records_per_batch', null);
                        }
                    } }
                    validate={validations.validateType}
            >
                <SelectInput optionText="title"
                />
            </ReferenceInput>

            {/* Only shows Task Assignees if type is Manual */}
            { formData.type === typeAuto &&
                <Fragment>
                    <TextInput source="queue_name" fullWidth={true} validate={validations.validateQueueName} />
                    <NumberInput source="max_records_per_batch" />
                </Fragment>
            }
            { formData.type === typeManual &&
                <Fragment>
                    <TextInput source="recipient" label="Task Assignees" helperText="Emails separated by ;" fullWidth={true} validate={validations.validateRecipient} />
                    <TextInput source="manual_task_subject" label="Task subject" fullWidth={true} validate={validations.validateTaskSubject} />
                    <TextInput multiline source="manual_task_body" label="Task body" fullWidth={true} validate={validations.validateTaskBody} />
                    <TextInput source="task_url" label="URL to application" fullWidth={true} validate={validations.validateTaskUrl} />
                </Fragment>
            }
            { formData.type === typeManualWithCompleteButton &&
                <Fragment>
                    <TextInput source="recipient" label="Task Assignees" helperText="Emails separated by ;" fullWidth={true} validate={validations.validateRecipient} />
                    <TextInput source="manual_task_subject" label="Task subject" fullWidth={true} validate={validations.validateTaskSubject} />
                    <TextInput multiline source="manual_task_body" label="Task body" fullWidth={true} validate={validations.validateTaskBody} />
                    <TextInput source="manual_task_status" label="New Import Status after Task finished" fullWidth={true} validate={validations.validateTaskStatus} />
                    <TextInput source="task_url" label="Data URL" fullWidth={true} validate={validations.validateDataUrl} />
                </Fragment>
            }
            { formData.type === typeManualSync &&
                <Fragment>
                    <TextInput source="recipient" label="Task Assignees" helperText="Emails separated by ;" fullWidth={true} validate={validations.validateRecipient} />
                    <TextInput source="manual_task_subject" label="Task subject" fullWidth={true} validate={validations.validateTaskSubject} />
                    <TextInput multiline source="manual_task_body" label="Task body" fullWidth={true} validate={validations.validateTaskBody} />
                </Fragment>
            }

        </Fragment>
    );
};

export default StepTypeInputArea;