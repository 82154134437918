import React from 'react';
import { Edit, useNotify, SimpleForm, FileInput, FileField} from 'react-admin';
import { validations } from './validations';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";
import EntitiesInfoCustomForm from "../../components/EntitiesInfoCustomForm";

const EntityPredicatesEdit = props => {
  console.log('PROPS', props);
  const notify = useNotify();

  const onError = (error) => {
    notify(error.data);
  }

  return (
    <>
      <BackButton {...props} action = {"edit"}/>
      <PageTitle {...props}>Edit</PageTitle>
      <Edit {...props} undoable={false} mutationOptions={{onError}}>
        <EntitiesInfoCustomForm {...props} showDelete={false} />
      </Edit>
    </>
      
  );
}


export default EntityPredicatesEdit;