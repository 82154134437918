import {minLength, maxLength, required, regex } from 'react-admin';

export const validations = {
  validateLongTextAR: [required(), minLength(3)],
  validateLanguageCode: [required()],
  validateCategory: [required(), minLength(3), maxLength(50), regex(/^([a-zA-z\s\d]+)$/)],
  validateLabel: [minLength(3), maxLength(30)],
  validateLongText: [minLength(1)],
  validateActive: [required()]
};
