import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const CommNumbers = (props) => {
    return (
        <div>
            <MappingSectionInput field='textAR' required={true} {...props}/>
            <MappingSectionInput field='typeAR' {...props}/>
            <MappingSectionInput field='country_codes' {...props}/>
            <MappingSectionInput field='number' required={true} {...props}/>
            <MappingSectionInput field='start' {...props}/>
            <MappingSectionInput field='end' {...props}/>
            <MappingSectionInput field='active' required={true} {...props}/>
            <MappingSectionInput field='validated' required={true} {...props}/>
        </div>
    );  
};

export default CommNumbers;