import {minLength, maxLength, required, } from 'react-admin';

export const validations = {
    validateMarketAR: [required(), minLength(3), maxLength(255)],
    validateMarketIdentifierCode: [required(), minLength(4), maxLength(4)],
    validateSymbol: [minLength(1), maxLength(20)],
    validateIsin: [minLength(10), maxLength(12)],
    validateNsin: [minLength(1), maxLength(12)],
    validateMain: [required()],
    validateActive: [required()]
};