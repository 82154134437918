import React from 'react';
import { ShowController, ShowView, SimpleShowLayout, ReferenceField, TextField, UrlField } from 'react-admin';
import JobLinkField from "../../components/JobLinkField";
import NoTokenUrlField from "../../components/NoTokenUrlField";
import CompleteTaskButton from "../../components/CompleteTaskButton";
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const TaskShow = props => {
    const typeManual = 2;
    const typeManualWithCompleteButton = 3;
    const typeManualSync = 4;
    const statusDone = 3;

    return (
    <ShowController {...props}>
        {controllerProps => 
            <ShowView {...props} {...controllerProps}>
                <SimpleShowLayout>
                    <BackButton {...props} action = {"show"} />
                    <PageTitle {...props}>Show</PageTitle>
                    <JobLinkField source="job" label="See Job details" />
                    <br/>
                    <TextField label="Job Id" source="job_id" />
                    <TextField label="Batch Id" source="job_batch_id" />
                    <ReferenceField label="Status" source="status" reference="task-status" link={false}>
                        <TextField source="title" />
                    </ReferenceField>

                    <TextField source="title" />
                    <TextField source="content" />

                    { controllerProps.record && controllerProps.record.step_type_id === typeManual &&
                        <NoTokenUrlField source="external_app_link" target="_blank" rel="noopener" label="Application url" />
                    }

                    { controllerProps.record && controllerProps.record.step_type_id === typeManualWithCompleteButton && controllerProps.record.status !== statusDone &&
                        <NoTokenUrlField source="app_link" target="_blank" rel="noopener" label="Data url" />
                    }

                    <TextField source="created_at" />

                    { controllerProps.record && 
                        (controllerProps.record.step_type_id === typeManualWithCompleteButton || controllerProps.record.step_type_id === typeManualSync)
                            && controllerProps.record.status !== statusDone && 
                        <CompleteTaskButton />
                    }
                </SimpleShowLayout>
            </ShowView>
        }
    </ShowController>
    );
}
export default TaskShow;