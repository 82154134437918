import React from 'react';
import { Field } from 'react-final-form';
import { useInput } from 'react-admin';
import Cron from '../CronGenerator'


export const CronInput = props => {
    const {
        input,
        meta: { touched, error },
        isRequired
    } = useInput(props);


    const formatToSave = (val) =>
        val.replace('?', '*').replace(/[0-9]+\//, '*/');

    const formatToSet = (val, newNumerator) =>
        val.replace(/\*\//, newNumerator + '/');

    const setCronExpToFormGenerator = (val) => {
        let vals = val.split(' ');
        let result = '';

        if (vals && vals.length === 5) {
            if (vals[2] === '*' && vals[4] !== '*')
            vals[2] = '?';

            return `0 ${formatToSet(vals[0], 0)} ${formatToSet(vals[1], 0)} ${formatToSet(vals[2], 1)} ${formatToSet(vals[3], 1)} ${vals[4]} *`;
        }
        else {
            return null;
        }
    }

    const getCronExpFromFormGenerator = (val) => {
        const vals = val.split(' ');
        let result = '';

        if (vals && vals.length === 7) {
            for (let i = 1; i <= 5; i++)
                result += ' ' + formatToSave(vals[i]);
        }
        return result.startsWith(' ') ? result.substr(1) : result;
    }

    return (
      <div>
        <Field 
            component="input"
            type="hidden"
            errorText={touched && error}
            required={isRequired}
            {...input}
        />

        <Cron
          onChange={(value)=> { input.onChange(getCronExpFromFormGenerator(value)); } }
          value={setCronExpToFormGenerator(input.value)}
          showResultText={true}
          showResultCron={false}
        />                      
      </div>
    );
};
