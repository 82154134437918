import React from 'react';
import {Filter, ReferenceInput, AutocompleteInput, List, Datagrid, TextField, EditButton, ReferenceField } from 'react-admin';
import CronFriendlyField from '../../components/CronFriendlyField'
import ListToolbarActions from "../../components/ListToolbarActions";
import { userHasPermission } from "../../helpers/authProvider";
import "../../index.css";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import { perPageReferenceFields } from "../../constants";
import ClearFiltersButton from "../../components/ClearFiltersButton";

const ScheduledJobFilter = (props) => {
    return(
        <div>
            <ClearFiltersButton {...props} filters={{process: ''}}/>
            <Filter {...props}>
                <ReferenceInput label="Process" source="process" reference="processes" perPage={perPageReferenceFields} alwaysOn>
                    <AutocompleteInput optionText="title" />
                </ReferenceInput>
            </Filter>
        </div>
    );
}

const ScheduledJobList = props => (
    <ThemeProvider theme={theme}>
        <h2>{props.options.label}</h2>
        <List filters={<ScheduledJobFilter/>} bulkActionButtons={<DocBulkActionButtons {...props} />} exporter={false} {...props} 
                actions={<ListToolbarActions showCreateButton={userHasPermission("scheduledjobs-create")} />}>
            <Datagrid>
                <ReferenceField label="Process" source="process" reference="processes" link={false}>
                    <TextField source="title" />
                </ReferenceField>
                <CronFriendlyField source="schedule" />

                { userHasPermission("scheduledjobs-update") && <EditButton /> }
            </Datagrid>
        </List>
    </ThemeProvider>
);

export default ScheduledJobList;