import {minLength, maxLength, required, } from 'react-admin';

export const validations = {
  validateId: [required()],
  validateReportId: [required()],
  validateSourceId: [required()],
  validateBatchId: [required()],
  validatePriority: [required()],
  validateStatus: [required()],
  validateValidated: [required()],
  validateControlled: [required()],
  validateUrl: [minLength(6)],
  validateEffectiveDate: [required()],
  validateUpdateDate: [required()],
  validateInsertDate: [required()],
  validateInternalNote: [minLength(10), maxLength(200)]
};
