import React from 'react';
import { TopToolbar, sanitizeListRestProps } from 'react-admin';
import { useButtonStyles, CompleteTaskButton, DeleteEntityButton, ShowDocButton } from './buttons.js';
import CreateSectionObjectsButton from '../../components/CreateSectionObjectsButton';


export const ToolbarCreate = ({
    className,
    doc_id,
    entity_id,
    entity_name,
    full_entity_name,
    section,
    operation_type,
    batch_id,
    records,
    onShowDocClick,
    onEntityDeletion,
    onCompleteTask,
    ...rest
}) => {

    let values = {doc_id: doc_id, entity_id: entity_id, section: section, operation_type: operation_type, records: records};
    const classes = useButtonStyles();
    console.log('records', records);
    console.log('values', values);
    console.log('doc_id', doc_id);
    console.log('entity_name', entity_name);
    console.log('full_entity_name', full_entity_name);
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                <div style={{display: 'flex', justifyContent: 'space-between', gap: '8px'}}>
                    <ShowDocButton onShowDocClick={onShowDocClick}/>
                    { (((['header'].includes(section)) && records[doc_id]['sourceId'].startsWith('XG') && !('filing' in records[doc_id])) || (!['entity Header', 'header', 'filing'].includes(section))) && <CreateSectionObjectsButton className={classes.add} values={values} button_name='+ ADD OBJECTS' />}
                    { ['entity Header'].includes(section) && <CreateSectionObjectsButton className={classes.add} values={values} entity_name={entity_name} button_name='+ ADD SECTION' />}
                    {/* entity deletion only allowed for Child Company or Child Person */}
                    { (!['Parent Company 1', 'Import Document'].includes(full_entity_name)) && <DeleteEntityButton values={values} entity_name={full_entity_name} batchId={batch_id} onEntityDeletion={onEntityDeletion} />}
                    {batch_id !== '' && <CompleteTaskButton batchId={batch_id} onCompleteTask={onCompleteTask} />}
                </div>    
        </TopToolbar>

    );
};