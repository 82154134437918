import React, { useEffect, useState } from "react";
import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
  SaveButton,
  FormDataConsumer,
  SimpleForm,
  BooleanInput,
  FileInput,
  FileField,
  SelectInput,
  Loading,
} from "react-admin";
import { Api } from "../../helpers/api";
import { JSONViewer } from "react-json-editor-viewer";
import PublishIcon from "@material-ui/icons/Publish";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { validations } from "./validations";
import { useNotify } from "ra-core";
import CircularProgress from "@material-ui/core/CircularProgress";

const ADITester = ({ linkedSources, defaultSample, ...props }) => {
  const [rawFile, setRawFile] = useState(defaultSample);
  const [docId, setDocId] = useState("");
  const [doc, setDoc] = useState();
  const [loading, setLoading] = useState(false);
  const [sampleTester, setSampleTester] = useState(!!defaultSample)
  const notify = useNotify();

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file.rawFile);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      }
    });

  const saveFile = (data) => {
    const text = toBase64(data.bulk_file).then((fileBase64) => {
      data.file64 = fileBase64;
      return Api.post("/adi-tester/save-file/", data).then((response) => {
        setRawFile(JSON.stringify(response.result, null, 4));
      });
    });
  };

  const mapFile = (data) => {
    data.rawFile = rawFile;
    setLoading(true);
    Api.post("/adi-tester/map-file/", data).then((response) => {
      if (response.type === "success") {
        setDocId(response.result.doc_id);
      } else {
        notify("Bad Request");
      }
    });
  };

  useEffect(() => {
    if (
      sampleTester &&
      linkedSources &&
      linkedSources.length > 0
    ) {
      mapFile({ source_code: linkedSources[0]["code"] });
      setSampleTester(false);
    }
  }, [sampleTester]);

  useEffect(() => {
    if (docId !== "") {
      waitResponse();
    }
  }, [docId]);

  const waitResponse = () => {
    Api.post("/adi-tester/wait-response/", { docId: docId }).then(
      (response) => {
        if (response.type === "success") {
          if (response.result.doc !== "") {
            setDoc(response.result.doc);
            setLoading(false);
          } else {
            setTimeout(waitResponse, 3000);
          }
        } else {
          notify("Document Id ".concat(docId).concat(" not found"));
        }
      }
    );
  };

  const handleChange = (e) => {
    setRawFile(e.target.value);
  };

  const LoadToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton alwaysEnable label="Upload" icon={<PublishIcon />} />
      </Toolbar>
    );
  };

  const MapToolbar = ({ pristine, ...props }) => {
    return (
      <Toolbar {...props}>
        {!loading && (
          <SaveButton
            disabled={rawFile ? false : true}
            label="Map"
            icon={<DoubleArrowIcon />}
          />
        )}
      </Toolbar>
    );
  };

  return (
    <>
      <h2>
        {props.resource === "adi-tester" ? props.options.label : undefined}
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ width: "45%" }}>
          <SimpleForm toolbar={false}>
            <BooleanInput source="upload" label="Upload file?" />
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData, ...rest }) =>
                formData.upload && (
                  <SimpleForm toolbar={<LoadToolbar />} save={saveFile}>
                    <FileInput
                      source="bulk_file"
                      accept="application/json,.csv"
                      label="Upload csv or json"
                    >
                      <FileField source="src" title="title" />
                    </FileInput>
                  </SimpleForm>
                )
              }
            </FormDataConsumer>
          </SimpleForm>

          <SimpleForm
            toolbar={<MapToolbar alwaysEnable={true} />}
            save={mapFile}
          >
            {(linkedSources || []).length == 0 ? (
              <ReferenceInput
                label="Source"
                source="source_code"
                reference="process-source-codes"
              >
                <AutocompleteInput
                  optionText="code"
                  validate={validations.validateSelection}
                />
              </ReferenceInput>
            ) : (
              <SelectInput
                source="source_code"
                optionText="code"
                choices={linkedSources}
                defaultValue={linkedSources[0]["id"]}
              />
            )}
            <BooleanInput source="useAsSample" label="Use as source sample?" />
            <TextInput
              label={false}
              source="rawFile"
              value={rawFile}
              onChange={handleChange}
              multiline
              rows="15"
              style={{ width: "100%" }}
            />
          </SimpleForm>
        </div>

        <div style={{overflowY: "scroll", height: "650px", width: "45%" }}>
          {loading ? (
            <CircularProgress/>
          ) : (
            <JSONViewer data={doc} collapsible />
          )}
        </div>
      </div>
    </>
  );
};

export default ADITester;
