import { Api } from "../../helpers/api";

const CustomAPIRequest = ({
  id,
  pathEnd,
  stateSet,
  tagErrorMessage,
  redirect = null,
  notify = null,
  notifyMessage = "",
  attribute = null,
  method = "get",
  bodyData = {},
}) => {
  return Api[method](`/jobs/${id}/${pathEnd}`, bodyData, true)
    .then((response) => {
      if (response.type === "success") {
        if (stateSet) {
          stateSet(
            attribute
              ? response.result.results[attribute]
              : response.result.results
          );
        } else {
          notify(notifyMessage);
        }
      } else if (response.type === "auth_error") {
        console.log(`Error getting ${tagErrorMessage} - authentication error`);
        redirect("/login");
      } else {
        console.log(`Error getting ${tagErrorMessage} - response`, response);
        if (response.error && notify) {
          notify(response.error, "warning");
        } else if (notify) {
          notify(`Could not get the ${tagErrorMessage}!`, "warning");
        }
      }
    })
    .catch((e) => {
      console.log(`Exception getting ${tagErrorMessage} - e`, e);
      if (notify) {
        notify(`Error getting the ${tagErrorMessage}!`, "warning");
      }
    });
};

export default CustomAPIRequest;
