import React, { Fragment } from 'react';
import { 
    List, 
    Datagrid, 
    TextField, 
    AutocompleteInput, 
    ShowButton,
    ReferenceInput, 
    SelectInput,
    SearchInput,
    Filter
} from 'react-admin';
import { perPageReferenceFields } from "../../constants";
import FixIssuesButton from "../../components/FixIssuesButton";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import ClearFiltersButton from "../../components/ClearFiltersButton";

const EntityFilter = (props) => {
    return (
      <div>
        <ClearFiltersButton
          {...props}
          filters={{ source_code: "", batch_id: "", status: "" }}
        />
        <Filter {...props}>
          <ReferenceInput
            label="Source"
            source="source_code"
            reference="sources"
            perPage={perPageReferenceFields}
            alwaysOn
          >
            <AutocompleteInput optionText="code" />
          </ReferenceInput>
          <SearchInput
            source="batch_id"
            placeholder="Batch Id"
            style={{ width: "200px" }}
            resettable
            alwaysOn
          />
          <ReferenceInput
            label="Status"
            source="status"
            reference="document-editor-status"
            allowEmpty
            alwaysOn
            perPage={perPageReferenceFields}
          >
            <SelectInput optionText="id"/>
        </ReferenceInput>
        </Filter>
      </div>
    );
}



const CustomList = props => {

    const useStyles = makeStyles({
        main: {
            backgroundColor: 'green',
        },
    });

    const additionalMessage = "For documents with status WIN_MATCH_ERROR and WIN_INSERT_ERROR the entity information (on Core) will also be deleted.";

    const classes = useStyles(props);
    
    return (
        <ThemeProvider theme={theme}>
            <h2>{props.options.label}</h2>
            <List filters={<EntityFilter />} bulkActionButtons={<DocBulkActionButtons {...props} additionalMessage={additionalMessage} classes={{ main: classes.main }}/>} exporter={false} {...props}
                sort={{ field: 'id', order: 'DESC' }}>
                <Datagrid>
                    <TextField label="Document Id" source="id" />
                    <TextField label="Source Code" source="source_code" />
                    <TextField label="Batch Id" source="batch_id" />
                    <TextField label='Job Id' source='job_id' />
                    <TextField label="Status" source="status" />
                    <FixIssuesButton {...props} values={{batch_id: 0, document_id: ''}}  />
                    <ShowButton  />
                </Datagrid>
            </List>
        </ThemeProvider>
    )
};
class EntityList extends React.Component{
    constructor(props){
        super(props);
        this.state = {batch_id: 0, document_id: ''};
    }

    
    render(){

        var props = this.props;
        

        return (
            <CustomList {...props}>
            </CustomList>
        );
    }
};

export default EntityList;
