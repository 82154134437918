import React from 'react';
import { TextInput, BooleanInput, SelectInput, ReferenceInput, AutocompleteInput, DateInput } from 'react-admin';
import { validations } from './validations';

const Registrations = (props) => (
    
        <div>
            { 'location' in props && 
                <TextInput source='textAR' fullWidth={true} label='Text AR' validate={validations.validateTextAR} />
            }
            <ReferenceInput source='registration' label='Registration' reference='enumerations-registration-status' allowEmpty={true} >
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes' allowEmpty={true}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput source='translationCode' label='Translation Code' reference='enumerations-translation-codes' allowEmpty={true} >
                <SelectInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <TextInput source='name' label='Name' validate={validations.validateName} />
            <DateInput source='start' label='Start Date' />
            <BooleanInput source='active' initialValue={true} label='Active?' validate={validations.validateActive} />
        </div>
            
)

export default Registrations;