import React from 'react';
import {useState, useEffect} from 'react';
import {Show, SimpleShowLayout, TextField, NumberField, DateField, BooleanField } from 'react-admin';
import "../../index.css";
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Api } from '../../helpers/api';
import TableComponent from './TableComponent';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }



const EntityShow = props => {
    const [value, setValue] = useState(0);
    const [names, setNames] = useState([]);
    const [namesColumns, setNamesColumns] = useState([])
    const [idkeys, setIdkeys] = useState([]);
    const [idkeysColumns, setIdkeysColumns] = useState([]);
    const [dates, setDates] = useState([]);
    const [datesColumns, setDatesColumns] = useState([]);
    const [predicates, setPredicates] = useState([]);
    const [predicatesColumns, setPredicatesColumns] = useState([]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    const getEntityInfo = props => {
      return Api.get(`/entities/${props.id}/get-entity-info`, true)
            .then(response => {
                if (response.type === "success") {
                    const results = response.result.results;
                    setNames(results.names.data);
                    setNamesColumns(results.names.columns);
                    setIdkeys(results.idkeys.data);
                    setIdkeysColumns(results.idkeys.columns);
                    setDates(results.dates.data);
                    setDatesColumns(results.dates.columns);
                    setPredicates(results.predicates.data);
                    setPredicatesColumns(results.predicates.columns);

                } 
                else if (response.type === "auth_error") {
                    console.log("Error starting the process - authentication error");
                }
                else {
                    console.log("Error starting the process - response", response);
                    if (response.error) {
                    }
                    else {
                    }
                }
            })
            .catch((e) => {
                console.log("Exception starting the process - e", e);
            });
    }

    useEffect(() => {
        getEntityInfo(props);
    }, []);


    return (
        <ThemeProvider theme={theme}>
          <BackButton {...props} action = {"show"} />
          <PageTitle {...props}>Show</PageTitle>
          <Show {...props}>
              <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <SimpleShowLayout>
                      <TextField label="ARTID" source="art_id" />
                      <TextField source="country_code" />
                      <NumberField source="successor_entity" />
                      <BooleanField source="verified" />
                    </SimpleShowLayout>
                  </Grid>
                  <Grid item xs={6}>
                    <SimpleShowLayout>
                      <BooleanField source="public" />
                      <BooleanField source="active" />
                      <DateField source="insert_date" />
                      <TextField label='Insert Source Code' source="source_code" />
                    </SimpleShowLayout>
                  </Grid>
              </Grid>
          </Show>
          <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange}>
                      <Tab label="Idkeys" {...a11yProps(0)} /> 
                      <Tab label="Names" {...a11yProps(1)} />
                      <Tab label="Dates" {...a11yProps(2)} />
                      <Tab label="Predicates" {...a11yProps(3)} />
                  </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <TableComponent values={idkeys} headers={idkeysColumns} {...props} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TableComponent values={names} headers={namesColumns} {...props} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <TableComponent values={dates} headers={datesColumns} {...props} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <TableComponent values={predicates} headers={predicatesColumns} {...props} />
              </TabPanel>
          </Box>
        </ThemeProvider>
    );
};

export default EntityShow;