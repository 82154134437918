import React from 'react';
import { List, Filter, ReferenceInput, AutocompleteInput, Datagrid, TextField, EditButton, ReferenceField } from 'react-admin';
import  StartJobButton from '../../components/StartJobButton'
import ListToolbarActions from "../../components/ListToolbarActions";
import { userHasPermission } from "../../helpers/authProvider";
import "../../index.css";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import { perPageReferenceFields } from "../../constants";
import ClearFiltersButton from "../../components/ClearFiltersButton";
import MigrateButton from "../../components/MigrateButton";

const ProcessFilters = (props) => {
    return(
        <div>
            <ClearFiltersButton {...props} filters={{id: '', source_code: ''}}/>
            <Filter {...props}>
                <ReferenceInput label="Title" source="id" reference="processes" perPage={perPageReferenceFields} alwaysOn>
                    <AutocompleteInput optionText="title"/>
                </ReferenceInput>
                <ReferenceInput label="Source Code" source="source_code" reference="process-source-codes" perPage={perPageReferenceFields} alwaysOn>
                    <AutocompleteInput optionText="code"/>
                </ReferenceInput>
            </Filter>
        </div>
    );
}

const ProcessList = (props) => (
  <ThemeProvider theme={theme}>
    <h2>{props.options.label}</h2>
    <List
      filters={<ProcessFilters />}
      sort={{ field: "priority", order: "DESC" }}
      bulkActionButtons={
        userHasPermission("processes-delete") && <DocBulkActionButtons />
      }
      exporter={false}
      {...props}
      actions={
        <ListToolbarActions
          showCreateButton={userHasPermission("processes-create")}
        />
      }
    >
      <Datagrid>
        <TextField source="title" />
        <TextField source="source_code" label="Source Code" />
        <TextField source="active" label="Is active?" />
        <TextField source="priority" label="Priority" />
        <TextField source="product_name" label="Product" />
        <TextField label="Script" source="script_name" />
        <TextField source="created_at" />
        <MigrateButton
          {...props}
          resource="Process"
          endpoint="/processes/migrate/"
        />
        {userHasPermission("processes-update") && <EditButton />}
        {userHasPermission("processes-startjob") && <StartJobButton />}
      </Datagrid>
    </List>
  </ThemeProvider>
);

export default ProcessList;