import React from 'react';
import { TextInput, NumberInput, ReferenceInput, AutocompleteInput, BooleanInput, DateInput } from 'react-admin';
import { validations } from './validations';


const Shares = (props) => (
    
        <div>
            { 'location' in props && 
                <TextInput source='textAR' fullWidth={true} label='Text AR' validate={validations.validateTextAR} />
            }
            <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes' validate={validations.validateLanguageCode} >
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <NumberInput source='quantity' label='Quantity' min={1} max={999999999999}/>
            <ReferenceInput source='type' label='Type' reference='enumerations-share-types' allowEmpty={true} >
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput source='extension' label='Extension' reference='enumerations-share-extensions' allowEmpty={true} >
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <TextInput source='class' label='Class' validate={validations.validateClass} />
            <ReferenceInput source='currencyCode' label='Currency Code' reference='enumerations-currency-codes' allowEmpty={true} >
                <AutocompleteInput optionText="code" optionValue="id" />
            </ReferenceInput>
            <NumberInput source='issued' label='Issued' min={0.00001} max={9999999999999.99999} />
            <NumberInput source='paidup' label='Paidup' min={0} max={9999999999999.99999} />
            <NumberInput source='value' label='Value'  min={0.00001} max={9999999999999.99999}/>
            <ReferenceInput source='currencyCode2' label='Currency Code 2' reference='enumerations-currency-codes' allowEmpty={true} >
                <AutocompleteInput optionText="code" optionValue="id" />
            </ReferenceInput>
            <NumberInput source='issued2' label='Issued 2' min={0.00001} max={9999999999999.99999} />
            <NumberInput source='paidup2' label='Paidup 2' min={0} max={9999999999999.99999} />
            <NumberInput source='value2' label='Value 2' min={0.00001} max={9999999999999.99999} />
            <ReferenceInput source='currencyCode3' label='Currency Code 3' reference='enumerations-currency-codes' allowEmpty={true} >
                <AutocompleteInput optionText="code" optionValue="id" />
            </ReferenceInput>
            <NumberInput source='issued3' label='Issued 3' min={0.00001} max={9999999999999.99999} />
            <NumberInput source='paidup3' label='Paidup 3' min={0} max={9999999999999.99999} />
            <NumberInput source='value3' label='Value 3' min={0.00001} max={9999999999999.99999} />
            <DateInput source='start' label='Start Date' />
            <DateInput source='end' label='End Date' />
            <BooleanInput source='active' defaultValue={true} label='Active?' validate={validations.validateActive} />
        </div>
            
)

export default Shares;