import React from 'react';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import { Edit, SimpleForm, BooleanInput, DateInput, TextField, TextInput, ReferenceInput, SelectInput } from 'react-admin';
import { validations } from './validations';
import { perPageReferenceFields } from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";


const SourceEdit = props => {

    return (
        <>
            <Edit undoable={true} {...props}>
                
                <SimpleForm toolbar={<EditFormToolbarActions />}>
                    <BackButton {...props} action = {"edit"}/>
                    <PageTitle {...props}>Edit</PageTitle>
                    <TextInput source="code" fullWidth={true} validate={validations.validateCode} />
                    <TextInput source="label" fullWidth={true} validate={validations.validateLabel} />
                    <BooleanInput source="public" label="Is it public?" />
                    <BooleanInput source="primary" label="Is it primary?" />
                    <BooleanInput source="active" label="Is it active?" />
                    <DateInput source='start_date' validate={validations.validateStartDate} />
                    <DateInput source='end_date' />
                    <ReferenceInput label="Source Type" source="source_type_code" reference="enumerations-source-types" validate={validations.validateSourceType} perPage={perPageReferenceFields}>
                        <SelectInput optionText="label" optionValue="id" />
                    </ReferenceInput>
                    <ReferenceInput label="Report Type" source="report_type" reference="enumerations-report-types" validate={validations.validateReportType} perPage={perPageReferenceFields}>
                        <SelectInput optionText="label" optionValue="id" />
                    </ReferenceInput>
                    <ReferenceInput label="Matching Algorithm" source="matching_algorithm" reference="enumerations-matching-algorithms" allowEmpty={true} perPage={perPageReferenceFields} >
                        <SelectInput optionText="label" optionValue="id"/>
                    </ReferenceInput>  
                    <ReferenceInput label="Crawling Algorithm" source="crawling_algorithm" reference="enumerations-crawling-algorithms" allowEmpty={true} perPage={perPageReferenceFields} >
                        <SelectInput optionText="label" optionValue="id"/>
                    </ReferenceInput>    
                    <ReferenceInput label="Import Mapping Schema" source="import_mapping_schema" reference="import-mapping-schemas" perPage={perPageReferenceFields} >
                        <SelectInput optionText="name" optionValue="id"/>
                    </ReferenceInput>
                </SimpleForm>
            </Edit>
        </>
    );
}

export default SourceEdit;