import React from 'react';
import { TextInput, NumberInput, BooleanInput, ReferenceInput, AutocompleteInput, DateInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import { validations } from './validations';

const Licenses = (props) => {
    console.log(props);

    return (
        <div>
            { 'location' in props &&
                <TextInput source='stringAR' label='textAR' validate={validations.validateTextAR} />
            }
            <DateInput source='updateDate' label='Update Date' validate={validations.validateUpdateDate} />
            <TextInput source='licensor' label='Licensor' validate={validations.validateLicensor} />
            <TextInput source='number' label='number' validate={validations.validateNumber} />
            <BooleanInput source='unique' label="Is unique?" validate={validations.validateUnique} />
            <ReferenceInput source='licenseStatus' label='License Status' reference='enumerations-license-statuses' validate={validations.validateLicenseStatus}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <DateInput source='issuedDate' label='Issued date' />
            <DateInput source='renewedDate' label='Renewed date' />
            <DateInput source='expiryDate' label='Expiry date' />
            <DateInput source='cancelledDate' label='Cancelled date' />
            <ArrayInput source='businesses' label='Businesses'>
                <SimpleFormIterator>
                    <TextInput source='label' label='Label' validate={validations.validateLabel} />
                    <TextInput source='code' label='Code' validate={validations.validateCode} />
                    <TextInput source='standard' label='Standard' validate={validations.validateStandard} />
                    <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes' fullWidth={true}>
                        <AutocompleteInput optionText="label" optionValue="id" />
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
        </div>  
    );  
};

export default Licenses;