import React from 'react';
import { Create, TextInput, SelectInput, ReferenceInput, AutocompleteInput, SimpleForm, required, useRefresh, useNotify} from 'react-admin';
import { qualityLevelsOptions } from '../../constants';

const SourceSectionQualityLevelsCreate = props => {
    const link = `/source-section-quality-levels?source_id=${props.source_id}&source_code=${props.source_code}`
    const refresh = useRefresh();
    const notify = useNotify();

    const onSuccess = () => {
        notify('Source Section Quality Level created');
        refresh();
    }

    return (
        <Create {...props} onSuccess={() => {onSuccess()}}>
            <SimpleForm defaultValue={{source: props.source_id}} redirect={link} >
                <ReferenceInput source="section_code" reference="enumerations-sections" validate={required()}>
                    <AutocompleteInput optionText="label" />
                </ReferenceInput>
                <ReferenceInput source="entity_relation" reference="enumerations-entity-relations" validate={required()}>
                    <AutocompleteInput optionText="label" />
                </ReferenceInput>
                <SelectInput source="quality_level" choices={qualityLevelsOptions} optionText="text" optionValue="value" validate={required()} />
            </SimpleForm>
        </Create>
    );
};

export default SourceSectionQualityLevelsCreate;