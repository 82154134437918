import React, { useState } from "react";
import Button from "@material-ui/core/Button";

const S3JobButton = ({ url }) => {
  const handleClick = (e) => {
    window.open(url, "_blank").focus();
  };

  return (
    url && (
      <Button
        label=""
        variant="outlined"
        color="primary"
        size="small"
        onClick={url ? handleClick : null}
      >
        Open S3 Folder
      </Button>
    )
  );
};
export default S3JobButton;
