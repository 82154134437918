import {
    required,
    regex,
    maxLength,
    minLength,
} from 'react-admin';

export const validations = {
    validateEntityType: [required()],
    validateCountryCode: [required()],
    validateSuccessorEntity: [regex(/^(P|C|X)[A-Z]{2}\d[A-Z\d]{8}$/, "Must be a valid ART ID!")],
    validateMinCharacter: [minLength(3)],
}