import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import {List, Datagrid, TextField} from 'react-admin';


const EntityAddressesList = props => {
    let entityFilter = props.location.search.split('=')[1];
    return (
        <ThemeProvider theme={theme}>
            <h3>Active addresses for entity {entityFilter}</h3>
            <List actions={false} emptyWhileLoading filter={{entity_id: entityFilter}} exporter={false} toolbar={false} bulkActionButtons={false} {...props}>
                <Datagrid>
                    <TextField label="Source" source="sourceId" />
                    <TextField label="Country Code" source="countryCode" />
                    <TextField label="County Code" source="countyCode" />
                    <TextField label="Text AR" source="textAR" />
                </Datagrid>
            </List>
        </ThemeProvider>
    );
};

export default EntityAddressesList;