import {
    required,
    maxLength,
    regex
} from 'react-admin';

export const validations = {
    validateCode: [required()],
    validateLoginType: [required()],
    validateUsername: [required(), maxLength(128)],
    validatePassword: [required(), maxLength(128)],
    validateIdkeyCode: [required()],
    validateUrl: [maxLength(300)]
};
