import {
    required,
    maxLength,
    regex,
} from 'react-admin';

export const validations = {
    validateTitle: [required(), maxLength(100)],
    validateDescription: maxLength(255),
    validateOutputs: [required(), maxLength(255)],
    validateConfiguration: maxLength(1000),
    validateType: [required()],

    validateQueueName: [required(), maxLength(100)],
    validateTaskUrl: [required(), maxLength(255)],
    validateTaskStatus: [required(), maxLength(100)],
    validateDataUrl: maxLength(255),
    validateRecipient: [required(), maxLength(2000), regex(/^((((?!\.)[\w-_.]*[^.])(@[\w-]+)(\.\w+(\.\w+)?[^.\W]));*)+$/, "Must be one or more valid emails separated by ;")],
    validateTaskSubject: [required(), maxLength(100)],
    validateTaskBody: [required(), maxLength(500)],
};
