import React from 'react'

const PageTitle = ({ children, ...props }) => {
    console.log('PROPS page title', props)
    let label = ''
    if ('label' in props.options){
        label = props.options.label
    }
    else {
        label = props.resource.replace(/-/g, " ")
    }
    
    let id = ''
    if ('record' in props){
        id = props.record.id
    }
    else if ('id' in props){
        id = props.id
    }

    if ('art_id' in props){
        id = id + '-' + props.art_id
    }

    let singular_item = ''
    if (label.slice(-4) == 'sses'){
        singular_item = label.slice(0,-2)
    }
    else if (label.slice(-1) == 's'){
        singular_item = label.slice(0,-1)
    }
    else if (label == 'document edit'){
        singular_item = 'object'
    }
    else if (label == 'AT-Editor'){
        if (props.location.pathname!="/document-edit" && props.location.pathname!="/document-edit/create"){
            singular_item = 'Document'
        }
    }
    else if (label == 'SE Editor'){
        singular_item = 'source entity'
    }

    else if (label == 'Entities Editor'){
        singular_item = 'entity'
        
    }
   
    else{
        singular_item = label
    }

    return(
        <h3 
            {...props}
            style={{ 
                "width": "fit-content",
            }}
        >
            {children} {singular_item} {id}
        </h3>
    )
}

export default PageTitle;
