import React, { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Filter,
  ShowButton,
  AutocompleteInput,
} from "react-admin";
import { userHasPermission } from "../../helpers/authProvider";
import { perPageReferenceFields } from "../../constants";
import CompleteTaskButton from "../../components/CompleteTaskButton";
import TaskBulkActionButtons from "../../components/TaskBulkActionButtons";
import { Link } from "react-router-dom";
import ClearFiltersButton from "../../components/ClearFiltersButton";
import { useRefresh, useNotify } from "ra-core";
import ButtonWithDialog from "../../components/ButtonWithDialog";
import { Api } from "../../helpers/api";
import DoneIcon from "@material-ui/icons/Done";

const TaskFilter = (props) => {
  return (
    <div>
      <ClearFiltersButton
        {...props}
        filters={{
          job_process_id: "",
          title: "",
          status: "",
          job: "",
          source_id: "",
        }}
      />

      <Filter {...props}>
        <ReferenceInput
          label="Process"
          source="job_process_id"
          reference="task-processes-titles"
          perPage={perPageReferenceFields}
          alwaysOn
        >
          <AutocompleteInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput
          label="Title"
          source="title"
          reference="task-titles"
          perPage={perPageReferenceFields}
          alwaysOn
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput
          label="Status"
          source="status"
          reference="task-status"
          perPage={perPageReferenceFields}
          alwaysOn
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput
          label="Job"
          source="job"
          reference="task-jobs"
          perPage={perPageReferenceFields}
          alwaysOn
        >
          <AutocompleteInput optionText="job_str" optionValue="job" />
        </ReferenceInput>
        <ReferenceInput
          label="Source Code"
          source="source_id"
          reference="sources"
          perPage={perPageReferenceFields}
          alwaysOn
        >
          <AutocompleteInput optionText="code" />
        </ReferenceInput>
      </Filter>
    </div>
  );
};

const TaskLinkField = ({ source, record, text }) => {
  if (record["status"] === statusDone) {
    return <TextField record={record} source="title" />;
  }

  if (record["step_type_id"] === typeManual) {
    text = "external_app_link";
  }
  return (
    <a target="_blank" href={record[text]}>
      {record[source]}
    </a>
  );
};

const JobLinkField = ({ record, location }) => {
  const link = "/jobs/" + record.job + "/show";
  return (
    <Link to={{ pathname: link, state: { prevPath: location.pathname } }}>
      {record.job}
    </Link>
  );
};

const statusNew = 1;
const statusExecuting = 2;
const statusDone = 3;

const typeManual = 2;

const CompleteTaskField = ({ value, record }) => {
  if (record[value] !== statusDone && record["step_type_id"] !== typeManual) {
    return <CompleteTaskButton record={record} />;
  }
  return null;
};

const CompleteAllTasksButton = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClickBulkFinishTasks = () => {
    if (props.data) {
      return Api.post(
        "/tasks/complete-all-tasks/",
        props.data?.filters,
        true
      ).then((response) => {
        if (response.type === "success") {
          notify("Successfully added tasks to complete!", "success");
          refresh();
        }
      });
    }
  };

  return props.data?.filters?.status == 1 &&
    props.data?.filters?.title &&
    props.data?.loaded === true &&
    props.data?.count !== 0 ? (
    <div
      style={{
        display: "flex",
        paddingTop: "10px",
        justifyContent: "flex-end",
      }}
    >
      <ButtonWithDialog
        dialogTitleMessage="Complete all tasks"
        dialogContentMessage={`You will complete ${props.data?.count} ${
          props.data.count > 1 ? "tasks" : "task"
        }. Are you sure you want to proceed?`}
        buttonLabel="complete all tasks"
        style={{ padding: "8px" }}
        onConfirm={handleClickBulkFinishTasks}
        startIcon={<DoneIcon />}
      />
    </div>
  ) : null;
};

const TaskList = (props) => {
  const [data, setData] = useState({});

  const GetTasksData = (props) => {
    useEffect(() => {
      if (
        props.filterValues &&
        (data.filters !== props.filterValues || data.count !== props.total) &&
        props.loaded === true
      ) {
        setData({ filters: props.filterValues, count: props.total, loaded: true });
      }
    }, [props]);

    return false;
  };

  return (
    <div>
      <h2>{props.options.label}</h2>
      <List
        actions={<GetTasksData {...props} />}
        filters={<TaskFilter {...props} />}
        bulkActionButtons={<TaskBulkActionButtons {...props} />}
        exporter={false}
        {...props}
        sort={{ field: "id", order: "DESC" }}
      >
        <Datagrid
          isRowSelectable={(record) =>
            record.status !== statusDone && record.step_type_id !== typeManual
          }
        >
          <TaskLinkField source="title" text="app_link" />
          <TextField label="Process" source="process_title" />
          <JobLinkField label="Job Id" location={props.history.location} />
          <TextField label="Batch Id" source="job_batch_id" />
          <ReferenceField
            label="Status"
            source="status"
            reference="task-status"
            link={false}
          >
            <TextField source="title" />
          </ReferenceField>
          <TextField source="created_at" />
          <CompleteTaskField value="status" />
          <ShowButton />
        </Datagrid>
      </List>
      {userHasPermission("management") && (
        <CompleteAllTasksButton {...props} data={data} />
      )}
    </div>
  );
};

export default TaskList;
