import React from 'react';
import { TextInput, BooleanInput, ReferenceInput, AutocompleteInput, SelectArrayInput, ReferenceArrayInput, NumberInput } from 'react-admin';
import { validations } from './validations';


const Markets = (props) => {
    console.log('PROPS');
    console.log(props);

    return (
        <div>
            { 'location' in props &&
                <div>
                    <TextInput source='longTextAR' fullWidth={true} label='Long text AR' validate={validations.validateLongTextAR} />
                    <TextInput source='exportMarketsAR' fullWidth={true} label='Export markets AR' validate={validations.validateExportMarketsAR} />
                    <TextInput source='importMarketsAR' fullWidth={true} label='Import markets AR' validate={validations.validateImportMarketsAR} />
                    <TextInput source='exportProductsAR' fullWidth={true} label='Export products AR' validate={validations.validateExportMarketsAR} />
                    <TextInput source='importProductsAR' fullWidth={true} label='Import products AR' validate={validations.validateImportMarketsAR} />
                </div>
            }
                        
            <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes' allowEmpty={true}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>

            <BooleanInput source='exporter' label='Exporter?' />
            <BooleanInput source='importer' label='Importer?' />
            <NumberInput source='exportPercentage' label='Export Percentage' fullWidth={true} min={0.01} max={100.00} step={0.01} />
            <BooleanInput source='exportPercentageExact' label='Export Percentage Exact' />
            <NumberInput source='importPercentage' label='Import Percentage' fullWidth={true} min={0.01} max={100.00} step={0.01} />
            <BooleanInput source='importPercentageExact' label='Import Percentage Exact' />

            <ReferenceArrayInput source="exportRegions" label='Export Regions' reference='enumerations-region-codes' fullWidth={true} allowEmpty={true}>
                <SelectArrayInput optionText='label' />
            </ReferenceArrayInput>

            <ReferenceArrayInput source="exportCountries" label='Export Countries' reference='enumerations-country-codes' fullWidth={true} allowEmpty={true}>
                <SelectArrayInput optionText='label' />
            </ReferenceArrayInput>

            <ReferenceArrayInput source="importRegions" label='Import Regions' reference='enumerations-region-codes' fullWidth={true} allowEmpty={true}>
                <SelectArrayInput optionText='label' />
            </ReferenceArrayInput>

            <ReferenceArrayInput source="importCountries" label='Import Countries' reference='enumerations-country-codes' fullWidth={true} allowEmpty={true}>
                <SelectArrayInput optionText='label' />
            </ReferenceArrayInput>

            <BooleanInput source='active' defaultValue={true} label='Active?' validate={validations.validateActive} />
        </div>
    );
};

export default Markets;