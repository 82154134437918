import React from 'react';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import { Edit, SimpleForm, TextField, TextInput, ReferenceInput, SelectInput } from 'react-admin';
import { perPageReferenceFields } from '../../constants';
import { validations } from './validations';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const SourceCredentialsEdit = props => {

    return (
        <>
            <Edit undoable={true} {...props}>   
                <SimpleForm toolbar={<EditFormToolbarActions showDeleteButton={false} />}>
                    <BackButton {...props} action = {"edit"} />
                    <PageTitle {...props}>Edit</PageTitle>
                    <TextField label='Source' source='source_code' />
                    <ReferenceInput label="Select Login Type" source="login_type" reference="login-types" perPage={perPageReferenceFields} validate={validations.validateLoginType}>
                        <SelectInput optionText="description" optionValue='id' />
                    </ReferenceInput>
                    <TextInput label='Username' source="username" fullWidth={true} validate={validations.validateUsername} />
                    <TextInput type='password' label='Password' source="password" fullWidth={true} validate={validations.validatePassword} />
                    <ReferenceInput label='Select Idkey Code' source='idkey_code' reference='enumerations-idkey-codes' validate={validations.validateIdkeyCode} perPage={perPageReferenceFields} >
                        <SelectInput optionText="label" />
                    </ReferenceInput>
                    <ReferenceInput label="Script" source="extraction_service_script" reference="extraction-service-script" perPage={perPageReferenceFields} 
                            allowEmpty>
                        <SelectInput optionText="name" optionValue="id"/>
                    </ReferenceInput>
                    <TextInput source="url" fullWidth={true} validate={validations.validateUrl} />
                </SimpleForm>
            </Edit>
        </>
    );
}

export default SourceCredentialsEdit;