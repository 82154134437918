import List from './List';
import Create from './Create';
import Edit from './Edit';
import Icon from '@material-ui/icons/Functions';;

export default {
  icon: Icon,
  list: List,
  create: Create,
  edit: Edit

};