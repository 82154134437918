import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const Shares = (props) => {

    return (
        <div>
            <MappingSectionInput field='textAR' required={true} {...props}/>
            <MappingSectionInput field='languageCode' required={true} {...props}/>
            <MappingSectionInput field='quantity' {...props}/>
            <MappingSectionInput field='type' {...props}/>
            <MappingSectionInput field='extension' {...props}/>
            <MappingSectionInput field='class' {...props}/>
            <MappingSectionInput field='currencyCode' {...props}/>
            <MappingSectionInput field='issued' {...props}/>
            <MappingSectionInput field='paidup' {...props}/>
            <MappingSectionInput field='value' {...props}/>
            <MappingSectionInput field='currencyCode2' {...props}/>
            <MappingSectionInput field='issued2' {...props}/>
            <MappingSectionInput field='paidup2' {...props}/>
            <MappingSectionInput field='value2' {...props}/>
            <MappingSectionInput field='currencyCode3' {...props}/>
            <MappingSectionInput field='issued3' {...props}/>
            <MappingSectionInput field='paidup3' {...props}/>
            <MappingSectionInput field='value3' {...props}/>
            <MappingSectionInput field='start' {...props}/>
            <MappingSectionInput field='end' {...props}/>
            <MappingSectionInput field='active' required={true} {...props}/>
            <MappingSectionInput field='validated' required={true} {...props}/>
        </div>
    );  
};

export default Shares;