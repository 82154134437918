import React, {
    Fragment,
} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const ClearFiltersButton = ({filters, ...props}) => {
    return(
        props.context !== 'button' && 
        <Button 
            onClick={() => props.setFilters(filters)}
            startIcon={<CloseIcon />}
            style={{
                boxSizing: "border-box",
                /* Shadow/xs */
                borderRadius: "8px",
                color: "#0000008a",
                /* Inside auto layout */
                fontSize: "85%",
                fontWeight: "bold",
                textTransform:"none"
            }}
        >
            Clear Filters
        </Button>
    );

}

ClearFiltersButton.propTypes = {
    record: PropTypes.object,
};

export default ClearFiltersButton;
