import React from 'react';
import { NumberInput, TextInput, BooleanInput, DateInput, Button, Confirm } from 'react-admin';
import {useState } from 'react';
import { validations } from './validations';
import { useForm } from 'react-final-form';
import DeleteIcon from '@material-ui/icons/Delete';

const Header = props => {
    const form = useForm();
    const [open, setOpen] = useState(false);
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        form.change('notParsedData', undefined);
        setOpen(false);
    };


    return (
        <div>
            <TextInput source='_id' label='Document id' validate={validations.validateId} />
            <NumberInput source='reportId' label='Report id' validate={validations.validateReportId} />
            <TextInput source='sourceId' label='SourceId' validate={validations.validateSourceId} />
            <NumberInput source='sourceEntityId' label='Source entity id' />
            <NumberInput source='batchId' label='Batch Id' validate={validations.validateBatchId} />
            <NumberInput source='priority' label='Priority' min={1} max={9} validate={validations.validatePriority} />
            <TextInput source='status' label='Status' validate={validations.validateStatus} />
            <BooleanInput source='validated' label='Validated?' validate={validations.validateValidated} />
            <BooleanInput source='controlled' label='Controlled?' validate={validations.validateControlled} />
            <TextInput source='url' label='Url' validate={validations.validateUrl} />
            <DateInput source='effectiveDate' label='Effective Date' validate={validations.validateEffectiveDate} />
            <DateInput source='updateDate' label='Update Date' validate={validations.validateUpdateDate} />
            <DateInput source='insertDate' label='Insert Date' validate={validations.validateInsertDate} />
            <TextInput source='internalNote' label='Internal Note' validate={validations.validateInternalNote} />
            <BooleanInput source='manualSkip' label='Manual Skip' />
            <TextInput
                source='notParsedData'
                label='Not Parsed Data'
                multiline
                rows="15"
                style={{ width: "100%" }}
            />
            {form.getState().values.notParsedData && <Button label="Delete not parsed data" startIcon={<DeleteIcon />} onClick={handleClick} style={{ color: "red" , "text-transform": "capitalize"}} />}
            <Confirm
                isOpen={open}
                content="Are you sure you want to delete the not parsed data?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </div>
    );

};

export default Header;