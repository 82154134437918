import React from 'react';
import {List, Datagrid, TextField, BooleanField, DateField, EditButton} from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import EntitiesEditorFilter from './EntitiesEditorFilter';
import ShowEntityInfo from './ShowEntityInfo';

const EntitiesEditor = props => (
    <>
        <h2>Entities Editor</h2>
        <List filters={ <EntitiesEditorFilter />} bulkActionButtons={false} exporter={false} {...props}
            actions={<ListToolbarActions showCreateButton={false}/>}>
            <Datagrid expand={<ShowEntityInfo {...props} />} >
                <TextField label="ARTID" source="art_id"/>
                <TextField label="Main Name" source="main_name"/>
                <TextField label="Entity Type" source="entity_type" />
                <TextField label="Country Code" source="country_code" />
                <TextField label="Sucessor Entity" source="successor_art_id" />
                <BooleanField label="Verified" source="verified" />
                <BooleanField label="Public" source="public" />
                <BooleanField label="Active" source="active" />
                <DateField label="Insert Date" source="insert_date" locales="fr-FR" />
                <TextField label="Insert Source" source="source_code" />
                <EditButton/>
            </Datagrid>
        </List>
    </>
)

export default EntitiesEditor;