import React from 'react';
import { ColoringField } from './utils.js';
import { SelectInput, Filter } from 'react-admin';
import {
    useRefresh,
} from 'ra-core';

export const DocumentEditFilter = props => {
    const refresh = useRefresh();
    console.log('Filter Props', props);
    let filter_params = {document_id: props.document_id, entity_id: props.entity_id, section_name: props.section_name};

    if (JSON.stringify(props.filterValues) !== JSON.stringify(filter_params)){
        props.setFilters(filter_params);
    } 

    return (
        <>
        <Filter {...props}>
            {/* when document id changes, get new pair of entity and section pair */}
            <SelectInput source="document_id"
                choices={props.doc_choices}
                optionText={<ColoringField to_check='hasErrors' to_check2='updated' to_display='identifier'/>}
                optionValue="id"
                onChange={(e) => {
                    if (props.filterValues.entity_id === 'Doc'){
                        props.setFilters({document_id: e.target.value, entity_id: props.filterValues.entity_id, section_name: 'header'});
                        props.onDocumentChange(e, props.entity_choices[e.target.value], props.filterValues.entity_id, 'header');
                    }
                    else {
                        props.setFilters({document_id: e.target.value, entity_id: '0', section_name: 'entity Header'});
                        props.onDocumentChange(e, props.entity_choices[e.target.value], '0', 'entity Header');
                    }
                }}
                alwaysOn
                allowEmpty={false}
                initialValue={props.document_id}
            />
            <SelectInput 
                source='entity_id' 
                choices={props.entity_choices[props.document_id]} 
                onChange={(e) => {
                    if (e.target.value === 'Doc'){
                        props.setFilters({document_id: props.document_id, entity_id: e.target.value, section_name: 'header'});
                        props.onSectionChange(e, props.entity_choices[props.document_id], 'header');
                    }
                    else {
                        props.setFilters({document_id: props.document_id, entity_id: e.target.value, section_name: 'entity Header'});
                        props.onSectionChange(e, props.entity_choices[props.document_id], 'entity Header');
                    }
                    refresh();
                }} 
                optionText={<ColoringField to_check='hasErrors' to_check2='updated' to_display='entity' />}
                optionValue="id"
                initialValue={props.entity_id}
                alwaysOn
                allowEmpty={false}
            />
            <SelectInput source='section_name' choices={props.section_choices[props.document_id][props.entity_id]} onChange={(e) => props.onSectionChange(e, null, null)} optionText={<ColoringField to_check='hasErrors' to_check2='updated' to_display='name'  />} optionValue="id" 
                initialValue={props.section_name} alwaysOn allowEmpty={false}/>
        </Filter>
        </>

    );
};