import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import SettingsIcon from "@material-ui/icons/Settings";
import LabelIcon from "@material-ui/icons/Label";
import BarChartIcon from "@material-ui/icons/BarChart";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import { userHasPermission } from "../../helpers/authProvider";

import { withRouter } from "react-router-dom";
import {
  translate,
  DashboardMenuItem,
  MenuItemLink,
  Responsive,
  SaveButton,
} from "react-admin";
import Icon from "@material-ui/icons/PlaylistPlayOutlined";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";

import SubMenu from "../SubMenu";
import NotificationErrorAlerts from "../NotificationErrorAlerts";
import processes from "../../pages/Processes";
import jobs from "../../pages/Jobs";
import sources from "../../pages/Sources";
import products from "../../pages/Products";
import sourceCredentials from "../../pages/SourceCredentials";
import userPermissions from "../../pages/UserPermissions";
import mappings from "../../pages/Mappings";
import enumerations from "../../pages/Enumerations";
import importMappingLabels from "../../pages/ImportMappingLabels";
import importMappingSources from "../../pages/ImportMappingSources";
import steps from "../../pages/Steps";
import scheduledJobs from "../../pages/ScheduledJobs";
import tasks from "../../pages/Tasks";
import alerts from "../../pages/Alerts";
import users from "../../pages/SystemSetup/Users";
import accounts from "../../pages/Accounts";
import sourceFilesConfigurations from "../../pages/SourceFilesConfigurations";
import documents from "../../pages/Documents";
import extraction_service_script from "../../pages/ExtractionServiceScript";
import dashboard from "../../pages/Dashboard/RecordsCount";
import companiesCount from "../../pages/Dashboard/CompaniesCount";
import source_entities_blocked_references from "../../pages/SourceEntitiesBlockedReferences";
import source_entities from "../../pages/SourceEntities";
import mongo_ip from "../../pages/SystemSetup/MongoIP";
import arttence_config from "../../pages/SystemSetup/ArtTenceConfig";
import adi_tester from "../../pages/ADITester";
import geocoding_tester from "../../pages/GeocodingTester";
import entities from "../../pages/Entities";
import orders from "../../pages/Orders";
import suppliers from "../../pages/Suppliers";
import supplier_product_requests from "../../pages/SupplierProductRequests";
import supplier_product_country_references from "../../pages/SupplierProductCountryReferences";
import importMappings from "../../pages/ImportMappings";
import importMappingFunctions from "../../pages/ImportMappingFunctions";
import notices from "../../pages/Notices";
import enrichmentScoringMappings from "../../pages/EnrichmentScoringMappings";
import EntitiesMerger from "../../pages/Tools/EntitiesMerger";
import activityValidator from "../../pages/ActivityValidator";
import rabbitMQGenerator from "../../pages/RabbitMQGenerator";
import hqBranchGUI from "../../pages/HQBranchGUI";
import exportMappingsDeliveries from "../../pages/ExportMappings";
import DeliverySetupIcon from '@material-ui/icons/CellWifiOutlined';

class Menu extends Component {
  state = {
    menuDashboard: false,
    menuSourceSetup: false,
    menuProcessSetup: false,
    menuProcessExecution: false,
    menuOrderSetup: false,
    menuDeliverySetup: false,
    menuSystemSetup: false,
  };

  static propTypes = {
    onMenuClick: PropTypes.func,
  };

  handleToggle = (menu) => {
    this.setState((state) => ({ [menu]: !state[menu] }));
  };

  render() {
    const { onMenuClick, open, logout, translate } = this.props;
    return (
      <div>
        {userHasPermission("dashboard-read") && (
          <SubMenu
            handleToggle={() => this.handleToggle("menuDashboard")}
            isOpen={this.state.menuDashboard}
            sidebarIsOpen={open}
            name="Dashboard"
            label="Dashboard"
            icon={<dashboard.icon />}
          >
            {/* <DashboardMenuItem 
              primaryText='Total Records'
              onClick={onMenuClick}
              leftIcon={<BarChartIcon />} 
            /> */}
            {/* <MenuItemLink
              to={`/statistics/companies-count`}
              primaryText="Total Companies"
              label="Total Companies"
              leftIcon={<ShowChartIcon />}
              rightIcon={<ShowChartIcon />}
              onClick={onMenuClick}
            /> */}
            <MenuItemLink
              to={`/statistics/total-records-count`}
              primaryText="Pending Documents"
              leftIcon={<ShowChartIcon />}
              rightIcon={<ShowChartIcon />}
              onClick={onMenuClick}
            />
            <MenuItemLink
              to={`/statistics/notices-count`}
              primaryText="Nº of Notices"
              leftIcon={<ShowChartIcon />}
              rightIcon={<ShowChartIcon />}
              onClick={onMenuClick}
            />
            <MenuItemLink
              to={`/statistics/new-entity-counts`}
              primaryText="Nº of New Entities"
              leftIcon={<ShowChartIcon />}
              rightIcon={<ShowChartIcon />}
              onClick={onMenuClick}
            />
            <MenuItemLink
              to={`/statistics/updated-entity-counts`}
              primaryText="Nº of Updated Entities"
              leftIcon={<ShowChartIcon />}
              rightIcon={<ShowChartIcon />}
              onClick={onMenuClick}
            />
          </SubMenu>
        )}

        {userHasPermission("sources-read") && (
          <SubMenu
            handleToggle={() => this.handleToggle("menuSourceSetup")}
            isOpen={this.state.menuSourceSetup}
            sidebarIsOpen={open}
            name="Source Setup"
            icon={<sources.icon />}
          >
            <MenuItemLink
              to={`/source-codes`}
              primaryText="Sources"
              leftIcon={<sources.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/source-credentials`}
              primaryText="Source Credentials"
              leftIcon={<sourceCredentials.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/source-files-configurations`}
              primaryText="Source Files"
              leftIcon={<sourceFilesConfigurations.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/extraction-service-script`}
              primaryText="Service Scripts"
              leftIcon={<extraction_service_script.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/source-entities-blocked-references`}
              primaryText="Blocked References"
              leftIcon={<source_entities_blocked_references.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/enumerations`}
              primaryText="Enumerations"
              leftIcon={<enumerations.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/import-mapping-labels`}
              primaryText="Import Mapping Labels"
              leftIcon={<importMappingLabels.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/import-mapping-sources`}
              primaryText="Import Mapping Sources"
              leftIcon={<importMappingSources.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/accounts`}
              primaryText="Accounts"
              leftIcon={<accounts.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/suppliers`}
              primaryText="Suppliers"
              leftIcon={<suppliers.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/products`}
              primaryText="Products"
              leftIcon={<products.icon />}
              onClick={onMenuClick}
            />

            {userHasPermission("import-mapping-update") && (
              <MenuItemLink
                to={`/import-mappings`}
                primaryText="Import Mappings"
                leftIcon={<importMappings.icon />}
                onClick={onMenuClick}
              />
            )}

            {userHasPermission("import-mapping-update") && (
              <MenuItemLink
                to={`/import-mapping-functions`}
                primaryText="Mapping Functions"
                leftIcon={<importMappingFunctions.icon />}
                onClick={onMenuClick}
              />
            )}
            <MenuItemLink
              to={`/enrichment-mapping-scorings`}
              primaryText="Enrichment Mappings"
              leftIcon={<enrichmentScoringMappings.icon />}
              onClick={onMenuClick}
            />
          </SubMenu>
        )}

        {userHasPermission("processes-read") && (
          <SubMenu
            handleToggle={() => this.handleToggle("menuProcessSetup")}
            isOpen={this.state.menuProcessSetup}
            sidebarIsOpen={open}
            name="Process Setup"
            icon={<processes.icon />}
          >
            <MenuItemLink
              to={`/steps`}
              primaryText="Steps"
              leftIcon={<steps.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/processes`}
              primaryText="Processes"
              leftIcon={<processes.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/cron-jobs`}
              primaryText="Scheduled Jobs"
              leftIcon={<scheduledJobs.icon />}
              onClick={onMenuClick}
            />

            {userHasPermission("adi-tester-read") && (
              <MenuItemLink
                to={`/adi-tester`}
                primaryText="ADI Tester"
                leftIcon={<adi_tester.icon />}
                onClick={onMenuClick}
              />
            )}
            {userHasPermission("geocoding-tester-read") && (
              <MenuItemLink
                to={`/geocoding-tester`}
                primaryText="Geocoding Tester"
                leftIcon={<geocoding_tester.icon />}
                onClick={onMenuClick}
              />
            )}
          </SubMenu>
        )}

        <SubMenu
          handleToggle={() => this.handleToggle("menuOrderSetup")}
          isOpen={this.state.menuOrderSetup}
          sidebarIsOpen={open}
          name="Order Setup"
          icon={<NoteAddOutlinedIcon />}
        >
          {userHasPermission("ordersgui-read") && (
            <MenuItemLink
              to={`/orders`}
              primaryText="Orders GUI"
              leftIcon={<orders.icon />}
              onClick={onMenuClick}
            />
          )}

          {userHasPermission("requests-read") && (
            <MenuItemLink
              to={`/supplier-product-requests`}
              primaryText="Requests"
              leftIcon={<supplier_product_requests.icon />}
              onClick={onMenuClick}
            />
          )}

          {userHasPermission("countryreferences-read") && (
            <MenuItemLink
              to={`/supplier-product-country-references`}
              primaryText="Country References"
              leftIcon={<supplier_product_country_references.icon />}
              onClick={onMenuClick}
            />
          )}
        </SubMenu>
        
        <SubMenu
          handleToggle={() => this.handleToggle("menuDeliverySetup")}
          isOpen={this.state.menuDeliverySetup}
          sidebarIsOpen={open}
          name="Delivery Setup"
          icon={<DeliverySetupIcon />}
        >
            <MenuItemLink
              to={`/export-mapping-deliveries`}
              primaryText="Export Mapping Delivery"
              leftIcon={<exportMappingsDeliveries.icon />}
              onClick={onMenuClick}
            />
        </SubMenu>

        {userHasPermission("dashboard-read") && (
          <SubMenu
            handleToggle={() => this.handleToggle("menuSystemSetup")}
            isOpen={this.state.menuSystemSetup}
            sidebarIsOpen={open}
            name="System Setup"
            label="System Setup"
            icon={<users.icon />}
          >
            {userHasPermission("usersmanagement-read") && (
              <MenuItemLink
                to={`/users`}
                primaryText="Users Management"
                leftIcon={<users.icon />}
                onClick={onMenuClick}
              />
            )}
            {userHasPermission("mongodb-ip-update") && (
              <MenuItemLink
                to={`/mongo-ip`}
                primaryText="MongoDB IP"
                leftIcon={<mongo_ip.icon />}
                onClick={onMenuClick}
              />
            )}
            {userHasPermission("management") && (
              <MenuItemLink
                to={`/arttence-config`}
                primaryText="ArtTence Config"
                leftIcon={<arttence_config.icon />}
                onClick={onMenuClick}
              />
            )}
          </SubMenu>
        )}

        {userHasPermission("jobs-read") && (
          <MenuItemLink
            to={`/jobs`}
            primaryText="Jobs"
            leftIcon={<jobs.icon />}
            onClick={onMenuClick}
          />
        )}

        {userHasPermission("tasks-read") && (
          <MenuItemLink
            to={`/tasks`}
            primaryText="Tasks"
            leftIcon={<tasks.icon />}
            onClick={onMenuClick}
          />
        )}

        {userHasPermission("tasks-read") && (
          <MenuItemLink
            to={`/notices`}
            primaryText="Notices"
            leftIcon={<notices.icon />}
            onClick={onMenuClick}
          />
        )}

        {userHasPermission("notifications-read") && (
          <MenuItemLink
            to={`/alerts`}
            primaryText={<NotificationErrorAlerts title="Error Alerts" />}
            leftIcon={<tasks.icon />}
            onClick={onMenuClick}
          />
        )}

        {userHasPermission("ateditor-read") && (
          <MenuItemLink
            to={`/documents`}
            primaryText="AT-Editor"
            leftIcon={<documents.icon />}
            onClick={onMenuClick}
          />
        )}

        {userHasPermission("seeditor-read") && (
          <MenuItemLink
            to={`/source-entities`}
            primaryText="SE Editor"
            leftIcon={<source_entities.icon />}
            onClick={onMenuClick}
          />
        )}

        {userHasPermission("activity-validator") && (
          <MenuItemLink
            to={`/activity-validator`}
            primaryText="Activity Validator"
            leftIcon={<activityValidator.icon />}
            onClick={onMenuClick}
          />
        )}

        {userHasPermission("entities-merger") && (
          <MenuItemLink
            to={`/entitiesmerger`}
            primaryText="Entities Merger"
            leftIcon={<EntitiesMerger.icon />}
            onClick={onMenuClick}
          />
        )}

        {userHasPermission("entitieseditor-read") && (
          <MenuItemLink
            to={`/entities`}
            primaryText="Entities Editor"
            leftIcon={<entities.icon />}
            onClick={onMenuClick}
          />
        )}

        {userHasPermission("rabbit-mq-insert") && (
          <MenuItemLink
            to={`/rabbit-mq-generator/create`}
            primaryText="Rabbit MQ Generator"
            leftIcon={<rabbitMQGenerator.icon />}
            onClick={onMenuClick}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
});

const enhance = compose(withRouter, connect(mapStateToProps, {}), translate);

export default enhance(Menu);
