import React from 'react';
import { ReferenceInput, AutocompleteInput, TextInput } from 'react-admin';
import { validations } from './validations';

const EntityHeader = (props) => {

    const containerStyle = {
        display: 'flex',
        flexDirection: 'row',
    };

    const verticalStackStyle = {
        display: 'flex',
        flexDirection: 'column',
    };

    const horizontalStackStyle = {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '20px',
    };

    return (
        <div style={containerStyle}>
            <div style={verticalStackStyle}>
                <ReferenceInput source='type' label='Type' reference='enumerations-entity-types' validate={validations.validateType}>
                    <AutocompleteInput optionText="label" optionValue="id" />
                </ReferenceInput>
                <ReferenceInput source='relation' label='Relation' reference='enumerations-entity-relations' validate={validations.validateRelation}>
                    <AutocompleteInput optionText="label" optionValue="id" />
                </ReferenceInput>
                <TextInput source='regName' label='Register Name' validate={validations.validateRegName} />
                <ReferenceInput source='countryCode' label='Country Code' reference='enumerations-country-codes' validate={validations.validateCountryCode}>
                    <AutocompleteInput optionText="label" optionValue="id" />
                </ReferenceInput>
            </div>
            <div style={horizontalStackStyle}>
                <TextInput source='reference' label='Reference' validate={validations.validateReference} />
                <TextInput source='referenceFormer' label='Former Reference' validate={validations.validateReferenceFormer} />
            </div>
        </div>
    );
};

export default EntityHeader;
