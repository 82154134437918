import React, { useState, useEffect } from 'react';
import { SimpleForm, Toolbar, SaveButton, FormDataConsumer, AutocompleteInput, Loading } from 'react-admin';
import CompanyStatuses from '../DocumentSections/CompanyStatuses';
import CompanyTypes from '../DocumentSections/CompanyTypes';
import EntityHeader from '../DocumentSections/EntityHeader';
import Header from '../DocumentSections/Header';
import PersonTypes from '../DocumentSections/PersonTypes';
import PersonStatuses from '../DocumentSections/PersonStatuses';
import Sics from '../DocumentSections/Sics';
import CompanyNames from '../DocumentSections/CompanyNames';
import PersonNames from '../DocumentSections/PersonNames';
import Registrations from '../DocumentSections/Registrations';
import Idkeys from '../DocumentSections/Idkeys';
import Addresses from '../DocumentSections/Addresses';
import CommAddresses from '../DocumentSections/CommAddresses';
import CommNumbers from '../DocumentSections/CommNumbers';
import Capitals from '../DocumentSections/Capitals';
import Events from '../DocumentSections/Events';
import Filings from '../DocumentSections/Filings';
import Markets from '../DocumentSections/Markets';
import Notes from '../DocumentSections/Notes';
import Relations from '../DocumentSections/Relations';
import Shares from '../DocumentSections/Shares';
import Credits from '../DocumentSections/Credits';
import Texts from '../DocumentSections/Texts';
import KeyFigures from '../DocumentSections/KeyFigures';
import Booleans from '../DocumentSections/Booleans';
import StockExchanges from '../DocumentSections/StockExchanges';
import Licenses from '../DocumentSections/Licenses';
import Hscs from '../DocumentSections/Hscs';
import Transactions from '../DocumentSections/Transactions';
import { Api } from '../../helpers/api';
import {
    useNotify,
    useRedirect,
    useRefresh
} from 'ra-core';
import "../../index.css";

const DocumentEditToolbar = props => {
    return(
        <Toolbar
            {...props}
            style = {{
                alignVertical: 'center'
            }}
        >
            <SaveButton/>
            {/* {!props.location && <DeleteSectionButton {...props}/>} */}
        </Toolbar>
    )
};

const handleClick = (dataToSend, props, notify, redirect, refresh) => {
    let data = Object.assign({}, dataToSend);
    delete data['id'];
    delete data['errors'];
    delete data['fieldErrors'];
    delete data['updated'];
    data['validated'] = true;
    
    let body = {};

    let filter = {};


    // CREATE SECTION OBJECT OR ADD SECTION
    if ('location' in props){
        body = {data: data, identifiers: {section: props.location.state.section, doc_id: props.location.state.doc_id, entity_id: props.location.state.entity_id}};

        if (!('section' in data)){
            data.section = body.identifiers.section;
        }

        filter = {document_id: body.identifiers.doc_id, entity_id: body.identifiers.entity_id, section_name: data.section};
        
        console.log('body', body);

        return Api.post(`/document-edit/`, body, true)
            .then(response => {
                if (response.type === "success") {
                    console.log("result", response.result);
                    notify('Section object sucessfully created!');
                    setTimeout(function() {
                        redirect(`/document-edit?filter=${JSON.stringify(filter)}`);
                    }, (0.8 * 1000))
                } 
                else if (response.type === "auth_error") {
                    console.log("Error starting the process - authentication error");
                }
                else {
                    console.log("Error starting the process - response", response);
                    if (response.error) {
                    }
                    else {
                    }
                }
            })
            .catch((e) => {
                console.log("Exception starting the process - e", e);
            });
    }
    // UPDATE SECTION OBJECT
    else {
        body = {data: data, identifiers: {section: props.section, doc_id: props.doc_id, object_id: props.id, entity_id: props.entity_id}};
        console.log('body', body);

        filter = {document_id: body.identifiers.doc_id, entity_id: body.identifiers.entity_id, section_name: body.identifiers.section};

        return Api.patch(`/document-edit/${body.identifiers.doc_id}/`, body, true)
            .then(response => {
                if (response.type === "success") {
                    console.log("result", response.result);
                    notify('Document successfully updated!');
                    setTimeout(function() {
                        window.location.reload();
                    }, (0.5 * 1000))
                   
                } 
                else if (response.type === "auth_error") {
                    console.log("Error starting the process - authentication error");
                }
                else {
                    console.log("Error starting the process - response", response);
                    if (response.error) {
                    }
                    else {
                    }
                }
            })
            .catch((e) => {
                console.log("Exception starting the process - e", e);
            });
    }
};

const SectionForm = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const [choices, setChoices] = useState({});
    const [loading, setLoading] = useState(true);

    const getChoices = () => {
        return Api.get(`/enumerations-at-editor-sections`, true)
        .then((response) => {
            if (response.type === "success") {
                setChoices(response.result.results);
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        if ('location' in props && 'entity_name' in props.location.state){
            getChoices();
        }
        else {
            setLoading(false);
        }
    }, [])

    if (loading) { return <Loading />};

    let section = '';
    let entity_name = '';
    let doc_id = '';
    let records = {};

    if ('location' in props){
        section = props.location.state.section;
        doc_id = props.location.state.doc_id;
        records = props.location.state.records;

        if ('entity_name' in props.location.state){
            entity_name = props.location.state.entity_name
            section = choices[records[doc_id].status][entity_name][0].id
        }
    }
    else {
        section = props.section;
    }
    
    let result = {};
    
    try {
        result = 
            <div>
                {'location' in props && !('entity_name' in props.location.state) && <h4>Section: {section}</h4>}
                <SimpleForm toolbar={<DocumentEditToolbar {...props}/>} save={(data) => {console.log(data); handleClick(data, props, notify, redirect, refresh); }} {...props}>
                    
                    { 'location' in props && 'entity_name' in props.location.state &&
                        <AutocompleteInput source='section' choices={choices[records[doc_id].status][entity_name]} alwaysOn
                            allowEmpty={false} defaultValue={section} onChange={(e) => {section = e; console.log('SECTION', section);}} />
                    }

                    <FormDataConsumer subscription={{ values: true }}>
                        {({ formData, ...rest }) => (formData.section || section) &&
                        <>
                            { ['addresses', 'adminAddresses', 'businessAddresses', 'postalAddresses', 'legalDomiciles', 'liquidationAddresses', 'professionalAddresses', 'birthPlace', 'nationalities', 'branchAddresses'].indexOf(section) > -1 &&
                                <Addresses section={section} {...props} />
                            }

                            { section === 'booleans' &&
                                <Booleans {...props} />
                            }

                            { ['emailAddresses', 'webAddresses', 'skypeAddresses', 'facebookAddresses', 'instagramAddresses', 'linkedinAddresses', 'twitterAddresses', 'youtubeAddresses', 'googleplusAddresses'].indexOf(section) > -1  &&
                                <CommAddresses {...props} />
                            }
                            { ['phoneNumbers', 'faxNumbers', 'mobileNumbers'].indexOf(section) > -1 &&
                                <CommNumbers {...props} />
                            }
                            { section === 'ordinaryCapitals' &&
                                <Capitals {...props} />
                            }
                            { ['names', 'businessNames', 'shortNames'].indexOf(section) > -1 && 
                                <CompanyNames {...props} />
                            }
                            { section === 'companyStatuses' &&
                                <CompanyStatuses {...props} />
                            }
                            { section === 'companyTypes' &&
                                <CompanyTypes {...props} />
                            }
                            { section === 'credits' &&
                                <Credits {...props} />
                            }
                            { section === 'entity Header' &&
                                <EntityHeader {...props} />
                            }
                            { section === 'events' && 
                                <Events {...props} />
                            }
                            { section === 'filing' && 
                                <Filings {...props} />
                            }
                            { section === 'header' &&
                                <Header {...props} />
                            }
                            { section === 'idkeys' &&
                                <Idkeys {...props} />
                            }
                            { section === 'licenses' &&
                                <Licenses {...props} />
                            }
                            { section === 'markets' &&
                                <Markets {...props} />
                            }
                            { section === 'notes' &&
                                <Notes {...props} />
                            }
                            { section === 'personNames' && 
                                <PersonNames {...props} />
                            }
                            { section === 'personStatuses' &&
                                <PersonStatuses {...props} />
                            }
                            { section === 'personTypes' &&
                                <PersonTypes {...props} />
                            }
                            { section === 'registrations' &&
                                <Registrations {...props} />
                            }
                            { ['directorshipsChild', 'directorshipsParent', 
                                'ownershipsChild', 'ownershipsParent', 
                                'relationshipsChild', 'relationshipsParent', "connectionsParent"].indexOf(section) > -1  &&
                                <Relations section={section} {...props} />
                            } 
                            { section === 'shares' &&
                                <Shares {...props} />
                            }
                            { ['standardFinancialsSL', 'standardFinancialsCD'].includes(section) && 
                                <KeyFigures {...props} />
                            }
                            { section === 'sics' &&
                                <Sics {...props} />
                            }
                            { section === 'stockExchanges' &&
                                <StockExchanges {...props} />
                            }
                            { ['activities', 'products', 'industries',
                                'profiles', 'purposes', 'creditTexts', 'mortgageTexts', 'adverseTexts', 'otherContents'].includes(section) &&
                                <Texts {...props} />
                            }
                            { section === 'hscs' &&
                                <Hscs {...props} />
                            }

                            { section === 'transactions' &&
                                <Transactions {...props} />
                            }
                        </>
                        }
                    </FormDataConsumer>
                </ SimpleForm>
            </div>
    } catch (error) {
        console.log(error.info);
    }

    return result;

};

export default SectionForm;