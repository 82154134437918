import React, { useState } from "react";
import {
  Toolbar,
  SaveButton,
  Create,
  DateInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { validations } from "./validations";
import PageTitle from "../../components/PageTitle";
import { Api } from "../../helpers/api";
import { useRefresh, useNotify } from "ra-core";

const CustomSaveButton = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const handleSubmitRequest = (props) => {
    if (props.info && props.info.jobId !== 0) {
      return Api.post(
        `/jobs/${props.info.jobId}/put-messages-rabbit/`,
        props.info,
        true
      ).then((response) => {
        if (response.type === "success" && response.result?.added_messages.length) {
            notify("Successfully added messages to Rabbit MQ queue!", "success");
            refresh();         
          } else {
            notify("No messages to add to RabbitMQ", "success");
            refresh();
          }
        })
      };
    }

  return (
    <Toolbar {...props}>
      <SaveButton
        style={{ "margin": "20px"}}
        label="Add messages"
        type="button"
        onClick={() => handleSubmitRequest(props)}
        onSave={() => null}
      />
    </Toolbar>
  );
};

const RabbitMessageCreate = (props) => {
  const [info, setInfo] = useState({ jobId: 0, startDate: "0" });

  return (
    <>
      <Create {...props}>
        <SimpleForm toolbar={<CustomSaveButton info={info} {...props} />}>
          <PageTitle {...props} />
          <TextInput
            source="job_id"
            label="Job Id"
            validate={validations.validateJobId}
            onChange={(e) => setInfo({ ...info, jobId: e.target.value })}
          />
          <DateInput
            source="start_date"
            validate={validations.validateStartDate}
            onChange={(e) => setInfo({ ...info, startDate: e.target.value })}
          />
        </SimpleForm>
      </Create>
    </>
  );
};

export default RabbitMessageCreate;
