import React, { Fragment } from 'react';
import {Datagrid, EditButton, List, ReferenceInput, SelectInput, AutocompleteInput, Filter, TextField, DateField} from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import { perPageReferenceFields } from "../../constants";
import ClearFiltersButton from "../../components/ClearFiltersButton";

const SourceEntitiesBlockedReferencesFilter = (props) => {
  return(
    <div>
        <ClearFiltersButton {...props} filters={{source_id: '', reference: ''}}/>
        <Filter {...props}>
            <ReferenceInput label="Source Code" source="source_id" reference="sources" perPage={perPageReferenceFields} alwaysOn>
              <AutocompleteInput optionText="code" />
            </ReferenceInput>
            <ReferenceInput label="Reference" source="reference" reference="source-entities-blocked-references-refs" perPage={perPageReferenceFields} alwaysOn>
              <AutocompleteInput optionText="reference" optionValue="reference" suggestionLimit={20} shouldRenderSuggestions={(value) => value.length > 0} />
            </ReferenceInput>
        </Filter>
      </div>
  );
}

const SourceEntitiesBlockedReferencesList = props => {
  return (
    <ThemeProvider theme={theme}>
      <h2>{props.options.label}</h2>
      <List
        filters={<SourceEntitiesBlockedReferencesFilter />}
        bulkActionButtons={<DocBulkActionButtons {...props} />}
        exporter={false}
        {...props}
        actions={<ListToolbarActions showCreateButton={true} />}
      >
        <Datagrid>
          <TextField source="source_code" label="Source Code" />
          <TextField source="reference" />
          <DateField source="insert_date" locales="pt-PT" />
          <TextField source="reason" />
          <TextField source="correct_reference" />
          <EditButton />
        </Datagrid>
      </List>
    </ThemeProvider>
  );
}

export default SourceEntitiesBlockedReferencesList;
