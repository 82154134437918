import React from 'react';
import { Filter, List, Datagrid, TextField, EditButton } from 'react-admin';
import { perPageReferenceFields } from "../../../constants";
import { Api } from "../../../helpers/api";

const ArtTenceConfigsList = props => (
    <>
        <h2>{props.options.label}</h2>
        <List  sort={{ field: 'id', order: 'DESC' }}
                bulkActionButtons={false} exporter={false} {...props} perPage={perPageReferenceFields}>
            <Datagrid>
                <TextField label='Key' source="key" />
                <TextField label='Configuration' source="configuration" />
                <EditButton />
            </Datagrid>
        </List>
    </>
);

export default ArtTenceConfigsList;