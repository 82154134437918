import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { userHasPermission } from "../../helpers/authProvider";

import {
  useShowController,
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  useRefresh,
  useRedirect,
  useNotify,
  CardContentInner,
  Button,
  useDataProvider,
  List,
  Datagrid,
} from "react-admin";
import AbortJobButton from "../../components/AbortJobButton";
import S3JobButton from "../../components/S3JobButton";
import JobStepStatusTable from "./JobStepsStatus";
import { Api } from "../../helpers/api";
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";
import JobInfoShow from "../JobInfo/Show";
import { Drawer } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomAPIRequest from "./helpers";
const useStyles = makeStyles({
  stepslist: {
    padding: 0,
    paddingTop: 16,
  },
});

const JobShow = (props) => {
  const refresh = useRefresh();
  const classes = useStyles();
  const redirect = useRedirect();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [s3url, setS3Url] = useState("");
  const [timer, setTimer] = useState(null);
  const {
    loaded, // boolean that is false until the record is available
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  const [recordSelected, setRecordSelected] = useState();
  const dataProvider = useDataProvider();

  const handleClick = () => {
    setLoading(true);
    clearInterval(timer);
    if (typeof props.id === "string") {
      console.log("PROPS ID", props.id);
      dataProvider.getOne("job-info", { id: props.id }).then(({ data }) => {
        setRecordSelected(data);
        setLoading(false);
      });
    }
  };

  const handleClose = () => {
    setRecordSelected(null);
  };

  const isJobInFinalState = (record) => {
    return loaded && record.final_state;
  };

  useEffect(() => {
    //console.log("willmount");
    // willmount handler
    let intervalTimer = -1;
    const jobStatusTagErrorMessage = "job status";
    const jobStatusEndpoint = "status/";
    const s3TagErrorMessage = "s3 folder url";
    const s3Endpoint = "s3url/";

    if (loaded) {
      console.log("pre", record.id);
      CustomAPIRequest({
        id: record.id,
        pathEnd: jobStatusEndpoint,
        stateSet: setRows,
        tagErrorMessage: jobStatusTagErrorMessage,
        redirect: redirect,
        notify: notify,
      });
      CustomAPIRequest({
        id: record.id,
        pathEnd: s3Endpoint,
        stateSet: setS3Url,
        tagErrorMessage: s3TagErrorMessage,
        redirect: redirect,
        notify: notify,
        attribute: "url",
      });

      if (!isJobInFinalState(record)) {
        intervalTimer = setInterval(() => {
          console.log("going to refresh");
          refresh();
          CustomAPIRequest({
            id: record.id,
            pathEnd: jobStatusEndpoint,
            stateSet: setRows,
            tagErrorMessage: jobStatusTagErrorMessage,
            redirect: redirect,
            notify: notify,
          });
        }, 10000);
        setTimer(intervalTimer);
      }
    }

    // unmount handler
    return () => {
      // Clean up the interval
      if (intervalTimer !== -1) {
        clearInterval(intervalTimer);
      }
    };
  }, [refresh, record]);

  return (
    <>
      <Show {...props}>
        <SimpleShowLayout>
          <BackButton {...props} action={"show"} />
          <PageTitle {...props}>Show</PageTitle>
          <TextField label="id" source="id" />
          <ReferenceField
            label="Process"
            source="process"
            reference="processes"
            link={false}
          >
            <TextField label="title" source="title" />
          </ReferenceField>
          <ReferenceField
            label="Status"
            source="status"
            reference="job-status"
            link={false}
          >
            <TextField label="title" source="title" />
          </ReferenceField>
          <TextField label="created_by" source="created_by" />
          <TextField label="created_at" source="created_at" />
          <TextField label="finished_at" source="finished_at" />
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                display: "flex",
                columnGap: "10px",
                justifyContent: "space-between",
              }}
            >
              {!isJobInFinalState(record) && <AbortJobButton record={record} />}
              <S3JobButton url={s3url} />
              {loading ? (
                <CircularProgress style={{ marginLeft: "10px" }} />
              ) : (
                <Button
                  label="+ More Info"
                  variant="outlined"
                  onClick={handleClick}
                />
              )}
            </div>
          </div>
        </SimpleShowLayout>
      </Show>
      {recordSelected ?
       (
        <Drawer anchor="right" open={true} onClose={handleClose} {...props}>
          <div style={{ width: "70vw", margin: "1vw" }}>
            <JobInfoShow {...props} record={recordSelected} />
          </div>
        </Drawer>
      ) : null}
      <CardContentInner className={classes.stepslist} key={props.version}>
        {loaded && <JobStepStatusTable rows={rows} record={record} />}
      </CardContentInner>
    </>
  );
};

export default JobShow;
