import React from 'react';
import { Show, SimpleShowLayout, TextField, DeleteButton, Toolbar } from 'react-admin';
import JobLinkField from "../../components/JobLinkField";

const NotificationShow = props => (
    <>
        <h2>{props.options.label}</h2>
        <Show {...props}>
            <SimpleShowLayout>
                <JobLinkField source="job" label="See Job details" />
                <br/>
                <TextField source="subject" />
                <TextField source="body" />
                <TextField source="created_at" />

                <Toolbar>
                    <DeleteButton undoable={false}/>
                </Toolbar>
            </SimpleShowLayout>
        </Show>
    </>
);

export default NotificationShow;