import {
    required,
    maxLength
} from 'react-admin';

export const validations = {
    validateCode: [required(), maxLength(10)],
    validateLabel: [required(), maxLength(100)],
    validateStartDate: [required()],
    validateSourceType: [required()],
    validateReportType: [required()],
    validateImportMappingSchema: [required()]
};
