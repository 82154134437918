import React from 'react';
import { useState, useEffect } from 'react';
import { List, Edit, TabbedForm, FormTab, TextInput, ReferenceInput, SelectInput, NumberInput, TextField, BooleanInput, Pagination,
    Datagrid, ReferenceField, ReferenceManyField, DeleteWithConfirmButton, ShowButton, useDataProvider, Loading, Error, useEditController, AutocompleteInput } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import { userHasPermission } from "../../helpers/authProvider";
import ContentCreate from '@material-ui/icons/Create';
import { validations } from "./validations";
import { priorityOptions, perPageReferenceFields } from "../../constants";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const AddProcessStepTransitionButton = ({ classes, record }) => {
    const label = "Add a Step Transition";
    const redirect = `/process-step-transitions/create?process_id=${record.id}`;
  
    return (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        component={Link}
        to={redirect}
      >
          {label}
      </Button>
    )
};

const EditProcessStepTransitionButton = ({ classes, record }) => {
    const label = "Edit";
    const redirect = `/process-step-transitions/${record.id}?process_id=${record.process}`;
  
    return (
  
        <Button
            color="primary"
            size="small"
            component={Link}
            to={redirect}
        >
            <ContentCreate />&nbsp;{label}
        </Button>
    )
};

const DeleteProcessStepTransitionButton = (props) => {
    const redirect = `/processes/${props.record.process}/steps`;
  
    return (
        <DeleteWithConfirmButton redirect={redirect} {...props} />
    )
};

const ProcessEdit = props => {
    const dataProvider = useDataProvider();
    const [sources, setSources] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const {
        loaded, // boolean that is false until the record is available
        record, // record fetched via dataProvider.getOne() based on the id from the location
    } = useEditController(props);

    useEffect(() => {

        if (loaded && !sources) { 
            dataProvider.getList('sources', {
                pagination: { page: 1, perPage: 300 },
                sort: { field: 'code', order: 'ASC' },
            })
                .then(( response ) => {
                    if (response) {
                        setSources(response.data);
                    }
                    else {
                        console.log("response", response);
                    }
                    setLoading(false);
                })
                .catch(error => {
                    console.log("error", error);
                    setError(error);
                    setLoading(false);
                })
        }
    }, [record, dataProvider, loaded, sources]);

    if (loading) return <Loading />;
    if (error) return <Error />;
    

    return (
        <ThemeProvider theme={theme}>
            <BackButton {...props} action = {"edit"}/>
            <PageTitle {...props}>Edit</PageTitle>
            <Edit {...props}>
                
                <TabbedForm toolbar={<EditFormToolbarActions showDeleteButton={false/*userHasPermission("processes-delete")*/} />}>
                    <FormTab label="Details">
                        <TextInput source="title" fullWidth={true} validate={validations.validateTitle} />
                        <TextInput multiline source="description" fullWidth={true} validate={validations.validateDescription} />
                        
                        <ReferenceInput label="Source" source="source_code" reference="process-source-codes" perPage={perPageReferenceFields}>
                            <AutocompleteInput optionText="code" validate={validations.validateSelection}/>
                        </ReferenceInput>

                        <BooleanInput source="subprocess" initialValue={false} label="Can be a sub-process?" />
                        <BooleanInput source="active" initialValue={false} label="Is active?" />
                        <SelectInput source="priority" choices={priorityOptions} optionText="text" optionValue="value"
                            validate={validations.validatePriority} />
                        <ReferenceInput label="Initial Step" source="initial_step" reference="steps" perPage={perPageReferenceFields} allowEmpty validate={validations.validateInitialStep}>
                            <SelectInput optionText="title" />
                        </ReferenceInput>
                        <ReferenceInput label="Product" source="product_id" reference="products" perPage={perPageReferenceFields} 
                            allowEmpty>
                            <SelectInput optionText="label" />
                        </ReferenceInput>
                        <ReferenceInput label="Script" source="script_id" reference="extraction-service-script" perPage={perPageReferenceFields} 
                                allowEmpty>
                            <SelectInput optionText="name"/>
                        </ReferenceInput>
                        <TextInput source="recipient" label="Process Owners" helperText="Emails separated by ;" fullWidth={true} validate={validations.validateRecipient} />
                        <TextField source="created_by" />
                        <TextField source="created_at" />
                        <TextField source="updated_at" />
                    </FormTab>

                    <FormTab label="Steps" path="steps">
                        <AddProcessStepTransitionButton />

                        <ReferenceManyField fullWidth reference="process-step-transitions" target="process_id" addLabel={false}
                            perPage={perPageReferenceFields} sort={{ field: 'source_step_id', order: 'ASC' }}
                        >
                            <List {...props}
                            filterDefaultValues={{process_id: props.id}}
                            exporter={false}
                            bulkActionButtons={<DocBulkActionButtons/>}
                            >
                                <Datagrid >
                                    <ReferenceField label="Source Step" source="source_step" reference="steps">
                                        <TextField source="title" />
                                    </ReferenceField>

                                    <TextField source="source_step_output" />

                                    <ReferenceField label="Next Step" source="next_step" reference="steps">
                                        <TextField source="title" />
                                    </ReferenceField>

                                    <EditProcessStepTransitionButton />
                                </Datagrid>
                            </List>
                        </ReferenceManyField>

                    </FormTab>

                    <FormTab label="Jobs History" path="jobshistory">

                        <ReferenceManyField fullWidth reference="jobs" target="process_id" addLabel={false}
                            pagination={<Pagination />} perPage={10} sort={{ field: 'id', order: 'DESC' }}
                        >
                            <Datagrid>
                                <ReferenceField label="Status" source="status" reference="job-status" link={false}>
                                    <TextField source="title" />
                                </ReferenceField>
                                <TextField source="created_by" />
                                <TextField source="created_at" />
                                <TextField source="finished_at" />
                                <ShowButton />
                            </Datagrid>
                        </ReferenceManyField>

                    </FormTab>
                </TabbedForm>
            </Edit>
        </ThemeProvider>
    );
    }

export default ProcessEdit;