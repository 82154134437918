import {minLength, maxLength, required, } from 'react-admin';

export const validations = {
  validateItemsAR: [required()],
  validateType: [required()],
  validateId: [minLength(1), maxLength(100)],
  validateReference: [minLength(1), maxLength(100)],
  validateCurrencyCode: [required()],
  validateValue: [required()],
  validateRating: [required()],
  validateRelatedParty: [minLength(3), maxLength(1000)],
  validateRemarks: [minLength(3), maxLength(1000)],
  validateStart: [required()],
  validateActive: [required()],
  validateValidated: [required()]
};
