import React from 'react';
import { TextInput, BooleanInput, ReferenceInput, AutocompleteInput, SelectInput, NumberInput, DateInput } from 'react-admin';
import { validations } from './validations';

const Notes = (props) => {
    console.log('PROPS');
    console.log(props);

    return (
        <div>
            { 'location' in props && 
                <TextInput source='longTextAR' fullWidth={true} label='Long text AR' validate={validations.validateLongTextAR} />
            }
            <TextInput source='longText' fullWidth={true} label='Long text' validate={validations.validateLongText} />
            <div  style={{display: 'flex', flexDirection: 'row', columnGap: '10px'}}>
                <div>
                <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes' validate={validations.validateLanguageCode} >
                <AutocompleteInput optionText="label" optionValue="id" />
                </ReferenceInput>
                </div>  
                <div>
            <ReferenceInput source='translationCode' label='Translation Code' reference='enumerations-translation-codes' allowEmpty={true} >
                <SelectInput optionText="label" optionValue="id" />
            </ReferenceInput>
            </div>
            </div>
            
            <div style={{display: 'flex', flexDirection: 'row', columnGap: '10px'}}>
            <TextInput source='category' label='Category' validate={validations.validateCategory} />
            <TextInput source='label' label='Label' validate={validations.validateLabel} />
            <NumberInput source='quantity' label='Quantity' min={1} max={999999999999} />
            <DateInput source='effectiveDate' label='Effective Date' />
            </div> 
            <div style={{display: 'flex', flexDirection: 'row', columnGap: '10px'}}>
            <DateInput source='start' label='Start date' />
            <DateInput source='end' label='End date' />
            </div>
            <BooleanInput source='active' defaultValue={true} label='Active?' validate={validations.validateActive} />
        </div>
    );
};

export default Notes;