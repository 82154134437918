import React from 'react';
import { Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeleteItemObject from '../DeleteItemObject';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const EditFormToolbarActions = ({
    showDeleteButton,
    ...rest
}) => (
    <Toolbar {...rest} classes={useStyles()}>
        <SaveButton />
        { showDeleteButton && <DeleteItemObject {...rest}/> }
    </Toolbar>
);

export default EditFormToolbarActions;