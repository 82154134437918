import {minLength, maxLength, required} from 'react-admin';

export const validations = {
  validateTextAR: [required(), minLength(1), maxLength(1000)],
  validateLanguageCode: [required()],
  validateName: [minLength(1), maxLength(300)],
  validateAddressLines: [minLength(0), maxLength(4)],
  validateAddressLine: [minLength(2), maxLength(100)],
  validatePostalCode: [minLength(1), maxLength(20)],
  validateCity: [minLength(2), maxLength(200)],
  validateCounty: [minLength(2), maxLength(200)],
  validateCoordinates: [minLength(0), maxLength(2)],
  validateTypeAR: [required(), minLength(1), maxLength(1)],
  validateActive: [required()],
  validateValidated: [required()]
};
