import React, { Fragment } from 'react';
import {Datagrid, EditButton, List, TextField, Filter, ReferenceInput, SelectInput, AutocompleteInput, FormDataConsumer, BooleanInput} from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import { perPageReferenceFields } from "../../constants";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { ThemeProvider } from '@material-ui/core/styles';
// import { ThemeProvider } from "@material-ui/material/styles";
import theme from "../../constants/styleOverride";
import ClearFiltersButton from "../../components/ClearFiltersButton";

const EnumerationsFilter = (props) => {
  return(
    <div>
      <ClearFiltersButton {...props} filters={{property_name: '', "enumerations-codes": '', "enumerations-labels":''}}/>
      <Filter {...props}>
          <ReferenceInput label="Property Name" source="property_name" reference="enumerations-property-names" perPage={perPageReferenceFields} alwaysOn>
            <AutocompleteInput optionText="property_name" optionValue="property_name" shouldRenderSuggestions={(value) => value.length >= 0} suggestionLimit={20} />
          </ReferenceInput>
          <ReferenceInput label="Code" source="code" reference="enumerations-codes" perPage={perPageReferenceFields} alwaysOn>
            <AutocompleteInput optionText="code" optionValue="code" shouldRenderSuggestions={(value) => value.length > 0} suggestionLimit={20} />
          </ReferenceInput>
          <ReferenceInput label="Label" source="label" reference="enumerations-labels" perPage={perPageReferenceFields} alwaysOn>
            <AutocompleteInput optionText="label" optionValue="label" suggestionLimit={20} shouldRenderSuggestions={(value) => value.length > 0} />
          </ReferenceInput>
      </Filter>
    </div>
  );
}



const EnumerationsList = props => {
  return (
    <ThemeProvider theme={theme}>
      <h2>{props.options.label}</h2>
      <List filters={<EnumerationsFilter />} bulkActionButtons={<DocBulkActionButtons {...props} />} exporter={false} {...props}
            actions={<ListToolbarActions showCreateButton={true}/>}>
        <Datagrid>
          <TextField source="property_name"/>
          <TextField source="code"/>
          <TextField source="label"/>
          <EditButton/>
        </Datagrid>
      </List>
  </ThemeProvider>
  );
}

export default EnumerationsList;
