import React from 'react';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import { Edit, SimpleForm, TextField, TextInput, ReferenceInput, SelectInput } from 'react-admin';
import { perPageReferenceFields } from '../../constants';
import { validations } from './validations';



const UserPermissionsEdit = props => {

    var service_classes = [{'id': 'WorldboxService', 'name': 'WorldboxService'}, {'id': 'ICPService', 'name': 'ICPService'}, 
    {'id': 'DnBService', 'name': 'DnBService'}, {'id': 'EllisphereService', 'name': 'EllisphereService'}]


    return (
        <Edit undoable={true} {...props}>   
            <SimpleForm toolbar={<EditFormToolbarActions showDeleteButton={true} />}>
                <TextInput label='Permission value' source='value' fullWidth={true} validate={validations.validateEditValue} />
                <TextInput label='Permission label' source='label' fullWidth={true} validate={validations.validateEditLabel} />
            </SimpleForm>
        </Edit>
    );
}

export default UserPermissionsEdit;