import {
    required,
    maxLength
} from 'react-admin';

export const validations = {
    validateFile: [required()],
    validateCode: [required()],
    validateReference: [required(), maxLength(100)],
    validateReason: [maxLength(255)],
    validateCorrectReference: [maxLength(100)],
};
