import {
    required,
    maxLength
} from 'react-admin';

export const validations = {
    validateCode: [required()],
    validateSourceField: [required()],
    validateTargetField: [required()],
    validateParentSection: [required()],
    validateEditSourceField: [maxLength(500)],
    validateEditTargetField: [maxLength(500)]
};
