import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const StockExchanges = (props) => {

    return (
        <div>
            <MappingSectionInput field='marketAR' required={true} {...props}/>
            <MappingSectionInput field='countryCode' {...props}/>
            <MappingSectionInput field='marketIdentifierCode' required={true} {...props}/>
            <MappingSectionInput field='symbol' {...props}/>
            <MappingSectionInput field='isin' {...props}/>
            <MappingSectionInput field='nsin' {...props}/>
            <MappingSectionInput field='main' required={true} {...props}/>
            <MappingSectionInput field='start' {...props}/>
            <MappingSectionInput field='end' {...props}/>
            <MappingSectionInput field='active' required={true} {...props}/>
            <MappingSectionInput field='validated' required={true} {...props}/>
        </div>
    );  
};

export default StockExchanges;