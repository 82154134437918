import { maxLength, required, number, minValue, maxValue } from 'react-admin';

export const validations = {
  validateScoringType: [required(), maxLength(4)],
  validateJurisdiction: [required(), maxLength(5)],
  validateCounty: [maxLength(50)],
  validateDescription: [required(), maxLength(200)],
  validateValue1: [required(), maxLength(50)],
  validateValue: [maxLength(50)],
  validateScore: [required(), number(), minValue(-100), maxValue(100)],
  validateWeight: [required(), number(), minValue(-5), maxValue(5)]
};
