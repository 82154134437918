import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, BooleanInput, FormDataConsumer, FileInput, FileField, AutocompleteInput} from 'react-admin';
import { validations } from './validations';
import { perPageReferenceFields } from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const ExportMappingsDeliveriesCreate = props => {

  return (
    <>
      <Create undoable={true} {...props}>
        <SimpleForm redirect="list">
          <BackButton {...props} action = {"create"} />
          <PageTitle {...props}>Create</PageTitle>
          {/* <BooleanInput source="bulkUpload" label="Multiple import mappings?" />
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => formData.bulkUpload &&
              <FileInput source="bulk_file" accept=".csv" label="Upload bulk csv file" validate={validations.validateFile}>
                <FileField source="src" title="title" />
              </FileInput>
            }
          </FormDataConsumer>
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => !formData.bulkUpload &&
              <React.Fragment> */}
              <BooleanInput source="newPropName" label="New property name?" />
              <FormDataConsumer subscription={{ values: true }}>
                {({ formData, ...rest }) => formData.newPropName &&
                  <TextInput fullWidth={true} source="property_name" {...rest} validate={validations.validatePropertyName}/>
                }
              </FormDataConsumer>
              <FormDataConsumer subscription={{ values: true }}>
                {({ formData, ...rest }) => !formData.newPropName &&
                  <ReferenceInput label="Select property name" source="property_name" reference="export-mapping-delivery-property-names" perPage={perPageReferenceFields}>
                    <SelectInput fullWidth={true} optionText="property_name" optionValue="property_name" validate={validations.validatePropertyName}/>
                  </ReferenceInput>
                }
              </FormDataConsumer>

              <ReferenceInput label="Delivery Id" source="delivery_id" reference="deliveries" perPage={perPageReferenceFields}>
                <AutocompleteInput optionText="code" optionValue="id" validate={validations.validateDeliveryId}/>
              </ReferenceInput>
              <TextInput source="label" fullWidth={true} validate={validations.validateLabel} />
              <TextInput source="value1" fullWidth={true} validate={validations.validateValue1} />
              <TextInput source="value2" fullWidth={true} validate={validations.validateValue2} />
              <TextInput source="value3" fullWidth={true} validate={validations.validateValue3} />
              <TextInput source="value4" fullWidth={true} validate={validations.validateValue4} />
              <TextInput source="value5" fullWidth={true} validate={validations.validateValue5} />
          {/* </FormDataConsumer> */}
        </SimpleForm>
      </Create>
    </>
  );
}

export default ExportMappingsDeliveriesCreate;
