import * as React from "react";
import { AppBar, UserMenu } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import NotificationsBadge from "./NotificationsBadge";
import classNames from 'classnames';



const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    prod_bar: {
        backgroundColor: '#2096f3',
        height: 50
    },
    dev_bar: {
        backgroundColor: 'orange',
        height: 50,
        paddingBottom: 20
    },
    local_bar: {
        backgroundColor: 'green',
        height: 50,
        paddingBottom: 20
    }
});


const AppTopBar = props => {
    console.log('ENV', window.location.href);
    const classes = useStyles();
    return (
        <AppBar
        className={classNames({                
                [classes.local_bar]: window.location.href.includes('localhost'),
                [classes.dev_bar]: window.location.href.includes('backoffice.dev.arttence.net'),
                [classes.prod_bar]: window.location.href.includes('backoffice.arttence.net')
            })}
            {...props}>
            <Typography
                variant="h4"
                color="inherit"
                className={classes.title}
            >
                Arttence Backoffice
            </Typography>
            <NotificationsBadge />
        </AppBar>
    );
};

export default AppTopBar;