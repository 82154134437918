import React from 'react';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import {Edit, SimpleForm, TextInput,  ReferenceInput, SelectInput, BooleanInput} from 'react-admin';
import {validations} from './validations';
import { perPageReferenceFields } from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const EnumerationsEdit = props => {

  return (
    <>
      <Edit undoable={true} {...props}>
        <SimpleForm toolbar={<EditFormToolbarActions showDeleteButton={false}/>}>
          <BackButton {...props} action = {"edit"}/>
          <PageTitle {...props}>Edit</PageTitle>
          <ReferenceInput label="Select property name" source="property_name" reference="enumerations-property-names" perPage={perPageReferenceFields}>
            <SelectInput optionText="property_name" optionValue="property_name"/>
          </ReferenceInput>
          <TextInput source="code" fullWidth={true} validate={validations.validateCode}/>
          <TextInput source="label" fullWidth={true} validate={validations.validateLabel}/>
          <TextInput source="info1"  fullWidth={true} validate={validations.validateInfo}/>
          <TextInput source="info2" fullWidth={true} validate={validations.validateInfo}/>
          <TextInput source="info3" fullWidth={true} validate={validations.validateInfo}/>
          <TextInput source="info4" fullWidth={true} validate={validations.validateInfo}/>
          <TextInput source="info5" fullWidth={true} validate={validations.validateInfo}/>
          <BooleanInput source="active" label="Is it active?"/>
        </SimpleForm>
      </Edit>
    </>
  );
}

export default EnumerationsEdit;
