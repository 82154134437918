import React from 'react';
import { Create, SimpleForm, FormDataConsumer, TextInput, NumberInput, ReferenceInput, SelectInput, BooleanInput } from 'react-admin';
import StepTypeInputArea from "./StepTypeInputArea";
import { validations } from "./validations";
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const StepCreate = props => (
    <>
        <Create {...props}>
            <SimpleForm redirect="list">
                <BackButton {...props} action = {"create"} />
                <PageTitle {...props}>Create</PageTitle>
                <TextInput source="title" fullWidth={true} validate={validations.validateTitle} />
                <TextInput multiline source="description" fullWidth={true} validate={validations.validateDescription} />
                <TextInput source="outputs" validate={validations.validateOutputs} />
                <BooleanInput source="reads_data_from_staging" initialValue={false} />
                <TextInput multiline source="configuration" fullWidth={true} validate={validations.validateConfiguration} />

                <FormDataConsumer>
                    {formDataProps => (
                        <StepTypeInputArea {...formDataProps} {...props} />
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    </>
);

export default StepCreate;