import {
    required,
    maxLength
} from 'react-admin';

export const validations = {
    validateCreateValue: [required(), maxLength(20)],
    validateCreateLabel: [required(), maxLength(20)],
    validateEditValue: maxLength(20),
    validateEditLabel: maxLength(20)
};
