import {minLength, maxLength, required, } from 'react-admin';

export const validations = {
  validateNameAR: [required(), minLength(1), maxLength(300)],
  validateFullName: [required(), minLength(1), maxLength(300)],
  validateTitle: [minLength(1), maxLength(50)],
  validateFirstName: [minLength(1), maxLength(300)],
  validateMiddleName: [minLength(1), maxLength(300)],
  validateLastName: [minLength(1), maxLength(300)],
  validateLanguageCode: [required()],
  validateActive: [required()]
};
