import {
    required,
    maxLength
} from 'react-admin';

export const validations = {
    validateIdkey: [required(), maxLength(50)],
    validateEntityIdkeyCode: [required()],
    validateActive: [required()],
    validateInsertSource: [required()]
}