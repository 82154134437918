import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";


const NoticesEdit = props => {

    return (
        <>
            <Edit undoable={true} {...props}>
                <SimpleForm toolbar={<EditFormToolbarActions showDeleteButton={false} />}>
                    <TextInput source="reference" fullWidth={false} />
                </SimpleForm>
            </Edit>
        </>
    );
}

export default NoticesEdit;