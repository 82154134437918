import React from 'react';
import { List,Filter, AutocompleteInput, SelectInput, BooleanInput, ReferenceInput, BooleanField, Datagrid, TextField, EditButton } from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import { perPageReferenceFields } from "../../constants";
import "../../index.css";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import ClearFiltersButton from "../../components/ClearFiltersButton";

const choices = [
    {id:true, name: "Yes"},
    {id:false, name: "No"}
];

const SourceFilesFilters = (props) => {
    return(
        <div>
            <ClearFiltersButton {...props} filters={{source: '', extract: ''}}/>
            <Filter {...props}>
                <ReferenceInput label="Source Code" source="source" reference="source-files-configurations-codes" perPage={perPageReferenceFields} alwaysOn>
                    <AutocompleteInput optionText="code"/>
                </ReferenceInput>
                <SelectInput label="To Process" source="extract" choices={choices} perPage={perPageReferenceFields} alwaysOn/>
            </Filter>
        </div>
    );
}

const SourceFilesConfigurationsList = (props) => (
  <ThemeProvider theme={theme}>
    <h2>{props.options.label}</h2>
    <List
      filters={<SourceFilesFilters />}
      bulkActionButtons={<DocBulkActionButtons {...props} />}
      exporter={false}
      {...props}
      actions={<ListToolbarActions showCreateButton={true} />}
    >
      <Datagrid>
        <TextField source="source_code" label="Source Code" />
        <TextField label="Filename Pattern" source="regex" />
        <BooleanField label="To Process" source="extract" />
        <TextField label="Report label" source="report_label" />
        <EditButton />
      </Datagrid>
    </List>
  </ThemeProvider>
);

export default SourceFilesConfigurationsList;