import React, {useState} from 'react';
import {Filter, SearchInput, SelectInput, SimpleForm, FormDataConsumer} from 'react-admin';
import {validations} from './validations';

const EntitiesEditorFilter = (props) => {
    const [nameSearch, setNameSearch] = useState("Name starts");

    let nameChoices = [
        {id: 'Name starts', name: 'Name starts'},
        {id: 'Name exact', name: 'Name exact'}
    ]

    const IdkeyFilter = (props) => {
        
        return  <Filter>
                    <SearchInput source="idkey" style={{width: '350px'}} alwaysOn placeholder="Idkey" 
                        onChange= { (e) => {
                            let idkey = e;
                            if (idkey !== ''){
                                idkey = idkey.target.value;
                            }
                            props.setFilters({'art_id': '', 'name': '', 'name_search_type': '', 'idkey': idkey, 'id': ''});
                        }}
                    />  
                </Filter>
    }

    const ArtIdFilter = (props) => {
        return  <Filter {...props}>
                    <SearchInput source="art_id" style={{width: '200px'}} alwaysOn placeholder="Art ID" 
                        onChange= { (e) => {
                            let artId = e;
                            if (artId !== ''){
                                artId = artId.target.value;
                            }
                            props.setFilters({'art_id': artId, 'name': '', 'name_search_type': '', 'idkey': '', 'id': ''});
                        }}
                    />
                </Filter>
    }

    const EntityIdFilter = (props) => {
        return <Filter {...props}>
                    <SearchInput source="id" style={{width: '200px'}} alwaysOn placeholder="Entity ID" 
                        onChange= { (e) => {
                            let entityId = e;
                            if (entityId !== ''){
                                entityId = entityId.target.value;
                            }
                            props.setFilters({'art_id': '', 'name': '', 'name_search_type': '', 'idkey': '', 'id': entityId});
                        }}
                    />
                </Filter>
    }

    const handleNameFilter = ({ formData, ...rest }) => {
        return <Filter {...props}>
                    <SearchInput 
                        source="name" 
                        style={{width: '700px'}} 
                        alwaysOn 
                        placeholder="Name" 
                        validate={formData.name_search_type === "Name starts" ? validations.validateMinCharacter : undefined}
                        onChange= { (e) => {
                            let name = e;
                            if (name !== ''){
                                name = name.target.value;
                            };
                            props.setFilters({'art_id': '', 'name': name, 'name_search_type': formData.name_search_type, 'idkey': '', 'id': ''});
                        }}
                    />
                </Filter>
    }
    const NameFilter = (props) => {
        return <SimpleForm toolbar={false}>
                <div style={{ display: 'flex', width: '100%'}}>
                    <SelectInput label="Name search" source="name_search_type" choices={nameChoices} initialValue="Name starts" value={nameSearch}
                        onChange = {(e) => {
                            setNameSearch(e.target.value);
                            props.setFilters({'art_id': '', 'name': props.filterValues.name, 'name_search_type': e.target.value, 'idkey': '', 'id': ''});
                        }}
                    />
                    <div style={{ marginTop: '0px', marginLeft: '10px'}} >
                        <FormDataConsumer subscription={{ values: true }}>
                            {handleNameFilter}
                        </FormDataConsumer>
                    </div>
                </div>
            </SimpleForm>
    }
    
    

    return  <div>
                <div style={{ display: 'flex', gap: '10px', width: '100%', marginLeft: '16px'}}>
                    <IdkeyFilter {...props} />
                    <ArtIdFilter {...props} />
                    <EntityIdFilter {...props} />
                </div>
                <NameFilter {...props} />
            </div>
}

export default EntitiesEditorFilter;