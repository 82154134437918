import React from 'react';
import {Create, DateInput, SimpleForm, ReferenceArrayInput, SelectArrayInput, BooleanInput, TextInput} from 'react-admin';
import {validations} from './validations';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const AccountCreate = props => {

  return (
    <>
      <Create undoable={true} {...props}>
        <SimpleForm redirect="list">
          <BackButton {...props} action = {"create"}/>
          <PageTitle {...props}>Create</PageTitle>
          <TextInput source='name' validate={validations.validateName} />
          <DateInput source='start_date' validate={validations.validateStartDate} />
          <DateInput source='end_date' />
          <BooleanInput source="active" initialValue={true} label="Is it active?" />
          <ReferenceArrayInput label='Products' reference='products' source='products'>
            <SelectArrayInput optionText='label' optionValue='id' />
          </ReferenceArrayInput>
        </SimpleForm>
      </Create>
    </>
  );
}

export default AccountCreate;
