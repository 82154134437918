import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, BooleanInput, AutocompleteInput} from 'react-admin';
import { CronInput } from '../../components/CronInput'
import { validations } from "./validations";
import { perPageReferenceFields } from "../../constants";
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const ScheduledJobCreate = props => (
    <>
        <Create {...props}>
            <SimpleForm redirect="list">
                <BackButton {...props} action = {"create"}/>
                <PageTitle {...props}>Create</PageTitle>
                <ReferenceInput label="Process" source="process" reference="processes" perPage={perPageReferenceFields} validate={validations.validateProcess}>
                    <AutocompleteInput optionText="title" />
                </ReferenceInput>
                <BooleanInput label="Allow parallel jobs" source="parallel_processing" reference="cron_jobs"/>
                <CronInput source="schedule" validate={validations.validateSchedule} />
                <TextInput multiline source="configuration" fullWidth={true} />
            </SimpleForm>
        </Create>
    </>
);

export default ScheduledJobCreate;