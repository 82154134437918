import List from './List';
import Show from './Show';
import Edit from './Edit';
import Icon from '@material-ui/icons/MenuBook';;

export default {
  icon: Icon,
  list: List,
  show: Show,
  edit: Edit
};