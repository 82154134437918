import React from 'react';
import { Edit, SimpleForm, ReferenceInput, SelectInput, BooleanInput, AutocompleteInput, TextInput } from 'react-admin';
import { CronInput } from '../../components/CronInput'
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import { validations } from "./validations";
import { perPageReferenceFields } from "../../constants";
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const ScheduledJobEdit = props => (
    <>
        <Edit undoable={false} {...props}>
            <SimpleForm toolbar={<EditFormToolbarActions showDeleteButton={false/*userHasPermission("scheduledjobs-delete")*/} />}>
                <BackButton {...props} action = {"edit"} />
                <PageTitle {...props}>Edit</PageTitle>
                <ReferenceInput label="Process" source="process" reference="processes" perPage={perPageReferenceFields} validate={validations.validateProcess}>
                    <AutocompleteInput optionText="title" />
                </ReferenceInput>
                <BooleanInput label="Allow parallel jobs" source="parallel_processing" reference="cron_jobs"/>
                <CronInput source="schedule" validate={validations.validateSchedule} />
                <TextInput multiline source="configuration" fullWidth={true} />
            </SimpleForm>                            
        </Edit>
    </>
);

export default ScheduledJobEdit;