import React from 'react';
import {Filter, ReferenceInput, DateField, BooleanField, AutocompleteInput, Datagrid, EditButton, List, TextField} from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import "../../index.css";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { perPageReferenceFields } from "../../constants";
import ClearFiltersButton from "../../components/ClearFiltersButton";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";

const SuppliersFilter = (props) => {
  return(
    <div>
      <ClearFiltersButton {...props} filters={{id: ''}}/>
      <Filter {...props}>
          <ReferenceInput label="Source Code" source="id" reference="products" perPage={perPageReferenceFields} alwaysOn>
              <AutocompleteInput optionText="code" />
          </ReferenceInput>
      </Filter>
    </div>
  );
}

const SuppliersList = props => (
  <ThemeProvider theme={theme}>
    <h2>{props.options.label}</h2>
    <List filters={false} bulkActionButtons={<DocBulkActionButtons/>} exporter={false} {...props}
          actions={<ListToolbarActions showCreateButton={true}/>}>
      <Datagrid>
        <TextField source="name" label="Name"/>
        <TextField source="address" label="Address"/>
        <TextField source="reference" label="Reference"/>
        <DateField source="start_date"/>
        <TextField source="products_string" label='Products'/>
        <BooleanField source="active"/>
        <EditButton/>
      </Datagrid>
    </List>
  </ThemeProvider>
);

export default SuppliersList;
