import React, { useState, useRef, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

const NestedRow = ({ row, value, nestedHeader, fixedHeight }) => {
  const [open, setOpen] = useState(true);

  const handleCellClick = () => {
    setOpen(!open);
  };

  return (
    <div style={fixedHeight > 5 ? { height: 300, overflow: "auto" } : {}}>
      {row[value].map((item) => (
        <TableCell
          onClick={handleCellClick}
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "13px",
          }}
        >
          <Link to={{ pathname: "/documents/" + item[nestedHeader] + "/show" }} target="_blank">
            {item[nestedHeader]}
          </Link>
        </TableCell>
      ))}
    </div>
  );
};

const RenderRows = ({
  values,
  headers,
  nestedHeader,
  rowClick,
  styleRow,
  styleCell,
  argsRow,
}) => {
  return values.map(function (row, index) {
    const args_key = argsRow ? Object.keys(argsRow)[0] : null;
    const check_row = args_key ? argsRow[args_key] === row[args_key] : null;
    const style_row = check_row ? styleRow : null;

    return (
      <TableRow style={style_row} key={index}>
        {headers.map(function (value) {
          if (Array.isArray(row[value])) {
            if (row[value].length > 0) {
              {
                return (
                  <NestedRow
                    row={row}
                    value={value}
                    nestedHeader={nestedHeader}
                    fixedHeight={row[value].length}
                  />
                );
              }
            } else {
              return <TableCell>No {nestedHeader}</TableCell>;
            }
          } else {
            return (
              <TableCell
                style={styleCell}
                onClick={() =>
                  typeof rowClick === "function" ? rowClick(row) : null
                }
              >
                {row[value]}
              </TableCell>
            );
          }
        })}
      </TableRow>
    );
  });
};

const RenderGrid = ({
  values,
  headers,
  styleRow,
  styleCell,
  rowClick,
  gridCol,
}) => {
  const [hover, setHover] = useState({ row: 0, on: false });
  let start_pos = 0;
  var rows = [];

  for (let row = 0; row < Math.ceil(values.length / gridCol); row++) {
    start_pos += row !== 0 ? gridCol : 0;
    rows.push(
      <TableRow style={styleRow}>
        {values.slice(start_pos, start_pos + gridCol).map((row) => {
          return (
            <TableCell
              onMouseEnter={() => {
                setHover({ row: row, on: true });
              }}
              onMouseLeave={() => {
                setHover({ row: row, on: false });
              }}
              style={{
                ...styleCell,
                ...(hover.on && hover.row === row ? styleCell.hover : null),
              }}
              onClick={() =>
                typeof rowClick === "function" ? rowClick(row) : null
              }
            >
              {headers.map((element) => row[element])}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }
  return rows.map((item) => item);
};

const RenderHeaders = ({ headers }) => {
  return headers.map(function (header) {
    return (
      <TableCell
        style={{
          fontSize: "13px",
          fontWeight: "bold",
        }}
      >
        {header}
      </TableCell>
    );
  });
};

const TableComponent = ({
  headers,
  values,
  styleRow = {},
  styleCell = {},
  styleTable = {},
  nestedHeader = "",
  rowClick = null,
  argsRow = null,
  gridCol = null,
}) => {
  const fixed_height = gridCol ? values.length / gridCol > 5 : null;

  return (
    <TableContainer
      component={Paper}
      style={{
        ...styleTable,
        height: fixed_height ? 300 : "auto",
      }}
    >
      <Table stickyHeader aria-label="a dense table">
        <TableHead>
          <TableRow>
            <RenderHeaders headers={headers} />
          </TableRow>
        </TableHead>
        <TableBody>
          {gridCol ? (
            <RenderGrid
              values={values}
              headers={headers}
              rowClick={rowClick}
              styleRow={styleRow}
              styleCell={styleCell}
              gridCol={gridCol}
            />
          ) : (
            <RenderRows
              values={values}
              headers={headers}
              nestedHeader={nestedHeader}
              rowClick={rowClick}
              styleRow={styleRow}
              styleCell={styleCell}
              argsRow={argsRow}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
