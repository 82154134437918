import React, { useEffect, useState } from 'react';
import { useNotify, useRedirect, useRefresh } from 'ra-core';
import { Filter, List, Datagrid, TextField, BooleanField, Loading, TextInput } from 'react-admin';
import { Api } from "../../helpers/api";
import queryString from 'query-string';
import { ThemeProvider } from '@material-ui/core/styles';
import useButtonStyles from './styling.js';
import { ShowDocButton, DocEditButton, ButtonsToolbar } from './buttons.js';
import CustomDialog from '../../components/CustomDialog';
import SuccessCheck from "../../static/icons/SuccessCheck.png";

const HQBranchGUIFilter = (props) => {
    return (
        <div>
            <Filter {...props}>
                <TextInput source="batch_id" style={{width: '150px'}} alwaysOn placeholder="Batch" helperText={"Batch Id"} 
                    onChange={(e) => props.onBatchUpdate(e.target.value)}
                />
            </Filter>
        </div>
    );
}

const EntityIdField = ({ source, record}) => {
    return (
      <a target="_blank" href={`/#/entities?filter={"id":"${record.entity_id}"}`}>
        {record[source]}
      </a>
    );
  };

const HQBranchGUI = props => {
    const classes = useButtonStyles();
    const [showDoc, setShowDoc] = useState(false);
    const [batchId, setBatchId] = useState('');
    const [taskId, setTaskId] = useState(null);
    const [dialogLoading, setDialogLoading] = useState(false);
    const [dialogLoadingMessage, setDialogLoadingMessage] = useState("");
    const [dialogSuccess, setDialogSuccess] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
        
    useEffect(() => {
        var query_params = queryString.parse(props.location.search);
        if (query_params.filter !== undefined){
            var filter_params = JSON.parse(query_params.filter);
            let batch = filter_params.batch_id;
            setBatchId(batch);
            if (!["", null, undefined].includes(batch)){
                getTaskByBatch(batch);
            }
        }
    }, [])

    const updateBatch = (batch) => {
        setBatchId(batch);
        if (!["", null, undefined].includes(batch)){
            getTaskByBatch(batch);
        }
    }


    const getTaskByBatch = (batchId) => {
        return Api.get(`/tasks/get-task-by-batch?batch_id=${batchId}`, true)
        .then(response => {
            if (response.type === "success") {
                setTaskId(response.result);
                setShowDoc(true);
            }
            else{
                setShowDoc(false);
            }
        })
    }

    const completeTask = (dialogLoadMessage="Completing task...") => {
        setDialogSuccess(false);
        setDialogLoadingMessage(dialogLoadMessage);
        setDialogLoading(true);
        return Api.post(`/tasks/${taskId}/finish/`, {}, true)
        .then(response => {
            if (response.type === "success") {
                setDialogSuccess(true);
                notify('Task finished');
                redirect('/tasks');
                refresh();
            } 
            else if (response.type === "auth_error") {
                console.log("Error completing the Task - authentication error");
                redirect('/login');
            }
            else {
                console.log("Error completing the Task - response", response.type);
                if (response.error) {
                    notify(response.error, 'warning');    
                }
                else {
                    notify("Could not complete the Task!", 'warning');
                }
            }
        })
        .catch((e) => {
            console.log("Exception completing the Task - e", e);
            notify('Error completing the Task', 'warning')
        })
    };

    return (
        <ThemeProvider>
            <h2>{props.options.label}</h2>
            <List
                actions={<ButtonsToolbar batchId={batchId} taskId={taskId} showDoc={showDoc} classes={classes} onConfirm={completeTask} {...props} />}
                filters={<HQBranchGUIFilter onBatchUpdate={updateBatch} {...props} />}
                bulkActionButtons={false} exporter={false} {...props}
                pagination={false} perPage={100}>
                <Datagrid>
                    <EntityIdField source="entity_id" />
                    <BooleanField label="Status" source="status" />
                    <TextField label="Name" source="name" />
                    <TextField label="State" source="state" />
                    <TextField label="Type" source="type" />
                    <TextField label="WIN" source="WIN" />
                    <TextField label="INGST" source="INGST" />
                    <TextField label="INPAN" source="INPAN" />
                    <TextField label="Other Idkeys" source="other_idkeys" />
                    <ShowDocButton class={classes.popUp} {...props} />
                    <DocEditButton class={classes.edit} {...props} />
                </Datagrid>
            </List>
            <CustomDialog {...props} showDialog={dialogLoading} dialogContent={dialogSuccess ? <img src={SuccessCheck} /> : <Loading loadingPrimary={false} loadingSecondary={dialogLoadingMessage} />} />
        </ThemeProvider>
    );
}

export default HQBranchGUI;