import React, { useState } from "react";
import {
  SimpleForm,
  Datagrid,
  List,
  TextInput,
  Filter,
  useNotify,
  useRefresh,
  Toolbar,
  SaveButton,
  NumberInput,
  Button,
  Create,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { ThemeProvider } from '@material-ui/core/styles';
import ClearFiltersButton from '../../components/ClearFiltersButton';
import { Api } from "../../helpers/api";
import { validations } from './validations';
import ScoringTester from "../../components/ScoringTester";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import { perPageReferenceFields } from '../../constants';

const CustomFilters = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Filter {...props}>
        <TextInput label="Scoring type" source="scoring_type" alwaysOn />
        <TextInput label="Jurisdiction" source="jurisdiction" alwaysOn />
        <TextInput label="County" source="county" alwaysOn />
        <TextInput label="Value" source="value1" alwaysOn />
      </Filter>
      <ClearFiltersButton {...props} filters={{ scoring_type: '', jurisdiction: '', county: '', value1: '' }} />
    </div>
  );
}

const CustomToolbar = props => {
  return (
    <Toolbar
      {...props}
      style={{
        alignVertical: 'center',
        display: 'none'
      }}
    >
      <SaveButton label='Save' style={{ display: 'none' }} />
    </Toolbar>
  )
};



const ImportMappingLabelsList = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const onSave = (values) => {

    Api.patch(`/enrichment-mapping-scorings/${values['id']}/`, values, true)
      .then(response => {
        if (response.type === 'success') {
          notify('Successfully updated', 'success');
          refresh();
        }
        else {
          console.log('Issue on updating', 'warning');
        }
      })
      .catch((e) => {
        console.log('Error on updating', 'warning');
      })

    console.log('Form values:', values);
  };

  const [showCreateForm, setShowCreateForm] = useState(false);

  const toggleShowCreateForm = () => {
    setShowCreateForm(!showCreateForm);
  };

  const CustomCreateToolbar = props => {
    const refresh = useRefresh();
    const toggleRefresh = () => {
      refresh();
      setShowCreateForm(false);
    };

    return (
      <Toolbar
        {...props}
        style={{
          alignVertical: 'center'
        }}
      >
        <SaveButton onClick={toggleRefresh} label='Save' />
      </Toolbar>
    )
  };

  return (
    <ThemeProvider >
      <h2>Test mappings</h2>
      <ScoringTester />
      <h2>{props.options.label}</h2>
      <Button onClick={toggleShowCreateForm} uppercase={false} color="inherit" startIcon={showCreateForm ? (<Remove color="action" />) : (<Add color="action" />)} variant="contained" size="large" />
      {showCreateForm && <Create undoable={true} {...props}>
        <SimpleForm toolbar={<CustomCreateToolbar />} >
          <div style={{ width: '100%', gap: '1rem' }}>
            <TextInput source="scoring_type" style={{ width: '105px' }} validate={validations.validateScoringType} />
            <TextInput source="jurisdiction" style={{ width: '100px' }} validate={validations.validateJurisdiction} />
            <TextInput source="county" style={{ width: '100px' }} validate={validations.validateCounty} />
            <TextInput source="description" style={{ width: '200px' }} validate={validations.validateDescription} />
            <TextInput source="value1" style={{ width: '80px' }} validate={validations.validateValue1} />
            <TextInput source="value2" style={{ width: '80px' }} validate={validations.validateValue} />
            <TextInput source="value3" style={{ width: '80px' }} validate={validations.validateValue} />
            <TextInput source="value4" style={{ width: '80px' }} validate={validations.validateValue} />
            <TextInput source="value5" style={{ width: '80px' }} validate={validations.validateValue} />
            <NumberInput source="score" style={{ width: '80px' }} step={0.01} validate={validations.validateScore} />
            <NumberInput source="weight" style={{ width: '80px' }} step={0.1} validate={validations.validateWeight} />
            <ReferenceInput label="Source" source="source_id" reference="sources" perPage={perPageReferenceFields}>
              <AutocompleteInput optionText="code" optionValue="id" validate={validations.validateSelection} />
            </ReferenceInput>
          </div>
        </SimpleForm>
      </Create>}
      <List bulkActionButtons={<DocBulkActionButtons {...props} />} exporter={false} {...props} filters={<CustomFilters />} actions={<ListToolbarActions showCreateButton={false} />}>
        <Datagrid>
          <SimpleForm save={onSave} toolbar={<CustomToolbar />}>
            <div style={{ width: '100%', gap: '1rem' }}>
              <TextInput source="scoring_type" style={{ width: '105px' }} validate={validations.validateScoringType} />
              <TextInput source="jurisdiction" style={{ width: '100px' }} validate={validations.validateJurisdiction} />
              <TextInput source="county" style={{ width: '100px' }} validate={validations.validateCounty} />
              <TextInput source="description" style={{ width: '200px' }} validate={validations.validateDescription} />
              <TextInput source="value1" style={{ width: '80px' }} validate={validations.validateValue1} />
              <TextInput source="value2" style={{ width: '80px' }} validate={validations.validateValue} />
              <TextInput source="value3" style={{ width: '80px' }} validate={validations.validateValue} />
              <TextInput source="value4" style={{ width: '80px' }} validate={validations.validateValue} />
              <TextInput source="value5" style={{ width: '80px' }} validate={validations.validateValue} />
              <NumberInput source="score" style={{ width: '80px' }} step={0.01} validate={validations.validateScore} />
              <NumberInput source="weight" style={{ width: '80px' }} step={0.1} validate={validations.validateWeight} />
            </div>
          </SimpleForm>
        </Datagrid>
      </List>
    </ThemeProvider>
  );
}

export default ImportMappingLabelsList;
