import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';
import { ArrayInput, SimpleFormIterator } from "react-admin";

const Relations = (props) => {
    let section = props.section;
    let functionsSource = `${props.source}.functions`

    return (
        <div>
            <MappingSectionInput field='nameAR' required={true} {...props} />
            <MappingSectionInput field='typeAR' required={true} {...props} />
            <MappingSectionInput field='languageCode' {...props} />
            <MappingSectionInput field='countryCode' {...props} />
            <MappingSectionInput field='type' {...props} />
            {(section == 'ownershipsParent' || section == 'ownershipsChild') &&
                <div>
                    <MappingSectionInput field='shareholding' {...props} />
                    <MappingSectionInput field='directShareholding' {...props} />
                    <MappingSectionInput field='capitalshare' {...props} />
                    <MappingSectionInput field='directCapitalshare' {...props} />
                    <MappingSectionInput field='numberOfShares' {...props} />
                    <MappingSectionInput field='capitalshareValue' {...props} />
                    <MappingSectionInput field='currencyCode' {...props} />
                    <MappingSectionInput field='exact' {...props} />
                </div>
            }
            {(section == 'directorshipsParent' || section == 'directorshipsChild') &&
                <div>
                    <ArrayInput
                        source={functionsSource}
                        label="Functions"
                    >
                        <SimpleFormIterator>
                            <MappingSectionInput
                                list={true}
                                field="functionCode"
                                types={props.types}
                                functions={props.functions}
                                listObject="functions"
                                functionsByType={props.functionsByType}
                            />
                            <MappingSectionInput
                                list={true}
                                field="type"
                                types={props.types}
                                functions={props.functions}
                                listObject="functions"
                                functionsByType={props.functionsByType}
                            />
                            <MappingSectionInput
                                list={true}
                                field="extension"
                                types={props.types}
                                functions={props.functions}
                                listObject="functions"
                                functionsByType={props.functionsByType}
                            />
                            <MappingSectionInput
                                list={true}
                                field="businessArea"
                                types={props.types}
                                functions={props.functions}
                                listObject="functions"
                                functionsByType={props.functionsByType}
                            />
                            <MappingSectionInput
                                list={true}
                                field="description"
                                types={props.types}
                                functions={props.functions}
                                listObject="functions"
                                functionsByType={props.functionsByType}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <MappingSectionInput field='signatureCode' {...props} />
                    <MappingSectionInput field='signatureRestrictionCode' {...props} />
                </div>
            }
            <MappingSectionInput field='start' {...props} />
            <MappingSectionInput field='end' {...props} />
            <MappingSectionInput field='active' required={true} {...props} />
            <MappingSectionInput field='validated' required={true} {...props} />
        </div>
    );
};

export default Relations;