import React from 'react';
import { Filter, List, Datagrid, TextField, ReferenceInput, SelectInput, ShowButton, ReferenceField, AutocompleteInput } from 'react-admin';
import { userHasPermission } from "../../helpers/authProvider";
import { perPageReferenceFields } from "../../constants";
import ClearFiltersButton from '../../components/ClearFiltersButton';

const JobFilter = (props) => {
    return(
        <div>
            <ClearFiltersButton {...props} filters={{process_id: '', status_id: ''}}/>
            <Filter {...props}>
                <ReferenceInput label="Process" source="process_id" reference="processes" perPage={perPageReferenceFields} alwaysOn>
                    <AutocompleteInput optionText="title" />
                </ReferenceInput>
                <ReferenceInput label="Status" source="status_id" reference="job-status" perPage={perPageReferenceFields} alwaysOn>
                    <SelectInput optionText="title" />
                </ReferenceInput>
            </Filter>
        </div>
    );
}

const JobList = props => (
    <>
        <h2>{props.options.label}</h2>
        <List filters={<JobFilter />} sort={{ field: 'id', order: 'DESC' }}
                bulkActionButtons={false} exporter={false} {...props}>
            <Datagrid>
                <TextField source="id" />
                <ReferenceField label="Process" source="process" reference="processes" link={false}>
                    <TextField source="title" />
                </ReferenceField>
                <ReferenceField label="Status" source="status" reference="job-status" link={false}>
                    <TextField source="title" />
                </ReferenceField>
                <TextField source="created_by" />
                <TextField source="created_at" />
                <TextField source="finished_at" />

                { userHasPermission("jobs-read") && <ShowButton /> }
            </Datagrid>
        </List>
    </>
);

export default JobList;