import React from 'react';
import EditFormToolbarActions from "../../../components/EditFormToolbarActions";
import { Edit, SimpleForm, TextInput } from 'react-admin';
import { validations } from "./validations";
import {perPageReferenceFields} from "../../../constants";

// needs to be tested
const ArtTenceConfigEdit = props => (
    <>
        <h2>{props.options.label}</h2> 
        <Edit undoable={true} {...props}>
            <SimpleForm toolbar={<EditFormToolbarActions showDeleteButton={true} />}>
                <TextInput label='Key' source="key" fullWidth={true} validate={validations.validateKey} />
                <TextInput multiline resettable label='Configuration' source="configuration" fullWidth={true} validate={validations.validateConfiguration} />                                
            </SimpleForm>
        </Edit>
    </>
);

export default ArtTenceConfigEdit;