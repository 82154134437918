import React from 'react';
import {Datagrid, EditButton, List, DateField, BooleanField, TextField} from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import "../../index.css";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';

const AccountList = props => (
  <>
    <h2 class="h2-short">{props.options.label}</h2>
    <List bulkActionButtons={false} exporter={false} {...props}
          actions={<ListToolbarActions showCreateButton={true}/>}>
      <Datagrid>
        <TextField source="name" />
        <DateField source="start_date"/>
        <DateField source="end_date"/>
        <BooleanField source="active" />
        <EditButton/>
      </Datagrid>
    </List>
  </>
);

export default AccountList;
