import React from 'react';
import { TextField, Card, SelectField, TextInput, BooleanInput, ReferenceInput, AutocompleteInput, SelectInput, ArrayInput, SimpleFormIterator, NumberInput, DateInput } from 'react-admin';
import { validations } from './validations';

const Addresses = (props) => {
    console.log('PROPS');
    console.log(props);
    let section = props.section;

    const all_types = [{id: 1, type: 1}, {id: 5, type: 5}, {id: 6, type: 6}, {id: 7, type: 7}, {id: 9, type: 9}];
    const other_types = [{id: 6, type: 6}, {id: 7, type: 7}];
    return (
        <div>
            { 'location' in props && <TextInput source='textAR' fullWidth={true} label='Text AR'  validate={validations.validateTextAR} />}
            <div  style={{display: 'flex', flexDirection: 'row', columnGap: '10px'}}>
            <div>
            <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes'>
                <AutocompleteInput optionText="label" optionValue="id"  validate={validations.validateLanguageCode}/>
            </ReferenceInput>
            </div>
            <div>
            <ReferenceInput source='translationCode' label='Translation Code' reference='enumerations-translation-codes' allowEmpty={true}>
                <SelectInput optionText="label" optionValue="id" validate={validations.validateTranslationCode} />
            </ReferenceInput>
            </div>
            </div>
            { section === 'branchAddresses' && 
                <TextInput source='name' label='Name' validate={validations.validateName} />
            }
            { (section === 'legalDomiciles' || section === 'birthPlace' || section === 'nationalities') &&
                <SelectField source='typeAR' label='Type AR' choices={other_types} optionText='type' optionValue='id' />
            }
            { (section !== 'legalDomiciles' && section !== 'birthPlace' && section !== 'nationalities') &&
                <div>
                    <ArrayInput source="addressLines" label='Address Lines' validate={validations.validateAddressLines}>
                        <SimpleFormIterator>
                            <TextInput label='Address Line' fullWidth={true} validate={validations.validateAddressLine} />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <SelectField source='typeAR' label='Type AR' choices={all_types} optionText='type' optionValue='id' />
                </div>
            }
            <div  style={{display: 'flex', flexDirection: 'row', columnGap: '10px'}}>
            <TextInput source='typeAR' label='type AR' validate={validations.validateTypeAR} />
            <TextInput source='postalCode' label='Postal Code' validate={validations.validatePostalCode} />
            <TextInput source='city' label='City' validate={validations.validateCity} />
            <TextInput source='county' label='County' validate={validations.validateCounty} />
            <ReferenceInput source='countyCode' label='County Code' reference='enumerations-county-codes' allowEmpty={true}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            </div>
            <div style={{width: '390px'}}>
            <ReferenceInput source='countryCode' label='Country Code' fullWidth={true} reference='enumerations-country-codes' allowEmpty={true} >
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            </div>
            <ArrayInput source='coordinates' label='Coordinates (Longitude, Latitude)' validate={validations.validateCoordinates} >
                <SimpleFormIterator>
                    <NumberInput label='Coordinate' step={0.000001} />
                </SimpleFormIterator>
            </ArrayInput>
            <div  style={{display: 'flex', flexDirection: 'row', columnGap: '10px'}}>
            <DateInput source='start' label='Start Date' />
            <DateInput source='end' label='End Date' />
            </div>
            <BooleanInput source='active' defaultValue={true} label='Active?' validate={validations.validateActive} />
        </div>
    );
};

export default Addresses;