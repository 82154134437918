import React, { useEffect, useState } from "react";
import {
    TextField,
    Loading,
    Button,
    List,
    Datagrid,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    BooleanField,
    DeleteWithConfirmButton
} from "react-admin";
import { Api } from "../../helpers/api";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { useNotify, useRefresh } from "ra-core";
import ActivityEdit from "./Edit";
import { perPageReferenceFields } from "../../constants";
import ClearFiltersButton from "../../components/ClearFiltersButton";

const ActivityValidator = (props) => {
    const [loading, setLoading] = useState(true);
    const notify = useNotify();
    const refresh = useRefresh();

    const ActivityFilters = (props) => {
        const { filterValues } = props;
        const isSourceSelected = Boolean(filterValues.source);

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Filter {...props}>
                    <ReferenceInput
                        label="Source"
                        source="source"
                        reference="sources"
                        perPage={perPageReferenceFields}
                        alwaysOn
                    >
                        <AutocompleteInput optionText="code" optionValue="id" />
                    </ReferenceInput>
                </Filter>
                {isSourceSelected && (
                    <ClearFiltersButton {...props} filters={{ source: "", id: "" }} style={{ marginLeft: 'auto' }} />
                )}
            </div>
        );
    };

    useEffect(() => {
        setLoading(false);
    }, []);

    const saveActivity = (data, action) => {
        console.log("DATA", data);
        data.action = action;
        let notifyMessages = { "A": "Activity accepted!", "D": "Activity deleted!", "S": "Activity updated!" }
        Api.patch(`/activity-validator/${data.id}/`, data, true)
            .then((response) => {
                if (response.type === 'success') {
                    notify(notifyMessages[action]);
                    refresh();
                }
                else {
                    notify('Bad request');
                }
            })
    }

    const AcceptButton = ({ record }) => {
        let data = record;
        if (data.validated == false) {
            return (
                <Button
                    label="Accept"
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={(e) => { saveActivity(data, "A"); }}
                />
            );
        }
        return (
            <></>
        );
    }

    const DeleteButton = (props) => {
        let data = props.record;
        if (data.validated == false) {
            return (
                <DeleteWithConfirmButton  variant="contained" style={{ backgroundColor: "red", color: "white"}} {...props} />
            );
        }
        return (
            <></>
        );
    }

    if (loading) { return <Loading />; };

    return (
        <ThemeProvider theme={theme} >
            <h2>Activity Validator</h2>
            <List {...props}
                toolbar={false}
                filters={<ActivityFilters />}
                exporter={false}
                bulkActionButtons={false}
            >
                <Datagrid expand={<ActivityEdit onActivityChange={saveActivity} />}>
                    <TextField source="source_code" label="Source" sortable={false} />
                    <TextField source="long_text_pre" label="Text" sortable={false} />
                    <TextField source="long_text_post" label="Enriched Text" sortable={false} />
                    <BooleanField source="validated" sortable={false} />
                    <AcceptButton {...props} />
                    <DeleteButton {...props} />
                    
                </Datagrid>
            </List>
        </ThemeProvider>
    );

}

export default ActivityValidator;
