import React from 'react';
import {BooleanInput, Create, DateInput, SimpleForm, TextInput, SelectInput, AutocompleteArrayInput, ReferenceArrayInput} from 'react-admin';
import {validations} from './validations';
import {perPageReferenceFields} from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const SupplierCreate = props => {

  return (
    <>
      <Create undoable={true} {...props}>
        <SimpleForm redirect="list">
          <BackButton {...props} action = {"create"}/>
          <PageTitle {...props}>Create</PageTitle>
          <TextInput source="name" fullWidth={true} validate={validations.validateName}/>
          <TextInput source="address" fullWidth={true} validate={validations.validateAddress}/>
          <TextInput source="reference" fullWidth={true} validate={validations.validateReference}/>
          <ReferenceArrayInput label='Products' reference='products' source='products' perPage={perPageReferenceFields}>
            <AutocompleteArrayInput optionText='label' optionValue='id'/>
          </ReferenceArrayInput>
          <SelectInput source="order_type" label="Order Type" choices={[
            {id: "ByWIN", name: "ByWIN"},
            {id: "ByName", name: "ByName"},
            {id: "ByWINandReference", name:"ByWINandReference"},
            {id: "ByRegNumberAndCountry", name:"ByRegNumberAndCountry"}
          ]} validate={validations.validateOrderType} />
          <BooleanInput source="active" defaultValue={true} label="Is it active?"/>
          <DateInput source='start_date' validate={validations.validateStartDate}/>
          <DateInput source='end_date'/>
        </SimpleForm>
      </Create>
    </>
  );
}

export default SupplierCreate;
