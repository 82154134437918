import React from 'react';
import { Datagrid, SimpleShowLayout, EditButton, List, TextField, Filter, ReferenceManyField, SearchInput, RichTextField, AutocompleteInput, ReferenceInput } from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import { perPageReferenceFields } from '../../constants';
import ClearFiltersButton from '../../components/ClearFiltersButton';
import DocBulkActionButtons from '../../components/DocBulkActionButtons';

const MappingFunctionsFilter = (props) => {
  return (
    <div>
      <ClearFiltersButton {...props} filters={{ name: '', }} />
      <Filter {...props}>
        <ReferenceInput label="Name" source="id" reference="import-mapping-functions" perPage={perPageReferenceFields} alwaysOn>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </Filter>
    </div>
  );
}

const ShowMappingFunctionInfo = props => {
  return (
    <SimpleShowLayout {...props}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ marginRight: '1rem' }}>
          <ReferenceManyField {...props} fullWidth reference="import-mapping-functions-params" label="Parameters" target="function_id"
            perPage={perPageReferenceFields} >
            <Datagrid {...props}>
              <TextField label="Parameters" source="value" />
            </Datagrid>
          </ReferenceManyField>
        </div>
        <div style={{ marginRight: '1rem' }}>
          <ReferenceManyField {...props} fullWidth reference="import-mapping-functions-types" label="Types" target="function_id"
            perPage={perPageReferenceFields} >
            <Datagrid {...props}>
              <TextField label="Types" source="type" />
            </Datagrid>
          </ReferenceManyField>
        </div>
        <div style={{ flex: 1, whiteSpace: "pre-wrap" }}>
          <div style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>Description:</div>
          <RichTextField label="Description" record={props.record} source="description" emptyText="-" sanitize={true} />
        </div>
      </div>
    </SimpleShowLayout>
  );
}

const MappingFunctionsList = props => {
  return (
    <ThemeProvider theme={theme}>
      <h2>{props.options.label}</h2>
      <List filters={<MappingFunctionsFilter />} bulkActionButtons={<DocBulkActionButtons {...props} />} exporter={false} {...props}
        actions={<ListToolbarActions showCreateButton={true} />}>
        <Datagrid expand={<ShowMappingFunctionInfo {...props} />} >
          <TextField label="name" source="name" />
          <EditButton />
        </Datagrid>
      </List>
    </ThemeProvider>
  );
}

export default MappingFunctionsList;