import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const Predicates = (props) => {
    let section = props.section;

    return (
        <div>
            <MappingSectionInput field='textAR' required={true} {...props} />
            {(section !== 'companyStatuses' && section !== 'registrations' && section !== 'personStatuses') &&
                <MappingSectionInput field='type' {...props} />}
            {(section !== 'companyTypes' && section !== 'registrations' && section !== 'personTypes') &&
                <MappingSectionInput field='status' {...props} />}
            {(section == 'registrations') &&
                <MappingSectionInput field='registration' {...props} />}
            {(section == 'companyTypes') &&
                <MappingSectionInput field='form' {...props} />}
            {(section == 'companyTypes') &&
                <MappingSectionInput field='legalForm' {...props} />}
            {(section == 'companyTypes') &&
                <MappingSectionInput field='exempted' {...props} />}
            <MappingSectionInput field='languageCode' {...props} />
            <MappingSectionInput field='translationCode' {...props} />
            {(section == 'registrations') &&
                <MappingSectionInput field='name' {...props} />}
            <MappingSectionInput field='start' {...props} />
            {(section != 'registrations') &&
                <MappingSectionInput field='end' {...props} />}
            <MappingSectionInput field='active' required={true} {...props} />
            <MappingSectionInput field='validated' required={true} {...props} />
        </div>
    );
};

export default Predicates;