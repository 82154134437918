import React from 'react';
import { Show, ReferenceManyField, Edit, TextInput, TabbedForm, FormTab, SearchInput, List, Filter, NumberInput, SimpleShowLayout, AutocompleteInput, ReferenceInput, ReferenceField, Datagrid, TextField, EditButton, CreateButton } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useRecordContext } from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import { perPageReferenceFields } from "../../constants";
import "../../index.css";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import EditFormToolbarActions from "../../components/EditFormToolbarActions";



const OrdersList = props => (
    <ThemeProvider theme={theme}>
        <h2>{props.options.label}</h2>
        <Edit {...props}>
            <TabbedForm toolbar={<EditFormToolbarActions showDeleteButton={false/*userHasPermission("processes-delete")*/} />}>
                <FormTab label="Search By Win">
                    <TextInput label='Win' source="win" />
                </FormTab>
                <FormTab label="Search by Name">
                    <TextInput label='name' source="name" />
                </FormTab>
                <FormTab label="Logs">
                </FormTab>
            </TabbedForm>
        </Edit>
    </ThemeProvider>
)

export default OrdersList;