import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../../constants/styleOverride";

const getLastThreeMonths = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const lastThreeMonths = [
        monthNames[(currentMonth - 3 + 12) % 12],
        monthNames[(currentMonth - 2 + 12) % 12],
        monthNames[(currentMonth - 1 + 12) % 12]
    ];

    return lastThreeMonths;
};

const NoticesCount = props => {
    const lastThreeMonthNames = getLastThreeMonths();

    return (
        <ThemeProvider theme={theme}>
            <h2>{props.options.label}</h2>
            <p style={{ color: 'grey', marginTop: '8px' }}>Number of notices per source in the last 3 months</p>
            <List
                bulkActionButtons={false} exporter={false} {...props}
                actions={null}
                pagination={false} perPage={100}>
                <Datagrid>
                    <TextField label="Source" source="source_code" sortable={false} />
                    {lastThreeMonthNames.map(month => (
                        <TextField
                            key={month}
                            label={month}
                            source={`${month}`}
                            sortable={false}
                        />
                    ))}
                </Datagrid>
            </List>
        </ThemeProvider>
    );
}

export default NoticesCount;
