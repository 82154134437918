import React from 'react';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import {TextArrayInput, SelectArrayInput, ReferenceArrayInput,ArrayInput, SimpleFormIterator, ReferenceManyField, Datagrid, Edit, SimpleForm, BooleanInput, DateInput, ReferenceField, TextField, TextInput, ReferenceInput, SelectInput } from 'react-admin';
// import { validations } from './validations';
import { perPageReferenceFields } from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";
import {validations} from "./validations";

const SourceEntitiesEdit = props => {

    return (
        <>
            <Edit undoable={true} {...props}>
                
                <SimpleForm toolbar={<EditFormToolbarActions />}>
                    <BackButton {...props} action = {"edit"}/>
                    <PageTitle {...props}>Edit</PageTitle>
                    <TextField label="Source Entity ID" source="id" fullWidth={true} />
                    <ReferenceField label="Source Code" source="source_id" reference="sources" link={false}>
                        <TextField source="code" />
                    </ReferenceField>
                    <TextInput source="reference" fullWidth={true} validate={validations.validateReference}/>
                    <TextInput source="win" fullWidth={true} validate={validations.validateWin}/>
                </SimpleForm>
            </Edit>
        </>
    );
}

export default SourceEntitiesEdit;