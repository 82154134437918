import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, BooleanInput, FormDataConsumer, FileInput, FileField, AutocompleteInput} from 'react-admin';
import { validations } from './validations';
import { perPageReferenceFields } from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const ImportMappingSourcesCreate = props => {

  return (
    <>
      <Create undoable={true} {...props}>
        <SimpleForm redirect="list">
          <BackButton {...props} action = {"create"} />
          <PageTitle {...props}>Create</PageTitle>
          <BooleanInput source="bulkUpload" label="Multiple import mappings?" />
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => formData.bulkUpload &&
              <FileInput source="bulk_file" accept=".csv" label="Upload bulk csv file" validate={validations.validateFile}>
                <FileField source="src" title="title" />
              </FileInput>
            }
          </FormDataConsumer>
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => !formData.bulkUpload &&
              <React.Fragment>
                <BooleanInput source="newPropName" label="New property name?" />
                <FormDataConsumer subscription={{ values: true }}>
                  {({ formData, ...rest }) => formData.newPropName &&
                    <TextInput fullWidth={true} source="property_name" {...rest} validate={validations.validatePropName}/>
                  }
                </FormDataConsumer>
                <FormDataConsumer subscription={{ values: true }}>
                  {({ formData, ...rest }) => !formData.newPropName &&
                    <ReferenceInput label="Select property name" source="property_name" reference="import-mapping-sources-property-names" perPage={perPageReferenceFields}>
                      <SelectInput fullWidth={true} optionText="property_name" optionValue="property_name" validate={validations.validateSelection}/>
                    </ReferenceInput>
                  }
                </FormDataConsumer>

                <ReferenceInput label="Source" source="source_id" reference="sources" perPage={perPageReferenceFields}>
                  <AutocompleteInput optionText="code" optionValue="id" validate={validations.validateSelection}/>
                </ReferenceInput>
                <TextInput source="label" fullWidth={true} validate={validations.validateLabel} />
                <TextInput source="value1" fullWidth={true} validate={validations.validateValue} />
                <TextInput source="value2" fullWidth={true} validate={validations.validateValue} />
                <TextInput source="value3" fullWidth={true} validate={validations.validateValue} />
                <TextInput source="value4" fullWidth={true} validate={validations.validateValue} />
                <TextInput source="value5" fullWidth={true} validate={validations.validateValue} />
              </React.Fragment>}
          </FormDataConsumer>

        </SimpleForm>
      </Create>
    </>
  );
}

export default ImportMappingSourcesCreate;
