import React, {useState} from 'react';
import {
  Datagrid,
  NumberField,
  SimpleShowLayout,
  TextField,
  ReferenceManyField,
  DateField,
  BooleanField,
  DeleteButton,
} from "react-admin";
import { Api } from "../../helpers/api";
import { perPageReferenceFields } from "../../constants";

const ShowEntityInfo = props => {
    const [reportFileUrl, setReportFileUrl] = useState(null);
  
    const ReportFileUrlField = ({ source, record}) => {
      if (record[source] === undefined) {
        return null;
      }
    
      let link = record[source];
      
      return (
          <a href="javascript:void(0);" onClick={() => getUrl(link)}>url</a>
      )
    }
  
    const getUrl = (url) => {
      return Api.get(`/report-files/get-report-file-url?url=${encodeURIComponent(url)}`)
      .then((response) => {
        if (response.type === "success") {
          setReportFileUrl(response.result.url);
          window.open(response.result.url, "_blank")
        }
      })
    }
  
    return (
      <SimpleShowLayout {...props}>
        <ReferenceManyField
          {...props}
          fullWidth
          reference="entity-idkeys"
          label="Idkeys"
          target="entity_id"
          perPage={perPageReferenceFields}
          sort={{ field: "id", order: "DESC" }}
        >
          <Datagrid>
            <TextField source="idkey" />
            <TextField source="idkey_code" />
            <BooleanField source="active" label="Active" />
            <BooleanField source="variation" label="Variation" />
            <DateField source="insert_date" locales="fr-FR" />
            <TextField label="Insert source" source="source_code" />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          {...props}
          fullWidth
          reference="entity-names"
          label="Names"
          target="entity_id"
          perPage={perPageReferenceFields}
          sort={{ field: "id", order: "DESC" }}
        >
          <Datagrid>
            <TextField source="name" />
            <TextField label="Name type" source="entity_name_type_label" />
            <BooleanField source="active" label="Active" />
            <DateField source="insert_date" locales="fr-FR" />
            <TextField label="Insert source" source="source_code" />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          fullWidth
          reference="entity-dates"
          label="Dates"
          target="entity_id"
          perPage={perPageReferenceFields}
          sort={{ field: "id", order: "DESC" }}
        >
          <Datagrid>
            <NumberField locales="pt-PT" source="year" />
            <NumberField locales="pt-PT" source="month" />
            <NumberField locales="pt-PT" source="day" />
            <TextField label="Date type" source="entity_date_type_label" />
            <DateField source="insert_date" locales="fr-FR" />
            <TextField label="Insert source" source="source_code" />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          fullWidth
          reference="entity-predicates"
          label="Predicates"
          target="entity_id"
          perPage={perPageReferenceFields}
          sort={{ field: "id", order: "DESC" }}
        >
          <Datagrid>
            <TextField source="predicate" />
            <TextField
              label="Predicate type"
              source="entity_predicate_type_label"
            />
            <DateField source="insert_date" locales="fr-FR" />
            <TextField label="Insert source" source="source_code" />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          fullWidth
          reference="entity-reports"
          label="Reports"
          target="entity_id"
          perPage={perPageReferenceFields}
          sort={false}
        >
          <Datagrid>
            <ReferenceManyField
              fullWidth
              source="report"
              reference="reports"
              label={false}
              target="id"
              perPage={perPageReferenceFields}
              sort={false}
            >
              <Datagrid>
                <TextField source="id" label="Report Id" />
                <TextField source="report_type_label" />
                <TextField source="label" />
                <TextField source="year_serial" />
                <DateField source="effective_date" locales="fr-FR" />
                <DateField source="insert_date" locales="fr-FR" />
                <TextField source="source_code" label="source" />
                <ReferenceManyField
                  fullWidth
                  reference="report-files"
                  label="Report Files"
                  target="report_id"
                  perPage={perPageReferenceFields}
                  sort={false}
                >
                  <Datagrid>
                    <TextField source="id" label="Report File Id" />
                    <TextField source="content" />
                    <NumberField source="size" />
                    <TextField source="format_type" label="Format" />
                    <ReportFileUrlField source="url" />
                  </Datagrid>
                </ReferenceManyField>
                <DeleteButton
                  {...props}
                  redirect={props.location.search}
                />
              </Datagrid>
            </ReferenceManyField>
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    );
  }

export default ShowEntityInfo;