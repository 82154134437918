import React, {useState, useEffect} from 'react';
import { Datagrid, List, TextField, CardActions, CreateButton, useListContext, useNotify, useRedirect, useRefresh } from 'react-admin';
import {parse} from 'query-string';
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import ListToolbarActions from "../../components/ListToolbarActions";
import { Route } from 'react-router';
import SourceSectionQualityLevelsCreate from './Create';
import SourceSectionQualityLevelsEdit from './Edit';
import BackButton from "../../components/BackButton";
import { Api } from "../../helpers/api";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const updateSourceCode = props => {
    if ('state' in props.location && props.location.state) {
        return props.location.state.source_code;
    }
    else {
        const {source_code: source_code_string} = parse(props.location.search)
        return source_code_string;
    }
}

const InitialLoadQualityLevels = ({sourceId}) => {
    return (
        <Box>
            <p>
                <AddDefaulSectionsButton label="Load from Import Mapping" sourceId={sourceId} loadMongo={true} />
                <h5>OR</h5>
                <AddDefaulSectionsButton label="Load default sections" sourceId={sourceId} />
            </p>
        </Box>
    );
}

const AddDefaulSectionsButton = ({ label, sourceId, loadMongo }) => {
    let data = {};
    data['source_id'] = sourceId;
    data['load_from_mongo'] = loadMongo;
    const notify = useNotify();
    const refresh = useRefresh();
  
    const handleClick = e => {

        return Api.post(`/source-section-quality-levels/create-default-sections/`, data, true)
                .then(response => {
                    if (response.type === 'success'){
                        notify('Successfully added section quality levels!', 'success');
                        refresh();
                    }
                    else {
                        notify('No import mapping available for this source!', 'warning');
                    }
                })
                .catch((e) => {
                    notify('Error starting the process!', 'warning');
                })      
    };

    return (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleClick}
      >
          {label}
      </Button>
    )
};

const SourceSectionQualityLevelsList = props => {
    console.log('SSQL PROPS', props);
    const [total, setTotal] = useState(null);
    const [sourceCode, setSourceCode] = useState(null);
    const [sourceId, setSourceId] = useState(null);
    

    useEffect(() => {
        const { source_id: source_id_string} = parse(props.location.search);
        const source_id = source_id_string ? parseInt(source_id_string, 10) : '';
        setSourceId(source_id);
        setSourceCode(updateSourceCode(props));
    }, []);


    const ListActions = props => {
        const {total} = useListContext();
        setTotal(total);
        return false;
    }

    return (
        <React.Fragment>
            <BackButton {...props} action = {"edit"}/>
            <h2>{props.options.label} for {sourceCode}</h2>
            { total > 0 ? 
                <SourceSectionQualityLevelsCreate
                    {...props} 
                    source_id={sourceId} 
                    source_code={sourceCode}  
                /> 
                : 
                <InitialLoadQualityLevels 
                    sourceId={sourceId} 
                /> 
            }
            <List 
                {...props} 
                actions={<ListActions />} 
                sort={{ field: 'id', order: 'ASC' }} 
                filterDefaultValues={{source_id: sourceId, source_code: sourceCode }} 
                bulkActionButtons={<DocBulkActionButtons {...props} />} 
                exporter={false} 
            >
                <Datagrid 
                    expand={
                        <SourceSectionQualityLevelsEdit 
                            source_id={sourceId} 
                            source_code={sourceCode} 
                        />
                    } 
                    rowClick="expand" 
                    expandSingle
                >
                    <TextField source="source_code" label="source" />
                    <TextField source="section_name" />
                    <TextField source="entity_relation" />
                    <TextField source="quality_level" />
                </Datagrid>
            </List>
        </React.Fragment>
    );
};

export default SourceSectionQualityLevelsList;