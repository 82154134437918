import React, { useState } from "react";
import { Api } from "../../helpers/api";
import { useNotify, useRedirect } from "ra-core";
import DoneIcon from "@material-ui/icons/Done";
import ButtonWithDialog from "../ButtonWithDialog";

const ReExecuteAllStepsButton = ({data, ...props}) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const handleClick = () => {
    return Api.post(`/alerts/close-all-alerts/`, data, true).then(
      (response) => {
        if (response.type === "success") {
          notify("Steps ready to be re-executed.");
          redirect("/alerts");
          window.location.reload();
        } else if (response.type === "auth_error") {
          console.log("Error re-executing the step - authentication error");
          redirect("/login");
        } else {
          console.log("Error re-executing the step - response", response.type);
          if (response.error) {
            notify(response.error, "warning");
          } else {
            notify("Could not re-execute the step!", "warning");
          }
        }
      }
    );
  };
  return data.count && data.count !== 0 ? (
    <div
      style={{
        display: "flex",
        paddingTop: "10px",
        justifyContent: "flex-end",
      }}
    >
      <ButtonWithDialog
        dialogTitleMessage="Re Execute All Steps"
        dialogContentMessage={`${data.count} ${
          data.count > 1 ? "alerts" : "alert"
        } selected. Are you sure you want to proceed?`}
        buttonLabel="re-execute all steps"
        style={{
          margin_top: 10,
          margin_left: "auto",
          margin_right: 0,
          display: "flex",
        }}
        onConfirm={handleClick}
        startIcon={<DoneIcon />}
      />
    </div>
  ) : null;
};

export default ReExecuteAllStepsButton;
