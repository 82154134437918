import {
    required,
    maxLength
} from 'react-admin';

export const validations = {
    validateCode: [required()],
    validateFilenamePattern: [maxLength(50), required()],
    validateLanguage: [required()]
};
