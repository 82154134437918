import React from 'react';
import {BooleanInput, useNotify, Create, DateInput, SimpleForm, TextInput, useCreate, SelectInput, NumberInput, ReferenceInput, AutocompleteArrayInput, ReferenceArrayInput, ReferenceManyInput} from 'react-admin';
import {validations} from './validations';
import {perPageReferenceFields} from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";
import { Api } from '../../helpers/api';
import Box from '@material-ui/core/Box';
import {credentialTypes, credentialLocations} from '../../constants';

const SupplierProductRequestsCredentialsCreate = props => {
  const notify = useNotify();
  const paramSave = (data) => {
    data['supplier_product_request'] = props.id;
    return Api.post('/supplier-product-requests-credentials/', data, true)
    .then(response => {
      if (response.type === "success") {
          console.log("result", response.result);
          notify('Supplier product request credential successfully created!');
          setTimeout(function() {
              window.location.reload();
          }, (0.5 * 1000))
         
      } 
      else if (response.type === "auth_error") {
          console.log("Error starting the process - authentication error");
      }
      else {
          console.log("Error starting the process - response", response);
          if (response.error) {
          }
          else {
          }
      }
  })
  .catch((e) => {
      console.log("Exception starting the process - e", e);
  });
  };

  return (
    <>
      <h2>Create credential for Request #{props.id}</h2>
      <SimpleForm save={(data) => paramSave(data, notify)} sx={{ maxWidth: 500 }}>
        <TextInput source="key" validate={validations.validateKey} />
        <TextInput source="value"
          helperText="string value or json path ($.data) or value from input (<name>) or value from cache, from a previous request (#token)"
          validate={validations.validateValue}
        />
        <tr>
          <td>
            <SelectInput label='Credential Type' source="credential_type" choices={credentialTypes} optionText="text" optionValue="value" isRequired fullWidth validate={validations.validateCredentialType} />
          </td>
          <td>
            <SelectInput label="Credential Location" source="credential_location" choices={credentialLocations} optionText="text" optionValue="value" isRequired fullWidth validate={validations.validateCredentialLocation} />
          </td>
        </tr>
      </SimpleForm>
    </>

  );
}

export default SupplierProductRequestsCredentialsCreate;
