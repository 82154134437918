import React from 'react';
import { TextField, Card, SelectField, TextInput, BooleanInput, ReferenceInput, AutocompleteInput, SelectInput, ArrayInput, SimpleFormIterator, NumberInput, DateInput } from 'react-admin';
import { Box, Toolbar } from '@material-ui/core';
import {validations} from './validations';


const EntityPredicate = (props) => {
    const location = props.location.pathname;

    return (
        <>
            <TextInput source="predicate" fullWidth={true} validate={validations.validatePredicate} />
            <ReferenceInput label="Predicate type" source='entity_predicate_type' reference='enumerations-entity-predicate-types' validate={validations.validateEntityPredicateType} >
                <SelectInput optionText="label" />
            </ReferenceInput>
            { location.includes('create') &&
                <ReferenceInput label="Insert source" source="insert_source" reference="sources" validate={validations.validateInsertSource} >
                    <AutocompleteInput optionText="code" optionValue="id" />
                </ReferenceInput>
            }
        </>
    );
}


export default EntityPredicate;


