import React from 'react';
import { TextInput, BooleanInput, ReferenceInput, AutocompleteInput, NumberInput, DateTimeInput } from 'react-admin';
import { validations } from './validations';

const Credits = (props) => {
    console.log('PROPS');
    console.log(props);

    return (
        <div>
            <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes' validate={validations.validateLanguageCode} >
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <DateTimeInput source='date' label='Date' validate={validations.validateDate} />
            {'location' in props &&
                <TextInput source='risk_score_ar' label='Risk Score AR' validate={validations.validateRiskScoreAR} />}
            <NumberInput source='risk_score' label='Risk Score' min={1} max={100} />
            {'location' in props &&
                <TextInput source='payment_score_ar' label='Payment Score AR' validate={validations.validatePaymentScoreAR} />}
            <NumberInput source='payment_score' label='Payment Score' min={1} max={100} />
            {'location' in props &&
                <TextInput source='trend_score_ar' label='Trend Score AR' validate={validations.validateTrendScoreAR} />}
            <NumberInput source='trend_score' label='Trend Score' min={1} max={100} />
            {'location' in props && 
                <TextInput source='credit_ar' label='Credit AR' validate={validations.validateCreditAR} />}
            
            <NumberInput source='credit_limit' label='Credit Limit' min={0.0} max={9999999999999.9} />
            {/* Pending enumerations
            <ReferenceInput source='credit_definition' label='Credit Definition' reference='enumerations-credit-definition' allowEmpty={true}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput> */}
            <ReferenceInput source='currency_code' label='Currency Code' reference='enumerations-currency-codes' allowEmpty={true}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <BooleanInput source='active' defaultValue={true} label='Active?' validate={validations.validateActive} />
        </div>
    );
};

export default Credits;