import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const Booleans = (props) => {

    return (
        <div>
            <MappingSectionInput field='textAR' required={true} {...props}/>
            <MappingSectionInput field='booleanType' {...props}/>
            <MappingSectionInput field='boolean' {...props}/>
            <MappingSectionInput field='start' {...props}/>
            <MappingSectionInput field='end' {...props}/>
            <MappingSectionInput field='active' required={true} {...props}/>
            <MappingSectionInput field='validated' required={true} {...props}/>
        </div>
    );  
};

export default Booleans;