import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, FormDataConsumer, AutocompleteInput} from 'react-admin';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const ImportMappingsCreate = (props) => {


    return (
        <Create {...props}>
            <SimpleForm redirect="edit">
                <BackButton {...props} action = {"create"}/>
                <PageTitle {...props}>Create</PageTitle>
                <TextInput source="name"/>
                <SelectInput source="relation_type" choices={[
                    {id:"parent", name:"Parent"},
                    {id:"child", name:"Child"}
                ]}/>
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.relation_type==="parent" &&
                        <SelectInput source="format_type" choices={[
                            {id:"CSV", name:"CSV"},
                            {id:"DOC", name:"DOC"},
                            {id:"DOCX", name:"DOCX"},
                            {id:"HTML", name:"HTML"},
                            {id:"JSON", name:"JSON"},
                            {id:"PDF", name:"PDF"},
                            {id:"TXT", name:"TXT"},
                            {id:"XBRL", name:"XBRL"},
                            {id:"XLS", name:"XLS"},
                            {id:"XLSX", name:"XLSX"},
                            {id:"XML", name:"XML"}
                        ]} {...rest} />
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.relation_type==="child" &&
                        <ReferenceInput label="Relation ID" source="relation_id" reference="import-mappings" perPage={300} {...rest}>
                            <AutocompleteInput optionText="name" optionValue="id"/>
                        </ReferenceInput>
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
}

export default ImportMappingsCreate;
