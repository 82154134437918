import React from 'react';
import { TextInput, TextField, SimpleForm} from 'react-admin';

const ActivityEdit = props => {

    return (
        <>
            <SimpleForm {...props} redirect={`/activity-validator`} save={(data) => props.onActivityChange(data, "S")}
                sx={{ maxWidth: 500 }}
            >
                <TextField source="long_text_pre" />
                <TextInput multiline rows="15" fullWidth={true} source="long_text_post" />
            </SimpleForm>
        </>
    );
};

export default ActivityEdit;