import {maxLength, required} from 'react-admin';

export const validations = {
  validateSupplierProduct: [required()],
  validateEndpointUrl: [maxLength(255), required()],
  validateRequestMethod: [maxLength(255), required()],
  validateLabel: [maxLength(255), required()],
  validateOutputCheck: [maxLength(255)],
  validateOutputPath: [maxLength(255), required()],
  validateOutputType: [maxLength(255), required()],
  validateKeySaveResponse: [maxLength(255)],
  validateFallbackRequest: [],
  validateErrorMessage: [maxLength(255), required()],
};
