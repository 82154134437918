export const priorityOptions = [
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
    { value: 4, text: '4' },
    { value: 5, text: '5' },
    { value: 6, text: '6' },
    { value: 7, text: '7' },
    { value: 8, text: '8' },
    { value: 9, text: '9' },
    { value: 10, text: '10' },
];
export const qualityLevelsOptions = [
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
    { value: 4, text: '4' },
    { value: 5, text: '5' },
    { value: 6, text: '6' },
    { value: 7, text: '7' },
    { value: 8, text: '8' },
    { value: 9, text: '9' }
];


export const credentialTypes = [
    {value: 'BasicAuth' , text: 'BasicAuth'},
    {value: 'TokenAuth', text: 'TokenAuth'},
    {value: 'ApiKeyAuth', text: 'ApiKeyAuth'}
]

export const parameterTypes = [
    {value: 'params' , text: 'params'},
    {value: 'body', text: 'body'},
    {value: 'urlParams', text: 'urlParams'}
]

export const outputTypes = [
    {value: 'array' , text: 'array'},
    {value: 'string', text: 'string'},
    {value: 'object', text: 'object'}
]

export const credentialLocations = [
    {value: 'session' , text: 'session'},
    {value: 'header' , text: 'header'}
]



export const perPageReferenceFields = 300;