import React from 'react';
import { TextInput, BooleanInput, ReferenceInput, AutocompleteInput, DateInput, NumberInput } from 'react-admin';
import { validations } from './validations';

const Idkeys = (props) => {
    console.log('IDKEY PROPS');
    console.log(props);

    return (
        <div>
            { 'location' in props &&
                <div>
                    <TextInput source='idkeyAR' label='Idkey AR' validate={validations.validateIdkeyAR} />
                    <TextInput source='textAR' label='Text AR' validate={validations.validateTextAR} />
                </div>
            }
            <ReferenceInput source='idkeyCode' label='Idkey Code' reference='enumerations-idkey-codes' validate={validations.validateIdkeyCode} allowEmpty={true} >
                <AutocompleteInput optionText="code" optionValue="id" />
            </ReferenceInput>
            <TextInput source='idkey' label='Idkey' validate={validations.validateIdkey} />
            <div>
                <NumberInput source='quality_level' label='Quality Level' step={1} min={1} max={9} style={{width: "200px"}} allowEmpty={true} />
            </div>
            <DateInput source='start' label='Start Date' />
            <DateInput source='end' label='End Date' />
            <BooleanInput source='primaryIdkey' initialValue={false} label='Is primary?' validate={validations.validateIsPrimary} />
            <BooleanInput source='active' initialValue={true} label='Active?' validate={validations.validateActive} />
        </div>  
    );  
};

export default Idkeys;