import React, {PureComponent} from 'react';
import {
    List, ShowButton, Button, Show, Datagrid, TextField, SimpleShowLayout
} from 'react-admin';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: "bold"
    }
  }))(TableCell);


class ListEntities extends React.Component {

    constructor(props) {
        super(props);
        console.log(props);
        this.getInvalidEntities = this.getInvalidEntities.bind(this);
        this.entityErrorsChange = this.entityErrorsChange.bind(this);
        this.getInvalidEntities();
    }
    
    entityErrorsChange(value) {
        this.props.entityErrorsChange(value)
    }

    getInvalidEntities(){
        var invalidEntities = [];
        this.entityErrorsChange(this.props.record.entityErrors);
        
        for (var key in this.props.record.entityErrors){
            var entity_id = {id: key};
            var entity_obj = this.props.record.entities[parseInt(key)];
            var entity = Object.assign({}, entity_id, entity_obj);
            invalidEntities.push(entity);
        }
       
        this.props.showInvalidEntities(invalidEntities);
    }
      
    render() {
        const invalidEntities = this.props.invalidEntities;
        return (
        <div>
        <TableContainer component={Paper}>
            <Table aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align='left'>Entity Type</StyledTableCell>
                        <StyledTableCell align="left">Entity Relation</StyledTableCell>
                        <StyledTableCell align="left">Entity Reference</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { invalidEntities.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell align='left'>{(row.type === 'C') ? 'C (Company)' : 'P (Person)'}</TableCell>
                            <TableCell align='left'>{(row.relation === 'C') ? 'C (Child)' : 'P (Parent)'}</TableCell>
                            <TableCell align='left'>{row.reference}</TableCell>
                            <TableCell align='left'><Button label='Fix Issues' onClick={() => this.props.onEntityChange(row.id)}/></TableCell>
                        </TableRow>
                    )) }

                </TableBody>
            </Table>
        </TableContainer>
        </div>
        );
    }
};

export default ListEntities;

/**return  <div>
            <table style={{width: "100%"}}>
                <tr>
                    <th>Entity Type</th>
                    <th>Entity Relation</th>
                    <th>Entity Reference</th>
                </tr>
                            
                {this.props.record.entities.map((item, index) => (
                    <tr key={index}>
                        <td>{item.type}</td>
                        <td>{item.relation}</td>
                        <td>{item.reference}</td>
                        <ShowButton value={index} label='Fix Issues' onClick={this.handleChange}/>
                    </tr>
                    ))
                }
            </table>
        </div>**/



/**<Row>
<Col>Entity Type</Col>
<Col>Entity Relation</Col>
<Col>Entity Reference</Col>
</Row>
            
{entities.map(function(item, i){
return (<Row key={i}>
            <Col>{item.type}</Col>
            <Col>{item.relation}</Col>
            <Col>{item.reference}</Col>
            <Col><ShowButton label='Fix Issues'/></Col>
        </Row>)
})
}**/

