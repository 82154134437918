
export class AuthHelper {
    static tokenKey = 'session_token';
    static permissions = null;
    
    static getSessionToken = () => localStorage.getItem(this.tokenKey);

    static saveSession = (token, permissions) => {
        localStorage.setItem(this.tokenKey, token);
        this.permissions = permissions;
    }

    static removeSession = () => {
        localStorage.removeItem(this.tokenKey);
        this.permissions = null;
    }

    static getSessionPermissions = () => this.permissions;
    static hasSessionPermissionsDefined = () => this.permissions != null;
}

