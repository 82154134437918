import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import DocumentEditor from '../../components/DocumentEditor';

export const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: "bold"
    }
  }))(TableCell);

export const ColoringField = ({ record, to_check, to_check2, to_display }) => <span style={record[to_check] && !record[to_check2] ? {color:'red', fontWeight: 'bold'} : record[to_check] && record[to_check2] ? {color: 'orange', fontWeight: 'bold'} : {color:'green', fontWeight: 'bold'}}>{record[to_display]}</span>;

export const AsideDocument = ({doc_to_show, batch_records, show_doc}) => {
    if (show_doc) {
        return <DocumentEditor record={batch_records[doc_to_show]} />;
    }
    return null;
}

export const concatAsReportedFields = (record) => {
    console.log('RECORD', record);
    let ar_fields = [
        "textAR", "typeAR", "longTextAR", "exportMarketsAR", "importMarketsAR",
        "exportProductsAR", "importProductsAR", "idkeyAR", "nameAR", "stringAR", "marketAR", "risk_score_ar", "payment_score_ar", "trend_score_ar", "credit_ar", "itemsAR"
    ];

    let as_reported = "";
    let ar_field_text = "";
    for (const ar_field of ar_fields) {
        ar_field_text = "";
        if (ar_field in record) {
            if (ar_field == 'nameAR' && as_reported.includes('idkeyAR')) {
                continue;
            }
            
            if (record[ar_field] instanceof Array){
                ar_field_text = ar_field.concat(": ", JSON.stringify(record[ar_field]));
            }
            else {
                ar_field_text = ar_field.concat(": ", record[ar_field]);
            }
            
            if (as_reported === ""){
                as_reported = ar_field_text;
            }
            else {
                as_reported = as_reported.concat('\n', ar_field_text);
            }
        }

    }

    return <>
            {as_reported.split("\n").map((ar_field, idx) => (
                <p key={idx}>{ar_field}</p>
            ))}
        </> 
}


export const GetRowHeader = (props) => {
    let record = props.record;
    let errors = '';
    if ('errors' in record){
        errors = record.errors.join('\n');
        console.log('ERRORS', errors);
    }

    const result = 
        <div>
            <Table aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align='left'>Original</StyledTableCell>
                        <StyledTableCell align="left">Errors</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={1}>
                        <TableCell align='left'>
                            {concatAsReportedFields(record)}
                        </TableCell>
                        <TableCell align='left'>
                            {errors.split("\n").map((error, idx) => (
                                <p key={idx}>{error}</p>
                            ))}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    
    return result;
}