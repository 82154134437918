import * as React from "react";
import {
    FormWithRedirect,
    ReferenceInput,
    SelectInput,
    TextInput,
    SaveButton,
    DeleteButton,
    AutocompleteInput
} from 'react-admin';
import { parse } from 'query-string';
import { Box, Toolbar, Card } from '@material-ui/core';
import { validations, validateWholeRecord } from "./validations";
import { perPageReferenceFields } from "../../constants";
import EntityName from "../../components/EntitiesForm/EntityName";
import EntityIdkey from "../../components/EntitiesForm/EntityIdkey";
import EntityDate from "../../components/EntitiesForm/EntityDate";
import EntityPredicate from "../../components/EntitiesForm/EntityPredicate";

const EntitiesInfoCustomForm = props => {
    const entity_info = props.location.state.entity_info;

    const divStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%'
    };

    // Read the entity_id from the location which is injected by React Router and passed to our component by react-admin automatically
    const { entity_id: entity_id_string } = parse(props.location.search);

    const entity_id = entity_id_string ? parseInt(entity_id_string, 10) : '';

    const redirect = entity_id ? `/entities/${entity_id}/${entity_info}` : false;

    return (
        <FormWithRedirect
            {...props}
            redirect={redirect} defaultValue={{ entity: entity_id }} validate={validateWholeRecord}
            render={formProps => (
                <Card>
                <form>
                    { entity_info === 'names' && 
                        <EntityName {...formProps} />
                    }
                    { entity_info === 'idkeys' &&
                        <EntityIdkey {...formProps} />
                    }
                    { entity_info === 'dates' &&
                        <EntityDate {...formProps} />
                    }
                    { entity_info === 'predicates' &&
                        <EntityPredicate {...formProps} />
                    }
                    <Toolbar>
                        <Box display="flex" justifyContent="space-between" width="100%">
                            <SaveButton
                                saving={formProps.saving}
                                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                            />
                        </Box>
                    </Toolbar>
                </form>
                </Card>
            )}
        />
    )
}


export default EntitiesInfoCustomForm;