import {
    required,
    maxLength
} from 'react-admin';

export const validations = {
    validatePredicate: [required(), maxLength(5)],
    validateEntityPredicateType: [required()],
    validateActive: [required()],
    validateInsertSource: [required()]
}