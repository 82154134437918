import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const Transactions = (props) => {
    return (
        <div>
            <MappingSectionInput field='itemsAR' required={true} {...props}/>
            <MappingSectionInput field='type' required={true} {...props}/>
            <MappingSectionInput field='id' {...props}/>
            <MappingSectionInput field='reference' {...props}/>
            <MappingSectionInput field='currencyCode' required={true} {...props}/>
            <MappingSectionInput field='value' required={true} {...props}/>
            <MappingSectionInput field='rating' required={true} {...props}/>
            <MappingSectionInput field='languageCode' {...props}/>
            <MappingSectionInput field='relatedParty' {...props}/>
            <MappingSectionInput field='remarks' {...props}/>
            <MappingSectionInput field='start' required={true} {...props}/>
            <MappingSectionInput field='update' {...props}/>
            <MappingSectionInput field='end' {...props}/>
            <MappingSectionInput field='active' required={true} {...props}/>
            <MappingSectionInput field='validated' required={true} {...props}/>
        </div>
    );  
};

export default Transactions;