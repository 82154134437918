import React from 'react';
import { Edit } from 'react-admin';
import { parse } from 'query-string';
import { StepTransitionCustomForm } from "./stepTransitionCustomForm";
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const ProcessStepTransitionEdit = props => {
    return (
        <>
            <BackButton {...props} action = {"edit"}/>
            <PageTitle {...props}>Edit</PageTitle>
            <Edit {...props}>
                <StepTransitionCustomForm {...props} showDelete={true} />
            </Edit>
        </>
    )
};

export default ProcessStepTransitionEdit;