import React, { useEffect, useState } from "react";
import {
  SelectInput,
  TextInput,
  AutocompleteArrayInput,
  BooleanInput,
  SimpleFormIterator,
  ArrayInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import "../../index.css";
import { validations } from "./validations";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-final-form";

const MappingInput = ({
  sourceName,
  labelName,
  tab,
  types,
  dataEnrichmentSections,
  functions,
  functionsByType,
  required,
  record,
  ...props
}) => {
  const form = useForm();
  let field = sourceName;
  const label = labelName;
  const location = props.source;
  const [functionChoices, setFunctionChoices] = useState(functions);

  let valueOnRecord = false;
  let typeSelectedFlag = "";
  let typeOnRecord = false;

  if (location.includes("filing.contacts")) {
    valueOnRecord = record?.[field]?.value ? true : false;
    typeSelectedFlag = record?.[field]?.type ? true : false;
    typeOnRecord = record?.[field]?.type ? record[field].type : "";
  }

  const [isTypeSelected, setIsTypeSelected] = useState(typeSelectedFlag);
  const [valueExists, setValueExists] = useState(valueOnRecord);
  const [typeSelected, setTypeSelected] = useState(typeOnRecord);
  const [showFunctions, setShowFunctions] = useState(isTypeSelected);

  useEffect(() => {
    if (location === "") {
      setValueExists(record?.[field]?.["value"] ? true : false);
      setIsTypeSelected(record?.[field]?.["type"] ? true : false);
      setTypeSelected(record?.[field]?.["type"] ? record[field]["type"] : "");
    } else if (location === "entities") {
      setValueExists(record.entities?.[field]?.["value"] ? true : false);
      setIsTypeSelected(record.entities?.[field]?.["type"] ? true : false);
      setTypeSelected(
        record.entities?.[field]?.["type"] ? record.entities[field]["type"] : ""
      );
    } else if (
      location.includes("filing") &&
      !location.includes("filing.contacts")
    ) {
      setValueExists(record?.filing?.[field]?.value ? true : false);
      setIsTypeSelected(record?.filing?.[field]?.type ? true : false);
      setTypeSelected(
        record?.filing?.[field]?.type ? record.filing[field].type : ""
      );
    }
  }, [location, record]);

  useEffect(() => {
    if (typeSelected && functionsByType) {
      setFunctionChoices(
        functionsByType.filter((pair) => pair.type === typeSelected)
      );
    }
  }, [typeSelected]);

  useEffect(() => {
    isTypeSelected && Array.isArray(functionChoices) && functionChoices.length
      ? setShowFunctions(true)
      : setShowFunctions(false);
  }, [isTypeSelected, functionChoices]);

  const handleTypeChange = (event) => {
    // change type -> take functions from form
    tab !== undefined
      ? form.change(`${props.source}.${field}.function`, undefined)
      : form.change(`${field}.function`, undefined);

    setValueExists(true);
    setIsTypeSelected(true);
    setTypeSelected(event.target.value);
  };

  if (record) {
    if (tab !== undefined && tab in record) {
      if (field in record[tab]) {
        if (valueExists) {
          record[tab][field]["required"] = required ? "true" : "false";
          if (!("function" in record[tab][field]))
            record[tab][field]["function"] = null;
        }
      }
    } else {
      if (field in record) {
        if (valueExists) {
          record[field]["required"] = required ? "true" : "false";
          if (!("function" in record[field])) record[field]["function"] = null;
        }
      }
    }
  }

  const resetFormValues = (form, source, record) => {
    setValueExists(false);
    setIsTypeSelected(false);
    setTypeSelected("");
    setFunctionChoices(functions);
    form.change(`${source}`, undefined);
    let tabField = source.split(".");
    let tab = "";
    let field = "";
    if (tabField.length == 2) {
      tab = tabField[0];
      field = tabField[1];
      if (tab in record) delete record[tab][field];
    } else {
      field = tabField[0];
      delete record[field];
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      <div style={{ width: "200px", marginRight: "10px", textAlign: "right" }}>
        <span>{label || field.charAt(0).toUpperCase() + field.slice(1)}:</span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {sourceName === "dataEnrichment" ? (
          <div style={{ marginRight: "10px", flexGrow: 1 }}>
            <ArrayInput
              source={`${props.source}.${field}`}
              label={""}
            >

              <SimpleFormIterator>

                <AutocompleteInput
                  {...props}
                  label="Section"
                  source={`${props.source}.${field}.value`}
                  optionText="name"
                  optionValue="name"
                  choices={dataEnrichmentSections}
                  fullWidth={true}
                  validate={validations.validateRequired}
                />
                <SelectInput
                  {...props}
                  label="Type"
                  source={`${props.source}.${field}.type`}
                  optionText="label"
                  optionValue="label"
                  defaultValue="Object"
                  fullWidth={true}
                  validate={validations.validateRequired}
                  style={{ display: 'none' }}
                />
                <ReferenceInput source={`${props.source}.${field}.languageCode`} label='Language' reference='enumerations-language-codes' allowEmpty={true}>
                  <SelectInput
                    {...props}
                    label="LanguageCode"
                    optionText="label"
                    optionValue="code"
                    fullWidth={true}
                    validate={validations.validateRequired}
                  />
                </ReferenceInput>
              </SimpleFormIterator>
            </ArrayInput>

          </div>
        ) : (<>
          <div style={{ marginRight: "10px", flexGrow: 1 }}>
            <SelectInput
              {...props}
              label="Type"
              source={`${props.source}.${field}.type`}
              optionText="label"
              optionValue="label"
              choices={types}
              // allowEmpty
              fullWidth={true}
              validate={required === true ? validations.validateRequired : null}
              onChange={handleTypeChange}
            />
          </div>
          <div
            style={{
              flexGrow: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
            {...props}
          >
            {showFunctions && (
              <div
                style={{
                  marginRight: "10px",
                  flexGrow: 4,
                  width: "175px",
                }}
              >
                <AutocompleteArrayInput
                  label="Functions"
                  source={`${props.source}.${field}.function`}
                  choices={functionChoices}
                  className="autocomplete-array-input"
                  disabled={!isTypeSelected && !valueExists}
                  fullWidth={true}
                  suggestionLimit={50}
                />
              </div>
            )}
            <div style={{ flexGrow: 10 }}>
              {typeSelected !== "PlainBoolean" ? (
                <TextInput
                  source={`${props.source}.${field}.value`}
                  fullWidth={true}
                  label="Value"
                  allowEmpty
                  validate={
                    required || isTypeSelected
                      ? validations.validateRequired
                      : null
                  }
                  disabled={!isTypeSelected && !valueExists}
                />
              ) : (
                <BooleanInput
                  source={`${props.source}.${field}.value`}
                  fullWidth={true}
                  label=""
                  allowEmpty
                  validate={
                    required || isTypeSelected
                      ? validations.validateRequired
                      : null
                  }
                  disabled={!isTypeSelected && !valueExists}
                />
              )}
            </div>
          </div>
        </>)}
      </div>

      <div style={{ marginRight: "10px", marginLeft: "10px", width: "50px" }}>
        {(valueExists || isTypeSelected) && (
          <Button
            onClick={() => {
              resetFormValues(
                form,
                tab !== undefined ? `${props.source}.${field}` : `${field}`,
                record,
                props
              );
            }}
            startIcon={<CloseIcon />}
            style={{
              boxSizing: "border-box",
              /* Shadow/xs */
              borderRadius: "8px",
              color: "#0000008a",
              /* Inside auto layout */
              fontSize: "85%",
              fontWeight: "bold",
              textTransform: "none",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MappingInput;
