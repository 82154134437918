import React, {
    useState,
} from 'react';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";


const CustomDialog = (
        {   
            showDialog,
            dialogContentMessage,
            dialogContent
        }
    ) => {

    return (
        <Dialog open={showDialog}>
            <DialogContent>
                {dialogContentMessage &&
                    <DialogContentText>
                        {dialogContentMessage}
                    </DialogContentText>
                } 
                {dialogContent}
            </DialogContent>
        </Dialog>
    );
}

export default CustomDialog;