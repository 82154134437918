import React from 'react';
import { Create, SimpleForm, TextInput, TextField, ReferenceInput, SelectInput, BooleanInput, useDataProvider, Loading, Error, useCreateController, AutocompleteInput} from 'react-admin';
import { perPageReferenceFields } from '../../constants';
import { validations } from './validations';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const SourceCredentialsCreate = props => {

    return (
        <>
            <Create undoable={true} {...props}>
                <SimpleForm redirect="list">
                    <BackButton {...props} action = {"create"} />
                    <PageTitle {...props}>Create</PageTitle>
                    <ReferenceInput label="Select Source" source="source" reference="source-codes" perPage={perPageReferenceFields} validate={validations.validateCode} >
                        <AutocompleteInput optionText="code" optionValue="id" />
                    </ReferenceInput>
                    <ReferenceInput label="Select Login Type" source="login_type" reference="login-types" validate={validations.validateLoginType} perPage={perPageReferenceFields} validate={validations.validateLoginType} >
                        <SelectInput optionText="description" optionValue="id" />
                    </ReferenceInput>
                    <TextInput label='Insert Username' source="username" fullWidth={true} validate={validations.validateUsername} />
                    <TextInput type='password' label='Insert Password' source="password" fullWidth={true} validate={validations.validatePassword} />
                    <ReferenceInput label='Select Idkey Code' source='idkey_code' reference='enumerations-idkey-codes' validate={validations.validateIdkeyCode} perPage={perPageReferenceFields} >
                        <SelectInput optionText="label"  />
                    </ReferenceInput>
                    <ReferenceInput label="Script" source="extraction_service_script" reference="extraction-service-script" perPage={perPageReferenceFields} 
                            allowEmpty>
                        <SelectInput optionText="name" optionValue="id"/>
                    </ReferenceInput>
                    <TextInput source="url" fullWidth={true} validate={validations.validateUrl} />
                </SimpleForm>
            </Create>
        </>
    );
}

export default SourceCredentialsCreate;