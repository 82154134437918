import React from 'react';
import { Edit, TextInput, SelectInput, SimpleForm, required, ReferenceInput, AutocompleteInput, useNotify, useRedirect } from 'react-admin';
import { qualityLevelsOptions } from '../../constants';
import Box from "@material-ui/core/Box";

const SourceSectionQualityLevelsEdit = props => {
    const link = `/source-section-quality-levels?source_id=${props.source_id}&source_code=${props.source_code}`

    return (
        <Edit {...props}>
            <SimpleForm sx={{ maxWidth: 500 }} redirect={link} >
                <tr>
                    <td>
                        <ReferenceInput source="section_code" reference="enumerations-sections" fullWidth={true} validate={required()}>
                            <AutocompleteInput optionText="label" />
                        </ReferenceInput>
                    </td>
                    <td>
                        <ReferenceInput source="entity_relation" reference="enumerations-entity-relations" fullWidth={true} validate={required()}>
                            <AutocompleteInput optionText="label" />
                        </ReferenceInput>
                    </td>
                    <td>
                        <SelectInput source="quality_level" fullWidth={true} choices={qualityLevelsOptions} optionText="text" optionValue="value" validate={required()} />
                    </td>
                </tr>
                
            </SimpleForm>
        </Edit>
    );
};

export default SourceSectionQualityLevelsEdit;