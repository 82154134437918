import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const Events = (props) => {

    return (
        <div>
            <MappingSectionInput field='textAR' required={true} {...props}/>
            <MappingSectionInput field='typeAR' required={true} {...props}/>
            <MappingSectionInput field='languageCode' {...props}/>
            <MappingSectionInput field='eventType' {...props}/>
            <MappingSectionInput field='date' {...props}/>
            <MappingSectionInput field='active' required={true} {...props}/>
            <MappingSectionInput field='validated' required={true} {...props}/>
        </div>
    );  
};

export default Events;