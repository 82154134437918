import { makeStyles, withStyles} from "@material-ui/core/styles";

const useButtonStyles = makeStyles({
    complete: {
      backgroundColor: "green",
      fontWeight: "bold",
      color: "white",
      '&:hover': {
        background: "#32521B",
      }
    },
    refresh: {
      backgroundColor: "#F29C11",
      fontWeight: "bold",
      color: "white",
      '&:hover': {
        background: "#BA7911",
      }
    },
    popUp: {
      backgroundColor: "#41DBDB",
      fontWeight: "bold",
      color: "white",
      '&:hover': {
        background: "#39C0C0",
      }
    },
    edit: {
      backgroundColor: "#2173E2",
      fontWeight: "bold",
      color: "white",
      '&:hover': {
        background: "#1D65C6",
      }
    }
  });

export default useButtonStyles;