import {maxLength, required, } from 'react-admin';

export const validations = {
  validateLabel: [required(), maxLength(200)],
  validatePrimaryValue: [required(), maxLength(200)],
  validateValue: [maxLength(200)],
  validateFile: [required()],
  validateSelection: [required()],
  validatePropName: [required(), maxLength(100)]
};
