import React from 'react';
import { Edit, TextInput, SelectInput, SimpleForm, required, ReferenceInput, AutocompleteInput, useNotify, useRedirect } from 'react-admin';
import { credentialTypes, credentialLocations } from '../../constants';
import Box from "@material-ui/core/Box";
import {validations} from './validations';

const SupplierProductRequestsCredentialsEdit = props => {

    return (

        <Edit {...props}>
            <SimpleForm sx={{ maxWidth: 500 }} redirect={false} >
                <tr>
                    <td>
                        <TextInput source="key" validate={validations.validateKey} />
                    </td>
                    <td>
                        <TextInput source="value" validate={validations.validateValue} />
                    </td>
                    <td>
                        <SelectInput label='Credential Type' source="credential_type" choices={credentialTypes} optionText="text" optionValue="value" isRequired fullWidth validate={validations.validateCredentialType} />
                    </td>
                    <td>
                        <SelectInput label="Credential Location" source="credential_location" choices={credentialLocations} optionText="text" optionValue="value "isRequired fullWidth validate={validations.validateCredentialLocation} />
                    </td>
                </tr>
            </SimpleForm>
        </Edit>
    );
}

export default SupplierProductRequestsCredentialsEdit;