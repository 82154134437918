import React, {useState, useEffect} from 'react';
import {Datagrid, List, TextField, SimpleForm, ArrayField, SelectInput, sanitizeListRestProps,
  DateField, BooleanField, TopToolbar, Loading, Button, ListBase, Title, ListToolbar, Pagination} from 'react-admin';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import { Api } from "../../helpers/api";
import {JSONViewer} from 'react-json-editor-viewer';
import { Drawer, CircularProgress } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import CompareArrowsOutlinedIcon from '@material-ui/icons/CompareArrowsOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles, withStyles} from "@material-ui/core/styles";
import ButtonWithDialog from "../../components/ButtonWithDialog";
import {
  useNotify,
  useRedirect,
  useRefresh
} from 'ra-core';
import {parse} from 'query-string';
import EntitiesEditor from './EntitiesEditor';
import EntitiesFilter from './EntitiesFilter';
import ShowEntityInfo from './ShowEntityInfo';
import TableComponent from '../../components/TableComponent';
import CustomDialog from '../../components/CustomDialog';
import SuccessCheck from "../../static/icons/SuccessCheck.png";
import {Card} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';


const useButtonStyles = makeStyles({
  complete: {
    backgroundColor: "green",
    fontWeight: "bold",
    color: "white",
    '&:hover': {
      background: "#32521B",
    }
  },
  iterator: {
    backgroundColor: "#F9A653",
    fontWeight: "bold",
    color: "white",
    '&:hover': {
      background: "#DA9149",
    }
  },
  delete: {
    backgroundColor: "#F84B4B",
    fontWeight: "bold",
    color: "white",
    '&:hover': {
      background: "#D94242",
    }
  },
  skip: {
    backgroundColor: "#F29C11",
    fontWeight: "bold",
    color: "white",
    '&:hover': {
      background: "#BA7911",
    }
  },
  popUp: {
    backgroundColor: "#41DBDB",
    fontWeight: "bold",
    color: "white",
    '&:hover': {
      background: "#39C0C0",
    }
  },
  match: {
    backgroundColor: "green",
    fontWeight: "bold",
    color: "white",
    '&:hover': {
      background: "#32521B",
    }
  },
  row: {
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  edit: {
    backgroundColor: "#2173E2",
    fontWeight: "bold",
    color: "white",
    '&:hover': {
      background: "#1D65C6",
    }
  }
});

const EntitiesList = props => {
  const [loading, setLoading] = useState(true);
  const [entityChoices, setEntityChoices] = useState(null);
  const [choiceIndex, setChoiceIndex] = useState(1);
  const [documentId, setDocumentId] = useState(null);
  const [documentEntityIndex, setDocumentEntityIndex] = useState(null);
  const [documentLabel, setDocumentLabel] = useState(null);
  const [selectedEntityForMatch, setSelectedEntityForMatch] = useState(null);
  const [documentEntityId, setDocumentEntityId] = useState(null);
  const [matchingCandidates, setMatchingCandidates] = useState([]);
  const [task, setTask] = useState(null);
  const [candidatesFilter, setCandidatesFilter] = useState("");
  const [matchResponse, setMatchResponse] = useState({});
  const [showMatchResponse, setShowMatchResponse] = useState(false);
  const [batchId, setBatchId] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [entityInfo, setEntityInfo] = useState([[]]);
  const [recordSelected, setRecordSelected] = useState(null);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogLoadingMessage, setDialogLoadingMessage] = useState("");
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [resetFilters, setResetFilters] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = useButtonStyles();
  let params = props.location.search;

  const getTaskByBatch = (batchId) => {
    return Api.get(`/tasks/get-task-by-batch?batch_id=${batchId}`, true)
      .then(response => {
        if (response.type === "success") {
          setTaskId(response.result);
        }
      })
  }
  
  const getEntityChoicesPerBatch = (params, firstLoad=false) => {
      Api.get(`/import-documents/get-entity-choices?batch_id=${params}`, true)
      .then((response) => {
          if (response.type === 'success'){
              setShowMatchResponse(false);
              setMatchResponse({});
              setEntityChoices(response.result);
              checkEntitiesToMatch(response.result, firstLoad);
          }
          else{
            notify('Bad request');
          }
      })
  }

  const checkEntitiesToMatch = (entitiesToMatch, firstLoad=false) => {
    if (entitiesToMatch.length === 0){
      completeTask();
    }
    else {
      getNextEntity(entitiesToMatch, false, false, firstLoad);
    }
  }


  const completeTask = (dialogLoadMessage="No more entities to match, completing task...") => {
    setDialogSuccess(false);
    setDialogLoadingMessage(dialogLoadMessage);
    setDialogLoading(true);
    return Api.post(`/tasks/${taskId}/finish/`, {}, true)
        .then(response => {
            if (response.type === "success") {
              setDialogSuccess(true);
              notify('Task finished');
              redirect('/tasks');
              refresh();
            } 
            else if (response.type === "auth_error") {
              console.log("Error completing the Task - authentication error");
              redirect('/login');
            }
            else {
                console.log("Error completing the Task - response", response.type);
                if (response.error) {
                  notify(response.error, 'warning');    
                }
                else {
                  notify("Could not complete the Task!", 'warning');
                }
            }
        })
        .catch((e) => {
          console.log("Exception completing the Task - e", e);
          notify('Error completing the Task', 'warning')
        })
  };

  const getEntityInfo = (documentId, entityIndex, label) => {
    Api.get(`/import-documents/get-entity-info?document_id=${documentId}&entity_index=${entityIndex}`, true)
    .then((response) => {
      if (response.type === 'success'){
          setDocumentId(documentId);
          setDocumentEntityIndex(entityIndex);
          setDocumentLabel(label);
          setEntityInfo(response.result);
          let candidates = response.result.matchingCandidates;
          if (candidates === undefined || candidates.length === 0) {
            candidates = [];
            if (documentEntityId !== null) {
              candidates = [documentEntityId];
            }
          }
          setMatchingCandidates(candidates);
          setCandidatesFilter(candidates.join(','));
          setResetFilters(true);
          setSelectedEntityForMatch(null);
          setDialogLoading(false);
          setLoading(false);
          setResetFilters(false);
      }
      else{
        notify('Bad request');
      }
    })
  }

  const deleteEntityFromDoc = () => {
    setDialogSuccess(false);
    setDialogLoadingMessage("Deleting entity from doc..");
    setDialogLoading(true);
    let data = {'document_id': documentId, 'entity_index': documentEntityIndex};
    Api.delete('/import-documents/delete-entity-from-document', data, true)
    .then((response) => {
      if (response.type === 'success'){
        setDialogSuccess(true);
        notify("Entity deleted from document!");
        getEntityChoicesPerBatch(batchId, true);
      }
      else{
        notify('Bad request');
      }
    })
  }

  const skipEntity = () => {
    setDialogSuccess(false);
    setDialogLoadingMessage("Skipping entity..");
    setDialogLoading(true);
    let data = {'document_id': documentId};
    Api.post('/import-documents/skip-entity/', data, true)
    .then((response) => {
      if (response.type === 'success'){
        setDialogSuccess(true);
        notify("Entity deleted from document!");
        getEntityChoicesPerBatch(batchId, true);
      }
      else{
        notify('Bad request');
      }
    })
  }

  const matchWithEntity = (selectedEntityForMatch) => {
    handleEntityForMatchChange(selectedEntityForMatch);
    setDialogLoadingMessage(`Matching document entity with entity ${selectedEntityForMatch}..`);
    setDialogLoading(true);
    let data = {'document_id': documentId, 'document_entity_index': documentEntityIndex, 'entity_id': selectedEntityForMatch};
    Api.post('/entities/match-with-entity/', data, true)
    .then((response) => {
      if (response.type === 'success'){
        getMatchResponse();
      }
      else{
        notify('Bad request');
      }
    })
  }

  const getMatchResponse = () => {
    Api.get(`/import-documents/get-match-response?document_id=${documentId}&entity_index=${documentEntityIndex}`, true)
    .then((response) => {
      if (response.type === "success") {
          setDialogSuccess(true);
          notify("Matched entity!")
          setTimeout(getEntityChoicesPerBatch(batchId), 2000);
      } else {
          setTimeout(getMatchResponse, 2000);
      }
    })
  }

  const getNextEntity = (entityChoices, showDialog=false, prev=false, firstLoad=false) => {
    if (showDialog) {
      setDialogSuccess(false);
      if (prev) {
        setDialogLoadingMessage("Getting previous entity..");
      }
      else {
        setDialogLoadingMessage("Getting next entity.."); 
      }
      setDialogLoading(true);
    }

    let newChoiceIndex = 0;
    let selectedEntity = {};
    if (!firstLoad){
      newChoiceIndex = choiceIndex + 1;
      if (prev) { newChoiceIndex = choiceIndex - 1; }
      if (typeof entityChoices[newChoiceIndex - 1] === 'undefined') {
        newChoiceIndex = 1;
      }
      selectedEntity = entityChoices[newChoiceIndex - 1];
      setChoiceIndex(newChoiceIndex);
    }
    else {
      newChoiceIndex = choiceIndex;
      selectedEntity = entityChoices[newChoiceIndex - 1];
    }

    getEntityInfo(selectedEntity.document, selectedEntity.entity_index, selectedEntity.label);
  }

  useEffect(() => {
      if (params.includes('batch_id')){
        setTask(true);
        const {batch_id : batch_id_string} = parse(params)
        setBatchId(batch_id_string);
        getTaskByBatch(batch_id_string);
        getEntityChoicesPerBatch(batch_id_string, true);
      }
      else {
        setLoading(false);
      }
  }, []);

  const handleEntityForMatchChange = (entityForMatch) => {
    setSelectedEntityForMatch(entityForMatch);
  }

  const handleClose = () => {
    setRecordSelected(null);
  }

  const CompleteTaskButton = () => {
    return  <ButtonWithDialog
              variant="contained" 
              color="secondary"
              className={classes.complete}
              endIcon={<CheckCircleIcon />}
              buttonLabel="Complete Task"
              dialogTitleMessage="Are you sure you want to complete this task?"
              dialogContentMessage={`Complete task ${taskId} for batch ${batchId}? You can't undo this`}
              confirmButtonLabel="Confirm"
              onConfirm={()=> completeTask("Completing task..")}
            />
  }

  const PrevEntityButton = () => {
    return <Button label="PREV ENTITY" variant="contained" disabled={!(entityChoices !== null && entityChoices.length > 1 && choiceIndex > 1)} startIcon={<ArrowBackIosIcon />} className={classes.iterator} onClick={() => getNextEntity(entityChoices, true, true)} />

  }

  const NextEntityButton = () => {
    return <Button label="NEXT ENTITY" variant="contained" disabled={!(entityChoices !== null && entityChoices.length > 1 && choiceIndex !== entityChoices.length)} endIcon={<ArrowForwardIosIcon />} className={classes.iterator} onClick={() => getNextEntity(entityChoices, true)} />
  }

  const DeleteEntityButton = () => {
    return <ButtonWithDialog
              variant="contained" 
              color="secondary"
              className={classes.delete}
              startIcon={<DeleteIcon />}
              buttonLabel="Delete"
              dialogTitleMessage="Are you sure you want to delete this entity from Doc?"
              dialogContentMessage={`Delete ${entityInfo["Main Name"]} - ${entityInfo["Country Code"]} from document? You can't undo this`}
              confirmButtonLabel="Delete"
              onConfirm={deleteEntityFromDoc}
              buttonLoadingLabel="Deleting..."
            />
  }

  const SkipButton = () => {
    return <ButtonWithDialog
              variant="contained" 
              color="secondary"
              className={classes.skip}
              buttonLabel="Skip"
              dialogTitleMessage="Are you sure you want to skip this entity?"
              dialogContentMessage={`The record will be skipped/moved.`}
              confirmButtonLabel="Skip"
              onConfirm={skipEntity}
              buttonLoadingLabel="Moving the record..."
            />
  }

  const ShowDocButton = () => {
    return <Button label="DOC" variant="contained" color="primary" startIcon={<VisibilityIcon />} className={classes.popUp} onClick={() => window.open(`/#/documents/${documentId}/show`, '_blank')} />
  }

  const DocEditButton = () => {
    return <Button label="EDIT" variant="contained" color="primary" startIcon={<EditIcon/>} className={classes.edit} onClick={() => window.open(`/#/document-edit?batch_id=${batchId}&document_id=${documentId}`)}  />
  }

  const AddressesButton = ({record}) => {
    return <Button label="Addresses" variant="contained" color="primary" className={classes.popUp} onClick={() => window.open(`/#/entity-addresses?entity_id=${record.id}`, '_blank')}/>
  }

  const MatchButton = ({record}) => {
    return <ButtonWithDialog 
            variant="contained" 
            color="primary" 
            className={classes.match}
            endIcon={<CompareArrowsOutlinedIcon />}
            buttonLabel="Match"
            dialogTitleMessage="Are you sure you to match the document entity with the selected entity?"
            dialogContentMessage={`Match ${entityInfo["Main Name"]} - ${entityInfo["Country Code"]}? You can't undo this.`}
            confirmButtonLabel="Match"
            onConfirm={() => matchWithEntity(record.id)}
            buttonLoadingLabel="Matching..."
          />
  }

  const IteratorButtonsToolbar = (props) => (
    <div style={{ display: "flex", justifyContent: "space-between"}}>
      <div
        style={{
          display: "flex",
          columnGap: "20px",
          justifyContent: "space-between",
          marginTop: '13px',
          marginLeft: "20px"
        }}
      >
        <PrevEntityButton  {...props} />
        <NextEntityButton {...props} />
      </div>
    </div>
  )

  const ButtonsToolbar = (props) => (
    <div style={{display: 'flex', width: '100%'}}>
      <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
        <div style={{display: "flex", width: 'inherit', height: 'fit-content', columnGap: "20px", marginTop: "20px", marginLeft: "10px"}}>
          <div style={{marginTop: "-15px"}}><h4>Pending entities: {entityChoices.length}</h4></div>
          <div style={{display:'flex'}}>
            <div>
              <PrevEntityButton {...props} />
            </div>
            <div style={{marginLeft: '10px'}}>
              <NextEntityButton {...props} />
            </div>
          </div>
        </div>
        <div style={{display: 'flex', width: 'inherit', height: 'fit-content', marginTop: '20px', justifyContent: 'flex-end'}}>
          <CompleteTaskButton {...props} />
        </div>
      </div>
    </div>
  )

  const StyledTableCell = withStyles((theme) => ({
    padding: "6px 24px 6px 16px"
  }))(TableCell);

  const DocEntityList = ({data}) => {
    return (
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="custom datagrid">
          <TableHead>
            <TableRow>
              <StyledTableCell>Main Name</StyledTableCell>
              <StyledTableCell>County Code</StyledTableCell>
              <StyledTableCell>Country Code</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Idkeys</StyledTableCell>
              <StyledTableCell>Import</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow key={item.id}>
                <StyledTableCell>{item["Main Name"]}</StyledTableCell>
                <StyledTableCell>{item["County Code"]}</StyledTableCell>
                <StyledTableCell>{item["Country Code"]}</StyledTableCell>
                <StyledTableCell>{item["Status"]}</StyledTableCell>
                <StyledTableCell>{item["Idkeys"]}</StyledTableCell>
                <StyledTableCell><ShowDocButton {...props} /></StyledTableCell>
                <StyledTableCell><DocEditButton {...props} /></StyledTableCell>
                <StyledTableCell><SkipButton {...props} /></StyledTableCell>
                <StyledTableCell><DeleteEntityButton {...props} /></StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
              
  }

  if (loading) { return <Loading />;}

  return (
    <ThemeProvider theme={theme}>
      { task && 
        <>
          <h2>Manual Entity Matching</h2>
          <ButtonsToolbar {...props} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ width: "100%" }}>
              {entityInfo !== undefined && <DocEntityList data={[entityInfo]} {...props} />}
            </div>
            <div style={{ width: "100%", marginTop: "10px" }}>
              <br />
              {matchingCandidates === undefined || matchingCandidates.length === 0 ? (<h3>Search</h3>) : (<h3>Matching Candidates</h3>)}
              <br />
              <List 
                toolbar={false}
                filters={matchingCandidates === undefined || matchingCandidates.length === 0 ? <EntitiesFilter {...props} mainName={entityInfo["Main Name"]} resetFilters={resetFilters} /> : false} 
                filter={{ matching_candidates: candidatesFilter}} 
                bulkActionButtons={false} 
                exporter={false}
                actions={false}
                {...props}
              >
                <Datagrid 
                  className={classes.row}
                  expand={<ShowEntityInfo {...props} />} 
                >
                  <TextField label="Main Name" source="main_name"/>
                  <TextField label="County Code" source="county_code" />
                  <TextField label="Country Code" source="country_code" />
                  <TextField label="Status" source="status" />
                  <TextField multiline source="idkeys" />
                  <AddressesButton {...props} />
                  <MatchButton {...props} />
                </Datagrid>
              </List>
              </div>
            </div>
          <CustomDialog {...props} showDialog={dialogLoading} dialogContent={dialogSuccess ? <img src={SuccessCheck} /> : <Loading loadingPrimary={false} loadingSecondary={dialogLoadingMessage} />} />
        </>
      }
      {!task && 
        <EntitiesEditor {...props} />
      }
      </ThemeProvider>
  );
}

export default EntitiesList;