import React from 'react';
import { List, Filter, AutocompleteInput, ReferenceInput, Datagrid, TextField, EditButton } from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import { perPageReferenceFields } from "../../constants";
import "../../index.css";
import { Link } from 'react-router-dom';
import ClearFiltersButton from "../../components/ClearFiltersButton";
import MigrateButton from "../../components/MigrateButton";

const SourceSectionQualityLevelsLinkField = ({record, location}) => {
    const link = `/source-section-quality-levels`
    console.log('CODE', record.code);
    return (
        <Link
        to={{
            pathname: link,
            search: `?source_id=${record.id}&source_code=${record.code}`,
        }}
        >
          Quality Levels
        </Link>
    )
}

const SourcesFilters = (props) => {
    return(
        <div>
            <ClearFiltersButton {...props} filters={{id: '', label: '', source_type_label: ''}}/>
            <Filter {...props}>
                <ReferenceInput label="Source Code" source="id" reference="source-codes" perPage={perPageReferenceFields} alwaysOn>
                    <AutocompleteInput optionText="code" />
                </ReferenceInput>
                <ReferenceInput label="Label" source="label" reference="source-code-labels" perPage={perPageReferenceFields} alwaysOn>
                    <AutocompleteInput optionText="label" optionValue="label" />
                </ReferenceInput>
                <ReferenceInput label="Source Type Label" source="source_type_label" reference="source-types" perPage={perPageReferenceFields} alwaysOn>
                    <AutocompleteInput optionText="labelEN"/>
                </ReferenceInput>
            </Filter>
        </div>
    );
}

const SourceList = (props) => (
  <>
    <h2>{props.options.label}</h2>
    <List
      filters={<SourcesFilters />}
      bulkActionButtons={false}
      exporter={false}
      {...props}
      actions={<ListToolbarActions showCreateButton={true} />}
    >
      <Datagrid>
        <TextField source="code" label="Source Code" />
        <TextField source="label" />
        <TextField source="source_type_label" label="Source Type Label" />
        <SourceSectionQualityLevelsLinkField
          location={props.history.location}
        />
        <MigrateButton
          {...props}
          resource="Sources"
          endpoint="/sources/migrate/"
        />
        <EditButton />
      </Datagrid>
    </List>
  </>
);

export default SourceList;