import List from './List';
import Create from './Create';
import Edit from './Edit';
import Icon from '@material-ui/icons/Place';

export default {
    list: List,
    icon: Icon,
    create: Create,
    edit: Edit
};