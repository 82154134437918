import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField, Toolbar } from 'react-admin';
import JobLinkField from "../../components/JobLinkField";
import CloseAlertButton from "../../components/CloseAlertButton";

const AlertShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <JobLinkField source="job" label="See Job details" />
            <br/>
            <ReferenceField label="Status" source="status" reference="alert-status" link={false}>
                <TextField source="title" />
            </ReferenceField>
            <ReferenceField label="Process" source="process_id" reference="processes" link={false}>
                <TextField source="title" />
            </ReferenceField>
            <ReferenceField label="Step" source="step_id" reference="steps" link={false}>
                <TextField source="title" />
            </ReferenceField>
            <TextField source="batch" />

            <TextField source="subject" />
            <TextField source="message" />
            <TextField source="created_at" />
            <TextField source="closed_at" />

            <Toolbar>
                <CloseAlertButton />
            </Toolbar>
        </SimpleShowLayout>
    </Show>
);

export default AlertShow;