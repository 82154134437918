import React from 'react';
import { Filter, List, Datagrid, TextField,  ReferenceManyField, AutocompleteInput, ReferenceInput, SimpleShowLayout } from 'react-admin';
import ClearFiltersButton from '../../../components/ClearFiltersButton';
import { perPageReferenceFields } from "../../../constants";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../../constants/styleOverride";
import ListToolbarActions from "../../../components/ListToolbarActions";

const PendingRecordsCountFilter = (props) => {
    return (
        <div>
            <ClearFiltersButton {...props} filters={{ source_id: '' }} />
            <Filter {...props}>
                <ReferenceInput label="Source" source="source_id" reference="source-codes" perPage={perPageReferenceFields} alwaysOn>
                    <AutocompleteInput optionText="code" optionValue="id" />
                </ReferenceInput>
            </Filter>
        </div>
    );
}

const TasksListField = ({record}) => {
    if (record["n_tasks"] === 0) {
      return null;
    }
    
    let link = `/#/tasks?filter={"job_process_id":${record.process_id},"title":"${record.task_title}","status":1}`;
    
    return (
        <a href={link} target="_blank">Show tasks</a>
    )
  }

  const OldTasksField = props => {
    return (
        <TextField source="old_tasks" style={{ color: props.record[props.source] !== 0 ? 'red' : 'inherit' }} {...props}/>
     
    );
  };

const ShowRecordsInfo = props => {
    return (
        <SimpleShowLayout {...props}>
            <ReferenceManyField {...props} fullWidth reference="statistics/records-count" perPage={perPageReferenceFields}
                label="Pending records and tasks per step" source="source_id" target="source_id"
                sort={false}
            >
                <Datagrid>
                    <TextField label="Process" source="process_title" />
                    <TextField label="Step" source="step_title" />
                    <TextField label="Nº of documents" source="n_records" />
                    <TextField label="Nº of tasks" source="n_tasks" />
                    <OldTasksField label="Nº of old tasks" source="old_tasks"/>
                    <TasksListField/>
                </Datagrid>
            </ReferenceManyField>
        </SimpleShowLayout>
    );
}

const PendingRecordsCount = props => {
    return (
        <ThemeProvider theme={theme}>
            <h2>{props.options.label}</h2>
            <List filters={<PendingRecordsCountFilter/>}
                bulkActionButtons={false} exporter={false} {...props}
                actions={<ListToolbarActions showCreateButton={false} />}
                pagination={false} perPage={100}>
                <Datagrid expand={<ShowRecordsInfo {...props} />} >
                    <TextField label="Source" source="source_code" />
                    <TextField label="Nº of documents" source="pending_records" />
                    <TextField label="Nº of tasks" source="pending_tasks" />
                    <OldTasksField label="Nº of old tasks" source="old_tasks"/>
                </Datagrid>
            </List>
        </ThemeProvider>
    );
}

export default PendingRecordsCount;