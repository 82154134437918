import {maxLength, required, } from 'react-admin';

export const validations = {
  validateDeliveryId: [required()],
  validatePropertyName: [required(), maxLength(100)],
  validateLabel: [required(), maxLength(200)],
  validateValue1: [required(), maxLength(200)],
  validateValue2: [maxLength(200)],
  validateValue3: [maxLength(200)],
  validateValue4: [maxLength(200)],
  validateValue5: [maxLength(200)],
};
