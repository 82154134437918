import React from 'react';
import { TextInput, BooleanInput, ReferenceInput, AutocompleteInput, DateInput } from 'react-admin';
import { validations } from './validations';

const Events = (props) => {
    console.log('PROPS');
    console.log(props);

    return (
        <div>
            { 'location' in props &&
                <div>
                    <TextInput source='textAR' fullWidth={true} label='Text AR' validate={validations.validateTextAR} />
                    <TextInput source='typeAR' label='Type AR' validate={validations.validateTypeAR} />
                </div>
            }
            <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes' validate={validations.validateLanguageCode}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput source='eventType' label='Event Type' reference='enumerations-event-types' allowEmpty={true}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <DateInput source='date' label='Date' />
            <BooleanInput source='active' defaultValue={true} label='Active?' validate={validations.validateActive} />
        </div>
    );
};

export default Events;