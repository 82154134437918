import React, { useState } from 'react';
import ListEntities from '../../components/ListEntities';
import ListErrors from '../../components/ListErrors';
import DocumentEditor from '../../components/DocumentEditor';
import Button from '@material-ui/core/Button';
import { ShowController, Datagrid, List, TopToolbar, EditButton, Show, ShowButton, ShowView, SimpleShowLayout, ReferenceField, TextField, UrlField } from 'react-admin';



const DocumentShow = props => {
    
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <DocumentEditor record={props.record} {...props} />
            </SimpleShowLayout>
        </Show>
    );
};

export default DocumentShow;