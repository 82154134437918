import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const Texts = (props) => {

    return (
        <div>
            <MappingSectionInput field='longTextAR' required={true} {...props}/>
            <MappingSectionInput field='languageCode' required={true} {...props}/>
            <MappingSectionInput field='translationCode' {...props}/>
            <MappingSectionInput field='longText' required={true} {...props}/>
            <MappingSectionInput field='start' {...props}/>
            <MappingSectionInput field='end' {...props}/>
            <MappingSectionInput field='active' required={true} {...props}/>
            <MappingSectionInput field='validated' required={true} {...props}/>
        </div>
    );  
};

export default Texts;