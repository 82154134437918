import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { useNotify, useRedirect, fetchStart, fetchEnd } from "ra-core";

import Button from "@material-ui/core/Button";
import { Confirm } from "react-admin";
import { Api } from "../../helpers/api";

const AbortJobButton = ({ record }) => {
  const dispatch = useDispatch();
  const [open, setOpen, loading] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();

  const handleClick = (e) => {
    setOpen(true);
    e.stopPropagation();
  };
  const handleDialogClose = (e) => {
    setOpen(false);
    e.stopPropagation();
  };

  const handleAbortJob = (e) => {
    dispatch(fetchStart()); // start the global loading indicator

    const body_data = {};

    return Api.post(`/jobs/${record.id}/abort/`, body_data, true)
      .then((response) => {
        if (response.type === "success") {
          notify("Job aborted");
          redirect("/jobs");
        } else if (response.type === "auth_error") {
          console.log("Error aborting the Job - authentication error");
          redirect("/login");
        } else {
          console.log("Error aborting the Job - response", response.type);
          if (response.error) {
            notify(response.error, "warning");
          } else {
            notify("Could not abort the Job!", "warning");
          }
        }
      })
      .catch((e) => {
        console.log("Exception aborting the Job - e", e);
        notify("Error aborting the Job", "warning");
      })
      .finally(() => {
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  };

  const dialogContent = (
    <Fragment>
      <div>Do you want to abort this Job?</div>
    </Fragment>
  );

  return (
    <Fragment>
      <Button
        label="Abort"
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleClick}
      >
        Abort Job
      </Button>
      <Confirm
        label="Confirm"
        isOpen={open}
        loading={loading}
        title="Please, confirm:"
        content={dialogContent}
        onConfirm={handleAbortJob}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

AbortJobButton.propTypes = {
  record: PropTypes.object,
};

export default AbortJobButton;
