import React from 'react';
import { TextInput, ArrayInput, ReferenceInput, AutocompleteInput, SelectInput, SimpleFormIterator, BooleanInput, DateInput } from 'react-admin';
import { validations } from './validations';

const StockExchanges = (props) => {
    console.log('PROPS');
    console.log(props);

    return (
        <div>
            { 'location' in props && 
                <TextInput source='marketAR' fullWidth={true} label='Market AR' validate={validations.validateMarketAR} />
            }
            <ReferenceInput source='countryCode' label='Country Code' allowEmpty={true} reference='enumerations-country-codes'>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput source='marketIdentifierCode' label='Market Identifier Code'  fullWidth={true} reference='enumerations-market-identifier-codes'>
                <AutocompleteInput optionText="label" optionValue="id" validate={validations.validateMarketIdentifierCode} />
            </ReferenceInput>
            <TextInput source='symbol' fullWidth={true} label='Symbol' validate={validations.validateSymbol} />
            <TextInput source='isin' fullWidth={true} label='ISIN' validate={validations.validateIsin} />
            <TextInput source='nsin' fullWidth={true} label='NSIN' validate={validations.validateNsin} />
            <BooleanInput source='main' defaultValue={true} label='Main?' validate={validations.validateMain} />
            <DateInput source='start' label='Start Date' />
            <DateInput source='end' label='End Date' />
            <BooleanInput source='active' defaultValue={true} label='Active?' validate={validations.validateActive} />
        </div>
    );
};

export default StockExchanges;