import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";
import { validations} from './validations';

const SourceEntityNameCreate = (props) => {

    console.log('props createa', props)
    const id = props.location.state.id
    console.log('id createa', id)
    return (
        <>
            <Create undoable={true} {...props}>
                <SimpleForm redirect={`/source-entities`}>
                    <BackButton {...props} action = {"create"}/>
                    <PageTitle {...props}>Create</PageTitle>
                    <TextInput disabled label="Source Entity ID" source="source_entity" defaultValue={id} />
                    <TextInput source="name" fullWidth={true} validate={validations.validateName}/>
                </SimpleForm>
            </Create>
        </>
    );
}

export default SourceEntityNameCreate;