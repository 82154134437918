import React from 'react';
import { TextField, Card, SelectField, TextInput, BooleanInput, ReferenceInput, AutocompleteInput, SelectInput, ArrayInput, SimpleFormIterator, NumberInput, DateInput } from 'react-admin';
import { Box, Toolbar } from '@material-ui/core';
import {validations} from './validations';


const EntityDate = (props) => {
    const location = props.location.pathname;

    return (
        <>
            <NumberInput locales='pt-PT' source="year" fullWidth={true} validate={validations.validateYear} />
            <NumberInput locales='pt-PT' source="month" fullWidth={true} validate={validations.validateMonth} />
            <NumberInput locales='pt-PT' source="day" fullWidth={true} validate={validations.validateDay} />
            <ReferenceInput label="Date type" source='entity_date_type' reference='enumerations-entity-date-types' validate={validations.validateEntityDateType} >
                <SelectInput optionText="label" />
            </ReferenceInput>
            { location.includes('create') &&
                <ReferenceInput label="Insert source" source="insert_source" reference="sources" validate={validations.validateInsertSource}>
                    <AutocompleteInput optionText="code" optionValue="id" />
                </ReferenceInput>
            }
        </>
    );
}


export default EntityDate;


