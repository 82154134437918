import React from 'react';
import { Filter, List, Datagrid, TextField, BooleanField, ReferenceInput, SelectInput, EditButton, ShowButton, ReferenceField } from 'react-admin';
import { perPageReferenceFields } from "../../../constants";

const UsersList = props => (
    <>
        <h2>{props.options.label}</h2>
        <List  sort={{ field: 'id', order: 'DESC' }}
                bulkActionButtons={false} exporter={false} {...props} perPage={perPageReferenceFields}>
            <Datagrid>
                <TextField label='Username' source="username" />
                <TextField label='First Name' source="first_name" />
                <TextField label='Last Name' source="last_name" />
                <BooleanField label='Active' source='active' />
                <BooleanField label='Super User' source='super_user' />
                <TextField label='Account' source='account_name' />
                <EditButton />
            </Datagrid>
        </List>
    </>
);

export default UsersList;