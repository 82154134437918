import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import Tooltip from '@material-ui/core/Tooltip';
import { useNotify, useRedirect } from 'ra-core';
import { AuthHelper } from "../../helpers/authHelper";
import { Api } from "../../helpers/api";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        cursor: "pointer",
    },
}));



const NotificationsErrorAlerts = props => {
    const classes = useStyles();
    const [numNotifications, setNumNotifications] = useState(0);

    const getNumberNotifications = () => { 
        return Api.get(`/alerts/open-alerts/`, true).then(response => {
            if (response.type === "success") {
                setNumNotifications(response.result.no_alerts);
            }
        });
    }

    useEffect(() => {
        if (numNotifications === 0){
            getNumberNotifications();
        }
        const interval = setInterval(() => {
            getNumberNotifications();
        }, 180000);
        
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  
      }, []);

    if (numNotifications>0){
        
        return (
            <div className={classes.root}>
                {props.title}<b><font color='red'>({numNotifications})</font></b>
            </div>
        );
    }
    return(
        <div className={classes.root}>
            {props.title}
        </div>
    );

    
};

export default NotificationsErrorAlerts;