import React from 'react';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import { Edit, SimpleForm, TextField, BooleanInput, TextInput, ReferenceInput, SelectInput } from 'react-admin';
import { perPageReferenceFields } from '../../constants';
import { validations } from './validations';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const SourceFilesConfigurationsEdit = props => {

    return (
        <>
            <Edit undoable={true} {...props}>   
                <SimpleForm toolbar={<EditFormToolbarActions showDeleteButton={false}/>}>
                    <BackButton {...props} action = {"edit"}/>
                    <PageTitle {...props}>Edit</PageTitle>
                    <TextField label='Source' source='source_code' />
                    <TextInput label='Insert filename pattern' source="regex" fullWidth={true} validate={validations.validateFilenamePattern} />
                    <BooleanInput source="extract" initialValue={true} label="Extract?" />
                    <TextInput label='Insert content pattern' source="content" fullWidth={true}/>
                    <ReferenceInput label="Select language" source="language" reference="enumerations-language-codes" validate={validations.validateLanguage} perPage={perPageReferenceFields}>
                        <SelectInput optionText="label" optionValue="id" />
                    </ReferenceInput>
                    <TextInput label='Insert report label' source="report_label" fullWidth={true} />
                    <TextInput label='Insert year serial' source="year_serial" fullWidth={true} />
                </SimpleForm>
            </Edit>
        </>
    );
}

export default SourceFilesConfigurationsEdit;