import React from 'react';
import { List, SimpleForm, AutocompleteInput, SelectInput, ReferenceInput, TextInput, BooleanInput, DateInput } from 'react-admin';
import { validations } from './validations';

const PersonTypes = (props) => (
    
        <div>
            { 'location' in props &&
                <TextInput source='textAR' label='Text AR' validate={validations.validateTextAR} />
            }
            <ReferenceInput source='type' label='Type' reference='enumerations-person-types' allowEmpty={true} >
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <div>
                <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes' allowEmpty={true} >
                    <AutocompleteInput optionText="label" optionValue="id" />
                </ReferenceInput>
                <ReferenceInput source='translationCode' label='Translation Code' reference='enumerations-translation-codes' allowEmpty={true} >
                    <SelectInput optionText="label" optionValue="id" />
                </ReferenceInput>
            </div>
            <div>
                <DateInput source='start' label='Start Date' />
                <DateInput source='end' label='End Date' />
            </div>
            <BooleanInput source='active' initialValue={true} label='Active?' validate={validations.validateActive} />
        </div>
            
)

export default PersonTypes;