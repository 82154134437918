import React, { useEffect, useState } from "react";
import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
  SaveButton,
  SimpleForm
} from "react-admin";
import { Api } from "../../helpers/api";
import { JSONViewer } from "react-json-editor-viewer";
import PublishIcon from "@material-ui/icons/Publish";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { useNotify } from "ra-core";
import CircularProgress from "@material-ui/core/CircularProgress";

const GeocodingTester = ({ linkedSources, defaultSample, ...props }) => {
  const [rawFile, setRawFile] = useState(defaultSample);
  const [docId, setDocId] = useState("");
  const [doc, setDoc] = useState();
  const [loading, setLoading] = useState(false);
  const [sampleTester, setSampleTester] = useState(!!defaultSample)
  const notify = useNotify();

  const parseText = (data) => {
    data.rawFile = rawFile;
    setLoading(true);
    Api.post("/geocoding-tester/geocode-file/", data).then((response) => {
      if (response.type === "success") {
        setDocId(response.result.doc_id);
      } else {
        notify("Bad Request");
      }
    });
  };

  useEffect(() => {
    if (
      sampleTester &&
      linkedSources &&
      linkedSources.length > 0
    ) {
      parseText({ source_code: linkedSources[0]["code"] });
      setSampleTester(false);
    }
  }, [sampleTester]);

  useEffect(() => {
    if (docId !== "") {
      waitResponse();
    }
  }, [docId]);

  const waitResponse = () => {
    Api.post("/geocoding-tester/wait-response/", { docId: docId }).then(
      (response) => {
        if (response.type === "success") {
          if (response.result.doc !== "") {
            setDoc(response.result.doc);
            setLoading(false);
          } else {
            setTimeout(waitResponse, 3000);
          }
        } else {
          notify("Document Id ".concat(docId).concat(" not found"));
        }
      }
    );
  };

  const handleChange = (e) => {
    setRawFile(e.target.value);
  };

  const ParseToolbar = ({ pristine, ...props }) => {
    return (
      <Toolbar {...props}>
        {!loading && (
          <SaveButton
            disabled={rawFile ? false : true}
            label="Parse"
            icon={<DoubleArrowIcon />}
          />
        )}
      </Toolbar>
    );
  };

  return (
    <>
      <h2>
        {props.resource === "geocoding-tester" ? props.options.label : undefined}
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ width: "45%" }}>
          <SimpleForm toolbar={<ParseToolbar alwaysEnable={true} />} save={parseText}>
            <ReferenceInput
              source='countryCode'
              label='Country'
              reference='enumerations-country-codes'
              fullWidth={true}
              allowEmpty={true}
            >
              <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <TextInput
              label="Address text"
              source="rawFile"
              value={rawFile}
              onChange={handleChange}
              fullWidth
              multiline
              rows="15"
              style={{ width: "100%" }}
            />
          </SimpleForm>
        </div>

        <div style={{ overflowY: "scroll", height: "650px", width: "45%" }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <JSONViewer data={doc} collapsible />
          )}
        </div>
      </div>
    </>
  );
};

export default GeocodingTester;
