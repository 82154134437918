import React, {
    Fragment, useState
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
    useNotify,
    useRedirect,
    fetchStart, fetchEnd,
    useRefresh,
    useUnselectAll
} from 'ra-core';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Api } from "../../helpers/api";


function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].slice(1);     
    }
    return splitStr.join(' '); 
}

const CompleteTaskButton = (props) => {
    console.log('props', props);
    let selected_ids = []
    let body = {}
    let item_id = ''
    let length = 0
    let item = ''
    const resource = props.resource

    const dispatch = useDispatch();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const unselect = useUnselectAll();
    const [open, setOpen] = useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);

    };


    if ("selectedIds" in props){
        selected_ids = props.selectedIds
        item = 'task'
        if (selected_ids.length > 0){
            console.log('selectedIds', selected_ids)
            item_id = selected_ids[0]
            body = {selected_ids: selected_ids}
            length = selected_ids.length
            if (selected_ids.length > 1){
                item = 'tasks'
            }
        }
    }
    else if (item_id == ''){
        item_id = props.record.id
        length = 1
        item = 'task'
        console.log('itemID', item_id)
    }

    const handleClick = e => {
        dispatch(fetchStart()); // start the global loading indicator 

        return Api.post(`/tasks/${item_id}/finish/`, body, true)
            .then(response => {
                if (response.type === "success") {
                    notify(response.result.message);
                    redirect('/tasks');
                    refresh();
                } 
                else if (response.type === "auth_error") {
                    console.log("Error completing the Task(s) - authentication error");
                    redirect('/login');
                }
                else {
                    console.log("Error completing the Task(s) - response", response.type);
                    if (response.error) {
                        notify(response.error, 'warning');    
                    }
                    else {
                        notify("Could not complete the Task(s)!", 'warning');
                    }
                }
            })
            .catch((e) => {
                console.log("Exception completing the Task(s) - e", e);
                notify('Error completing the Task', 'warning')
            })
            .finally(() => {
                dispatch(fetchEnd()); // stop the global loading indicator
            });

        e.stopPropagation();
    };

    return (
        <Fragment>
            <Button
                style={{
                    background: "#3248A8",
                    boxSizing: "border-box",
                    /* Shadow/xs */
                    border: "#FF0000",
                    borderRadius: "8px",
                    /* Inside auto layout */
                    color: "#FFFFFF",
                    fontSize: "85%",
                    fontWeight: "bold",
                    textTransform:"none",
                    bottom:"5px"
                }}
                onClick={handleClickOpen}
            >
                Complete {length} {titleCase(item)}
            </Button>
            <Dialog
                id = "dialog-box"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <span style={{"fontWeight": "bold"}}>Are you sure you want to complete this {item}?</span>
                </DialogTitle>
                <DialogContent>
                <DialogContentText 
                    id="alert-dialog-description"
                >
                    You are about to <span style={{"fontWeight": "bold"}}>complete</span> the {item} selected. You can't undo this.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    style={{
                        /* current_last */
                        background: "#F4F4F4",
                        /* Shadow/xs */
                        borderColor: "#F4F4F4",
                        borderRadius: "8px",
                        fontWeight: "bold",
                        fontSize: "85%",
                        textTransform:"none"
                    }}
                >
                    Cancel
                </Button>
                <Button 
                    onClick={() => {
                        handleClick();
                        unselect(resource);
                    }}
                    autoFocus
                    variant="outlined"
                    style={{
                        background: "#3248A8",
                        boxSizing: "border-box",
                        /* Shadow/xs */
                        borderRadius: "8px",
                        border: "#FF0000",
                        /* Inside auto layout */
                        color: "#FFFFFF",
                        fontSize: "85%",
                        fontWeight: "bold",
                        textTransform:"none"
                    }}
                >
                    Complete 
                </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

CompleteTaskButton.propTypes = {
    record: PropTypes.object,
};

export default CompleteTaskButton;