import React from 'react';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import {BooleanInput, DateInput, Edit, SimpleForm, TextInput, AutocompleteArrayInput, ReferenceArrayInput} from 'react-admin';
import {validations} from './validations';
import {perPageReferenceFields} from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const ProductEdit = props => {

  return (
    <>
      <Edit undoable={true} {...props}>
        <SimpleForm toolbar={<EditFormToolbarActions showDeleteButton={false}/>}>
          <BackButton {...props} action = {"edit"}/>
          <PageTitle {...props}>Edit</PageTitle>
          <TextInput source="code" fullWidth={true} validate={validations.validateCode}/>
          <TextInput source="label" fullWidth={true} validate={validations.validateLabel}/>
          <ReferenceArrayInput label='Processes' reference='processes' source='processes' perPage={perPageReferenceFields}>
            <AutocompleteArrayInput optionText='title' optionValue='id'/>
          </ReferenceArrayInput>
          <BooleanInput source="active" label="Is it active?"/>
          <DateInput source='start_date' validate={validations.validateStartDate}/>
          <DateInput source='end_date'/>
        </SimpleForm>
      </Edit>
    </>
  );
}

export default ProductEdit;
