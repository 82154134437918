import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const Hscs = (props) => {

    return (
        <div>
            <MappingSectionInput field='textAR' required={true} {...props}/>
            <MappingSectionInput field='hsCode' required={true} {...props}/>
            <MappingSectionInput field='label' required={true} {...props}/>
            <MappingSectionInput field='start' {...props}/>
            <MappingSectionInput field='end' {...props}/>
            <MappingSectionInput field='active' required={true} {...props}/>
            <MappingSectionInput field='validated' required={true} {...props}/>
        </div>
    );  
};

export default Hscs;