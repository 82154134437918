import React from 'react';
import { List, Filter, AutocompleteInput, ReferenceInput, Datagrid, TextField, EditButton } from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import { perPageReferenceFields } from "../../constants";
import "../../index.css";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import ClearFiltersButton from "../../components/ClearFiltersButton";

const SourceCredentialsFilters = (props) => {
    return(
        <div>
            <ClearFiltersButton {...props} filters={{id: ''}}/>
            <Filter {...props}>
                <ReferenceInput label="Source Code" source="id" reference="source-credentials" perPage={perPageReferenceFields} alwaysOn>
                    <AutocompleteInput optionText="source_code" />
                </ReferenceInput>
            </Filter>
        </div>
    );
}

const SourceCredentialsList = (props) => (
  <ThemeProvider theme={theme}>
    <h2>{props.options.label}</h2>
    <List
      filters={<SourceCredentialsFilters />}
      bulkActionButtons={<DocBulkActionButtons {...props} />}
      exporter={false}
      {...props}
      actions={<ListToolbarActions showCreateButton={true} />}
    >
      <Datagrid>
        <TextField source="source_code" label="Source Code" />
        <TextField source="source_label" />
        <TextField source="login_type_description" />
        <TextField label="Script" source="extraction_service_script_name" />
        <TextField source="url" />
        <EditButton />
      </Datagrid>
    </List>
  </ThemeProvider>
);

export default SourceCredentialsList;