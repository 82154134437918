import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';
import {ArrayInput, SimpleFormIterator} from "react-admin";


const Licenses = (props) => {
    let businessesSource = `${props.source}.businesses`

    return (
        <div>
            <MappingSectionInput field='textAR' required={true} {...props}/>
            <MappingSectionInput field='updateDate' required={true} {...props}/>
            <MappingSectionInput field='licensor' required={true} {...props}/>
            <MappingSectionInput field='number' required={true} {...props}/>
            <MappingSectionInput field='unique' required={true} {...props}/>
            <MappingSectionInput field='licenseStatus' required={true} {...props}/>
            <MappingSectionInput field='issuedDate' {...props}/>
            <MappingSectionInput field='renewedDate' {...props}/>
            <MappingSectionInput field='expiryDate' {...props}/>
            <MappingSectionInput field='cancelledDate' {...props}/>
            <ArrayInput
                source={businessesSource}
                label="Businesses"
            >
                <SimpleFormIterator>
                    <MappingSectionInput
                        list={true}
                        field="label"
                        types={props.types}
                        functions={props.functions}
                        listObject="businesses"
                        functionsByType={props.functionsByType}
                    />
                    <MappingSectionInput
                        list={true}
                        field="code"
                        types={props.types}
                        functions={props.functions}
                        listObject="businesses"
                        functionsByType={props.functionsByType}
                    />
                    <MappingSectionInput
                        list={true}
                        field="standard"
                        types={props.types}
                        functions={props.functions}
                        listObject="businesses"
                        functionsByType={props.functionsByType}
                    />
                    <MappingSectionInput
                        list={true}
                        field="languageCode"
                        types={props.types}
                        functions={props.functions}
                        listObject="businesses"
                        functionsByType={props.functionsByType}
                    />
                </SimpleFormIterator>
            </ArrayInput>
            <MappingSectionInput field='validated' required={true} {...props}/>
        </div>
    );  
};

export default Licenses;