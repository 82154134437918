import {JSONViewer} from 'react-json-editor-viewer';
import React, {PureComponent} from 'react';
import {
    List, ShowButton, Button, Show, Datagrid, TextField, SimpleShowLayout
} from 'react-admin'; 

class DocumentEditor extends React.Component{
    constructor(props){
        super(props);
        this.onJsonChange = this.onJsonChange.bind(this);
        this.state = {record: this.props.record};
    }
       
    onJsonChange(key, value, parent, data){
        console.log(key, value, parent, data);
    }
      
    
    render(){
        return (
        <JSONViewer
            data={this.state.record}
            collapsible
            onChange={this.onJsonChange}
        />);
    }
}

export default DocumentEditor;