import List from './List';
import Create from './Create';
import Edit from './Edit';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';

export default {
  list: List,
  create: Create,
  edit: Edit,
  icon: RateReviewOutlinedIcon,
};