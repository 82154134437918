import React, { useState, useEffect } from "react";
import { List, Datagrid, TextField, ReferenceField, Filter, ReferenceInput, AutocompleteInput, SimpleShowLayout, ReferenceManyField } from 'react-admin';
import CloseAlertButton from "../../components/CloseAlertButton";
import { perPageReferenceFields } from "../../constants";
import ClearFiltersButton from '../../components/ClearFiltersButton';
import ReExecuteAllStepsButton from '../../components/ReExecuteAllStepsButton';
import { userHasPermission } from "../../helpers/authProvider";


const AlertFilter = (props) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Filter {...props}>
                <ReferenceInput label="Process" source="process_id" reference="processes" perPage={perPageReferenceFields} alwaysOn>
                    <AutocompleteInput optionText="title" />
                </ReferenceInput>
            </Filter>
            <ClearFiltersButton {...props} filters={{ process_id: '' }} />
        </div>
    );
}

const LogsUrlField = ({ record, source }) => {
    const value = record && record[source];

    if (value == null) {
        return null;
    }

    return (
        <a href={`${value}`} target="_blank" >Search logs</a>
    );
};

const ShowJobAlerts = (props) => (
    <SimpleShowLayout {...props}>
        <ReferenceManyField {...props} fullWidth reference="alerts/job-alerts" label="Alerts" target="job_id" source="job"
        >
            <Datagrid>
                <ReferenceField label="Step" source="step_id" reference="steps" link={false}>
                    <TextField source="title" />
                </ReferenceField>
                <TextField source="batch" />
                <TextField label="Created at" source="created_at" />
                <LogsUrlField source="cloudwatch_url" />

                <CloseAlertButton />
            </Datagrid>
        </ReferenceManyField>
    </SimpleShowLayout>
);

const AlertList = (props) => {
    const [data, setData] = useState({});

    const GetAlertData = (props) => {
      useEffect(() => {
        if (
          (data.filters?.process_id !== props.filterValues?.process_id ||
            data.count !== props.total) &&
          props.loaded === true
        ) {
          setData({
            alerts_ids: props.ids,
            filters: props.filterValues,
            count: props.total,
          });
        }}, [props, data]);
        return false
    };    

    return (
      <div>
        <h2>{props.options.label}</h2>
        <List
          bulkActionButtons={false}
          exporter={false}
          actions={<GetAlertData {...props} />}
          filters={<AlertFilter {...props} />}
          filterDefaultValues={{ status_id: 1 }}
          sort={{ field: "id", order: "DESC" }}
          {...props}
        >
          <Datagrid expand={ShowJobAlerts}>
            <ReferenceField source="job" reference="jobs" link="show">
              <TextField source="id" />
            </ReferenceField>
            <ReferenceField
              label="Process"
              source="process_id"
              reference="processes"
              link={false}
            >
              <TextField source="title" />
            </ReferenceField>
            <TextField label="Last created at" source="created_at" />
          </Datagrid>
        </List>
        {userHasPermission("management") && (
          <ReExecuteAllStepsButton {...props} data={data} />
        )}
      </div>
    );
};

export default AlertList;