import React from 'react';
import { List, Filter, SelectInput, ReferenceInput, Datagrid, TextField, EditButton } from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import { perPageReferenceFields } from "../../constants";

const UserPermissionsList = props => (
    <List bulkActionButtons={false} exporter={false} {...props} 
            actions={<ListToolbarActions showCreateButton={true} />}>
        <Datagrid>
            <TextField label='Permission value' source='value'/>
            <TextField label='Permission label' source='label'/>
            <EditButton />
        </Datagrid>
    </List>
);

export default UserPermissionsList;