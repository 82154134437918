import React, {
    Fragment,
} from 'react';
import PropTypes from 'prop-types';
import CompleteTaskButton from './BulkCompleteTaskButton';

const TaskBulkActionButtons = props => {
    return (
        <Fragment {...props}>
            <CompleteTaskButton {...props} />
        </Fragment>
    ) 
};

TaskBulkActionButtons.propTypes = {
    record: PropTypes.object,
};

export default TaskBulkActionButtons;


