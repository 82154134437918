import React, { Fragment } from 'react';
import { Filter, List, Datagrid, TextField, DateField, SelectInput, ReferenceField, BooleanField, SearchInput, NullableBooleanInput } from 'react-admin';
import ClearFiltersButton from '../../components/ClearFiltersButton';
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import NoticesEdit from './Edit';
import { Api } from "../../helpers/api";
import {
    useNotify,
    useRedirect,
    useRefresh
} from 'ra-core';

import Button from '@material-ui/core/Button';

const data_types = [
    { id: "F", name: "financial" },
    { id: "X", name: "other" }
]


const NoticeFilter = (props) => {
    return (
        <div>
            <ClearFiltersButton {...props} filters={{}} />
            <Filter {...props}>
                <SearchInput source="job" placeholder="Job Id" resettable alwaysOn />
                <SearchInput source="batch_id" placeholder="Batch Id" resettable alwaysOn />
                <SearchInput source="reference" placeholder="reference" resettable alwaysOn />
                <SearchInput source="win" placeholder="win" resettable alwaysOn />
                <SelectInput source="data_type" placeholder="Data Type" choices={data_types} optionText="name" alwaysOn />
                <NullableBooleanInput source="resolved" placeholder="Resolved" alwaysOn />
            </Filter>
        </div>
    );
}

const ReferenceLinkField = (props) => {
    let link = '';
    let { source, reference, filterKey, record, filterValue } = props;
    let fieldShown = record[source];
    if (filterValue){
        filterValue = `"${record[filterValue]}"`;
    }
    else {
        filterValue = record[filterKey];
    }

    if (source === filterKey) {
        link = `/#/${reference}?filter={"${filterKey}":"${filterValue}"}`;
    }
    else {
        link = `/#/${reference}?filter={"${filterKey}":${filterValue}}`;
    }

    return (
        <a target='_blank' href={link}>{fieldShown}</a>
    )
}


const RerunFinancialUpdatesButton = ({ record }) => {

    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const handleClick = e => {
        record["resolved"] = true;
        return Api.patch(`/notices/${record.id}/`, record, true)
            .then(response => {
                if (response.type === "success") {
                    notify(`Added symbol ${record.reference} to reprocessing queue!`);
                    redirect('/notices');
                    refresh();
                }
                else if (response.type === "auth_error") {
                    console.log("Error reprocessing symbol - authentication error");
                    redirect('/login');
                }
                else {
                    console.log("Error reprocessing symbol - response", response.type);
                    if (response.error) {
                        notify(response.error, 'warning');
                    }
                    else {
                        notify("Could not reprocess symbol!", 'warning');
                    }
                }
            })
            .catch((e) => {
                console.log("Exception reprocessing symbol - e", e);
                notify("Could not reprocess symbol!", 'warning')
            })
    };

    if (record["resolved"] === true) {
        return null;
    }
    return (
        <Fragment>
            <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleClick}
            >
                Reprocess Symbol
            </Button>
        </Fragment>
    );
}


const NoticeList = props => (
    <>
        <h2>{props.options.label}</h2>
        <List filters={<NoticeFilter />}
            bulkActionButtons={<DocBulkActionButtons/>} exporter={false} {...props}>
            <Datagrid expand={<NoticesEdit />} >
                <TextField source="id" sortable={false} />
                <ReferenceField source="job" reference="jobs" link="show">
                    <TextField source="id" />
                </ReferenceField>
                <ReferenceLinkField source="batch" filterKey="job" reference="tasks" />
                <TextField source="company_name" />
                <ReferenceLinkField source="reference" filterKey="idkey" filterValue="reference" reference="entities" />
                <TextField source="win" />
                <DateField source="date" locales="pt-PT" />
                <TextField source="data_type" />
                <TextField source="error_message" />
                <BooleanField source="resolved" />
                <RerunFinancialUpdatesButton />
            </Datagrid>
        </List>
    </>
);

export default NoticeList;