import React from 'react';
import {Filter, ReferenceInput, AutocompleteInput, Datagrid, EditButton, List, TextField, ShowButton} from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import { userHasPermission } from "../../helpers/authProvider";
import "../../index.css";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import { perPageReferenceFields } from "../../constants";
import ClearFiltersButton from "../../components/ClearFiltersButton";

const ExtractionServiceScriptFilters = (props) => {
  return(
    <div>
      <ClearFiltersButton {...props} filters={{id: ''}}/>
      <Filter {...props}>
          <ReferenceInput label="Name" source="id" reference="extraction-service-script" perPage={perPageReferenceFields} alwaysOn>
              <AutocompleteInput optionText="name" />
          </ReferenceInput>
      </Filter>
    </div>
  );
}

const ExtractionServiceScriptList = props => (
    <ThemeProvider theme={theme}>
      <h2>{props.options.label}</h2>
      <List filters={<ExtractionServiceScriptFilters />} bulkActionButtons={<DocBulkActionButtons {...props} />} exporter={false} {...props}
            actions={<ListToolbarActions showCreateButton={true}/>}>
        <Datagrid>
          <TextField source="name" />
          <EditButton/>
          { userHasPermission("jobs-read") && <ShowButton /> }
        </Datagrid>
      </List>
    </ThemeProvider>
);

export default ExtractionServiceScriptList;
