import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const CommAddresses = (props) => {
    return (
        <div>
            <MappingSectionInput field='textAR' required={true} {...props}/>
            <MappingSectionInput field='typeAR'{...props}/>
            <MappingSectionInput field='language_codes' {...props}/>
            <MappingSectionInput field='address' required={true} {...props}/>
            <MappingSectionInput field='start' {...props}/>
            <MappingSectionInput field='end' {...props}/>
            <MappingSectionInput field='active' required={true} {...props}/>
            <MappingSectionInput field='validated' required={true} {...props}/>
        </div>
    );  
};

export default CommAddresses;