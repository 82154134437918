import React from 'react';
import {Filter, ReferenceInput, DateField, BooleanField, AutocompleteInput, Datagrid, EditButton, List, TextField, ReferenceManyField, SimpleShowLayout} from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import "../../index.css";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { perPageReferenceFields } from "../../constants";
import ClearFiltersButton from "../../components/ClearFiltersButton";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";

const SuppliersFilter = (props) => {
  return(
    <div>
      <ClearFiltersButton {...props} filters={{id: ''}}/>
      <Filter {...props}>
          <ReferenceInput label="Source Code" source="id" reference="products" perPage={perPageReferenceFields} alwaysOn>
              <AutocompleteInput optionText="code" />
          </ReferenceInput>
      </Filter>
    </div>
  );
}


const ShowParamsAndCredentials = props => {
  console.log('props', props);
  return (
      <SimpleShowLayout {...props}>
        <ReferenceManyField {...props} fullWidth reference="supplier-product-requests-credentials" label="Credentials" target="supplier_product_request_id"
         perPage={perPageReferenceFields} sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid>
              <TextField source="key" />
              <TextField source="value" />
              <TextField source="credential_type" />
              <TextField source="credential_location" />
            </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField {...props} fullWidth reference="supplier-product-requests-params" label="Params" target="supplier_product_request_id"
          perPage={perPageReferenceFields} sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid>
              <TextField source="key" />
              <TextField source="value" />
              <TextField source="parameter_type"/>
              <TextField source="parameter_label" />
            </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    );
}

const SupplierProductRequestsList = props => (
  <ThemeProvider theme={theme}>
    <h2>{props.options.label}</h2>
    <List filters={false} bulkActionButtons={<DocBulkActionButtons/>} exporter={false} {...props}
          actions={<ListToolbarActions showCreateButton={true}/>}>
      <Datagrid expand={<ShowParamsAndCredentials {...props} />}>
      <TextField source="supplier_product_label" label="Supplier Product"/>
        <TextField source="label" label="Label"/>
        <TextField source="endpoint_url" label="Endpoint"/>
        <TextField source="request_method" label="Method"/>
        <EditButton/>
      </Datagrid>
    </List>
  </ThemeProvider>
);

export default SupplierProductRequestsList;
