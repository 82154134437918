import React from 'react';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import { Edit, SimpleForm, TextInput, TextField} from 'react-admin';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";
import { validations} from './validations';

const SourceEntityNamesEdit = props => {

    return (
        <>
            <Edit undoable={true} {...props}>      
                <SimpleForm  redirect={`/source-entities`} toolbar={<EditFormToolbarActions />}>
                    <BackButton {...props} action = {"edit"}/>
                    <PageTitle {...props}>Edit</PageTitle>
                    <TextField label="Source Entity ID" source="source_entity" />
                    <TextInput source="name" fullWidth={true} validate={validations.validateName}/>
                </SimpleForm>
            </Edit>
        </>
    );
}

export default SourceEntityNamesEdit;