import React from 'react';
import { TextField, Card, SelectField, TextInput, BooleanInput, ReferenceInput, AutocompleteInput, SelectInput, ArrayInput, SimpleFormIterator, NumberInput, DateInput } from 'react-admin';
import { Box, Toolbar } from '@material-ui/core';
import {validations} from './validations';


const EntityName = (props) => {
    const location = props.location.pathname;

    return (
        <>
            <TextInput source="name" fullWidth={true} validate={validations.validateName} />
            <ReferenceInput label="Name type" source='entity_name_type' reference='enumerations-entity-name-types' validate={validations.validatEntityNameType} >
                <SelectInput optionText="label" />
            </ReferenceInput>
            <BooleanInput source="active" defaultValue={true} label="Is it active?" validate={validations.validateActive} />
            { location.includes('create') &&
                <ReferenceInput label="Insert source" source="insert_source" reference="sources" validate={validations.validateInsertSource} >
                    <AutocompleteInput optionText="code" optionValue="id" />
                </ReferenceInput>
            }
        </>
    );
}


export default EntityName;


