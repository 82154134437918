import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const Credits = (props) => {

    return (
        <div>
            <MappingSectionInput field='languageCode' required={true} {...props}/>
            <MappingSectionInput field='date' required={true} {...props}/>
            <MappingSectionInput field='risk_score_ar' {...props}/>
            <MappingSectionInput field='risk_score' {...props}/>
            <MappingSectionInput field='payment_score_ar' {...props}/>
            <MappingSectionInput field='payment_score' {...props}/>
            <MappingSectionInput field='trend_score_ar' {...props}/>
            <MappingSectionInput field='trend_score' {...props}/>
            <MappingSectionInput field='credit_ar' {...props}/>
            <MappingSectionInput field='credit_limit' {...props}/>
            <MappingSectionInput field='credit_definition' {...props}/>
            <MappingSectionInput field='currency_code' {...props}/>
            <MappingSectionInput field='active' required={true} {...props}/>
            <MappingSectionInput field='validated' required={true} {...props}/>
        </div>
    );  
};

export default Credits;