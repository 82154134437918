import React from "react";
import { Admin, Resource } from "react-admin";
import drfProvider from "../../helpers/drfDataProvider";
import { authProvider, httpClient } from "../../helpers/authProvider";
import Dashboard from "../../pages/Dashboard/RecordsCount";
import CompaniesCount from "../../pages/Dashboard/CompaniesCount";
import PendingRecordsCount from "../../pages/Dashboard/PendingRecordsCount";
import NoticesCount from "../../pages/Dashboard/NoticesCount";
import NewEntityCounts from "../../pages/Dashboard/NewEntityCounts";
import UpdatedEntityCounts from "../../pages/Dashboard/UpdatedEntityCounts";
import steps from "../../pages/Steps";
import sources from "../../pages/Sources";
import sourceCredentials from "../../pages/SourceCredentials";
import processes from "../../pages/Processes";
import processStepTransitions from "../../pages/ProcessStepTransitions";
import scheduledJobs from "../../pages/ScheduledJobs";
import jobs from "../../pages/Jobs";
import notifications from "../../pages/Notifications";
import tasks from "../../pages/Tasks";
import alerts from "../../pages/Alerts";
import mappings from "../../pages/Mappings";
import products from "../../pages/Products";
import enumerations from "../../pages/Enumerations";
import importMappingLabels from "../../pages/ImportMappingLabels";
import enrichmentScoringMappings from "../../pages/EnrichmentScoringMappings";
import importMappingSources from "../../pages/ImportMappingSources";
import users from "../../pages/SystemSetup/Users";
import accounts from "../../pages/Accounts";
import documents from "../../pages/Documents";
import documentEdit from "../../pages/DocumentEdit";
import extraction_service_script from "../../pages/ExtractionServiceScript";
import source_entities_blocked_references from "../../pages/SourceEntitiesBlockedReferences";
import source_entities from "../../pages/SourceEntities";
import source_entity_names from "../../pages/SourceEntityNames";
import mongo_ip from "../../pages/SystemSetup/MongoIP";
import arttence_config from "../../pages/SystemSetup/ArtTenceConfig";
import adi_tester from "../../pages/ADITester";
import geocoding_tester from "../../pages/GeocodingTester";
import entities from "../../pages/Entities";
import entity_names from "../../pages/EntityNames";
import entity_idkeys from "../../pages/EntityIdkeys";
import entity_dates from "../../pages/EntityDates";
import entity_predicates from "../../pages/EntityPredicates";
import orders from "../../pages/Orders";
import suppliers from "../../pages/Suppliers";
import source_section_quality_levels from "../../pages/SourceSectionQualityLevels";
import supplier_product_requests from "../../pages/SupplierProductRequests";
import supplier_product_requests_params from "../../pages/SupplierProductRequestsParams";
import supplier_product_requests_credentials from "../../pages/SupplierProductRequestsCredentials";
import supplier_product_country_references from "../../pages/SupplierProductCountryReferences";
import importMappings from "../../pages/ImportMappings";
import importMappingFunctions from "../../pages/ImportMappingFunctions";
import notices from "../../pages/Notices";
import entitiesmerger from "../../pages/Tools/EntitiesMerger";
import { componentBasedOnPermission } from "../../helpers/authProvider";
import AppLayout from "../AppLayout";
import "./App.css";
import userPermissions from "../../pages/UserPermissions";
import sourceFilesConfigurations from "../../pages/SourceFilesConfigurations";
import activityValidator from "../../pages/ActivityValidator";
import entityAddresses from "../../pages/EntityAddresses";
import rabbitMQGenerator from "../../pages/RabbitMQGenerator";
import hqBranchGUI from "../../pages/HQBranchGUI";
import exportMappingDeliveries from "../../pages/ExportMappings";

const dataProvider = drfProvider(process.env.REACT_APP_API_BASE, httpClient);

if (window.location.href.includes(".dev.")) {
  document.getElementById("title").innerHTML = "Dev Arttence Backoffice";
}
if (window.location.href.includes("localhost")) {
  document.getElementById("title").innerHTML = "(Local) Arttence Backoffice";
}

const App = () => (
  <Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    layout={AppLayout}
  >
    {(permissions) => [
      //<Resource name="statistics/records-count" options={{ label: 'Total Records' }}
      //icon={Dashboard.icon}
      //list={Dashboard.list}
      ///>,
      <Resource
        name="statistics/total-records-count"
        options={{ label: "Pending Documents" }}
        icon={PendingRecordsCount.icon}
        list={PendingRecordsCount.list}
      />,
      // <Resource
      //   name="statistics/companies-count"
      //   options={{ label: "Total Companies" }}
      //   icon={CompaniesCount.icon}
      //   list={CompaniesCount.list}
      // />,
      <Resource
        name="statistics/notices-count"
        options={{ label: "Nº of Notices" }}
        icon={NoticesCount.icon}
        list={NoticesCount.list}
      />,
      <Resource name="statistics/records-count" />,
      <Resource
        name="statistics/new-entity-counts"
        options={{ label: "Nº of New Entities" }}
        icon={NewEntityCounts.icon}
        list={NewEntityCounts.list}
      />,
      <Resource
        name="statistics/updated-entity-counts"
        options={{ label: "Nº of Updated Entities" }}
        icon={UpdatedEntityCounts.icon}
        list={UpdatedEntityCounts.list}
      />,
      <Resource name="job-status" />,
      <Resource name="alert-status" />,
      <Resource name="job-batches" />,
      <Resource name="step-types" />,
      <Resource name="job-batch-outputs" />,
      <Resource name="task-status" />,
      <Resource name="task-jobs" />,
      <Resource name="start-task" />,
      <Resource name="sources" />,
      <Resource name="source-types" />,
      <Resource name="login-types" />,
      <Resource name="enumerations-property-names" />,
      <Resource name="enumerations-codes" />,
      <Resource name="enumerations-report-types" />,
      <Resource name="enumerations-matching-algorithms" />,
      <Resource name="enumerations-service-classes" />,
      <Resource name="enumerations-idkey-codes" />,
      <Resource name="enumerations-source-types" />,
      <Resource name="enumerations-language-codes" />,
      <Resource name="enumerations-translation-codes" />,
      <Resource name="enumerations-country-codes" />,
      <Resource name="enumerations-county-codes" />,
      <Resource name="enumerations-rounding-levels" />,
      <Resource name="enumerations-currency-codes" />,
      <Resource name="enumerations-entity-types" />,
      <Resource name="enumerations-entity-relations" />,
      <Resource name="enumerations-event-types" />,
      <Resource name="enumerations-filing-types" />,
      <Resource name="enumerations-filing-status" />,
      <Resource name="enumerations-region-codes" />,
      <Resource name="enumerations-company-forms" />,
      <Resource name="enumerations-company-legal-forms" />,
      <Resource name="enumerations-company-status" />,
      <Resource name="enumerations-company-types" />,
      <Resource name="enumerations-person-status" />,
      <Resource name="enumerations-person-types" />,
      <Resource name="enumerations-registration-status" />,
      <Resource name="enumerations-relation-types" />,
      <Resource name="enumerations-ownership-classes" />,
      <Resource name="enumerations-function-codes" />,
      <Resource name="enumerations-board-types" />,
      <Resource name="enumerations-business-areas" />,
      <Resource name="enumerations-function-extensions" />,
      <Resource name="enumerations-sic-labels" />,
      <Resource name="enumerations-crawling-algorithms" />,
      <Resource name="import-mapping-labels-property-names" />,
      <Resource name="import-mapping-sources-property-names" />,
      <Resource name="roles" />,
      <Resource name="firstlink-permissions" />,
      <Resource name="job-batch-output-status" />,
      <Resource name="active-job-batches" />,
      <Resource name="task-titles" />,
      <Resource name="enumerations-share-types" />,
      <Resource name="enumerations-share-extensions" />,
      <Resource name="enumerations-labels" />,
      <Resource name="source-entities-blocked-references-refs" />,
      <Resource name="process-source-codes" />,
      <Resource name="source-code-labels" />,
      <Resource name="task-sources" />,
      <Resource name="source-files-configurations-codes" />,
      <Resource name="import-mapping-labels-labels" />,
      <Resource name="import-mapping-sources-labels" />,
      <Resource name="task-processes-titles" />,
      <Resource name="source-entity-refs" />,
      <Resource name="source-entity-wins" />,
      <Resource name="source-entity-distinct-names" />,
      <Resource name="supplier-products" />,
      <Resource name="enumerations-financial-item-codes" />,
      <Resource name="enumerations-group-financial-item-codes" />,
      <Resource name="enumerations-signature-codes" />,
      <Resource name="enumerations-boolean-types" />,
      <Resource name="enumerations-market-identifier-codes" />,
      <Resource name="enumerations-ownership-types" />,
      <Resource name="entity-art-ids" />,
      <Resource name="enumerations-license-statuses" />,
      <Resource name="enumerations-hsc-labels" />,
      <Resource name="enumerations-at-editor-sections" />,
      <Resource
        name="supplier-product-requests-params"
        icon={supplier_product_requests_params.icon}
        create={supplier_product_requests_params.create}
        edit={supplier_product_requests_params.edit}
      />,
      <Resource
        name="supplier-product-requests-credentials"
        icon={supplier_product_requests_credentials.icon}
        create={supplier_product_requests_credentials.create}
        edit={supplier_product_requests_credentials.edit}
      />,
      <Resource
        name="entity-idkeys"
        create={entity_idkeys.create}
        edit={entity_idkeys.edit}
      />,
      <Resource
        name="entity-names"
        create={entity_names.create}
        edit={entity_names.edit}
      />,
      <Resource
        name="entity-dates"
        create={entity_dates.create}
        edit={entity_dates.edit}
      />,
      <Resource
        name="entity-predicates"
        create={entity_predicates.create}
        edit={entity_predicates.edit}
      />,
      <Resource name="entity-reports" />,
      <Resource name="reports" />,
      <Resource name="report-files" />,
      <Resource name="enumerations-artid-change-types" />,
      <Resource name="enumerations-entity-name-types" />,
      <Resource name="enumerations-entity-date-types" />,
      <Resource name="enumerations-entity-predicate-types" />,
      <Resource name="enumerations-sections" />,
      <Resource name="enumerations-connection-types" />,
      <Resource name="enumerations-transaction-types" />,
      <Resource name="enumerations-signature-restriction-codes" />,

      <Resource
        name="source-section-quality-levels"
        options={{ label: "Sections Quality Levels" }}
        list={source_section_quality_levels.list}
      />,

      <Resource
        name="extraction-service-script"
        options={{ label: "Extraction Service Scripts" }}
        icon={extraction_service_script.icon}
        list={extraction_service_script.list}
        show={extraction_service_script.show}
        create={extraction_service_script.create}
        edit={extraction_service_script.edit}
      />,

      <Resource
        name="source-codes"
        options={{ label: "Sources" }}
        icon={sources.icon}
        list={sources.list}
        create={sources.create}
        edit={sources.edit}
      />,

      <Resource
        name="products"
        options={{ label: "Products" }}
        icon={products.icon}
        list={products.list}
        create={products.create}
        edit={products.edit}
      />,

      <Resource
        name="source-credentials"
        options={{ label: "Source Credentials" }}
        icon={sourceCredentials.icon}
        list={sourceCredentials.list}
        edit={sourceCredentials.edit}
        create={sourceCredentials.create}
      />,

      <Resource
        name="user-permissions"
        options={{ label: "Permissions" }}
        icon={userPermissions.icon}
        list={userPermissions.list}
        edit={userPermissions.edit}
        create={userPermissions.create}
      />,

      <Resource name="period-types" />,
      <Resource name="alerts/job-alerts" />,

      <Resource
        name="steps"
        options={{ label: "Steps" }}
        icon={steps.icon}
        list={componentBasedOnPermission("steps-read", steps.list)}
        create={componentBasedOnPermission("steps-create", steps.create)}
        edit={componentBasedOnPermission("steps-update", steps.edit)}
      />,

      <Resource
        name="processes"
        {...processes}
        options={{ label: "Processes" }}
        icon={processes.icon}
        list={componentBasedOnPermission("processes-read", processes.list)}
        create={componentBasedOnPermission(
          "processes-create",
          processes.create
        )}
        edit={componentBasedOnPermission("processes-update", processes.edit)}
      />,

      <Resource
        name="process-step-transitions"
        create={componentBasedOnPermission(
          "processes-update",
          processStepTransitions.create
        )}
        edit={componentBasedOnPermission(
          "processes-update",
          processStepTransitions.edit
        )}
      />,

      <Resource
        name="cron-jobs"
        options={{ label: "Scheduled Jobs" }}
        icon={scheduledJobs.icon}
        list={componentBasedOnPermission(
          "scheduledjobs-read",
          scheduledJobs.list
        )}
        create={componentBasedOnPermission(
          "scheduledjobs-create",
          scheduledJobs.create
        )}
        edit={componentBasedOnPermission(
          "scheduledjobs-update",
          scheduledJobs.edit
        )}
      />,

      <Resource
        name="jobs"
        {...jobs}
        options={{ label: "Jobs" }}
        icon={jobs.icon}
        list={componentBasedOnPermission("jobs-read", jobs.list)}
        show={componentBasedOnPermission("jobs-read", jobs.show)}
      />,

      <Resource
        name="notifications"
        options={{ label: "Notifications" }}
        list={componentBasedOnPermission(
          "notifications-read",
          notifications.list
        )}
        show={componentBasedOnPermission(
          "notifications-read",
          notifications.show
        )}
      />,

      <Resource
        name="tasks"
        options={{ label: "Tasks" }}
        icon={tasks.icon}
        list={componentBasedOnPermission("tasks-read", tasks.list)}
        show={componentBasedOnPermission("tasks-read", tasks.show)}
      />,

      <Resource
        name="alerts"
        options={{ label: "Error Alerts" }}
        icon={tasks.icon}
        list={componentBasedOnPermission("notifications-read", alerts.list)}
        show={componentBasedOnPermission("notifications-read", alerts.show)}
      />,

      <Resource
        name="enumerations"
        options={{ label: "Enumerations" }}
        icon={enumerations.icon}
        list={enumerations.list}
        create={enumerations.create}
        edit={enumerations.edit}
      />,

      <Resource
        name="import-mapping-labels"
        options={{ label: "Import Mapping Labels" }}
        icon={importMappingLabels.icon}
        list={importMappingLabels.list}
        create={importMappingLabels.create}
        edit={importMappingLabels.edit}
      />,

      <Resource
        name="import-mapping-sources"
        options={{ label: "Import Mapping Sources" }}
        icon={importMappingSources.icon}
        list={importMappingSources.list}
        create={importMappingSources.create}
        edit={importMappingSources.edit}
      />,

      <Resource
        name="users"
        options={{ label: "Users Management" }}
        icon={users.icon}
        list={users.list}
        create={users.create}
        edit={users.edit}
      />,

      <Resource
        name="accounts"
        options={{ label: "Accounts" }}
        icon={accounts.icon}
        list={accounts.list}
        create={accounts.create}
        edit={accounts.edit}
      />,

      <Resource
        name="source-files-configurations"
        options={{ label: "Source Files" }}
        icon={sourceFilesConfigurations.icon}
        list={sourceFilesConfigurations.list}
        create={sourceFilesConfigurations.create}
        edit={sourceFilesConfigurations.edit}
      />,

      <Resource name="validations" />,

      <Resource name="transformations" />,

      <Resource name="import-mapping-schemas" />,

      <Resource
        name="documents"
        options={{ label: "AT-Editor" }}
        icon={documents.icon}
        list={documents.list}
        show={documents.show}
        create={documents.create}
      />,
      <Resource name="sections" />,

      <Resource name="entity-errors" />,

      <Resource name="import-documents" />,
      <Resource name="export-mapping-delivery-property-names" />,
      <Resource name="export-mapping-delivery-labels" />,
      <Resource name="deliveries" />,

      <Resource
        name="document-edit"
        options={{ label: "AT-Editor" }}
        list={documentEdit.list}
        create={documentEdit.create}
      />,
      <Resource name="document-editor-status" />,

      <Resource
        name="source-entities-blocked-references"
        options={{ label: "Blocked References" }}
        icon={source_entities_blocked_references.icon}
        list={source_entities_blocked_references.list}
        create={source_entities_blocked_references.create}
        edit={source_entities_blocked_references.edit}
      />,

      <Resource
        name="source-entities"
        options={{ label: "SE Editor" }}
        icon={source_entities.icon}
        list={source_entities.list}
        // create={source_entities.create}
        edit={source_entities.edit}
      />,

      <Resource
        name="source-entity-names"
        create={source_entity_names.create}
        edit={source_entity_names.edit}
      />,

      <Resource
        name="entities"
        options={{ label: "Entities Editor" }}
        icon={entities.icon}
        list={entities.list}
        edit={entities.edit}
      />,

      <Resource
        name="orders"
        options={{ label: "Orders GUI" }}
        icon={orders.icon}
        list={orders.list}
        edit={orders.edit}
      />,
      <Resource
        name="suppliers"
        options={{ label: "Suppliers" }}
        icon={suppliers.icon}
        list={suppliers.list}
        create={suppliers.create}
        edit={suppliers.edit}
      />,
      <Resource
        name="supplier-product-requests"
        options={{ label: "Supplier Product Requests" }}
        icon={supplier_product_requests.icon}
        list={supplier_product_requests.list}
        create={supplier_product_requests.create}
        edit={supplier_product_requests.edit}
      />,
      <Resource
        name="supplier-product-country-references"
        options={{ label: "Country References" }}
        icon={supplier_product_country_references.icon}
        list={supplier_product_country_references.list}
        create={supplier_product_country_references.create}
        edit={supplier_product_country_references.edit}
      />,
      <Resource
        name="mongo-ip"
        options={{ label: "Whitelist MongoDB IP" }}
        list={mongo_ip.list}
      />,
      <Resource
        name="arttence-config"
        options={{ label: "ArtTence Config" }}
        list={arttence_config.list}
        create={arttence_config.create}
        edit={arttence_config.edit}
      />,
      <Resource
        name="adi-tester"
        options={{ label: "ADI Tester" }}
        list={adi_tester.list}
      />,
      <Resource
        name="geocoding-tester"
        options={{ label: "Geocoding Tester" }}
        list={geocoding_tester.list}
      />,

      <Resource
        name="import-mappings"
        options={{ label: "Import Mappings" }}
        icon={importMappings.icon}
        list={importMappings.list}
        create={importMappings.create}
        edit={importMappings.edit}
      />,
      <Resource
        name="import-mapping-functions"
        options={{ label: "Import Mappings Functions" }}
        icon={importMappingFunctions.icon}
        list={importMappingFunctions.list}
        create={importMappingFunctions.create}
        edit={importMappingFunctions.edit}
      />,
      <Resource
        name="import-mapping-functions-params"
        options={{ label: "Import Mappings Functions Params" }}
      />,
      <Resource
        name="import-mapping-functions-types"
        options={{ label: "Import Mappings Functions Types" }}
      />,
      <Resource
        name="notices"
        options={{ label: "Notices" }}
        icon={notices.icon}
        list={notices.list}
        edit={notices.edit}
      />,

      <Resource
        name="enrichment-mapping-scorings"
        options={{ label: "Enrichment Mapping Scorings" }}
        icon={enrichmentScoringMappings.icon}
        list={enrichmentScoringMappings.list}
      />,
      <Resource
        name="entitiesmerger"
        options={{ label: "Entities Merger" }}
        icon={entitiesmerger.icon}
        list={entitiesmerger.list}
      />,
      <Resource
        name="activity-validator"
        options={{ label: "Activity Validator" }}
        icon={activityValidator.icon}
        list={activityValidator.list}
      />,
      <Resource name="entity-addresses" list={entityAddresses.list} />,
      <Resource
        name="rabbit-mq-generator"
        options={{ label: "Rabbit MQ Message generator" }}
        icon={rabbitMQGenerator.icon}
        create={rabbitMQGenerator.create}
      />,
      <Resource
        name="hq-branches/check-hq-branch-info"
        options={{ label: "HQ Branch GUI" }}
        icon={hqBranchGUI.icon}
        list={hqBranchGUI.list}
      />,
      <Resource
        name="export-mapping-deliveries"
        options={{ label: "Export Mapping Delivery" }}
        icon={exportMappingDeliveries.icon}
        list={exportMappingDeliveries.list}
        create={exportMappingDeliveries.create}
        edit={exportMappingDeliveries.edit}
      />
    ]}
  </Admin>
);

export default App;
