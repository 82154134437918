import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const Names = (props) => {
    return (
        <div>
            <MappingSectionInput field='nameAR' required={true} {...props}/>
            <MappingSectionInput field='name' {...props}/>
            <MappingSectionInput field='languageCode' required={true} {...props}/>
            <MappingSectionInput field='translationCode' {...props}/>
            <MappingSectionInput field='start' {...props}/>
            <MappingSectionInput field='end' {...props}/>
            <MappingSectionInput field='active' required={true} {...props}/>
            <MappingSectionInput field='characterControlled' {...props}/>
            <MappingSectionInput field='validated' required={true} {...props}/>
        </div>
    );  
};

export default Names;