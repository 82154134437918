import React, { useState } from "react";
import { TextInput, required, FormDataConsumer } from "react-admin";
import { useForm } from "react-final-form";

const OthersSectionInput = ({ record, ...props }) => {
  const form = useForm();
  let recordField;
  let recordValue;
  let fieldLocation = props.source;

  if (record !== {}) {
    recordField = Object.keys(record)[0];
    if (recordField) {
      recordValue = record?.[recordField]?.value;
    }
  }

  const [field, setField] = useState(recordField ? recordField : "");
  const [currentValue, setCurrentValue] = useState(recordValue);
  const [valueExists, setValueExists] = useState(recordValue ? true : false);

  const handleChangeField = (event) => {
    const new_field = event.target.value;
    setField(new_field);
    form.change(`${fieldLocation}`, undefined);
    if (valueExists) {
      form.change(`${fieldLocation}.${new_field}.value`, currentValue);
      form.change(`${fieldLocation}.${new_field}.type`, "Object");
      form.change(`${fieldLocation}.${new_field}.required`, "false");
    }
  };

  const handleChangeValue = (event) => {
    const value = event.target.value;
    setCurrentValue(value);
  };

  const handleBlur = (event) => {
    const value = event.target.value;
    if (value !== "") {
      setValueExists(true);
      form.change(`${fieldLocation}.${field}.type`, "Object");
      form.change(`${fieldLocation}.${field}.required`, "false");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ marginRight: "10px", flexGrow: 1 }}>
          <TextInput
            source={`${fieldLocation}`}
            label="Field"
            fullWidth={true}
            onChange={handleChangeField}
            value={field}
          />
        </div>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <div style={{ flexGrow: 8 }}>
              <TextInput
                source={`${fieldLocation}.${field}.value`}
                label="Value"
                fullWidth={true}
                onChange={handleChangeValue}
                onBlur={handleBlur}
                value={currentValue}
              />
            </div>
          )}
        </FormDataConsumer>
      </div>
    </div>
  );
};

export default OthersSectionInput;
