import React from 'react';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import { Edit, SimpleForm, FileInput, FileField} from 'react-admin';
import { validations } from './validations';
import "../../index.css";
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const ExtractionServiceScriptEdit = props => {

  return (
    <>
      <Edit undoable={true} {...props}>
        <SimpleForm toolbar={<EditFormToolbarActions showDeleteButton={false} />}>
          <BackButton {...props} action = {"edit"}/>
          <PageTitle {...props}>Edit</PageTitle>
          <FileInput source="python_file" label="Upload Python script" validate={validations.validateFile}>
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Edit>
    </>
  );
}

export default ExtractionServiceScriptEdit;
