import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DescriptionIcon from '@material-ui/icons/Description';

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: "bold"
    }
  }))(TableCell);

const checkAction = (row) => {
    if (!row.hasOwnProperty('ACTION')) {
        return row.ACTION;
    }
    else {
        return <a target='_blank' href={row.ACTION}><DescriptionIcon /></a>;
    }
}
  
export default class OrderStatusTable extends PureComponent {


    
    render() {
        return (
            <TableContainer component={Paper}>
                <Table aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {/*<StyledTableCell>SR. NO.</StyledTableCell> */}
                            {/* <StyledTableCell align="right">Initial Records</StyledTableCell> */}
                            <StyledTableCell>ORDERNO</StyledTableCell>
                            <StyledTableCell>JOB</StyledTableCell>
                            <StyledTableCell>WIN</StyledTableCell>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>HRNUMMER</StyledTableCell>
                            <StyledTableCell>EHRANUMMER</StyledTableCell>
                            <StyledTableCell>REFERENCE</StyledTableCell>
                            <StyledTableCell>SUPPLIER ID</StyledTableCell>
                            <StyledTableCell>ADR1 COUNTRY CODE</StyledTableCell>
                            <StyledTableCell>STATUS</StyledTableCell>
                            <StyledTableCell>CREATED AT</StyledTableCell>
                            <StyledTableCell align="center">ACTION</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { this.props.rows.map(row => (
                            <TableRow style={row.action_needed ? {backgroundColor:'#ff8e88'} : null} key={row.name}>
                                {/* <TableCell component="th" scope="row">{row.id}</TableCell> */}
                                {/* <TableCell align="right">{row.initial_record_count}</TableCell> */}
                                <TableCell>{row.ORDERNO}</TableCell>
                                <TableCell><a target='_blank' href={'#/jobs/' + row.JOB + '/show'}>{row.JOB}</a></TableCell>
                                <TableCell><a target='_blank' href={row.win_url}>{row.WIN}</a></TableCell>
                                <TableCell >{row.NAME}</TableCell>
                                <TableCell>{row.HRNUMMER}</TableCell>
                                <TableCell>{row.EHRANUMMER}</TableCell>
                                <TableCell>{row.reference}</TableCell>
                                <TableCell>{row.SUPPLIER_ID}</TableCell>
                                <TableCell>{row.ADR1_COUNTRY_CODE}</TableCell>
                                <TableCell >{row.STATUS}</TableCell>
                                <TableCell>{row.CREATED_AT}</TableCell>
                                <TableCell align="center">{checkAction(row)}</TableCell>
                            </TableRow>
                        )) }
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}
