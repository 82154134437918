import React from 'react';
import {Filter, ReferenceInput, AutocompleteInput, Datagrid, EditButton, List, TextField} from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import "../../index.css";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { perPageReferenceFields } from "../../constants";
import ClearFiltersButton from "../../components/ClearFiltersButton";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";

const ProductsFilter = (props) => {
  return(
    <div>
      <ClearFiltersButton {...props} filters={{id: ''}}/>
      <Filter {...props}>
          <ReferenceInput label="Source Code" source="id" reference="products" perPage={perPageReferenceFields} alwaysOn>
              <AutocompleteInput optionText="code" />
          </ReferenceInput>
      </Filter>
    </div>
  );
}

const ProductsList = props => (
  <ThemeProvider theme={theme}>
    <h2>{props.options.label}</h2>
    <List filters={<ProductsFilter />} bulkActionButtons={<DocBulkActionButtons/>} exporter={false} {...props}
          actions={<ListToolbarActions showCreateButton={true}/>}>
      <Datagrid>
        <TextField source="code" label ="Source Code"/>
        <TextField source="label"/>
        <TextField label="processes" source="processes_string" />
        <EditButton/>
      </Datagrid>
    </List>
  </ThemeProvider>
);

export default ProductsList;
