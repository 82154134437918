import React from 'react';
import { List, SimpleForm, ReferenceInput, AutocompleteInput, SelectInput, TextInput, BooleanInput, DateInput } from 'react-admin';
import { validations } from './validations';

const CompanyStatuses = (props) => (
        <div>
            { 'location' in props && 
                <TextInput source='textAR' label='TextAR' validate={validations.validateTextAR}/>
            }
            <ReferenceInput source='status' label='Status' reference='enumerations-company-status' allowEmpty={true}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes' allowEmpty={true}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput source='translationCode' label='Translation Code' reference='enumerations-translation-codes' allowEmpty={true}>
                <SelectInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <DateInput source='start' label='Start Date' />
            <BooleanInput source='active' initialValue={true} label='Active?' validate={validations.validateActive} />
        </div>
)

export default CompanyStatuses;