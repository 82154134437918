import React, {useState} from 'react';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import {Edit, useEditContext, TextInput, ReferenceManyField, List, NumberField, AutocompleteInput, Datagrid, TextField, TabbedForm, FormTab,  ReferenceInput, SelectInput, BooleanField, DateField, BooleanInput} from 'react-admin';
import {validations} from './validations';
import { perPageReferenceFields } from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import ContentCreate from '@material-ui/icons/Create';

// const EntitiesEdit = props => {

//   return (
//     <>
//       <Edit undoable={true} {...props}>
//         <SimpleForm toolbar={<EditFormToolbarActions showDeleteButton={false}/>}>
//           <BackButton {...props} action = {"edit"}/>
//           <PageTitle {...props}>Edit</PageTitle>
//           <TextInput source="entity_type" />
//           <TextInput source="country_code" />
//           <TextInput source="successor_entity" />
//           <BooleanInput source="verified" label="Is it verified?"/>
//           <BooleanInput source="public" label="Is it public?"/>
//           <BooleanInput source="active" label="Is it active?"/>
//         </SimpleForm>
//       </Edit>
//     </>
//   );
// }


const getEntityInfoValue = (label) => {
  let entity_info = '';
  
  if (label.includes('idkey')){
    entity_info = 'idkeys';
  }
  else if (label.includes('name')){
    entity_info = 'names';
  }
  else if (label.includes('date')){
    entity_info = 'dates';
  } else {
    entity_info = 'predicates';
  }
  return entity_info;
}

const CustomAddButton = ({ classes, label, record, search, redirect}) => {
  let entity_info = '';
  entity_info = getEntityInfoValue(label);

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      component={Link}
      to={{
        pathname: redirect,
        search: search,
        state: { entity_info: entity_info }
      }}
    >
        {label}
    </Button>
  )
};

const CustomEditButton = ({classes, label, record, search, redirect}) => {
  let entity_info = '';
  entity_info = getEntityInfoValue(redirect);
  const redirectTo = redirect + record.id;

  return (
  
    <Button
        color="primary"
        size="small"
        component={Link}
        to={{
          pathname: redirectTo,
          search: `?entity_id=${record.entity}`,
          state: { entity_info: entity_info }
        }}
    >
        <ContentCreate />&nbsp;{label}
    </Button>
  )
}


const EntitiesEdit = props => {
  const [artId, setArtId] = useState(null);


  const GetArtId = props => {
    if ('data' in props) {
      if (props.data !== undefined) {
        setArtId(props.data.art_id);
      }
    }
    return false;
  }
  
  return (
    <ThemeProvider theme={theme}>
        <BackButton art_id={artId} {...props} action = {"edit"}/>
        <PageTitle art_id={artId} {...props}>Edit</PageTitle>
        <Edit actions={<GetArtId {...props} />} {...props}>
            <TabbedForm toolbar={<EditFormToolbarActions showDeleteButton={false/*userHasPermission("processes-delete")*/} />}>
                <FormTab label="Details">
                  {/*<ReferenceInput source="entity_type" reference="enumerations-entity-types" validate={validations.validateEntityType} >
                    <AutocompleteInput optionText="label" />
                  </ReferenceInput>
                  <ReferenceInput source="country_code" reference="enumerations-country-codes" validate={validations.validateCountryCode} >
                    <AutocompleteInput optionText="label" />
                  </ReferenceInput> */}
                  <TextField source="entity_type" />
                  <TextField source="country_code" />
                  <TextInput source="successor_art_id" label="Successor Entity" helperText="ART ID of the successor entity" validate={validations.validateSuccessorEntity} />
                  {/* <ReferenceInput source="successor_entity" reference="entity-art-ids" allowEmpty >
                    <AutocompleteInput optionText="art_id" shouldRenderSuggestions={(val) => { return val.trim().length > 2 }}/>
                  </ReferenceInput> */}
                  {/* <ReferenceInput source="change_type" reference="enumerations-artid-change-types" allowEmpty>
                    <SelectInput optionText="label" />
                  </ReferenceInput> */}
                  <BooleanInput source="verified" label="Verified"/>
                  <BooleanInput source="public" label="Public"/>
                  <BooleanInput source="active" label="Active"/>
                  <DateField source="insert_date" label="Insert date" locales="fr-FR" />
                  <TextField source="source_code" label="Insert source" />
                </FormTab>
                <FormTab label="idkeys" path='idkeys' >
                  <CustomAddButton label="Add idkey" redirect={`/entity-idkeys/create`} search={`?entity_id=${props.id}`} />
                  <ReferenceManyField fullWidth reference="entity-idkeys" target="entity_id" addLabel={false}
                      perPage={perPageReferenceFields} sort={{ field: 'id', order: 'DESC' }}
                  >
                      <List {...props}
                      filterDefaultValues={{entity_id: props.id}}
                      exporter={false}
                      bulkActionButtons={<DocBulkActionButtons/>}
                      > 
                          <Datagrid>
                            <TextField source="idkey" />
                            <TextField source="idkey_code" />
                            <BooleanField source="active" label='Active'/>
                            <BooleanField source="variation" label='Variation'/>
                            <DateField source="insert_date" locales="fr-FR" />
                            <TextField label="Insert source" source="source_code" />
                            <CustomEditButton label="Edit" redirect={`/entity-idkeys/`} />
                          </Datagrid>
                      </List>
                  </ReferenceManyField>
                </FormTab>
                <FormTab label="names" path='names' >
                  <CustomAddButton label="Add name" redirect={`/entity-names/create`} search={`?entity_id=${props.id}`} />
                  <ReferenceManyField fullWidth reference="entity-names" target="entity_id" addLabel={false}
                      perPage={perPageReferenceFields} sort={{ field: 'id', order: 'DESC' }}
                  >
                      <List {...props}
                      filterDefaultValues={{entity_id: props.id}}
                      exporter={false}
                      bulkActionButtons={<DocBulkActionButtons/>}
                      > 
                          <Datagrid>
                            <TextField source="name" />
                            <TextField label="Name type" source="entity_name_type_label" />
                            <BooleanField source="active" label='Active'/>
                            <DateField source="insert_date" locales="fr-FR" />
                            <TextField label="Insert source" source="source_code" />
                            <CustomEditButton label="Edit" redirect={`/entity-names/`} />
                          </Datagrid>
                      </List>
                  </ReferenceManyField>
                </FormTab>
                <FormTab label="dates" path='dates' >
                  <CustomAddButton label="Add date" redirect={`/entity-dates/create`} search={`?entity_id=${props.id}`} />
                  <ReferenceManyField fullWidth reference="entity-dates" target="entity_id" addLabel={false}
                      perPage={perPageReferenceFields} sort={{ field: 'id', order: 'DESC' }}
                  >
                      <List {...props}
                      filterDefaultValues={{entity_id: props.id}}
                      exporter={false}
                      bulkActionButtons={<DocBulkActionButtons/>}
                      > 
                          <Datagrid>
                            <NumberField locales='pt-PT' source="year" />
                            <NumberField locales='pt-PT' source="month" />
                            <NumberField locales='pt-PT' source="day" />
                            <TextField label="Date type" source="entity_date_type_label" />
                            <DateField source="insert_date" locales="fr-FR" />
                            <TextField label="Insert source" source="source_code" />
                            <CustomEditButton label="Edit" redirect={`/entity-dates/`} />
                          </Datagrid>
                      </List>
                  </ReferenceManyField>
                </FormTab>
                <FormTab label="predicates" path='predicates' >
                  <CustomAddButton label="Add predicate" redirect={`/entity-predicates/create`} search={`?entity_id=${props.id}`} />
                  <ReferenceManyField fullWidth reference="entity-predicates" target="entity_id" addLabel={false}
                      perPage={perPageReferenceFields} sort={{ field: 'id', order: 'DESC' }}
                  >
                      <List {...props}
                      filterDefaultValues={{entity_id: props.id}}
                      exporter={false}
                      bulkActionButtons={<DocBulkActionButtons/>}
                      > 
                          <Datagrid>
                            <TextField source="predicate" />
                            <TextField label="Predicate type" source="entity_predicate_type_label" />
                            <DateField source="insert_date" locales="fr-FR" />
                            <TextField label="Insert source" source="source_code" />
                            <CustomEditButton label="Edit" redirect={`/entity-predicates/`} />
                          </Datagrid>
                      </List>
                  </ReferenceManyField>
                </FormTab>
          </TabbedForm>
        </Edit>
    </ThemeProvider>
  );
}

export default EntitiesEdit;