import React from 'react';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import { Edit, SimpleForm, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { validations } from './validations';
import { perPageReferenceFields } from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const SourceEntitiesBlockedReferencesEdit = props => {

    return (
        <>
            <Edit undoable={true} {...props}>      
                <SimpleForm toolbar={<EditFormToolbarActions />}>
                    <BackButton {...props} action = {"edit"}/>
                    <PageTitle {...props}>Edit</PageTitle>
                    <ReferenceInput label="Select Source" source="source" reference="source-codes" perPage={perPageReferenceFields} validate={validations.validateCode} >
                        <AutocompleteInput optionText="code" optionValue="id" />
                    </ReferenceInput>
                    <TextInput source="reference" fullWidth={true} validate={validations.validateReference} />
                    <TextInput source="reason" fullWidth={true} validate={validations.validateReason} />
                    <TextInput source="correct_reference" fullWidth={true} validate={validations.validateCorrectReference} />

                </SimpleForm>
            </Edit>
        </>
    );
}

export default SourceEntitiesBlockedReferencesEdit;