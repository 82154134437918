import React from 'react';
import {BooleanInput, Create, DateInput, SimpleForm, TextInput, SelectInput, NumberInput, ReferenceInput, AutocompleteArrayInput, ReferenceArrayInput, ReferenceManyInput} from 'react-admin';
import {validations} from './validations';
import {outputTypes} from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const SupplierProductRequestCreate = props => {

  return (
    <>
      <Create undoable={true} {...props}>
        <SimpleForm redirect="list">
          <BackButton {...props} action = {"create"}/>
          <PageTitle {...props}>Create</PageTitle>
          <Typography variant="h6" gutterBottom>
            Supplier Product
          </Typography>
          <ReferenceInput source="supplier_product" reference="supplier-products" fullWidth={true} validate={validations.validateSupplierProduct} >
            <SelectInput optionText="product_label" />
          </ReferenceInput>
          <TextInput source="endpoint_url"  helperText="Url of the request" fullWidth={true} validate={validations.validateEndpointUrl}/>
          <SelectInput 
            source="request_method" 
            choices={[
              { id: 'GET', label: 'GET' },
              { id: 'POST', label: 'POST' }
            ]}
            optionText="label"
            helperText={"Type of the request"}
            validate={validations.validateRequestMethod}
          />
          <TextInput label="Request label" source="label" fullWidth={true} validate={validations.validateLabel}/>
          <BooleanInput source="is_order" defaultValue={false} label="Is it inside an order execution?"/>
          <NumberInput source="request_order" helperText={"execution ordering of the request (empty if no ordering is needed)"} min={1} max={10} defaultValue={null} label="Order of execution"/>
          <Typography variant="h6" gutterBottom>
            Output
          </Typography>
          <TextInput source="output_check" fullWidth={true} helperText={"path to check if the response is valid"} validate={validations.validateOutputCheck}/>
          <TextInput source="output_path" fullWidth={true} helperText={"path of the response to return"} validate={validations.validateOutputPath}/>
          <SelectInput source="output_type" fullWidth={true} choices={outputTypes} optionText="text" optionValue="value" helperText={"type of the output in the response"} validate={validations.validateOutputType}/>
          <Typography variant="h6" gutterBottom>
            Save in Cache
          </Typography>
          <BooleanInput source="save_response" helperText={"if the response should be saved in cache"} defaultValue={true} label="Save response in cache?"/>
          <TextInput label="Key to save the response" helperText={"key to where the response has to be associated in the cache"} source="key_save_response" validate={validations.validateKeySaveResponse}/>
          <Typography variant="h6" gutterBottom>
            Fallback request
          </Typography>
          <ReferenceInput source="fallback_request" reference="supplier-product-requests" allowEmpty validate={validations.validateFallbackRequest} >
            <SelectInput helperText={"request to perform if the current request fails"} optionText="label" />
          </ReferenceInput>
          <Typography variant="h6" gutterBottom>
              Failure
          </Typography>
          <TextInput label="Message on failure" helperText={"message to show when error occurs"} source="error_message" fullWidth={true} validate={validations.validateErrorMessage}/>
        </SimpleForm>
      </Create>
    </>
  );
}

export default SupplierProductRequestCreate;
