import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const IdKeys = (props) => {
    return (
        <div>
            <MappingSectionInput field='idkeyAR' required={true} {...props}/>
            <MappingSectionInput field='textAR' {...props}/>
            <MappingSectionInput field='idkeyCode' {...props}/>
            <MappingSectionInput field='idkey' {...props}/>
            <MappingSectionInput field='quality_level' {...props}/>
            <MappingSectionInput field='primaryIdkey' {...props}/>
            <MappingSectionInput field='start' {...props}/>
            <MappingSectionInput field='end' {...props}/>
            <MappingSectionInput field='active' required={true} {...props}/>
            <MappingSectionInput field='validated' required={true} {...props}/>
        </div>  
    );  
};

export default IdKeys;