import React from 'react';
import {Filter, ReferenceInput, DateField, BooleanField, AutocompleteInput, Datagrid, EditButton, List, TextField, ReferenceManyField, SimpleShowLayout} from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import "../../index.css";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { perPageReferenceFields } from "../../constants";
import ClearFiltersButton from "../../components/ClearFiltersButton";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";


const SupplierProductCountryReferencesList = props => (
    <ThemeProvider theme={theme}>
      <h2>{props.options.label}</h2>
      <List filters={false} bulkActionButtons={<DocBulkActionButtons/>} exporter={false} {...props}
            actions={<ListToolbarActions showCreateButton={true}/>}>
        <Datagrid  {...props} >
          <TextField source="supplier_product_label" label="Supplier Product"/>
          <TextField source="country_label" label="Country"/>
          <TextField source="reference" label="Reference"/>
          <EditButton/>
        </Datagrid>
      </List>
    </ThemeProvider>
  );
  
  export default SupplierProductCountryReferencesList;