import React from 'react';
import {useFormState} from 'react-final-form';
import {useState, useRef, useEffect} from 'react';
import { Show, Loading, SimpleForm, Toolbar, CardContentInner, useDataProvider, FormDataConsumer, useCreateController, ReferenceManyField, Edit, SelectInput, TextInput, TabbedForm, FormTab, SearchInput, List, Filter, SaveButton, NumberInput, SimpleShowLayout, AutocompleteInput, ReferenceInput, ReferenceField, Datagrid, TextField, EditButton, CreateButton } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useRecordContext } from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import { perPageReferenceFields } from "../../constants";
import "../../index.css";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import { validations } from "./validations";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {
  useNotify,
  useRedirect,
  useRefresh,
  fetchStart, fetchEnd, 
} from 'ra-core';
import { makeStyles } from '@material-ui/core/styles';
import OrderStatusTable from './OrderStatus';
import CompaniesList from './CompaniesList';
import { Api } from "../../helpers/api";

const useStyles = makeStyles({
  stepslist: {
    padding: 0,
    paddingTop: 16,
  },
});

const useIteratorStyle = makeStyles(() => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'row',
  },
  form: {
    display: 'inline',
    width: '100%',
  },
}));


const DocumentEditToolbar = props => {
  return(
      <Toolbar
          {...props}
          style = {{
              alignVertical: 'center'
          }}
      >
          <SaveButton label='Submit' icon={<ArrowUpwardIcon />}/>
      </Toolbar>
  )
};

function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const OrdersList = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const classes = useStyles();
    const iteratorStyleClass = useIteratorStyle();
    const refresh = useRefresh();
    const [value, setValue] = React.useState(0);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [orderCount, setOrderCount] = useState(0);
    const [companies, setCompanies] = useState([]);
    const [supplier, setSupplier] = useState();
    const [product, setProduct] = useState();
    const [reference, setReference] = useState();
    const [client, setClient] = useState();
    const [companyName, setCompanyName] = useState();
    const [page, setPage] = useState(1);
    const [suppliers, setSuppliers] = useState([]);
    const [manualTasks, setManualTasks] = useState(rows);
   

    useEffect(() => {
      getOrdersHistory();
    }, [orderCount]);

    useEffect(() => {
      getSuppliers();

      const interval = setInterval(() => {
        setRows([]);
        getOrdersHistory();
      }, 180000);
      
      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.

    }, []);

    const FormComponent = props => {

      const filterBy = props.filterBy;

      return (
        <>
          <Box flex={1} mr={{ xs: 0, sm: '0.33em' }}>
            <SelectInput source="client" choices={[
                  { id: 'Worldbox', name: 'Worldbox' }
                ]} validate={validations.validateClient} />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.33em' }}>
            <SelectInput source="supplier" choices={filterSuppliers(filterBy)} optionText="name" optionValue="id" validate={validations.validateSupplier} />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.33em' }}>
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData, ...rest }) => (
                <ReferenceInput source="product" reference="supplier-products" filter={formData.supplier ? {supplier: formData.supplier} : false} validate={validations.validateProduct} >
                  <SelectInput optionValue="product" optionText="product_label" />
                </ReferenceInput>
              )}
              </FormDataConsumer>
          </Box>
        </>
      );
    }


  const filterSuppliers = (type) => {
    const suppliersByWin = [];
    const suppliersByName = [];

    suppliers.forEach((supplier) => {
      if (supplier.order_type === "ByName") {
        suppliersByName.push(supplier);
      }
      else {
        suppliersByWin.push(supplier);
      }
    });
    
    if (type === "win") {
      return suppliersByWin;
    }
    else {
      return suppliersByName;
    }
  }

    const getSuppliers = () => {
      return Api.get('/suppliers', true)
          .then(( response ) => {
              console.log('RESPONSE', response);
              if (response.type === "success") {
                setSuppliers(response.result.results);
              }
              else {
                  console.log("response", response);
              }
              setLoading(false);
          })
          .catch(error => {
              console.log("error", error);
              setError(error);
              setLoading(false);
          })
    }
    const getOrdersHistory = () => {
      return Api.get('/orders', true)
          .then(( response ) => {
              console.log('RESPONSE', response);
              if (response.type === "success") {
                setRows(response.result.results);
                const action_needed = response.result.results.filter(row => row.action_needed == true)
                setManualTasks(action_needed.length);
              }
              else {
                  console.log("response", response);
              }
              setLoading(false);
          })
          .catch(error => {
              console.log("error", error);
              setError(error);
              setLoading(false);
          })
  };

    const createOrderByWin = (data) => {
      setProduct(data['product']);
      setSupplier(data['supplier']);
      setClient(data['client']);
      setLoading(true);
      
      return Api.post('/orders/create-and-process-order-by-win/', data, true)
        .then(response => {
          if (response.type === 'success'){
            setRows([]);
            setOrderCount(orderCount + 1);
            if ('name' in data){
              handleChange(null, 0);
            }
            
            notify('Order created successfully', 'success');
            setProduct();
            setSupplier();
            setReference();
            window.location.reload();
          }
          else if (response.type === "auth_error") {
            console.log("Error starting the process - authentication error");
          }
          else {
              console.log("Error starting the process - response", response);
              notify(response.message, 'error');
              setProduct();
              setSupplier();
              setReference();
              setLoading(false);
          }
        })
        .catch((e) => {
          console.log("Exception starting the process - e", e);
        }); 
    }

    const searchCompanyByName = (data) => {
      setProduct(data['product']);
      setSupplier(data['supplier']);
      setClient(data['client']);
      setCompanyName(data['name']);
      setLoading(true);

      return Api.post('/orders/search-company-by-name/', data, true)
      .then(response => {
        if (response.type === 'success'){
          setCompanies(response.result.results)
          setLoading(false);
        }
        else if (response.type === "auth_error") {
          console.log("Error starting the process - authentication error");
        }
        else {
            console.log("Error starting the process - response", response);
            notify(response.message, 'error');
            setProduct();
            setSupplier();
            setReference();
            setLoading(false);
        }
      })
      .catch((e) => {
        console.log("Exception starting the process - e", e);
      }); 
    }
    
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
      setCompanies([]);
    };

    const showManualTasks = () => {
      if (manualTasks > 0) {
        return <h2 align="right" style={{"color": "red"}}>There are {manualTasks} manual tasks pending!</h2>
      }
      else {
        return <h2 align="right" >There are no manual tasks pending.</h2>
      }
      
    };

    return(
        <ThemeProvider theme={theme}>
            <h2>{props.options.label}</h2>
            {showManualTasks()}
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Search by win" {...a11yProps(0)} /> 
                        <Tab label="Search by name" {...a11yProps(1)} />
                        <Tab label="Logs" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <SimpleForm classes={iteratorStyleClass} toolbar={<DocumentEditToolbar {...props}/>} save={(data) => {console.log(data); createOrderByWin(data, props, notify, redirect, refresh); }} {...props}>
                    
                    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                      <FormComponent filterBy="win" />
                    </Box>
                    
                    <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, ...rest }) => (formData.supplier && suppliers[formData.supplier-1].order_type === "ByRegNumberAndCountry" &&
                      <>
                        <TextInput source='crn' label='Reg Number' fullWidth={true} validate={validations.validateRegisterNumber} />
                        <ReferenceInput source='country' reference='enumerations-country-codes' fullWidth={true} validate={validations.validateCountryCode} >
                          <AutocompleteInput optionText="label" optionValue="id" />
                        </ReferenceInput>
                      </>
                    )}
                    </FormDataConsumer>

                    <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, ...rest }) => ((formData.supplier && suppliers[formData.supplier-1].order_type === "ByWIN") &&
                      <>
                        <TextInput source='win' label='WIN' validate={validations.validateWin} />
                      </>
                    )}
                    </FormDataConsumer>

                    <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, ...rest }) => ((formData.supplier && suppliers[formData.supplier-1].order_type === "ByWINandReference") &&
                      <>
                        <TextInput source='win' label='WIN' fullWidth={true} validate={validations.validateWin} />
                        <TextInput source='reference' label='Reference' fullWidth={true} validate={validations.validateReference} />
                      </>
                    )}
                    </FormDataConsumer>
                  </SimpleForm>
                  {loading && (
                    <div>
                      <h4>Creating order...</h4>
                      <CircularProgress/>
                    </div>)}
                  <CardContentInner 
                      className={classes.stepslist}
                      key={props.version}
                  >
                    <OrderStatusTable rows={rows} />
                  </CardContentInner>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <SimpleForm classes={iteratorStyleClass} toolbar={<DocumentEditToolbar {...props}/>} save={(data) => {console.log('DATA', data); searchCompanyByName(data); }} {...props}>
                    
                    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                      <FormComponent filterBy="name" />
                    </Box>
                    <TextInput source='name' label='Name' fullWidth={true} validate={validations.validateName} />
                  </SimpleForm>
                  {loading &&
                  (<div>
                      <h4>Searching company by name...</h4>
                      <CircularProgress/>
                    </div>)}
                  <CardContentInner 
                      className={classes.stepslist}
                      key={props.version}
                  >
                    <CompaniesList product={product} supplier={supplier} client={client} companyName={companyName} rows={companies} onWinSubmission={createOrderByWin}/>
                  </CardContentInner>
                </TabPanel>
                <TabPanel value={value} index={2}>
                </TabPanel>
            </Box>
        </ThemeProvider>
    )
};
export default OrdersList;