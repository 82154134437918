import {
    required,
    maxLength,
} from 'react-admin';

export const validations = {
    validateUsername: [required(), maxLength(128)],
    validatePassword: [required(), maxLength(128)],
    validateFirstName: [required(), maxLength(128)],
    validateLastName: [maxLength(128)],
    validateAccount: [required()]
};
