import React from 'react';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, BooleanInput } from 'react-admin';
import { validations } from './validations';
import { perPageReferenceFields } from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const ImportMappingLabelsEdit = props => {

  return (
    <>
      <Edit undoable={true} {...props}>
        <SimpleForm toolbar={<EditFormToolbarActions showDeleteButton={false} />}>
          <BackButton {...props} action = {"edit"}/>
          <PageTitle {...props}>Edit</PageTitle>
          <ReferenceInput label="Select property name" source="property_name" reference="import-mapping-labels-property-names" perPage={perPageReferenceFields}>
            <SelectInput fullWidth={true} optionText="property_name" optionValue="property_name" />
          </ReferenceInput>
          <ReferenceInput label="Language Code" source="language_code" reference="enumerations-language-codes" perPage={perPageReferenceFields}>
            <SelectInput optionText="code" optionValue="id" />
          </ReferenceInput>
          <TextInput source="label" fullWidth={true} validate={validations.validateLabel} />
          <TextInput source="value1" fullWidth={true} validate={validations.validateValue} />
          <TextInput source="value2" fullWidth={true} validate={validations.validateValue} />
          <TextInput source="value3" fullWidth={true} validate={validations.validateValue} />
          <TextInput source="value4" fullWidth={true} validate={validations.validateValue} />
          <TextInput source="value5" fullWidth={true} validate={validations.validateValue} />
        </SimpleForm>
      </Edit>
    </>
  );
}

export default ImportMappingLabelsEdit;
