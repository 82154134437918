import React, { useState } from "react";
import OthersSectionInput from "../../OthersSectionInput";
import { useForm } from "react-final-form";

const Others = (props) => {
  const form = useForm();
  var formdata = form.getState().values;

  return (
    <div>
      <OthersSectionInput style={{ width: "100%" }} {...props}/>
    </div>
  );
};

export default Others;
