import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Show, SimpleForm, Toolbar, CardContentInner, Form, useDataProvider, FormDataConsumer, useCreateController, ReferenceManyField, Edit, SelectInput, TextInput, TabbedForm, FormTab, SearchInput, List, Filter, SaveButton, NumberInput, SimpleShowLayout, AutocompleteInput, ReferenceInput, ReferenceField, Datagrid, TextField, EditButton, CreateButton } from 'react-admin';
import Button from '@material-ui/core/Button';
import createOrderByWin from './List.js';
import { validations } from "./validations";

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: "bold"
    }
  }))(TableCell);
  
export default class CompaniesList extends PureComponent {
    
    render() {
        return (
            <TableContainer component={Paper}>
                <Table aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {/* <StyledTableCell align="right">Initial Records</StyledTableCell> */}
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>RegisteredName</StyledTableCell>
                            <StyledTableCell>TradeName</StyledTableCell>
                            <StyledTableCell>HeadOfficeAddress</StyledTableCell>
                            <StyledTableCell>RegistrationNumber</StyledTableCell>
                            <StyledTableCell>CountryCode</StyledTableCell>
                            <StyledTableCell>WIN</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { this.props.rows.map(row => (
                            <TableRow key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.registeredName}</TableCell>
                                <TableCell>{row.tradeName}</TableCell>
                                <TableCell>{row.headOfficeAddress}</TableCell>
                                <TableCell>{row.registrationNumber}</TableCell>
                                <TableCell>{row.countryCode}</TableCell>
                                <TableCell>
                                    <SimpleForm save={(data) => {
                                        data['product'] = this.props.product;
                                        data['supplier'] = this.props.supplier;
                                        data['client'] = this.props.client;
                                        data['name'] = row.name;
                                        data['reference']= row.id; console.log(data); this.props.onWinSubmission(data)}} >
                                        <TextInput label='win' source='win' validate={validations.validateWin} />
                                    </SimpleForm>
                                </TableCell>
                            </TableRow>
                        )) }
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}
