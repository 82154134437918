import React from 'react';
import { CreateButton, TopToolbar, sanitizeListRestProps } from 'react-admin';


const ListToolbarActions = ({
    showCreateButton,
    className,
    basePath,
    ...rest
}) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        { showCreateButton && <CreateButton basePath={basePath} /> }
    </TopToolbar>
);

export default ListToolbarActions;
