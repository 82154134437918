import {minLength, maxLength, required, } from 'react-admin';

export const validations = {
  validateStringAR: [required()],
  validateLanguageCode: [required(), minLength(2)],
  validateType: [required()],
  validateEnd: [required()],
  validateRestated: [required()],
  validateActive: [required()],
  validateValidated: [required()],
  validateElements: [required()],
  validadeElementsAR: [required()],
  validateElementsValue: [required()],
  validateElementsType: [required()],
  validateElementsExact: [required()],
  validateElementsARstringAR: [required()],
  validateElementsARvalueAR: [required()],
  validateElementsARGroup: [required()],
  validateElementsARExact: [required()]

};