import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, BooleanInput, FormDataConsumer, FileInput, FileField, AutocompleteInput} from 'react-admin';
import { validations } from './validations';
import { perPageReferenceFields } from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const SourceEntitiesBlockedReferencesCreate = props => {
  return (
    <>
      
      <Create undoable={true} {...props}>
        <SimpleForm redirect="list">
          <BackButton {...props} action = {"create"}/>
          <PageTitle {...props}>Create</PageTitle>
          <BooleanInput source="bulkUpload" label="Multiple blocked references?" />
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => formData.bulkUpload &&
              <FileInput source="bulk_file" accept=".csv" label="Upload blocked references csv file" validate={validations.validateFile}>
                <FileField source="src" title="title"/>
              </FileInput>
            }
          </FormDataConsumer>

          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => !formData.bulkUpload &&
              <React.Fragment>
                <ReferenceInput label="Select Source" source="source" reference="source-codes" perPage={perPageReferenceFields} validate={validations.validateCode} >
                  <AutocompleteInput optionText="code" optionValue="id" />
                </ReferenceInput>
                <TextInput source="reference" fullWidth={true} validate={validations.validateReference} />
                <TextInput source="reason" fullWidth={true} validate={validations.validateReason} />
                <TextInput source="correct_reference" fullWidth={true} validate={validations.validateCorrectReference} />

              </React.Fragment>
            }
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    </>
  );
}

export default SourceEntitiesBlockedReferencesCreate;
