import React, { useState, useCallback, useEffect } from "react";
import {
  SelectInput,
  TextInput,
  AutocompleteArrayInput,
  required,
  BooleanInput
} from "react-admin";
import { useForm } from "react-final-form";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

const MappingSectionInput = ({ record, ...props }) => {
  let types = props.types;
  let field = props.field;
  let source = props.source;
  // If it is an item then the source needs to take off the last character (a dot)
  if (props.listObject === "item") {
    source = source.slice(0, -1);
  }
  let functions = props.functions;
  let functionsByType = props.functionsByType;
  let requiredField = props.required;
  let subsectionChoices = props.subsectionChoices;

  // Define required subfield
  const form = useForm();

  if (field in record) {
    record[field]["required"] = props.required ? "true" : "false";
  }

  // Set typeSelected as value in the form
  var formdata = form.getState().values;

  function getSubSection(obj, str) {
    const keys = str.split(".");
    let value = obj;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const match = key.match(/(.+)\[(\d+)\]/); // check if the key contains an array index
      if (match) {
        const propKey = match[1];
        const index = Number(match[2]); // extract the index number
        value = value[propKey][index];
      } else {
        value = value[key];
      }
    }
    return value;
  }

  const subSection = getSubSection(formdata, source);

  let typeSelectedFlag = subSection?.[field]?.type !== undefined;
  let typeOnRecord = subSection?.[field]?.type;

  if (typeSelectedFlag)
    form.change(
      `${source}.${field}.required`,
      requiredField ? "true" : "false"
    );

  const [functionChoices, setFunctionChoices] = useState(functions);
  const [isTypeSelected, setIsTypeSelected] = useState(typeSelectedFlag);
  const [showFunctions, setShowFunctions] = useState(isTypeSelected);
  const [typeSelected, setTypeSelected] = useState(typeOnRecord);

  useEffect(() => {
    if (typeSelected && functionsByType) {
      setFunctionChoices(
        functionsByType.filter((pair) => pair.type === typeSelected)
      );
    }
  }, [typeSelected]);

  useEffect(() => {
    isTypeSelected && Array.isArray(functionChoices) && functionChoices.length
      ? setShowFunctions(true)
      : setShowFunctions(false);
  }, [isTypeSelected, functionChoices]);

  // Function when type is selected
  const handleTypeChange = (event) => {
    form.change(`${source}.${field}.function`, undefined);
    setIsTypeSelected(true);
    setTypeSelected(event.target.value);
  };

  const formReset = useCallback(() => {
    form.change(`${source}.${field}`, undefined);
    delete record[field];
  }, [form]);

  const handleClick = () => {
    formReset();
    setTypeSelected("");
    setIsTypeSelected(false);
    setFunctionChoices(functions);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      <div style={{ width: "200px", marginRight: "10px", textAlign: "right" }}>
        <span>
          {field.charAt(0).toUpperCase() + field.slice(1).replace("_", " ")}:
        </span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ marginRight: "10px", flexGrow: 1 }}>
          <SelectInput
            label="Type"
            source={`${source}.${field}.type`}
            optionText="label"
            optionValue="label"
            choices={types}
            onChange={handleTypeChange}
            fullWidth={true}
            validate={props.required ? required() : false}
          />
        </div>
        <div
          style={{
            flexGrow: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {showFunctions && (
            <div
              style={{
                marginRight: "10px",
                flexGrow: 4,
                width: "175px",
              }}
            >
              <AutocompleteArrayInput
                label="Functions"
                source={`${source}.${field}.function`}
                choices={functionChoices}
                disabled={isTypeSelected ? false : true}
                fullWidth={true}
                suggestionLimit={50}
              />
            </div>
          )}
          <div style={{ flexGrow: 8 }}>
            {typeSelected !== "PlainBoolean" ? (
              subsectionChoices ? (
                <SelectInput
                  choices={subsectionChoices}
                  source={`${source}.${field}.value`}
                  label="Value"
                  fullWidth={true}
                  disabled={isTypeSelected ? false : true}
                  validate={props.required ? required() : false}
                />
              ) : (
                <TextInput
                  source={`${source}.${field}.value`}
                  label="Value"
                  fullWidth={true}
                  disabled={isTypeSelected ? false : true}
                  validate={props.required ? required() : false}
                />
              )
            ) : (
              <BooleanInput
                source={`${source}.${field}.value`}
                label=""
                fullWidth={true}
                disabled={isTypeSelected ? false : true}
                validate={props.required ? required() : false}
              />
            )}
          </div>
        </div>
      </div>

      <div style={{ marginRight: "10px", marginLeft: "10px", width: "50px" }}>
        {isTypeSelected && (
          <Button
            startIcon={<CloseIcon />}
            onClick={() => {
              handleClick();
            }}
            style={{
              boxSizing: "border-box",
              /* Shadow/xs */
              borderRadius: "8px",
              color: "#0000008a",
              /* Inside auto layout */
              fontSize: "85%",
              fontWeight: "bold",
              textTransform: "none",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MappingSectionInput;
