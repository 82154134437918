import React, { useState, useEffect } from "react";
import MappingSectionInput from "../../MappingSectionInput";
import {
  ArrayInput,
  SimpleFormIterator,
  Button,
  FormDataConsumer,
  Loading,
} from "react-admin";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Api } from "../../../helpers/api";

const Financials = (props) => {
  let financialItemsSource = `${props.source}.item`;
  const newObj = {};
  if (props.record?.item){
    for (const [key, value] of Object.entries(props.record?.item)) {
      newObj[key] = Array(value.length).fill(false);
    }
  }
  
  const [collapsedItems, setCollapsedItems] = useState(newObj);
  const [collapsedOtherItems, setCollapsedOtherItems] = useState(newObj);
  const [financialCodes, setFinancialCodes] = useState([]);
  const [allChoices, setAllChoices] = useState([]);
  
  const toggleCollapse = (index, subsection) => {
    const newCollapsedItems = {...collapsedItems};
    newCollapsedItems[subsection][index] = !newCollapsedItems[subsection][index];
    setCollapsedItems(newCollapsedItems);
  };

  const toggleCollapseOther = (index) => {
    const newCollapsedOtherItems = {...collapsedOtherItems};
    newCollapsedOtherItems["Other"][index] = !newCollapsedOtherItems["Other"][index];
    setCollapsedOtherItems(newCollapsedOtherItems);
  };

  const getFinancialCodes = () => {
    return Api.get(`/enumerations-im-financial-item-codes/`, true).then(
      (response) => {
        if (response.type === "success") {
          setFinancialCodes([
            ...new Set(response.result.results.map((item) => item.info2)),
          ]);
          setAllChoices(
            response.result.results.map((item) => {
              return { subsection: item.info2, id: item.code, name: item.code };
            })
          );
        }
      }
    );
  };

  useEffect(() => {
    getFinancialCodes();
  }, []);

  if (allChoices.length == 0) {
    return <Loading />;
  }

  return (
    <div>
      <MappingSectionInput field="availability_date" {...props} />
      <MappingSectionInput field="interval_type" required={true} {...props} />
      <MappingSectionInput field="rounding_level" required={true} {...props} />
      <MappingSectionInput field="currency_code" required={true} {...props} />
      <MappingSectionInput field="start" {...props} />
      <MappingSectionInput field="end" {...props} />
      <MappingSectionInput field="end_year" required={true} {...props} />
      <MappingSectionInput
        field="number_of_months"
        required={true}
        {...props}
      />
      <MappingSectionInput field="reporting_standard_latin" {...props} />
      <MappingSectionInput field="reporting_standard_local" {...props} />
      <MappingSectionInput field="restated" required={true} {...props} />
      <MappingSectionInput field="active" required={true} {...props} />
      <MappingSectionInput field="audited" required={true} {...props} />
      <MappingSectionInput field="limited" required={true} {...props} />
      <MappingSectionInput field="consolidated" required={true} {...props} />
      {financialCodes.map((item) => {
        const choices = allChoices.filter(choice => choice.subsection==item);
        return (
          <div>
            <span
              style={{
                fontSize: "1em",
                color: "rgb(50, 72, 168)",
                fontWeight: "bold",
              }}
            >
              {item}
            </span>
            <ArrayInput
              source={`${financialItemsSource}.${item}`}
              label={""}
              key={item}
            >
              <SimpleFormIterator>
                <FormDataConsumer>
                  {({
                    formData, // The whole form data
                    scopedFormData, // The data for this item of the ArrayInput
                    getSource,
                    ...rest
                  }) => (
                    <div>
                      <span style={{ fontSize: "0.8em" }}>
                        {scopedFormData?.arttence_financial_item_code?.value}
                      </span>
                      <Button
                        label={
                          collapsedItems[item][
                            getSource("")
                            .split(`item.${item}`)[1]
                              .match(/\[(.*?)\]/)[1]
                          ] ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <KeyboardArrowRightIcon />
                          )
                        }
                        onClick={() =>
                          toggleCollapse(
                            getSource("")
                            .split(`item.${item}`)[1]
                              .match(/\[(.*?)\]/)[1], item
                          )
                        }
                      />
                      {collapsedItems[item][
                        getSource("")
                          .split(`item.${item}`)[1]
                          .match(/\[(.*?)\]/)[1]
                      ] && (
                        <div>
                          <MappingSectionInput
                            list={true}
                            field="source_financial_item_code"
                            types={props.types}
                            functions={props.functions}
                            listObject="item"
                            functionsByType={props.functionsByType}
                            source={getSource("")}
                            {...rest}
                          />
                          <MappingSectionInput
                            list={true}
                            field="arttence_financial_item_code"
                            types={props.types}
                            functions={props.functions}
                            listObject="item"
                            functionsByType={props.functionsByType}
                            source={getSource("")}
                            subsectionChoices={choices}
                            {...rest}
                          />
                          <MappingSectionInput
                            list={true}
                            field="value"
                            types={props.types}
                            functions={props.functions}
                            listObject="item"
                            functionsByType={props.functionsByType}
                            required={true}
                            source={getSource("")}
                            {...rest}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </div>
        );
      })}
      <div>
            <span
              style={{
                fontSize: "1em",
                color: "rgb(50, 72, 168)",
                fontWeight: "bold",
              }}
            >Other</span>
            <ArrayInput
              source={`${financialItemsSource}.Other`}
              label={""}
              key="Other"
            >
              <SimpleFormIterator>
                <FormDataConsumer>
                  {({
                    formData, // The whole form data
                    scopedFormData, // The data for this item of the ArrayInput
                    getSource,
                    ...rest
                  }) => (
                    <div>
                      <span style={{ fontSize: "0.8em" }}>
                        {scopedFormData?.source_financial_item_code?.value}
                      </span>
                      <Button
                        label={
                          collapsedOtherItems["Other"][
                            getSource("")
                              .split(`item.Other`)[1]
                              .match(/\[(.*?)\]/)[1]
                          ] ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <KeyboardArrowRightIcon />
                          )
                        }
                        onClick={() =>
                          toggleCollapseOther(
                            getSource("")
                              .split(`item.Other`)[1]
                              .match(/\[(.*?)\]/)[1]
                          )
                        }
                      />
                      
                      {collapsedOtherItems["Other"][
                        getSource("")
                          .split(`item.Other`)[1]
                          .match(/\[(.*?)\]/)[1]
                      ] && (
                        <div>
                          <MappingSectionInput
                            list={true}
                            field="source_financial_item_code"
                            types={props.types}
                            functions={props.functions}
                            listObject="item"
                            functionsByType={props.functionsByType}
                            source={getSource("")}
                            {...rest}
                          />
                          <MappingSectionInput
                            list={true}
                            field="value"
                            types={props.types}
                            functions={props.functions}
                            listObject="item"
                            functionsByType={props.functionsByType}
                            required={true}
                            source={getSource("")}
                            {...rest}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </div>
    </div>
    
  );
};

export default Financials;
