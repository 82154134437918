import React from 'react';
import {BooleanInput, Create, DateInput, SimpleForm, TextInput, AutocompleteArrayInput, ReferenceArrayInput} from 'react-admin';
import {validations} from './validations';
import {perPageReferenceFields} from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const ProductCreate = props => {

  return (
    <>
      <Create undoable={true} {...props}>
        <SimpleForm redirect="list">
          <BackButton {...props} action = {"create"}/>
          <PageTitle {...props}>Create</PageTitle>
          <TextInput source="code" fullWidth={true} validate={validations.validateCode}/>
          <TextInput source="label" fullWidth={true} validate={validations.validateLabel}/>
          <ReferenceArrayInput label='Processes' reference='processes' source='processes' perPage={perPageReferenceFields}>
            <AutocompleteArrayInput optionText='title' optionValue='id'/>
          </ReferenceArrayInput>
          <BooleanInput source="active" label="Is it active?" defaultValue={true} />
          <DateInput source='start_date' validate={validations.validateStartDate}/>
          <DateInput source='end_date'/>
        </SimpleForm>
      </Create>
    </>
  );
}

export default ProductCreate;
