import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';
import {ArrayInput, SimpleFormIterator} from "react-admin";


const KeyFinancials = (props) => {
    let elementsSource = `${props.source}.elements`
    let elementsARSource = `${props.source}.elementsAR`

    return (
        <div>
            <MappingSectionInput field='stringAR' required={true} {...props}/>
            <MappingSectionInput field='languageCode' required={true} {...props}/>
            <MappingSectionInput field='type' required={true} {...props}/>
            <MappingSectionInput field='levelOfRounding' {...props}/>
            <MappingSectionInput field='number_of_months' {...props}/>
            <MappingSectionInput field='currencyCode' {...props}/>
            <MappingSectionInput field='restated' required={true} {...props}/>
            <MappingSectionInput field='active' required={true} {...props}/>
            <MappingSectionInput field='validated' required={true} {...props}/>
            <MappingSectionInput field='end' required={true} {...props}/>
            <ArrayInput
                source={elementsSource}
                label="Elements"
            >
                <SimpleFormIterator>
                    <MappingSectionInput
                        list={true}
                        field="valueField"
                        types={props.types}
                        functions={props.functions}
                        listObject="elements"
                        required={true}
                        functionsByType={props.functionsByType}
                    />
                    <MappingSectionInput
                        list={true}
                        field="typeField"
                        types={props.types}
                        functions={props.functions}
                        listObject="elements"
                        required={true}
                        functionsByType={props.functionsByType}
                    />
                    <MappingSectionInput
                        list={true}
                        field="exact"
                        types={props.types}
                        functions={props.functions}
                        listObject="elements"
                        required={true}
                        functionsByType={props.functionsByType}
                    />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput
                source={elementsARSource}
                label="ElementsAR"
            >
                <SimpleFormIterator>
                    <MappingSectionInput
                        list={true}
                        field="stringAR"
                        types={props.types}
                        functions={props.functions}
                        listObject="elementsAR"
                        required={true}
                        functionsByType={props.functionsByType}
                    />
                    <MappingSectionInput
                        list={true}
                        field="valueAR"
                        types={props.types}
                        functions={props.functions}
                        listObject="elementsAR"
                        required={true}
                        functionsByType={props.functionsByType}
                    />
                    <MappingSectionInput
                        list={true}
                        field="group"
                        types={props.types}
                        functions={props.functions}
                        listObject="elementsAR"
                        required={true}
                        functionsByType={props.functionsByType}
                    />
                    <MappingSectionInput
                        list={true}
                        field="exact"
                        types={props.types}
                        functions={props.functions}
                        listObject="elementsAR"
                        required={true}
                        functionsByType={props.functionsByType}
                    />
                </SimpleFormIterator>
            </ArrayInput>
        </div>
    );  
};

export default KeyFinancials;