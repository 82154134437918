import {minLength, maxLength, required, } from 'react-admin';

export const validations = {
  validateType: [required()],
  validateRelation: [required()],
  validateReference: [minLength(1), maxLength(100)],
  validateReferenceFormer: [minLength(1), maxLength(100)],
  validateRegName: [minLength(5), maxLength(100)],
  validateCountryCode: [required()]
};
