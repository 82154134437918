import {minLength, maxLength, required, } from 'react-admin';

export const validations = {
  validateReferences: [required(), minLength(1)],
  validateReference: [required(), minLength(1), maxLength(500)],
  validateNameAR: [required(), minLength(1), maxLength(500)],
  validateTypeAR: [minLength(1), maxLength(500)],
  validateRelationType: [required()],
  validateOwnershipClass: [required()],
  validateConnectionType: [required()],
  validateFunctions: [minLength(0), maxLength(3)],
  validateFunctionCode: [required()],
  validateBoardType: [required()],
  validateBussinessAreas: [maxLength(3)],
  validateDescription: [minLength(2), maxLength(50)],
  validateActive: [required()]
};
