import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const Addresses = (props) => {
    let section = props.section;

    return (
        <div>
            <MappingSectionInput field='textAR' required={true}{...props}/>
            <MappingSectionInput field='languageCode' required={true} {...props}/>
            <MappingSectionInput field='translationCode' {...props}/>
            { section === 'branchAddresses' && 
                <MappingSectionInput field='name' {...props}/>
            }
            { (section !== 'legalDomiciles' && section !== 'birthPlace' && section !== 'nationalities') &&
                <div>
                    <MappingSectionInput field='addressLines' {...props}/>
                </div>
            }
            <MappingSectionInput field='typeAR' {...props}/>
            <MappingSectionInput field='postalCode' {...props}/>
            <MappingSectionInput field='city' {...props}/>
            <MappingSectionInput field='county' {...props}/>
            <MappingSectionInput field='countyCode' {...props}/>
            <MappingSectionInput field='countryCode' {...props}/>
            <MappingSectionInput field='coordinates' {...props}/>
            <MappingSectionInput field='start' {...props}/>
            <MappingSectionInput field='end' {...props}/>
            <MappingSectionInput field='active' required={true} {...props}/>
            <MappingSectionInput field='validated' required={true} {...props}/>
        </div>
    );
};

export default Addresses;