
import React, { Fragment } from "react";
import { TextField, Edit, Datagrid, SelectInput, SimpleForm, TextInput, ReferenceInput, Filter} from 'react-admin';
import { perPageReferenceFields } from "../../constants";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import  UpdateDocButton from '../../components/UpdateDocButton';


const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: "bold"
    }
  }))(TableCell);

const SectionFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Section" source="section" reference="sections" perPage={perPageReferenceFields} alwaysOn>
            <SelectInput optionText="section" optionValue='section' />
        </ReferenceInput>
    </Filter>
);

class ListErrors extends React.Component {
    constructor(props){
        super(props);
        console.log('LIST ERRORS', props);
        this.getErrorSections = this.getErrorSections.bind(this);
        this.errorSectionsChange = this.errorSectionsChange.bind(this);
        this.getAllErrors = this.getAllErrors.bind(this);
        this.getErrorValues = this.getErrorValues.bind(this);
        this.state = {document_id: props.id, errorInputs: {}};
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleErrorsChange = this.handleErrorsChange.bind(this);
        this.handleBooleanChange = this.handleBooleanChange.bind(this);
    }

    componentDidMount(){
        this.getAllErrors();
        console.log('ERROR VALUES', this.state);

    }

    handleErrorsChange(e){
        this.setState(e.target.values);
        console.log('HANDLE ERRORS CHANGE', this.state);
    }

    handleBooleanChange(e){
        var value = e.target.value;
        console.log('TARGET', e.target);
        const { name, checked } = e.target;
        console.log('NAME', name);
        console.log('VALUE', value);
        
        value = checked;

        this.setState({
            ...this.state, errorInputs: {
                ...this.state.errorInputs,
                [name]: value
                
                }
        });  

        console.log('Input Change', this.state.errorInputs);
    }

    handleInputChange(e){
        const { name, value } = e.target;

        const updated = e.target.getAttribute('data-updated');
        console.log('TARGET', e.target);
        console.log('NAME', name);
        console.log('VALUE', value);
        console.log('UPDATED', updated);

        this.setState({
            ...this.state, errorInputs: {
                ...this.state.errorInputs,
                [name]: value,
                [updated]: true
            }
        });  

        console.log('Input Change', this.state.errorInputs);
    }

    getErrorValues(errorInputs){
        this.setState({errorInputs: errorInputs});
    }


    getAllErrors(){
        var all_errors = [];
        var all_entity_errors = this.props.record.entityErrors;
        console.log('ALL ENTITY ERRORS', all_entity_errors);
        
        let index = 0;
        const entities = this.props.record.entities;
        const errors = [];
        const errorInputs = {};
        for (var entity_index in all_entity_errors){
            console.log(entity_index);
            const entity_errors = all_entity_errors[entity_index];
            entity_errors.forEach(function(item){
                console.log(item);
                errorInputs[`location_${index}`] = item['location']
                errorInputs[`current_value_${index}`] = item['current_value']
                errorInputs[`updated_${index}`] = false
                console.log('ERROR INPUTS', errorInputs);
                var error = Object.assign({}, {id: index, reference: entities[entity_index].reference}, item);
                errors.push(error);
                index = index + 1
            });

            // console.log(entity_errors);
            // all_errors = all_errors.concat(entity_errors);
        }

        /* const entities = this.props.record.entities;
        const errors = [];
        const errorInputs = {};
        all_errors.forEach(function(item, index){
            console.log(item);
            errorInputs[`location_${index}`] = item['location']
            errorInputs[`current_value_${index}`] = item['current_value']
            console.log('ERROR INPUTS', errorInputs);
            var error = Object.assign({}, {id: index, reference: entities[index].reference}, item);
            errors.push(error);
        });
     */ 
        this.state.errorInputs = errorInputs;
        this.errorSectionsChange(errors);


    }

    componentDidUpdate(prevProps){
        if (this.props.entityId !== prevProps.entityId){
            this.getErrorSections();
        }
    }

    errorSectionsChange(value){
        this.props.onErrorSectionsChange(value);
    }

    getErrorSections(){
        console.log('LIST ERRORS PROPS ENTITY ID:', this.props.entityId);
        var errors = [];
        if (this.props.entityId !== ''){
            console.log('LIST ERRORS ENTITY ERRORS', this.props.entityErrors);
            const entity_errors = this.props.entityErrors[this.props.entityId];
            const entity_id = this.props.entityId;
            const entities = this.props.record.entities;
            var errorInputs = {};
            entity_errors.forEach(function(item, index){
                console.log(item);
                errorInputs[`location_${index}`] = item['location']
                errorInputs[`current_value_${index}`] = item['current_value']
                errorInputs[`updated_${index}`] = false
                var error = Object.assign({}, {id: index, reference: entities[entity_id].reference}, item);
                errors.push(error);
            });

            this.state.errorInputs = errorInputs;
            this.errorSectionsChange(errors);
        } 
    }
    

    render(){
        let errorSections = this.props.errorSections;
        let props = this.props;

        return (


            /* <ListContextProvider {...props} value={listContext} >
                <Datagrid expand={<SectionEdit />}>
                    <TextField label="Location" source="reference" />
                    <TextField label="Location" source="location" />
                    <TextField label="Message" source="message" />
                    <TextField label="Samples" source="samples" />
                </Datagrid>
            </ListContextProvider> */
            /* <div>
                <table>
                    <thead>
                        <tr>
                            <th>Entity Reference</th>
                            <th>Location</th>
                            <th>Current Value</th>
                            <th>Message</th>
                            <th>Samples</th>
                        </tr>
                    </thead>
                    <tbody>
                        { errorSections.map((row) => (
                            <tr key={row.id}>
                                <td>{row.reference}</td>
                                <td>{row.location}</td>
                                <td>
                                    <input type='text' name='current_value' defaultValue={row.current_value} />
                                </td>
                                <td>{row.message}</td>
                                <td>{row.samples}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div> */


            
            <TableContainer component={Paper}>
                <Table aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align='left'>Entity Reference</StyledTableCell>
                            <StyledTableCell align='left'>Location</StyledTableCell>
                            <StyledTableCell align="left">Current Value</StyledTableCell>
                            <StyledTableCell align="left">Message</StyledTableCell>
                            <StyledTableCell align="left">Samples</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { errorSections.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell align='left'>{row.reference}</TableCell>
                                <TableCell align='left'>{row.location}</TableCell>
                                <TableCell align='left'>
                                    { !('value_type' in row) &&
                                        <input type='text' data-updated={`updated_${row.id}`} name={`current_value_${row.id}`} value={this.state.errorInputs[`current_value_${row.id}`]} onChange={this.handleInputChange} />
                                    }
                                    { row.value_type === 'boolean' &&
                                        <Fragment>
                                            <input type='checkbox' id='bool' data-updated={`updated_${row.id}`} name={`current_value_${row.id}`} value={this.state.errorInputs[`current_value_${row.id}`]} onChange={this.handleBooleanChange} checked={this.state.errorInputs[`current_value_${row.id}`]} />
                                            <label for='bool'>Active</label>
                                        </Fragment>
                                    }
                                    { row.value_type === 'variableDate' &&
                                        <input type='date' name={`current_value_${row.id}`} data-updated={`updated_${row.id}`} value={this.state.errorInputs[`current_value_${row.id}`]} onChange={this.handleInputChange} />
                                    }
                                </TableCell>
                                <TableCell align='left'>{row.message}</TableCell>
                                <TableCell align='left'>{row.samples}</TableCell>
                            </TableRow>
                        )) }
                    </TableBody>
                </Table>
                <UpdateDocButton values={this.state} />
            </TableContainer>
        );
    }
}


const SectionEdit = props => (

    
    <Edit 
        {...props}
        /* disable the app title change when shown */
        title=""
    >
        <SimpleForm
            /* The form must have a name dependent on the record, because by default all forms have the same name */
            form={`section_edit_${props.errorSections.id}`}
        >
            <TextInput label="Current Value" source="current_value" />
            <input type='hidden' name='document_id' defaultValue={props.id} />
        </SimpleForm>
    </Edit>
)
/* const ListErrors = props => {
    console.log('LIST ERRORS ENTITY ERRORS', props.entityErrors);
    console.log('List Errors');
    console.log(props);
    const validationErrors = props.record.validationErrors;
    var entities = props.invalidEntities;

    console.log('List Errors');
    console.log(props.entityId);

    var errorSections = function(){
        var sections_with_Errors = [];
        console.log('LIST ERRORS PROPS ENTITY ID:', props.entityId);

        if (props.entityId !== ''){
            sections_with_Errors = props.entityErrors[parseInt(props.entityId)]
        }

        return sections_with_Errors;
    };

    
    var errorSections = errorSections();
    console.log(errorSections);


    return (
        <List filters={<SectionFilter />} bulkActionButtons={false} exporter={false} {...props}
        sort={{ field: 'id', order: 'DESC' }}>
            <Datagrid>
                <TextField label="Location" source="id" />
                <TextField label="Current Value" source="source_code" />
                <TextField label="Message" source="batch_id" />
                <TextField label="Samples" source="status" />
            </Datagrid>
        </List>
    );
}; */

export default ListErrors;


/**<div className="dropdown">
            <Dropdown>
                <Dropdown.Toggle 
                variant="secondary btn-sm" 
                id="dropdown-basic">
                    Section
                </Dropdown.Toggle>

                <Dropdown.Menu style={{backgroundColor:'#73a47'}}>
                    {errorSections.map(function(item){
                            return (<Dropdown.Item key={item.id}>{item.name}</Dropdown.Item>);
                        })   

                    }
                </Dropdown.Menu>
            </Dropdown>
        </div> **/