import React, { useEffect, useState} from "react";
import {
  TextInput,
  Toolbar,
  SaveButton,
  SimpleForm,
  TextField,
  Labeled,
  Button
} from "react-admin";
import { Api } from "../../helpers/api";
import { JSONViewer } from "react-json-editor-viewer";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Visibility from "@material-ui/icons/Visibility";
import { useNotify } from "ra-core";
import CircularProgress from "@material-ui/core/CircularProgress";

const ScoringTester = ({ defaultSample, ...props }) => {
  const [rawFile, setRawFile] = useState(defaultSample);
  const [win, setWin] = useState("");
  const [doc, setDoc] = useState("");
  const [loading, setLoading] = useState(false);
  const [showJSON, setShowJSON] = useState(false);
  const [showJSONLabel, setShowJSONLabel] = useState("Show score card");
  const notify = useNotify();

  const getScore = (data) => {
    setLoading(true);
    setDoc("");
    setWin("");
    Api.post("/scoring-tester/request-score/", data).then((response) => {
      if (response.type === "success") {
        setWin(response.result.win);
        console.log("WIN", response.result)
      } else {
        setWin("");
        setLoading(false);
        notify("Bad Request");
      }
    });
  };

  useEffect(() => {
    if (win !== "") {
      waitResponse();
    }
  }, [win]);

  const waitResponse = () => {
    Api.post("/scoring-tester/wait-response/", { win: win }).then(
      (response) => {
        if (response.type === "success") {
          if (response.result !== "") {
            setDoc(response.result);
            setLoading(false);
          } else {
            setTimeout(waitResponse, 3000);
          }
        } else {
          notify("WIN ".concat(win).concat(" not found"));
        }
      }
    );
  };

  const handleChange = (e) => {
    setRawFile(e.target.value);
  };

  const WINToolbar = ({ pristine, ...props }) => {
    return (
      <Toolbar {...props} style={{ background: "none" }}>
        {!loading && (
          <SaveButton
            disabled={rawFile ? false : true}
            label="Test"
            icon={<DoubleArrowIcon />}
          />
        )}
      </Toolbar>
    );
  };

  const toggleJSON = () => {
    setShowJSON(!showJSON);
    if (showJSONLabel == "Show score card") {
      setShowJSONLabel("Hide");
    }
    else {
      setShowJSONLabel("Show score card");
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ marginRight: "16px" }}>
        <SimpleForm toolbar={<WINToolbar alwaysEnable={true} />} save={getScore}>
          <TextInput label="WIN" source="win" onChange={handleChange} />
        </SimpleForm>
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          {loading ? (
            <Labeled label="Final Score" >
              <CircularProgress />
            </Labeled>
          ) : (
            <>
              <Labeled label="Final Score" >
                <TextField source="final_score" record={doc} style={{ fontSize: "15px", marginRight: "40px" }} emptyText="-" />
              </Labeled>
              {doc && <Button onClick={toggleJSON} label={showJSONLabel} uppercase={false} startIcon={<Visibility />} variant="outlined" size="small"/>} {/* Button to toggle showJSON state */}
              {showJSON && <JSONViewer data={doc} collapsible />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScoringTester;
