import React from 'react';
import SectionForm from '../../components/SectionForm';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";


const DocumentEditCreate = props => {
    console.log('props create', props)
    return (
        <>
            <BackButton {...props} action = {"create"}/>
            <PageTitle {...props}></PageTitle>
            <SectionForm {...props} />
        </>
    )
}


export default DocumentEditCreate;