import React from 'react';
import { List, SimpleForm, ReferenceInput, SelectInput, AutocompleteInput, TextInput, BooleanInput, DateInput } from 'react-admin';
import { validations } from './validations';

const CompanyTypes = (props) => (

        <div>
            { 'location' in props && 
                <TextInput source='textAR' label='TextAR' validate={validations.validateTextAR}/>
            }
            <ReferenceInput source='type' label='Type' reference='enumerations-company-types' validate={validations.validateType} allowEmpty={true} >
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput source='form' label='Form' reference='enumerations-company-forms' allowEmpty={true}>
                <AutocompleteInput optionText="code" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput source='legalForm' label='Legal form' reference='enumerations-company-legal-forms' allowEmpty={true}>
                <AutocompleteInput optionText="code" optionValue="id" />
            </ReferenceInput>
            <BooleanInput source='exempted' label='Company is exempted?' />
            <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes' allowEmpty={true}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput source='translationCode' label='Translation Code' reference='enumerations-translation-codes' allowEmpty={true}>
                <SelectInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <DateInput source='start' label='Start Date' />
            <DateInput source='end' label='End Date' />
            <BooleanInput source='active' initialValue={true} label='Active?' validate={validations.validateActive} />
        </div>
            
)

export default CompanyTypes;