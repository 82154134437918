import * as React from "react";
import {
    FormWithRedirect,
    ReferenceInput,
    SelectInput,
    TextInput,
    SaveButton,
    DeleteButton,
    AutocompleteInput
} from 'react-admin';
import { parse } from 'query-string';
import { Box, Toolbar, Card } from '@material-ui/core';
import { validations, validateWholeRecord } from "./validations";
import { perPageReferenceFields } from "../../constants";

export const StepTransitionCustomForm = props => {

    const divStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%'
    };

    // Read the process_id from the location which is injected by React Router and passed to our component by react-admin automatically
    const { process_id: process_id_string } = parse(props.location.search);

    const process_id = process_id_string ? parseInt(process_id_string, 10) : '';

    const redirect = process_id ? `/processes/${process_id}/steps` : false;

    return (
        <FormWithRedirect
            {...props}
            redirect={redirect} defaultValue={{ process: process_id }} validate={validateWholeRecord}
            render={formProps => (
                <Card>
                <form>
                    <Box p="1em">
                        <Box display="flex">
                            <Box flex={1} mr="1em">

                                <ReferenceInput label="Source Step" source="source_step" reference="steps" 
                                        perPage={perPageReferenceFields} validate={validations.validateSourceStep} fullWidth>
                                    <AutocompleteInput optionText="title" />
                                </ReferenceInput>

                                <TextInput source="source_step_output" validate={validations.validateSourceStepOutput} fullWidth />

                                <Box display="flex">
                                    <Box flex={3} mr="0.5em">
                                        <ReferenceInput label="Next Step" source="next_step" reference="steps"
                                                perPage={perPageReferenceFields} allowEmpty={true} fullWidth>
                                            <AutocompleteInput optionText="title" />
                                        </ReferenceInput>
                                    </Box>
                                    <Box flex={1} ml="0.5em">
                                        <div style={divStyle}>or</div>
                                    </Box>
                                    <Box flex={3} ml="0.5em">
                                        <ReferenceInput label="Next Sub-process" source="next_step_sub_process" reference="processes" 
                                                perPage={perPageReferenceFields} filter={{ subprocess: true }} allowEmpty={true} fullWidth>
                                            <SelectInput optionText="title" />
                                        </ReferenceInput>
                                    </Box>
                                </Box>

                            </Box>

                        </Box>
                    </Box>
                    <Toolbar>
                        <Box display="flex" justifyContent="space-between" width="100%">
                            <SaveButton
                                saving={formProps.saving}
                                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                            />
                        </Box>
                    </Toolbar>
                </form>
                </Card>
            )}
        />
    )
}