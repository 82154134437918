import React from 'react';
import { TextInput, BooleanInput, ReferenceInput, AutocompleteInput, SelectInput, DateInput } from 'react-admin';
import { validations } from './validations';

const Texts = (props) => {
    console.log('PROPS');
    console.log(props);

    return (
        <div>
            { 'location' in props && 
                <TextInput source='longTextAR' fullWidth={true} label='Long text AR' validate={validations.validateLongTextAR} />
            }
            <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes' validate={validations.validateLanguageCode} >
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput source='translationCode' label='Translation Code' reference='enumerations-translation-codes' allowEmpty={true} >
                <SelectInput optionText="label" optionValue="id" />
            </ReferenceInput>
            <TextInput source='longText' label='Long text' validate={validations.validateLongText} />
            <DateInput source='start' label='Start Date' />
            <DateInput source='end' label='End Date' />
            <BooleanInput source='active' initialValue={true} label='Active?' validate={validations.validateActive} />
            <BooleanInput source='validated' initialValue={true} label='Validated?' validate={validations.validateValidated} />
        </div>
    );
};

export default Texts;