import {
    required,
    maxLength,
    minLength,
    regex
} from 'react-admin';

export const validations = {
    validateWin: [required(), regex(/^[A-Z]{2}\d{10}$/, 'Win number is not valid, must have 12 alphanumeric characters')],
    validateReference: [required()],
    validateSupplier: [required()],
    validateProduct: [required()],
    validateName: [required()],
    validateClient: [required()],
    validateRegisterNumber: [required()],
    validateCountryCode: [required()]
};
