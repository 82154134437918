import React from 'react';
import { TextField, TextInput, BooleanInput, ReferenceInput, AutocompleteInput, NumberInput, DateInput } from 'react-admin';
import { validations } from './validations';

const Booleans = (props) => {
    console.log('PROPS');
    console.log(props);

    return (
        <div>
            { 'location' in props && 
                
                <TextInput source='textAR' fullWidth={true} label='Text AR' validate={validations.validateTextAR} />
            
            }
            <ReferenceInput source='booleanType' label='Boolean Type' reference='enumerations-boolean-types'>
                <AutocompleteInput optionText="label" optionValue="id" validate={validations.validateBooleanType} />
            </ReferenceInput>
            <BooleanInput source='boolean' defaultValue={true} label='Boolean?' validate={validations.validateBoolean} />
            <DateInput source='start' label='Start Date' />
            <DateInput source='end' label='End Date' />
            <BooleanInput source='active' defaultValue={true} label='Active?' validate={validations.validateActive} />
        </div>
    );
};

export default Booleans;