import React from 'react';
import { Filter, List, Datagrid, TextField, SelectInput, DateInput , FunctionField, AutocompleteInput} from 'react-admin';
import { useState, useEffect } from 'react';
import { useDataProvider, Loading, Error, useCreateController } from 'react-admin';
import { useFormState } from 'react-final-form';
import BasicLinechart from '../../../components/BasicLineChart';
import { makeStyles } from '@material-ui/core/styles';
import ClearFiltersButton from '../../../components/ClearFiltersButton';

const months = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
]

function years(startYear) {
    var currentYear = new Date().getFullYear(), years = [];
    startYear = startYear || 1980;  
    while ( startYear <= currentYear ) {
        years.push({"id":startYear, "name":startYear});
        startYear++;
    }   
    return years;
}


const CompaniesCount = props => {
    const dataProvider = useDataProvider();
    const [sources, setSources] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const {
        loaded, // boolean that is false until the record is available
        record, // record fetched via dataProvider.getOne() based on the id from the location
    } = useCreateController(props);

    useEffect(() => {

        if (loaded && !sources) { 
            dataProvider.getList('sources', {
                pagination: { page: 1, perPage: 300 },
                sort: { field: 'code', order: 'ASC' },
            })
                .then(( response ) => {
                    if (response) {
                        setSources(response.data);
                    }
                    else {
                        console.log("response", response);
                    }
                    setLoading(false);
                })
                .catch(error => {
                    console.log("error", error);
                    setError(error);
                    setLoading(false);
                })

        }
    }, [record, dataProvider, loaded, sources]);


    const CompaniesFilter = (props) => {
        return(
            <div>
                <ClearFiltersButton {...props} filters={{source_id: '', month: '', year: ''}}/>
                <Filter {...props} >
                    <AutocompleteInput source="source_id" choices={sources} optionText="code" optionValue="id" allowEmpty alwaysOn/>
                    <SelectInput source="month" choices={months} label="Month" helperText=""  allowEmpty alwaysOn/>
                    <SelectInput source="year" choices={years(2020)} label="Year" helperText="" allowEmpty alwaysOn/>
                </Filter>
            </div>
        );
    }
    
    const monthlyCountsStyle = makeStyles({
        count: {
            color: 'green' 
        },
    });

    const MonthlyNewCountsField = props => {
        const classes = monthlyCountsStyle();
        return <FunctionField className={classes.count}  render={record => `+${record.monthly_new_companies}`} {...props}/>;
    };
    const MonthlyProcessedCountsField = props => {
        const classes = monthlyCountsStyle();
        return <FunctionField className={classes.count}  render={record => `+${record.monthly_processed_companies}`} sortable={true} {...props}/>;
    };

    return <>
        {/* <BasicLinechart></BasicLinechart> */}
        <h2>{props.options.label}</h2>
        <List title=" " filters={<CompaniesFilter />} bulkActionButtons={false} exporter={false} {...props} perPage={25}>
            
            <Datagrid>
                <TextField label="Source" source="source_code" sortable={false} />
                <TextField source="year" label="Year" />
                <TextField source="month_name" label="Month" />
                <TextField label="Total Companies" source="total_companies" />
                <MonthlyNewCountsField label="New Companies" sortBy="monthly_new_companies"/>
                <TextField label="Total Processed Companies" source="total_processed_companies" />
                <MonthlyProcessedCountsField label="Processed Companies" sortBy="monthly_processed_companies"/>
            </Datagrid>
        </List>

    </>


};

export default CompaniesCount;