import {minLength, maxLength, required, } from 'react-admin';

export const validations = {
  validateLanguageCode: [required()],
  validateDate: [required()],
  validateRiskScoreAR: [minLength(1), maxLength(300)],
  validatePaymentScoreAR: [minLength(1), maxLength(300)],
  validateTrendScoreAR: [minLength(1), maxLength(300)],
  validateCreditAR: [minLength(1), maxLength(300)],
  validateActive: [required()]
};
