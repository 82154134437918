import React from 'react';
import { Edit, SimpleForm, TextInput, DateInput, TextField, AutocompleteInput, ReferenceInput, SelectInput, BooleanInput, useDataProvider, Loading, Error, useCreateController } from 'react-admin';
import { validations } from './validations';
import { perPageReferenceFields } from '../../constants';
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";

const SupplierProductCountryReferencesEdit = props => {

    return (
        <>
            <Edit undoable={true} {...props}>
                <SimpleForm redirect="list">
                    <BackButton {...props} action = {"edit"}/>
                    <PageTitle {...props}>Edit</PageTitle>
                    <ReferenceInput source="supplier_product" reference="supplier-products" fullWidth={true} validate={validations.validateSupplierProduct} >
                        <SelectInput optionText="product_label" />
                    </ReferenceInput>
                    <ReferenceInput source="country_code" reference="enumerations-country-codes" fullWidth={true} validate={validations.validateCountryCode} >
                        <AutocompleteInput optionText="label" helperText={"for all countries, leave empty"} shouldRenderSuggestions={(val) => { return val.trim().length >= 2 }}/>
                    </ReferenceInput>
                    <TextInput source="reference" fullWidth={true} validate={validations.validateLabel} />
                </SimpleForm>
            </Edit>
        </>
    );
}

export default SupplierProductCountryReferencesEdit;