import React from 'react';
import { List, Datagrid, Loading } from 'react-admin';
import { Api } from "../../helpers/api";
import SectionForm from '../../components/SectionForm';
import { AsideDocument, GetRowHeader } from './utils.js';
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import theme from "../../constants/styleOverride";
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";
import { ThemeProvider } from "@material-ui/core/styles";
import { ToolbarCreate } from './toolbar.js';
import { DocumentEditFilter } from './filter.js';
import CustomDialog from '../../components/CustomDialog';


let _isMounted = false;

class DocumentEditList extends React.Component {
    constructor(props){
        super(props);
        this.getState = this.getState.bind(this);
        this.handleSectionChange = this.handleSectionChange.bind(this);
        this.handleDocumentChange = this.handleDocumentChange.bind(this);
        this.toogleDocument = this.toogleDocument.bind(this);
        this.showDocument = this.showDocument.bind(this);
        this.hideDocument = this.hideDocument.bind(this);
        this.deleteEntity = this.deleteEntity.bind(this);
        this.completeTask = this.completeTask.bind(this);
        this.state = {dialogLoading: false, dialogLoadingMessage: "", record: {}, batch_records: {}, document_id: props.id, entity_id: '', entity_name: 'Import Document', full_entity_name: 'Import Document', show_doc: false, section_name: '', batch_id: '', doc_choices: [], entity_choices: {}, section_choices: {}};
    }

    completeTask(batchId) {
        this.setState({dialogLoadingMessage: "Completing task..."});
        this.setState({dialogLoading: true});
        return Api.get(`/tasks/get-task-by-batch?batch_id=${batchId}`, true)
            .then(response => {
                if (response.type === "success") {
                    Api.post(`/tasks/${response.result}/finish/`, {}, true)
                    .then(response => {
                        if (response.type === "success") {
                          this.setState({dialogLoading: false});
                          window.location.replace('/#/tasks');
                          window.location.reload();
                        } 
                        else if (response.type === "auth_error") {
                          console.log("Error completing the Task - authentication error");
                          window.location.replace('/login');
                        }
                        else {
                            this.setState({dialogLoadingMessage: response.detail});
                            this.setState({dialogLoading: false});
                        }
                    })
                    .catch((e) => {
                      console.log("Exception completing the Task - e", e);
                    })
                }
            })
    };

    deleteEntity(values, batchId){
        this.setState({dialogLoadingMessage: "Deleting entity..."});
        this.setState({dialogLoading: true});
        return Api.post(`/document-edit/delete-entity/`, values, true)
            .then(response => {
                console.log('response', response);
                if (response.type === "success") {
                    this.setState({dialogLoading: false});
                    window.location.href = window.location.href.split("?")[0] + `?batch_id=${batchId}`;
                    window.location.reload();
                }
                else if (response.type === "auth_error") {
                    window.location.replace('/login');
                }
                else {
                    this.setState({dialogLoadingMessage: response.detail});
                    this.setState({dialogLoading: false});
                }
            })
            .catch((e) => { 
                console.log("Exception starting the process - e", e);
            })
    }



    getBatch(props){
        var query_params = props.location.search
        query_params = query_params.replace('?', '');
        let searchParams = new URLSearchParams(query_params);
        console.log('BATCH_ID', searchParams.get('batch_id'));
    }

    showDocument(){
        this.setState({show_doc: true});
        document.getElementById('show_doc').innerHTML = 'HIDE DOC'
    }

    hideDocument(){
        this.setState({show_doc: false});
        document.getElementById('show_doc').innerHTML = 'SHOW DOC'
    }

    toogleDocument(){
        // IF IT WAS SHOWNED -> (button -> HIDE DOC)
        if (this.state.show_doc){
            // HIDE DOC
           this.hideDocument()


        }
        //IF IT WAS HIDDEN (button -> SHOW DOC)
        else{
            // SHOW DOC
           this.showDocument()
        }

    }

    componentDidMount(){
        if (_isMounted !== true){
            this.getState(this.props);
            console.log('IS MOUNTED', _isMounted);
            _isMounted = true;
            console.log('IS MOUNTED', _isMounted);
        }
        /* this.getEntities(this.state.record);
        this.getSections(); */
    }

    handleSectionChange(e, choices, section){
        if (section){
            const full_entity_name = choices.filter(({
                id
            }) => e.target.value == id)[0].entity;

            const entity_name = full_entity_name.split(' ')[1];
            
            this.setState({entity_id: e.target.value, section_name: section, entity_name: entity_name, full_entity_name: full_entity_name});
        }
        else {
            this.setState({section_name: e.target.value});
        }
    }

    handleDocumentChange(e, choices, entity_id, section_name){
        const full_entity_name = choices.filter(({
            id
        }) => entity_id == id)[0].entity;

        const entity_name = full_entity_name.split(' ')[1];
        
        this.setState({document_id: e.target.value, entity_id: entity_id, section_name: section_name, entity_name: entity_name, full_entity_name: full_entity_name});
    }

    getState(props) {
        if (!('location' in props && 'search' in props.location)){
            return {};
        }

        var params = props.location.search;
        let state = Api.get(`/documents/info${params}`, true)
        .then(response => {
            if (response.type === "success") {
                if (_isMounted){

                    console.log(response.result);
                    let response_values = response.result;
                    this.setState({
                        batch_id: response_values.batch_id,
                        document_id: response_values.document_id,
                        entity_id: response_values.entity_id,
                        entity_name: response_values.entity_name,
                        full_entity_name: response_values.full_entity_name,
                        section_name: response_values.section_name,
                        doc_choices: response_values.doc_choices,
                        entity_choices: response_values.entity_choices,
                        section_choices: response_values.section_choices,
                        record: response_values.record,
                        batch_records: response_values.batch_records
                    });
                
                    return this.state;

                }
            } 
        });

        return state;
    };

    

    componentWillUnmount(){
        _isMounted = false;
    }
    
    render(){
        //update record
        var props = this.props;

        if (_isMounted === true && this.state.entity_id !== ''){
            try{
                return (
                    <ThemeProvider theme={theme}>
                        {['NOT_MATCHED', 'MULTIPLE_MATCHES'].includes(this.state.record.status) ? 
                            <BackButton {...props} basePath="/entities" batch_id={this.state.batch_id} action= {"fix"} /> :
                            <BackButton {...props} batch_id={this.state.batch_id} action = {"fix"}/>
                        }
                        <PageTitle {...props}></PageTitle>
                        <List 
                            actions={<ToolbarCreate doc_id={this.state.document_id} 
                                                    entity_id={this.state.entity_id} 
                                                    entity_name={this.state.entity_name}
                                                    full_entity_name={this.state.full_entity_name} 
                                                    section={this.state.section_name} 
                                                    batch_id={this.state.batch_id} 
                                                    records={this.state.batch_records}
                                                    operation_type='create'
                                                    onShowDocClick={this.toogleDocument}
                                                    onEntityDeletion={this.deleteEntity}
                                                    onCompleteTask={this.completeTask}
                                    />} 
                            aside={<AsideDocument doc_to_show={this.state.document_id} batch_records={this.state.batch_records} show_doc={this.state.show_doc} />}
                            filters={<DocumentEditFilter  
                                        document_id={this.state.document_id}
                                        entity_id={this.state.entity_id}
                                        section_name={this.state.section_name}
                                        doc_choices={this.state.doc_choices}
                                        entity_choices={this.state.entity_choices}
                                        onSectionChange={this.handleSectionChange}
                                        onDocumentChange={this.handleDocumentChange}
                                        section_choices={this.state.section_choices}/>} 
                            filterDefaultValues={{document_id: this.state.document_id, entity_id: this.state.entity_id, section_name: this.state.section_name}}
                            bulkActionButtons={<DocBulkActionButtons batch_id ={this.state.batch_id} {...props} />}
                            create={true}
                            exporter={false} 
                            {...props}
                            sort={{ field: 'id', order: 'DESC' }}
                            styles ={{"backgroundColor":"green", "zIndex":"5"}}>
                            
                            <Datagrid expand={<SectionForm batch_id={this.state.batch_id} doc_id={this.state.document_id} entity_id={this.state.entity_id} section={this.state.section_name} />} >
                                <GetRowHeader {...props} />
                            </Datagrid>
                        </List>
                        <CustomDialog {...props} showDialog={this.state.dialogLoading} dialogContent={<Loading loadingPrimary={false} loadingSecondary={this.state.dialogLoadingMessage} />} />
                    </ThemeProvider>
                );
            }
            catch{
                console.log('LOADING 2');
                return (
                    <div>LOADING...</div>
                );
            }
        }
        return (
            <div>LOADING...</div>
        );
    }
}

export default DocumentEditList;