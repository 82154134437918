import React from 'react';
import EditFormToolbarActions from "../../../components/EditFormToolbarActions";
import { Edit, SimpleForm, TextInput, BooleanInput, SelectArrayInput, SelectInput, ReferenceInput, ReferenceArrayInput } from 'react-admin';
import { validations } from "./validations";
import {perPageReferenceFields} from "../../../constants";

// needs to be tested
const UsersEdit = props => (
    <>
        <h2>{props.options.label}</h2> 
        <Edit undoable={true} {...props}>
            <SimpleForm toolbar={<EditFormToolbarActions showDeleteButton={true} />}>
                <TextInput label='Username' source="username" fullWidth={true} validate={validations.validateUsername}/>
                <TextInput type='password' label='Password' source="password" fullWidth={true} validate={validations.validatePassword}/>
                <TextInput label='First Name' source="first_name" fullWidth={true} validate={validations.validateFirstName} />
                <TextInput label='Last Name' source='last_name' fullWidth={true} validate={validations.validateLastName}/>
                <BooleanInput label='User is active?' source='active' />
                <BooleanInput label='User is a super user?' source="super_user" initialValue={false}/>
                <ReferenceInput label='Account' source='account' reference='accounts' perPage={perPageReferenceFields} validate={validations.validateAccount} >
                    <SelectInput optionText='name' optionValue='id' />
                </ReferenceInput>
                <ReferenceArrayInput label="Roles"  source='roles' reference='roles' perPage={perPageReferenceFields}>
                    <SelectArrayInput optionText="label" optionValue='id'/>
                </ReferenceArrayInput>
                <ReferenceArrayInput label="Permissions"  source='permissions' reference='firstlink-permissions' perPage={perPageReferenceFields}>
                    <SelectArrayInput optionText="label" optionValue='id'/>
                </ReferenceArrayInput>
                
            </SimpleForm>
        </Edit>
    </>
);

export default UsersEdit;