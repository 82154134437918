import { fetchUtils } from 'react-admin';
import { Api } from "./api";
import { AuthHelper } from "./authHelper";


export const httpClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = AuthHelper.getSessionToken();
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
  };


////// Permissions Management //////
export const componentBasedOnPermission = (permissionToCheck, component) =>
    AuthHelper.getSessionPermissions().indexOf(permissionToCheck) !== -1 ? component : null;

export const userHasPermission = (permissionToCheck) =>
    AuthHelper.getSessionPermissions().indexOf(permissionToCheck) !== -1;


////// authProvider //////
  export const authProvider = {

    // called when the user attempts to log in
    login: ({ username, password }) => {
        //console.log("login");
        return Api.post('/auth/login/', { username, password }, false)
            .then(response => {
                if (response.type === "success") {
                    //console.log("login response", response.result);
                    AuthHelper.saveSession(response.result.token, response.result.permissions);
                } else {
                    console.log("Api error: " + response.type);
                }
            });
    },

    // called when the user clicks on the logout button
    logout: () => {
        //console.log("logout");
        const token = AuthHelper.getSessionToken();

        if (token) {
            return Api.post('/auth/logout/', null, true)
                .then(response => {
                    if (response.type === "success" || response.type === "auth_error") {
                        AuthHelper.removeSession();
                        return Promise.resolve();
                    }
                    else {
                        console.log("Api error: " + response.type);
                        return Promise.reject();
                    }
                });

        } else {
            AuthHelper.removeSession();
            return Promise.resolve();
        }
    },

    // called when the API returns an error
    checkError: ({ status }) => {
        console.log("checkError - status", status);

        if (status === 401 || status === 403) {
            AuthHelper.removeSession();
            return Promise.reject();
        }
        return Promise.resolve();
    },

    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        //console.log("checkAuth");
        return AuthHelper.getSessionToken()
            ? Promise.resolve()
            : Promise.reject();
    },

    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        //console.log("getPermissions");
        const token = AuthHelper.getSessionToken();

        if (!AuthHelper.hasSessionPermissionsDefined() && token) {
            return Api.get('/permissions/', true)
                .then(response => {
                    if (response.type === "success") {
                        //console.log("permissions response", response.result);
                        AuthHelper.saveSession(token, response.result);
                    }
                    else if (response.type === "auth_error") {
                        console.log("Error getting permissions - authentication error");
                        Promise.reject();
                    }
                        else {
                        console.log("Api error: " + response.type);
                    }
                });

            // AuthHelper.saveSession(token, [
            //     "processes-read", "processes-create", "processes-update", "processes-delete", "processes-startjob",
            //     "steps-read", "steps-create", "steps-update", "steps-delete",
            //     "scheduledjobs-read", "scheduledjobs-create", "scheduledjobs-update", "scheduledjobs-delete",
            //     "jobs-read",
            //     "notifications-read",
            //     "tasks-read",
            // ]);
        }
        return AuthHelper.hasSessionPermissionsDefined()
            ? Promise.resolve(AuthHelper.getSessionPermissions()) 
            : Promise.reject();
    },
};