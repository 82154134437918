import React from 'react';
import { Edit, SimpleForm, FormDataConsumer, TextInput, NumberInput, BooleanInput } from 'react-admin';
import StepTypeInputArea from "./StepTypeInputArea";
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import { validations } from "./validations";
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";


const StepEdit = props => (
    <>
        <Edit undoable={false} {...props}>
            <SimpleForm toolbar={<EditFormToolbarActions showDeleteButton={false/*userHasPermission("steps-delete")*/} />}>
                <BackButton {...props} action = {"edit"} />
                <PageTitle {...props}>Edit</PageTitle>
                <TextInput source="title" fullWidth={true} validate={validations.validateTitle} />
                <TextInput multiline source="description" fullWidth={true} validate={validations.validateDescription} />
                <TextInput source="outputs" validate={validations.validateOutputs} />
                <BooleanInput source="reads_data_from_staging" initialValue={false} />
                <TextInput multiline source="configuration" fullWidth={true} validate={validations.validateConfiguration} />

                <FormDataConsumer>
                    {formDataProps => (
                        <StepTypeInputArea {...formDataProps} {...props} />
                    )}
                </FormDataConsumer>
                <TextInput source="td_name" />
                <TextInput source="td_aux_name" />
                <TextInput source="aws_log_group" />
            </SimpleForm>
        </Edit>
    </>
);

export default StepEdit;