import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  AutocompleteInput,
  ShowButton,
  EditButton,
  ReferenceInput,
  SearchInput,
  Filter,
  Button,
  useDataProvider,
} from "react-admin";
import DocBulkActionButtons from "../../components/DocBulkActionButtons";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../constants/styleOverride";
import ClearFiltersButton from "../../components/ClearFiltersButton";
import ListToolbarActions from "../../components/ListToolbarActions";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { JSONViewer } from "react-json-editor-viewer";
import { Drawer } from "@material-ui/core";
import MigrateButton from "../../components/MigrateButton";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

const ImportMappingFilter = (props) => {
  return (
    <div>
      <ClearFiltersButton
        {...props}
        filters={{ source_code: "", batch_id: "", status: "" }}
      />
      <Filter {...props}>
        <ReferenceInput
          label="Source"
          source="source_code"
          reference="process-source-codes"
          alwaysOn
        >
          <AutocompleteInput optionText="code" />
        </ReferenceInput>
        <SearchInput
          source="name"
          placeholder="Search by name"
          alwaysOn
        ></SearchInput>
      </Filter>
    </div>
  );
};

const CustomDialog = (props) => {
  const history = useHistory();
  const [showWarning, setShowWarning] = useState(false);

  const handleClick = (event, record) => {
    event.preventDefault();
    setShowWarning(true);
  };

  const handleClose = () => {
    setShowWarning(false);
  };

  const handleConfirm = () => {
    setShowWarning(false);
    history.push(`/import-mappings/${props.record.id}/`);
  };

  const handleEdit = () => {
    history.push(`/import-mappings/${props.record.id}/`);
  };

  if (props.record?.lastEditUser) {
    return (
      <>
        <Dialog open={showWarning} onClose={true}>
          <DialogTitle>Another user may be editing this document</DialogTitle>
          <DialogContent>
            {props.record?.lastEditUser != "" && (
              <DialogContentText>
                Last Editor: {props.record?.lastEditUser}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button label="Cancel" onClick={handleClose} color="primary" />
            <Button label="Edit" onClick={handleConfirm} color="primary" />
          </DialogActions>
        </Dialog>
        <EditButton label="Edit" onClick={handleClick} />
      </>
    );
  } else {
    return (
      <Button label="Edit" startIcon={<EditIcon />} onClick={handleEdit} />
    );
  }
};

const CustomList = (props) => {
  const useStyles = makeStyles({
    main: {
      backgroundColor: "green",
    },
  });

  const classes = useStyles(props);

  const [recordSelected, setRecordSelected] = useState();
  const dataProvider = useDataProvider();

  const handleClick = (id) => {
    if (typeof id === "string") {
      dataProvider.getOne("import-mappings", { id: id }).then(({ data }) => {
        setRecordSelected(data);
      });
    }
  };

  const handleClose = () => {
    setRecordSelected(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <h2>{props.options.label}</h2>
      <div>
        <List
          filters={<ImportMappingFilter />}
          actions={<ListToolbarActions showCreateButton={true} />}
          bulkActionButtons={
            <DocBulkActionButtons {...props} classes={{ main: classes.main }} />
          }
          exporter={false}
          {...props}
          sort={{ field: "id", order: "DESC" }}
        >
          <Datagrid rowClick={(id) => handleClick(id)}>
            <TextField label="Name" source="name" />
            <TextField label="Last Editor" source="lastEditUser" />
            <CustomDialog />
            <MigrateButton
              {...props}
              resource="Import Mapping"
              endpoint="/import-mappings/migrate/"
            />
          </Datagrid>
        </List>
        {recordSelected && (
          <Drawer anchor="right" open={true} onClose={handleClose} {...props}>
            <div style={{ width: "50vw", margin: "1vw" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {recordSelected.name && <h2>{recordSelected.name}</h2>}
                <div>
                  <CustomDialog record={recordSelected} />
                </div>
              </div>
              <JSONViewer data={recordSelected} collapsible />
            </div>
          </Drawer>
        )}
      </div>
    </ThemeProvider>
  );
};

const ImportMappingsList = (props) => {
  return <CustomList {...props}></CustomList>;
};

export default ImportMappingsList;
