import React from 'react';
import MappingSectionInput from '../../MappingSectionInput';

const Notes = (props) => {

    return (
        <div>
            <MappingSectionInput field='longTextAR' required={true} {...props} />
            <MappingSectionInput field='languageCode' required={true} {...props} />
            <MappingSectionInput field='translationCode' {...props} />
            <MappingSectionInput field='category' {...props} />
            <MappingSectionInput field='label' {...props} />
            <MappingSectionInput field='longText' {...props} />
            <MappingSectionInput field='effectiveDate' {...props} />
            <MappingSectionInput field='quantity' {...props} />
            <MappingSectionInput field='start' {...props} />
            <MappingSectionInput field='end' {...props} />
            <MappingSectionInput field='active' required={true} {...props} />
            <MappingSectionInput field='validated' required={true} {...props} />
        </div>
    );
};

export default Notes;