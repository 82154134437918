import {
    required,
    maxLength
} from 'react-admin';

export const validations = {
    validateName: [required(), maxLength(1050)],
    validateEntityNameType: [required()],
    validateActive: [required()],
    validateInsertSource: [required()]
}