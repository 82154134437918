import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

import { Api } from "../../helpers/api";
import { useNotify } from "ra-core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button } from "react-admin";

const MigrateButton = (props) => {
  const notify = useNotify();
  const resource = props.resource;
  const endpoint = props.endpoint;
  let recordName = "";
  let environment = window.location.href.includes(".dev.") ? "PROD" : "DEV";

  if (props.record.name) {
    recordName = props.record.name
  } else if (props.record.code) {
    recordName = props.record.code
  } else if (props.record.source_code) {
    recordName = props.record.source_code
  }

  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  function handleClick(e) {
    console.log(props.record);
    e.stopPropagation();
    setShowDialog(!showDialog);
  }

  function handleMigrate(record) {
    setShowDialog(false);
    setLoading(true);
    Api.post(endpoint, record).then((response) => {
      setLoading(false);
      if (response.type === "success") {
        notify(`${resource} migrated successfully`);
      } else {
        notify(response.error);
      }
    });
  }

  if (loading) return <span style={{ color: "#3f51b5" }}>Migrating...</span>;

  return (
    <>
      <Dialog open={showDialog} onClose={handleClick}>
        <DialogTitle>
          <span style={{ fontWeight: "bold" }}>
            Are you sure you want to migrate {recordName} to {environment}
            environment?
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to{" "}
            <span style={{ fontWeight: "bold" }}>permanently migrate</span> the{" "}
            <span style={{ fontWeight: "bold" }}>
              {resource} - {recordName}
            </span>{" "}
            to the production environment. You can't undo this.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            label="Cancel"
            onClick={handleClick}
            style={{ alignSelf: "center", marginRight: "1rem", color: "red" }}
          />
          <Button
            label="MIGRATE"
            onClick={() => handleMigrate(props.record)}
            style={{ alignSelf: "center", marginRight: "1rem" }}
          />
        </DialogActions>
      </Dialog>

      <Button
        label={!loading ? `Migrate to ${environment}` : "Migrating..."}
        disabled={loading}
        variant="outlined"
        onClick={(e) => handleClick(e)}
      />
    </>
  );
};

MigrateButton.propTypes = {
  values: PropTypes.object,
};

export default MigrateButton;
