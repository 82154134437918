import React from 'react';
import { TextInput, NumberInput, ReferenceArrayInput, SimpleFormIterator, ReferenceInput, ArrayInput, AutocompleteInput, SelectInput, SelectArrayInput, BooleanInput, DateInput } from 'react-admin';
import { validations } from './validations';

const Relations = (props) => {
    let section = props.section;

    return (
    <div>
        <ArrayInput source='references' label='References' validate={validations.validateReferences} >
            <SimpleFormIterator>
                <TextInput label='Reference' key={Math.random()} validate={validations.validateReference} />
            </SimpleFormIterator>
        </ArrayInput>
        { 'location' in props &&
            <div>
                <TextInput source='nameAR' fullWidth={true} label='Name AR' validate={validations.validateNameAR} />
                <TextInput source='typeAR' label='Type AR' validate={validations.validateTypeAR} />
            </div>
        }
        <ReferenceInput source='languageCode' label='Language Code' reference='enumerations-language-codes' allowEmpty={true} >
            <AutocompleteInput optionText="label" optionValue="id" />
        </ReferenceInput>

        { section === 'directorshipsParent' &&
            <ReferenceInput source='countryCode' label='Country Code' reference='enumerations-country-codes' allowEmpty={true} >
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
        }

        { (section === 'relationshipsChild' || section === 'relationshipsParent') &&
            <ReferenceInput source='type' label='Type' reference='enumerations-relation-types' validate={validations.validateRelationType} >
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
        }

        { (section === 'directorshipsChild' || section === 'directorshipsParent') &&
            <ReferenceInput source='type' label='Type' reference='enumerations-entity-types' allowEmpty={true} >
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
        }

        { (section === 'ownershipsChild' || section === 'ownershipsParent') &&
            <ReferenceInput source='type' label='Ownership Class' reference='enumerations-ownership-classes' fullWidth={true} validate={validations.validateOwnershipClass} >
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
        }

        { (section === 'connectionsParent') &&
            <ReferenceInput source='type' label='Type' reference='enumerations-connection-types' validate={validations.validateConnectionType} >
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>
        }

        { (section === 'ownershipsParent' || section === 'ownershipsChild') &&
            <div>
                <div style={{display: 'flex', justifyContent: 'flex-start', columnGap: '30px'}}>
                    <NumberInput style={{width:"200px"}} source='shareholding' label='Shareholding %' min={0} max={100} step={0.001} />
                    <NumberInput style={{width:"200px"}} source='directShareholding' label='Direct Shareholding %' min={0} max={100} step={0.001} />
                    <NumberInput style={{width:"200px"}} source='capitalshare' label='Capital Share %' min={0} max={100} step={0.001} />
                    <NumberInput style={{width:"200px"}} source='directCapitalshare' label='Direct Capital Share %' min={0} max={100} step={0.001} />
                    <BooleanInput source='exact' label='Exact?' />
                </div>
                <NumberInput style={{width:"200px"}} source='numberOfShares' label='Number of shares' min={0} max={999999999999} />
                <div style={{display: 'flex', justifyContent: 'flex-start', columnGap: '30px'}}>
                    <NumberInput style={{width:"300px"}} source='capitalshareValue' label='Amount of capital share owned' min={0.1} max={999999999999.99} />
                    <ReferenceInput source='currencyCode' label='Currency code' reference='enumerations-currency-codes' allowEmpty={true} >
                        <SelectInput optionText='label' />
                    </ReferenceInput>
                </div>
            </div>
        }
        
        { (section === 'directorshipsParent' || section === 'directorshipsChild') &&
            <>
                <ArrayInput source='functions' label='Functions' allowEmpty={true} validate={validations.validateFunctions} >
                    <SimpleFormIterator>
                        <ReferenceInput source='functionCode' label='Function code' reference='enumerations-function-codes' validate={validations.validateFunctionCode} >
                            <SelectInput optionText='label' />
                        </ReferenceInput>
                        <ReferenceInput source='type' label='type' reference='enumerations-board-types' validate={validations.validateBoardType} >
                            <SelectInput optionText='label' />
                        </ReferenceInput>
                        <ReferenceInput source='extension' label='extension' reference='enumerations-function-extensions' allowEmpty={true} >
                            <SelectInput optionText='label' />
                        </ReferenceInput>
                        <ReferenceArrayInput source='businessAreas' label='businessAreas' reference='enumerations-business-areas' allowEmpty={true} validate={validations.validateBussinessAreas} >
                            <SelectArrayInput optionText='label' />
                        </ReferenceArrayInput>
                        <TextInput source='description' label='Description' allowEmpty={true} validate={validations.validateDescription} />
                    </SimpleFormIterator>
                </ArrayInput>
                <ReferenceInput source='signatureCode' label='Signature code' reference='enumerations-signature-codes' allowEmpty={true} fullWidth={true} >
                    <SelectInput optionText='label' />
                </ReferenceInput>
                <ReferenceInput source='signatureRestrictionCode' label='Signature restriction code' reference='enumerations-signature-restriction-codes' allowEmpty={true} fullWidth={true}>
                    <SelectInput optionText='label' />
                </ReferenceInput>
            </>
        }
        <div style={{display: 'flex', justifyContent: 'flex-start', columnGap: '30px'}}>
            <DateInput source='start' label='Start Date' />
            <DateInput source='end' label='End Date' />
        </div>
        <BooleanInput source='active' initialValue={true} label='Active?' validate={validations.validateActive} />
    </div>
    );
            
};

export default Relations;