import * as React from "react";
import PropTypes from 'prop-types';
import cronstrue from 'cronstrue'

const CronFriendlyField = ({ source, record = {} }) => 
    <span>{record[source] ? cronstrue.toString(record[source].replace(/,/g, ' ').replace(/!/g, ',')) : record[source]}</span>;

CronFriendlyField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default CronFriendlyField;