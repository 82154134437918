import {
    required,
    maxLength,
    regex,
} from 'react-admin';

export const validations = {
    validateSelection: [required()],
    validateText: [required()],
};
