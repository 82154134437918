import React from 'react';
import { Create, SimpleForm, TextInput, TextField, ReferenceInput, SelectInput, BooleanInput, useDataProvider, Loading, Error, useCreateController } from 'react-admin';
import { perPageReferenceFields } from '../../constants';
import { validations } from './validations';

const UserPermissionsCreate = props => {

    var service_classes = [{'id': 'WorldboxService', 'name': 'WorldboxService'}, {'id': 'ICPService', 'name': 'ICPService'}, 
    {'id': 'DnBService', 'name': 'DnBService'}, {'id': 'EllisphereService', 'name': 'EllisphereService'}]

    return (
        <Create undoable={true} {...props}>
            <SimpleForm redirect="list">
                <TextInput label='Permission value' source='value' fullWidth={true} validate={validations.validateCreateValue} />
                <TextInput label='Permission label' source='label' fullWidth={true} validate={validations.validateCreateLabel} />
            </SimpleForm>
        </Create>
    );
}

export default UserPermissionsCreate;