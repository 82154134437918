import { AuthHelper } from "./authHelper";

export class Api {

    static get = async (path, withAuthentication = false) => {
        const request = new Request(process.env.REACT_APP_API_BASE + path, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        if (withAuthentication) {
            const token = AuthHelper.getSessionToken();
            request.headers.append('Authorization', `Bearer ${token}`);
        }

        const response = await fetch(request)
            .catch ((error) => {
                console.error('Error on fetch:', error);
                return null;
            });

        return await this.processResponse(response);
    }

    static delete = async(path, body, withAuthentication = false) => {
        const request = new Request(process.env.REACT_APP_API_BASE + path, {
            body: JSON.stringify(body),
            method: 'DELETE',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        if (withAuthentication) {
            const token = AuthHelper.getSessionToken();
            request.headers.append('Authorization', `Bearer ${token}`);
        }

        const response = await fetch(request)
            .catch ((error) => {
                console.error('Error on fetch:', error);
                return null;
            });

        return await this.processResponse(response);
    }

    static patch = async(path, body, withAuthentication = false) => {
        const request = new Request(process.env.REACT_APP_API_BASE + path, {
            body: JSON.stringify(body),
            method: 'PATCH',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        if (withAuthentication) {
            const token = AuthHelper.getSessionToken();
            request.headers.append('Authorization', `Bearer ${token}`);
        }

        const response = await fetch(request)
            .catch ((error) => {
                console.error('Error on fetch:', error);
                return null;
            });

        return await this.processResponse(response);

    }

    static post = async (path, body, withAuthentication = false) => {
        const request = new Request(process.env.REACT_APP_API_BASE + path, {
            body: JSON.stringify(body),
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        if (withAuthentication) {
            const token = AuthHelper.getSessionToken();
            request.headers.append('Authorization', `Bearer ${token}`);
        }

        const response = await fetch(request)
            .catch ((error) => {
                console.error('Error on fetch:', error);
                return null;
            });

        return await this.processResponse(response);
    }

    static processResponse = async (res) => {
        let json = {}

        if (res == null) {
            return {
                type: "server_error",
            };
        }

        const text = await res.text()
        if (text.length > 0)
        {
            try {
                json = JSON.parse(text);
            }
            catch (e) {
                console.log("Exception parsing response json");
                json = { error: res.statusText };
            }
        }

        if (res.status === 401 || res.status === 403){
            return {
                ...json,
                type: "auth_error"
            };
        }

        if (res.status == 404){
            return {
                ...json,
                type: "not_found"
            };
        }

        if (res.status >= 400 && res.status <= 499){
            return {
                ...json,
                type: "error"
            };
        }

        if (res.status >= 500 && res.status <= 599) {
            return {
                ...json,
                type: "server_error",
                text: text
            };
        }

        // if (res.status === 409)
        // {
        //     json = {
        //         ...json,
        //         type: "conflict",
        //     }
        //     return json;
        // }

        return {
            type:"success",
            result: json
        };
    }
}
