import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "react-admin";

const IntegerInputField = ({
  dialogNumberField,
  numberFieldParams,
  setNumberCounter,
}) => {
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleChange = (e) => {
    if (
      e.target.value > parseInt(e.target.max) ||
      e.target.value < parseInt(e.target.min)
    ) {
      e.target.value = e.target.value.slice(-1);
    }
    setNumberCounter(e.target.value);
  };

  return (
    <div style={{ display: "flex" }}>
      <DialogContentText style={{ margin: "auto" }}>
        {dialogNumberField}
      </DialogContentText>
      <input
        style={{
          display: "flex",
          margin: "auto",
          width: "30%",
          padding: "5px",
        }}
        type="number"
        pattern={`\\b\\d\\b|\\b${numberFieldParams.max}\\b`}
        onChange={handleChange}
        onKeyDown={blockInvalidChar}
        defaultValue={numberFieldParams.defaultValue}
        max={numberFieldParams.max}
        min={numberFieldParams.min}
        step={numberFieldParams.step}
      />
    </div>
  );
};

const ButtonWithDialog = ({
  variant,
  color,
  className,
  style,
  startIcon,
  endIcon,
  buttonLabel,
  dialogTitleMessage,
  dialogContentMessage,
  confirmButtonLabel,
  onConfirm,
  customOnClick = () => {},
  buttonLoadingLabel,
  icon,
  dialogNumberField,
  numberFieldParams,
}) => {
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [numberCounter, setNumberCounter] = useState(
    parseInt(numberFieldParams?.defaultValue)
  );

  function handleClick(e) {
    customOnClick();
    setShowDialog(!showDialog);
  }

  function handleConfirm() {
    setShowDialog(!showDialog);
    if (numberCounter) {
      onConfirm(numberCounter);
      setLoading(false);
    } else {
      onConfirm();
    }
  }

  function handleCancel(e) {
    e.stopPropagation();
    setShowDialog(!showDialog);
    setLoading(false);
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Dialog open={showDialog} onClose={handleCancel}>
        <DialogTitle>
          <span style={{ fontWeight: "bold" }}>{dialogTitleMessage}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContentMessage}</DialogContentText>
          {dialogNumberField && (
            <IntegerInputField
              dialogNumberField={dialogNumberField}
              numberFieldParams={numberFieldParams}
              setNumberCounter={setNumberCounter}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            label="Cancel"
            onClick={(e) => {
              handleCancel(e);
            }}
            style={{ alignSelf: "center", marginRight: "1rem", color: "red" }}
          />
          <Button
            label="Confirm"
            onClick={() => handleConfirm()}
            style={{ alignSelf: "center", marginRight: "1rem", color: "green" }}
          />
        </DialogActions>
      </Dialog>
      {icon ? (
        <img
          src={icon}
          style={style ?? null}
          className={className ?? null}
          variant={variant ?? "contained"}
          color={color ?? "primary"}
          onClick={(e) => handleClick(e)}
        />
      ) : (
        <Button
          label={loading ? buttonLoadingLabel : buttonLabel}
          disabled={loading}
          style={style ?? null}
          className={className ?? null}
          variant={variant ?? "contained"}
          color={color ?? "primary"}
          startIcon={startIcon}
          endIcon={endIcon}
          onClick={(e) => handleClick(e)}
        />
      )}
    </>
  );
};

ButtonWithDialog.propTypes = {
  values: PropTypes.object,
};

export default ButtonWithDialog;
