import React from 'react';
import { Edit, SimpleForm, ArrayInput, TextInput, SimpleFormIterator, SelectArrayInput } from 'react-admin';
import EditFormToolbarActions from "../../components/EditFormToolbarActions";
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";
import { validations } from './validations';

const ImportMappingFunctionCreate = props => {
    const types = [
        { id: "String", name: "String" },
        { id: "Integer", name: "Integer" },
        { id: "Double", name: "Double" },
        { id: "Boolean", name: "Boolean" },
        { id: "Date", name: "Date" },
        { id: "DateDayFirst", name: "DateDayFirst" }
    ]
    return (
        <Edit undoable={false} {...props}>
            <SimpleForm toolbar={<EditFormToolbarActions showDeleteButton={false/*userHasPermission("steps-delete")*/} />}>
                <BackButton {...props} action={"edit"} />
                <PageTitle {...props}>Edit</PageTitle>
                <TextInput source="name" fullWidth={true} validate={validations.validateName} />
                <TextInput source="description" multiline={true} rows={5} fullWidth={true} validate={validations.validateDescription} />
                <ArrayInput source="params">
                    <SimpleFormIterator inline>
                        <TextInput label="Value" source="value" helperText={false} validate={validations.validateParam} />
                    </SimpleFormIterator>
                </ArrayInput>
                <SelectArrayInput {...props}
                    label="Type"
                    optionText="name"
                    optionValue="name"
                    source="types"
                    choices={types}
                    helperText="Accepted value types"
                />

            </SimpleForm>
        </Edit>)


};

export default ImportMappingFunctionCreate;