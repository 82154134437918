import React from 'react';
import { 
        TextInput, 
        BooleanInput, 
        ReferenceInput, 
        AutocompleteInput, 
        DateInput,
        ArrayInput,
        SimpleFormIterator,
        NumberInput,
        SelectInput
} from 'react-admin';
import { validations } from './validations';

const Transactions = (props) => {
    const ratings = [
        {id: 1, rating: '1= transaction without issues'}, 
        {id: 5, rating: '5= not rated transaction'}, 
        {id: 6, rating: '6= transaction with minimal issues'}, 
        {id: 7, rating: '7= transaction with moderate issues'}, 
        {id: 8, rating: '8= transaction with strong issues'}, 
        {id: 9, rating: '9= transaction with maximal issues'}
    ];

    return(
        
        <div>
            { 'location' in props && 
                <ArrayInput source="itemsAR" label='itemsAR' validate={validations.validateItemsAR}>
                    <SimpleFormIterator>
                        <TextInput label='item' />
                    </SimpleFormIterator>
                </ArrayInput>
            }

            <ReferenceInput source='type' label='type' reference='enumerations-transaction-types' validate={validations.validateType} >
                    <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>

            <div style={{display: 'flex', justifyContent: 'flex-start', columnGap: '30px'}}>
                <TextInput source="id" label="id" validate={validations.validateId} />
                <TextInput source="reference" label="reference" validate={validations.validateReference} />
            </div>

            <ReferenceInput source='currencyCode' label='currencyCode' reference='enumerations-currency-codes' validate={validations.validateCurrencyCode}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>

            <div style={{display: 'flex', justifyContent: 'flex-start', columnGap: '30px'}}>
                <NumberInput source='value' label='value' min={0.1} max={9999999999999.9} />
                <SelectInput source='rating' label='rating' choices={ratings} optionText='rating' optionValue='id' validate={validations.validateRating} />
            </div>

            <ReferenceInput source='languageCode' label='languageCode' reference='enumerations-language-codes' allowEmpty={true}>
                <AutocompleteInput optionText="label" optionValue="id" />
            </ReferenceInput>

            <div style={{display: 'flex', justifyContent: 'flex-start', columnGap: '30px'}}>
                <TextInput source="relatedParty" label="relatedParty" validate={validations.validateRelatedParty} />
                <TextInput source="remarks" label="remarks" validate={validations.validateRemarks} />
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-start', columnGap: '30px'}}>
                <DateInput source='start' label='Start Date' validate={validations.validateStart} />
                <DateInput source='update' label='Update Date' />
                <DateInput source='end' label='End Date' />
            </div>
            
            <BooleanInput source='active' initialValue={true} label='Active?' validate={validations.validateActive} />
            <BooleanInput source='validated' initialValue={true} label='Validated?' validate={validations.validateValidated} />
        </div>
        
        );
};

export default Transactions;