import React from 'react';
import { Datagrid, EditButton, List, TextField, Filter, ReferenceInput, SelectInput, ReferenceField, AutocompleteInput } from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import { perPageReferenceFields } from "../../constants";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import ClearFiltersButton from "../../components/ClearFiltersButton";

const PropertyNameFilter = (props) => {
  return(
    <div>
      <ClearFiltersButton {...props} filters={{property_name: '', source_id: '', id:''}}/>
      <Filter {...props}>
        <ReferenceInput label="Property Name" source="property_name" reference="export-mapping-delivery-property-names" perPage={perPageReferenceFields} alwaysOn>
          <SelectInput optionText="property_name" optionValue="property_name" />
        </ReferenceInput>
        <ReferenceInput label="Delivery Code" source="delivery_id" reference="deliveries" perPage={perPageReferenceFields} alwaysOn>
          <AutocompleteInput optionText="code" />
        </ReferenceInput>
        <ReferenceInput label="Label" source="label" reference="export-mapping-delivery-labels" perPage={perPageReferenceFields} alwaysOn>
          <AutocompleteInput optionText="label" suggestionLimit={20}/>
        </ReferenceInput>
      </Filter>
    </div>
  );
}

const ExportMappingsDeliveriesList = props => (
  <ThemeProvider theme={theme}>
    <h2>{props.options.label}</h2>
    <List filters={<PropertyNameFilter />} bulkActionButtons={<DocBulkActionButtons {...props} />} exporter={false} {...props}
      actions={<ListToolbarActions showCreateButton={true} />}>
      <Datagrid>
        <TextField source="property_name" sortable={false} />
        <ReferenceField label="Delivery id" source="delivery_id" reference="deliveries" link={false} sortable={false}>
          <TextField source="code" />
        </ReferenceField>
        <TextField source="label" sortable={false} />
        <TextField source="value1" sortable={false} />
        <TextField source="value2" sortable={false} />
        <TextField source="value3" sortable={false} />
        <TextField source="value4" sortable={false} />
        <TextField source="value5" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  </ThemeProvider>
);

export default ExportMappingsDeliveriesList;
