import React from 'react';
import {Datagrid, EditButton, List, TextField, Filter, ReferenceInput,AutocompleteInput, SelectInput} from 'react-admin';
import ListToolbarActions from "../../components/ListToolbarActions";
import { perPageReferenceFields } from "../../constants";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";
import ClearFiltersButton from '../../components/ClearFiltersButton';

const PropertyNameFilter = (props) => {
  return(
    <div>
      <ClearFiltersButton {...props} filters={{property_name: '', "import-mapping-labels-labels": ''}}/>
      <Filter {...props}>
          <ReferenceInput label="Property Name" source="property_name" reference="import-mapping-labels-property-names" perPage={perPageReferenceFields} alwaysOn>
            <SelectInput optionText="property_name" optionValue="property_name"/>
          </ReferenceInput>
          <ReferenceInput label="Label" source="id" reference="import-mapping-labels-labels" perPage={perPageReferenceFields} alwaysOn>
            <AutocompleteInput optionText="label" suggestionLimit={20} />
          </ReferenceInput>
      </Filter>
    </div>
  );
}

const ImportMappingLabelsList = props => (
  <ThemeProvider theme={theme}>
    <h2>{props.options.label}</h2>
    <List filters={<PropertyNameFilter />}  bulkActionButtons={<DocBulkActionButtons {...props} />} exporter={false} {...props}
          actions={<ListToolbarActions showCreateButton={true}/>}>
      <Datagrid>
        <TextField source="property_name"/>
        <TextField source="language_code"/>
        <TextField source="label"/>
        <TextField source="value1"/>
        <TextField source="value2"/>
        <TextField source="value3"/>
        <TextField source="value4"/>
        <TextField source="value5"/>
        <EditButton/>
      </Datagrid>
    </List>
  </ThemeProvider>
);

export default ImportMappingLabelsList;
