import {minLength, maxLength, required, regex } from 'react-admin';

export const validations = {
  validateTextAR: [minLength(1)],
  validateStatus: [required()],
  validateReference: [minLength(3), maxLength(50)],
  validateUrl: [minLength(6)],
  validateContent: [minLength(1), maxLength(20), regex(/^([SFRKLDZOX]+)$/)],
  validateChapter: [minLength(3), maxLength(150)],
  validateTitle: [minLength(3), maxLength(100)],
  validateNotification: [minLength(1)],
  validateContacts: [minLength(0), maxLength(4)],
  validateDesignation: [required(), minLength(3), maxLength(60)],
  validateTextLines: [required(), minLength(1), maxLength(9)],
  validateTextLine: [required(), minLength(2), maxLength(60)],

};
