import React from 'react';
import { List, Datagrid, TextField, BooleanField, ShowButton, DeleteButton } from 'react-admin';
import { userHasPermission } from "../../helpers/authProvider";
import "../../index.css";
import DocBulkActionButtons from '../../components/DocBulkActionButtons';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../constants/styleOverride";

// const notificationRowStyle = (record, index) => ({
//     backgroundColor: record.viewed ? '' : '#eee'
// });

const NotificationList = props => (
    <ThemeProvider theme={theme}>
        <h2 class="h2-short">{props.options.label}</h2>
        <List bulkActionButtons={<DocBulkActionButtons {...props} />} exporter={false} {...props}
                sort={{ field: 'id', order: 'DESC' }}>
            {/* <Datagrid rowStyle={notificationRowStyle}> */}
            <Datagrid>
                <TextField source="subject" />
                <TextField source="created_at" />

                { userHasPermission("notifications-read") && <ShowButton /> }
            </Datagrid>
        </List>
    </ThemeProvider>
);

export default NotificationList;