import {
    required,
    maxLength,
    regex,
} from 'react-admin';

export const validations = {
    validateTitle: [required(), maxLength(100)],
    validateDescription: maxLength(255),
    validatePriority: [required()],
    validateInitialStep: [required()],
    validateRecipient: [required(), maxLength(500), regex(/^((((?!\.)[\w-_.]*[^.])(@[\w-]+)(\.\w+(\.\w+)?[^.\W]));*)+$/, "Must be one or more valid emails separated by ;")],
};
